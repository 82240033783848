import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

type IProps = {
  id: string
  title: string
  contentText: string | React.ReactNode
  handleClose: () => void
  action: () => void
  open: boolean
}

export default function DeleteDialog({ id, title, contentText, handleClose, action, open }: IProps): React.JSX.Element {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='md'>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText fontWeight='bold' id={id}>
          {contentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={handleClose}>
          Annuler
        </Button>
        <Button variant='contained' type='submit' color='error' onClick={action}>
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  )
}
