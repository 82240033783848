import { Box, Chip, FormControl, Grid, IconButton, InputLabel, OutlinedInput, Select, TextField } from '@mui/material'
import React, { ChangeEvent, useMemo, useState } from 'react'
import IconAdd from '../../custom-icons/icon-add'

type IProps<T> = {
  values: T[]
  addValue?(valueToAdd: string): void
  removeValue?(valueToAdd: string): void
  label: string
  disabled?: boolean
}
export default function ListInput({
  values,
  label,
  disabled = false,
  addValue,
  removeValue,
}: IProps<string>): React.JSX.Element {
  const [valueToAdd, setValueToAdd] = useState<string>('')
  const labelId: string = useMemo(() => label.replace(/[^a-zA-Z0-9]/g, ''), [label])

  const addLabel = useMemo(() => 'Ajouter '.concat(label.toLowerCase()), [label])

  function isEmpty(): boolean {
    return values.length === 0
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    const target = event.target
    const value = target.value

    setValueToAdd(value)
  }

  function addValueToList(): void {
    if (addValue) {
      addValue(valueToAdd)
      setValueToAdd('')
    }
  }

  function removeValuefromList(value: string): void {
    if (removeValue) {
      removeValue(value)
    }
  }

  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={6}>
        <FormControl sx={{ width: '100%' }}>
          <InputLabel id={'multiple-chip-'.concat(labelId)} shrink={!isEmpty()}>
            {label}
          </InputLabel>
          <Select
            id={'multiple-chip'.concat(labelId)}
            sx={{
              cursor: 'default',
              '& .MuiSelect-select': {
                cursor: 'default',
              },
            }}
            labelId={'multiple-chip-label'.concat(labelId)}
            multiple
            value={values}
            input={isEmpty() ? undefined : <OutlinedInput id='select-multiple-chip' label={label} />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value: string) => (
                  <Chip key={value} label={value} onDelete={() => removeValuefromList(value)} />
                ))}
              </Box>
            )}
            IconComponent='p'
            open={false}
            readOnly
            displayEmpty
            disabled={disabled}
          />
        </FormControl>
      </Grid>
      {!disabled && (
        <Grid item xs={5} display='flex' justifyItems='center'>
          <TextField
            type='string'
            label={addLabel}
            variant='outlined'
            value={valueToAdd}
            onChange={handleChange}
            sx={{ height: '100%' }}
          />
        </Grid>
      )}
      <Grid item xs={1} display='flex' alignItems='center'>
        {!disabled && (
          <IconButton onClick={addValueToList} sx={{ maxHeight: '75%' }}>
            <IconAdd />
          </IconButton>
        )}
      </Grid>
    </Grid>
  )
}
