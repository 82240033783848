import { Typography } from '@mui/material'
import React from 'react'

type IProps = {
  text: string
}

export default function TitleH1({ text }: IProps): React.JSX.Element {
  return (
    <Typography variant='h2' gutterBottom color='primary'>
      <b>{text}</b>
    </Typography>
  )
}
