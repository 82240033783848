import DownloadIcon from '@mui/icons-material/Download'
import { Box, CircularProgress, Paper, Typography } from '@mui/material'
import React, { useContext, useRef, useState } from 'react'
import { ProjectContext } from '../../../core/context/project/project-context'
import { ProjectStatusEnum } from '../../../core/enum/projectStatusEnum'

type IProps = {
  onChange: (modelFile: File) => void

  file: File | undefined
  progress: number
  disabled: boolean
  fileType: string
}

export default function FileInput({ onChange, file, progress, disabled, fileType }: IProps): React.JSX.Element {
  const [isDragOver, setIsDragOver] = useState(false)
  const [isMouseOver, setIsMouseOver] = useState(false)
  const dragEnterCount: React.MutableRefObject<number> = useRef<number>(0)
  const { project } = useContext(ProjectContext)

  function handleChangeFile(event: React.ChangeEvent<HTMLInputElement>): void {
    if (event?.target?.files && event.target.files[0]) {
      onChange(event.target.files[0])
    }
  }

  function stopDefaults(e: any): void {
    e.stopPropagation()
    e.preventDefault()
  }

  const dragEvents =
    disabled && project.status !== ProjectStatusEnum.ARCHIVED
      ? {}
      : {
          onMouseEnter(): void {
            setIsMouseOver(true)
          },
          onMouseLeave: () => {
            setIsMouseOver(false)
          },
          onDragEnter: (e: React.DragEvent) => {
            stopDefaults(e)
            dragEnterCount.current += 1
            if (dragEnterCount.current === 1) {
              setIsDragOver(true)
            }
          },
          onDragLeave: (e: React.DragEvent) => {
            stopDefaults(e)
            dragEnterCount.current -= 1
            if (dragEnterCount.current === 0) {
              // Drag has actually left the container
              setIsDragOver(false)
            }
          },
          onDragOver: stopDefaults,
          onDrop: (e: React.DragEvent) => {
            stopDefaults(e)
            setIsDragOver(false)
            dragEnterCount.current = 0
            const files = e?.dataTransfer?.files
            if (files?.length && files[0]) {
              onChange(e.dataTransfer.files[0])
            }
          },
        }

  function getTypographyForInputFile(): string {
    let label = ''

    if (file) {
      label = "Fichier prêt à l'envoi"
    } else if (!disabled) {
      label = 'Déposez votre fichier ici'
    } else {
      label = "Aucun fichier n'a été chargé"
    }

    return label
  }

  function overOpacity(): number {
    return isMouseOver || isDragOver ? 1 : 0.3
  }

  return (
    <Paper variant='outlined' sx={{ display: 'flex', border: '2px dashed #cccccc', alignItems: 'center' }}>
      <input
        onChange={handleChangeFile}
        style={{ display: 'none' }}
        accept={fileType}
        id='file-upload'
        type='file'
        disabled={disabled}
      />
      <Box
        component='label'
        htmlFor='file-upload'
        {...dragEvents}
        sx={{
          width: '100%',
          p: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: disabled ? '' : 'pointer',
          textAlign: 'center',
          noMouseEvent: {
            pointerEvents: 'none',
          },
          opacity: isMouseOver || isDragOver ? 1 : 1.3,
        }}>
        {progress === -1 ? (
          <Box>
            {!file && <DownloadIcon fontSize='large' />}
            {!file && <Typography>{getTypographyForInputFile()}</Typography>}
          </Box>
        ) : (
          <Box>
            {(progress === 0 || progress === 100) && <DownloadIcon fontSize='large' />}
            {progress !== 100 && file !== undefined && <CircularProgress />}
            {(progress === 0 || progress === 100) && <Typography>{getTypographyForInputFile()}</Typography>}
          </Box>
        )}

        <Typography sx={{ opacity: disabled ? 0 : overOpacity() }}>
          {file ? file.name : '(Cliquer ici pour ouvrir et selectionner)'}
        </Typography>
      </Box>
    </Paper>
  )
}
