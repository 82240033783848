export enum CalculTypeEnum {
  HIGH_CARBON = 'HIGH_CARBON',
  STANDARD = 'STANDARD',
  LOW_CARBON = 'LOW_CARBON',
}

export const calculTypeEnumLabelsOption = [
  { value: CalculTypeEnum.HIGH_CARBON, label: 'Défavorables' },
  { value: CalculTypeEnum.STANDARD, label: 'Standards' },
  { value: CalculTypeEnum.LOW_CARBON, label: 'Optimisées' },
]
