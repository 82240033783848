import { useMemo } from 'react'
import { appConstants } from '../../appConstants'
import { BimModel } from '../../dto/bim-model/bim-model'
import { CodeExtrait } from '../../dto/code-extrait/code-extrait'
import { CodeExtraitCreation } from '../../dto/code-extrait/code-extrait-creation'
import { streamToBlob } from '../../services/file-service'
import { useHttp } from '../use-http'
import { resolveUrl } from '../../services/http-service'
import { BimModelAuthToken } from '../../dto/bim-model/bim-model-auth-token'
import { ModuleTypeEnum } from '../../enum/bimModel/ModuleTypeEnum'
import { CodeExtraitDisplay } from '../../dto/code-extrait/CodeExtraitDisplay'

type BimModelHook = {
  sendBimModel(bimModel: BimModel): Promise<BimModel>
  sendFile(bimModelFile: File, bimModelId: string): Promise<Response>
  fetchBimModelsByProject(projectId: string): Promise<Response>
  fetchBimModelById(bimModelId: string): Promise<BimModel>
  fetchCodeExtraitsForBimModel(bimModelId: string): Promise<CodeExtrait[]>
  fetchBimModelByProjectIdAndPhases(projectId: string, phase: string): Promise<BimModel | undefined>
  postCodeExtraitsForBimModel(bimModelId: string, codeExtraitsCreation: CodeExtraitCreation[]): Promise<void>
  fetchBimModelFile(projectId: string, phase: string): Promise<Blob | undefined>
  fetchTutorialModel(): Promise<File | undefined>
  createBimModelAuthToken(bimModelId: string, bimModelType: ModuleTypeEnum): Promise<BimModelAuthToken>
  fetchBimModelFromAuthToken(bimModelToken: string, bimModelType: ModuleTypeEnum): Promise<Blob | undefined>
  fetchCodeExtraitsFromToken(bimModelToken: string): Promise<CodeExtraitDisplay[]>
  fetchCodeExtraitsFromTokenForBeemShot(bimModelToken: string): Promise<CodeExtraitDisplay[]>
}

export function useBimModel(): BimModelHook {
  const { get, post, postFile } = useHttp()

  return useMemo(
    () => ({
      sendBimModel(bimModel: BimModel): Promise<BimModel> {
        return post(appConstants.apiEndpoints.BIM_MODEL, bimModel)
          .then((r) => r.json())
          .then((data) => BimModel.fromDto(data))
      },
      sendFile(bimModelFile: File, bimModelId: string): Promise<Response> {
        const formData = new FormData()
        formData.append('ifcFile', bimModelFile)
        formData.append('bimModelId', bimModelId)
        return postFile(`${appConstants.apiEndpoints.BIM_MODEL}/file`, formData)
      },
      fetchBimModelsByProject(projectId: string): Promise<Response> {
        return get(`${appConstants.apiEndpoints.BIM_MODEL}/by-project`, [
          {
            key: 'projectId',
            value: projectId,
          },
        ])
      },
      fetchBimModelById(bimModelId: string): Promise<BimModel> {
        return get(appConstants.apiEndpoints.BIM_MODEL, [
          {
            key: 'bimModelId',
            value: bimModelId,
          },
        ])
          .then((r) => r.json())
          .then((data: any) => BimModel.fromDto(data))
      },
      fetchCodeExtraitsForBimModel(bimModelId: string): Promise<CodeExtrait[]> {
        return get(`${appConstants.apiEndpoints.BIM_MODEL}/${bimModelId}/codes-extraits`).then((r) => r.json())
      },
      fetchBimModelByProjectIdAndPhases(projectId: string, phase: string): Promise<BimModel | undefined> {
        return get(`${appConstants.apiEndpoints.BIM_MODEL}/by-project-and-phase`, [
          {
            key: 'projectId',
            value: projectId,
          },
          {
            key: 'phase',
            value: phase,
          },
        ])
          .then((r) => r.json())
          .then((data: any) => BimModel.fromDto(data))
          .catch(() => undefined)
      },
      postCodeExtraitsForBimModel(bimModelId: string, codeExtraitsCreation: CodeExtraitCreation[]): Promise<void> {
        return post(`${appConstants.apiEndpoints.BIM_MODEL}/${bimModelId}/codes-extraits`, codeExtraitsCreation).then(
          () => undefined
        )
      },
      fetchBimModelFile(projectId, phase): Promise<Blob | undefined> {
        return get(`${appConstants.apiEndpoints.BIM_MODEL}/file`, [
          {
            key: 'projectId',
            value: projectId,
          },
          {
            key: 'phase',
            value: phase,
          },
        ]).then((res) => streamToBlob(res))
      },
      fetchTutorialModel(): Promise<File | undefined> {
        return get(`${appConstants.apiEndpoints.BIM_MODEL}/tutorial-file`)
          .then((response) => streamToBlob(response))
          .then((blob: Blob | undefined) => {
            if (blob) {
              return new File([blob], 'Tutoriel', { type: 'application/ifc' })
            } else {
              return undefined
            }
          })
      },
      createBimModelAuthToken(bimModelId: string, bimModelType: ModuleTypeEnum): Promise<BimModelAuthToken> {
        return post(
          resolveUrl(appConstants.apiEndpoints.BIM_MODEL_ID_AUTH_TOKEN, [bimModelId], { bimModelType }),
          undefined
        ).then((response) => response.json())
      },
      fetchBimModelFromAuthToken(bimModelFileToken: string, bimModelType: ModuleTypeEnum): Promise<Blob | undefined> {
        return get(resolveUrl(appConstants.apiEndpoints.BIM_MODEL_AUTH_TOKEN, [], { bimModelFileToken, bimModelType })).then(
          (res) => streamToBlob(res)
        )
      },
      fetchCodeExtraitsFromToken(bimModelToken: string): Promise<CodeExtraitDisplay[]> {
        return get(appConstants.apiEndpoints.BIM_MODEL_PUBLIC_CODES_EXTRAITS, [
          { key: 'bimModelToken', value: bimModelToken },
        ]).then((r) => r.json())
      },
      fetchCodeExtraitsFromTokenForBeemShot(bimModelToken: string): Promise<CodeExtraitDisplay[]> {
        return get(appConstants.apiEndpoints.BIM_MODEL_PUBLIC_CODES_EXTRAITS_FOR_BEEM_SHOT, [
          { key: 'bimModelToken', value: bimModelToken },
        ]).then((r) => r.json())
      },
    }),
    [get, post, postFile]
  )
}
