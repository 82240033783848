import { FolderOutlined } from '@mui/icons-material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { BSCategoriesContext } from '../../../../core/context/beem-shot/BSCategory/BSCategoriesContext'
import { BSItemSubCategory } from '../../../../core/dto/beem-shot/BSItem/BSItemSubCategory'
import { CodeExtrait } from '../../../../core/dto/code-extrait/code-extrait'
import { stringToNumber } from '../../../../core/services/helper-service'
import { CodeDisplayer } from './CodeDisplayer'

type iProps = {
  codesExtraits: CodeExtrait[]
  handleClickAcv?: (codeExtract: CodeExtrait) => void
  selectedCodeExtrait?: CodeExtrait
}

export function ExtractedCodeDisplayer({
  codesExtraits,
  handleClickAcv,
  selectedCodeExtrait,
}: Readonly<iProps>): React.JSX.Element {
  const { bsCategories } = useContext(BSCategoriesContext)
  const [openAccordion, setOpenAccordion] = useState('')
  const [openAccordionChild, setOpenAccordionChild] = useState('')

  const subCategoriesForCodesExtraits = useMemo(() => {
    const acc: Record<string, BSItemSubCategory[]> = {}

    codesExtraits.forEach((x: CodeExtrait) => {
      if (x.category && x.subCategory) {
        if (!acc[x.category.name]) {
          acc[x.category.name] = []
        }
        acc[x.category.name].push(x.subCategory)
      }
    })

    return acc
  }, [codesExtraits])

  useEffect(() => {
    if (selectedCodeExtrait?.category) {
      const ref: any = document.getElementById(selectedCodeExtrait.category.name)
      if (ref) {
        ref.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }
  }, [selectedCodeExtrait])

  useEffect(() => {
    if (selectedCodeExtrait?.category && selectedCodeExtrait?.subCategory) {
      setOpenAccordion(selectedCodeExtrait.category.name)
      setOpenAccordionChild(selectedCodeExtrait?.subCategory.name)
    }
  }, [selectedCodeExtrait])

  function handleAccordionChange(e: string): void {
    if (openAccordion === e) {
      setOpenAccordion('')
      return
    }
    setOpenAccordion(e)
  }

  function handleAccordionChildChange(e: string): void {
    if (openAccordionChild === e) {
      setOpenAccordionChild('')
      return
    }
    setOpenAccordionChild(e)
  }

  function sortCodesExtraits(a: CodeExtrait, b: CodeExtrait): number {
    if (a.order < b.order) {
      return -1
    } else if (a.order > b.order) {
      return 1
    } else {
      return stringToNumber(a.occurence) - stringToNumber(b.occurence)
    }
  }

  return (
    <Box component='div' p={2} borderRadius={5}>
      {bsCategories
        .filter((x) => Object.keys(subCategoriesForCodesExtraits).includes(x.name))
        .map((categorie) => (
          <Accordion
            id={categorie.name}
            key={categorie.name}
            disableGutters
            square
            expanded={openAccordion === categorie.name}
            onChange={() => handleAccordionChange(categorie.name)}
            sx={{
              borderRadius: 6,
              m: 1,
            }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
              sx={{
                border: 0,
              }}>
              <Box
                component={FolderOutlined}
                sx={{
                  mr: 1,
                  color: openAccordion === categorie.name ? 'black' : '#BDBDBD',
                  fontWeight: openAccordion === categorie.name ? 600 : 400,
                }}
              />
              <Typography variant='body1' sx={{ fontWeight: openAccordion === categorie.name ? 600 : 400 }}>
                {categorie.label}
              </Typography>
            </AccordionSummary>

            {categorie.subCategories
              ?.filter((subCategory) =>
                subCategoriesForCodesExtraits[categorie.name]?.some((sc) => sc.name === subCategory.name)
              )
              .map((subCategory, index) => (
                <AccordionDetails key={subCategory.name}>
                  <Accordion
                    id={subCategory.name}
                    elevation={0}
                    square
                    disableGutters
                    expanded={openAccordionChild === subCategory.name}
                    onChange={() => handleAccordionChildChange(subCategory.name)}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='child1-content' id='child1-header'>
                      <Box
                        component={FolderOutlined}
                        sx={{
                          mr: 1,
                          color: openAccordionChild === subCategory.label ? '#08CA8F' : '#BDBDBD',
                          fontWeight: openAccordionChild === subCategory.label ? 600 : 400,
                        }}
                      />
                      <Typography
                        variant='body1'
                        sx={{
                          color: openAccordionChild === subCategory.label ? '#08CA8F' : 'black',
                          fontWeight: openAccordionChild === subCategory.label ? 600 : 400,
                        }}>
                        {subCategory.label}
                      </Typography>
                    </AccordionSummary>

                    {codesExtraits
                      .filter((x) => subCategory.name === x.subCategory?.name)
                      .sort(sortCodesExtraits)
                      .map((codeExtrait) => (
                        <AccordionDetails id={codeExtrait.id} key={codeExtrait.code + codeExtrait.occurence}>
                          <CodeDisplayer
                            highlightBorder={
                              codeExtrait.code + codeExtrait.occurence ===
                              `${selectedCodeExtrait ? selectedCodeExtrait.code + selectedCodeExtrait.occurence : ''}`
                            }
                            codeExtrait={codeExtrait}
                            codeTitle={codeExtrait.descriptionFromCodeAcv || codeExtrait.descriptionFromBimModel}
                            codeColor={codeExtrait.color}
                            handleClickAcv={() => {
                              if (handleClickAcv) handleClickAcv(codeExtrait)
                            }}
                          />
                        </AccordionDetails>
                      ))}
                  </Accordion>
                </AccordionDetails>
              ))}
          </Accordion>
        ))}
    </Box>
  )
}
