import { Alert, Box, Button, CircularProgress, Grid } from '@mui/material'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import NavigationDialog from '../../../components/dialog/navigation-dialog'
import ViewerIFC from '../../../components/ifc-displayer/ViewerIFC'
import { BSIfcDisplayer } from '../../../components/ifc-displayer/bs-ifc-displayer'
import { ViewerTypes } from '../../../components/ifc-displayer/enums/ViewerTypes'
import { SubsetInfo } from '../../../components/ifc-displayer/models/SubsetInfo'
import { CodeReferenceContext } from '../../../core/context/code-acv/CodeReferenceContext'
import { CodeExtraitDisplay } from '../../../core/dto/code-extrait/CodeExtraitDisplay'
import { CodeExtrait } from '../../../core/dto/code-extrait/code-extrait'
import { codeStateEnum } from '../../../core/enum/codeStateEnum'
import { ExtractedTabPanel } from './BSBimModelExtractedCode/ExtractedTabPanel'

type IProps = {
  bimModelFile: File
  reset: () => void
  isTree?: boolean
  analyzeModel: (modelId: number, ifc: ViewerIFC) => void
  codesExtraits: CodeExtrait[]
  setProgress(progress: number): void
  handleClickAcv: (codeExtrait: CodeExtraitDisplay) => void
  handleClickCodeManquant: (codeManquant: SubsetInfo) => void
  handleCodeVariantChange: (codeVariant: codeStateEnum, unhilight?: boolean) => void
  selectedCodeExtrait?: CodeExtrait
  cancelUpload?: () => void
  isTutorialPage?: boolean
  progress: number
}

/**
 * This component analyze the bim model using the analyze function.
 *
 * @param bimModelFile
 * @param reset
 * @param analyzeModel
 * @param codesExtraits
 * @param setProgress
 * @param handleClickAcv
 * @param handleClickCodeManquant
 * @param handleCodeVariantChange
 * @param selectedCodeExtract
 * @constructor
 */

export function BSBimModelAnalyzer({
  bimModelFile,
  reset,
  analyzeModel,
  codesExtraits,
  setProgress,
  handleClickAcv,
  handleClickCodeManquant,
  handleCodeVariantChange,
  selectedCodeExtrait,
  isTree,
  cancelUpload,
  isTutorialPage,
  progress,
}: Readonly<IProps>): React.JSX.Element {
  const { isCodeAcvLoading } = useContext(CodeReferenceContext)

  const [codeState, setCodeState] = useState<codeStateEnum>(codeStateEnum.CODE_ACV)
  const [displayedCodeExtrait, setDisplayedCodeExtrait] = useState<CodeExtrait[]>([])
  const [codeManquantElements, setCodeManquantElements] = useState<SubsetInfo[]>([])
  const [selectedCodeManquantElement, setSelectedCodeManquantElement] = useState<SubsetInfo>()
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const getCodeExtrait = useCallback((): CodeExtrait[] => {
    if (codeState === codeStateEnum.CODE_ACV) {
      return codesExtraits.filter((codeExtrait) => codeExtrait.errors.length === 0)
    } else if (codeState === codeStateEnum.CODE_INVALIDE) {
      return codesExtraits.filter((codeExtrait) => codeExtrait.errors.length > 0)
    } else {
      return []
    }
  }, [codeState, codesExtraits])

  const updateCodeState = useCallback(
    (newState: codeStateEnum, unhighlight?: boolean): void => {
      if (newState === codeStateEnum.CODE_ACV || newState === codeStateEnum.CODE_INVALIDE) {
        setSelectedCodeManquantElement(undefined)
      }
      handleCodeVariantChange(newState, unhighlight)
      setCodeState(newState)
    },
    [handleCodeVariantChange]
  )

  function updateCodeManquantElements(subsets: SubsetInfo[]): void {
    setCodeManquantElements(subsets)
  }

  function updateSelectedCodeManquantElement(subset: SubsetInfo): void {
    handleClickCodeManquant(subset)
    setSelectedCodeManquantElement(subset)
  }

  function handleClickedCodeManquant(subset: SubsetInfo): void {
    if (selectedCodeManquantElement?.id === subset.id) {
      handleClickCodeManquant(subset)
      setSelectedCodeManquantElement(undefined)
    } else {
      updateSelectedCodeManquantElement(subset)
    }
  }

  const updateVariantForCodeExtrait = useCallback(
    (codeExtrait: CodeExtrait): void => {
      if (codeExtrait.errors.length > 0) {
        updateCodeState(codeStateEnum.CODE_INVALIDE)
      } else {
        updateCodeState(codeStateEnum.CODE_ACV)
      }
    },
    [updateCodeState]
  )

  useEffect(() => {
    if (selectedCodeExtrait) {
      updateVariantForCodeExtrait(selectedCodeExtrait)
    }
  }, [selectedCodeExtrait, updateVariantForCodeExtrait])

  useEffect(() => {
    if (selectedCodeManquantElement) {
      updateCodeState(codeStateEnum.CODE_MANQUANT)
    }
  }, [selectedCodeManquantElement, updateCodeState])

  useEffect(() => {
    setDisplayedCodeExtrait(getCodeExtrait())
  }, [codesExtraits, codeState, getCodeExtrait])

  function handleOpen(): void {
    setIsDialogOpen(true)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '500px' }}>
      <Grid container rowSpacing={1} columnSpacing={2}>
        <Grid item xs={5} sm={5}>
          <Grid item sm={12}>
            {!isTutorialPage && codesExtraits?.length === 0 && bimModelFile && progress === 100 && (
              <>
                <Alert
                  severity='error'
                  action={
                    <Button variant='outlined' color='inherit' size='small' onClick={handleOpen}>
                      AIDE
                    </Button>
                  }>
                  Erreur, aucun code renseigné dans la maquette
                </Alert>
                <NavigationDialog handleClose={() => setIsDialogOpen(false)} open={isDialogOpen} />
              </>
            )}
          </Grid>
          <Box
            display='flex'
            flexGrow={1}
            flexShrink={1}
            border={2}
            my={3}
            borderRadius={3}
            justifyContent='flex-start'
            flexDirection='column'
            alignContent='flex-start'
            alignItems='center'
            borderColor='#ECF3F3'
            sx={{ backgroundColor: '#F5F8F8', height: '70vh', overflow: 'auto' }}>
            <ExtractedTabPanel
              displayedCodeExtrait={displayedCodeExtrait}
              handleClickAcv={handleClickAcv}
              handleClickedCodeManquant={handleClickedCodeManquant}
              selectedCodeExtrait={selectedCodeExtrait}
              selectedCodeManquantElement={selectedCodeManquantElement}
              codeManquantElements={codeManquantElements}
              updateCodeState={updateCodeState}
              variant={codeState}
              progress={progress}
            />
          </Box>
        </Grid>
        <Grid item xs={7}>
          {!isCodeAcvLoading && bimModelFile ? (
            <BSIfcDisplayer
              file={bimModelFile}
              reset={reset}
              onChangeModel={analyzeModel}
              setExternalProgress={setProgress}
              type={ViewerTypes.CODE_VERIFICATION}
              codesExtraits={codesExtraits}
              showFileName
              setSelectedCodeExtrait={handleClickAcv}
              setCodeManquantElements={updateCodeManquantElements}
              setSelectedCodeManquantElement={updateSelectedCodeManquantElement}
              cancelUpload={cancelUpload}
              height='70vh'
            />
          ) : (
            <CircularProgress />
          )}
        </Grid>
      </Grid>
    </Box>
  )
}
