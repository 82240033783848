import { Box, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material'
import React from 'react'
import FlatRates from '../../../../../../core/dto/calculation/flat-rates'

type ItemRateDataFormProps = {
  fieldName: keyof FlatRates
  value: string
  isChecked: boolean
  rank: string
  labelToCheck: string
  checkBoxReadOnly?: boolean
  disabled?: boolean
  title: string
  onChange: (field: keyof FlatRates, value: string | boolean, isCheckbox: boolean) => void
}

export default function ItemRateDataForm({
  rank,
  title,
  labelToCheck,
  isChecked,
  value,
  fieldName,
  onChange,
  checkBoxReadOnly,
  disabled,
}: ItemRateDataFormProps): React.JSX.Element {
  return (
    <Box display='flex' justifyContent='center' sx={{ margin: '40px 0' }}>
      <Box sx={{ width: '20%' }}>
        <Typography component='span'>{rank} </Typography>
        <Typography component='span'>{title}</Typography>
      </Box>
      <TextField
        label={
          <div>
            kgeqCO<sub>2</sub>/m²
          </div>
        }
        type='number'
        disabled
        variant='outlined'
        InputLabelProps={{
          shrink: true,
        }}
        value={isChecked ? '' : value}
      />
      <Box
        display='flex'
        justifyContent='center'
        sx={{
          marginLeft: '40px',
        }}>
        <FormControlLabel
          value='end'
          disabled={checkBoxReadOnly}
          control={
            <Checkbox
              checked={isChecked}
              onChange={(_, checked) => onChange(fieldName, checked, true)}
              disabled={disabled}
            />
          }
          label={labelToCheck}
          labelPlacement='end'
        />
      </Box>
    </Box>
  )
}
