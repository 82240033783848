import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { pagesUrl } from '../../core/appConstants'
import { OrganizationContext } from '../../core/context/organization/organization-context'
import { UserContext, UserStore } from '../../core/context/user/user-context'
import { isMegaUser } from '../../core/services/authentication-service'
import SubscriptionCard from './subscription-card'

export default function SubscriptionCards(): React.JSX.Element {
  const { organization } = useContext(OrganizationContext)
  const { user }: any = React.useContext<UserStore>(UserContext)

  return (
    <Box sx={{ pl: 20, pr: 20 }}>
      {organization ? (
        <>
          <Typography fontWeight='bold' variant='h5' fontSize={20} sx={{ mt: 4, mb: 2 }}>
            MES ABONNEMENTS
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={3}>
              <SubscriptionCard
                count={organization.projectCounter}
                max={organization.maxProjectCount}
                libelle='Nombre de projets BIM'
              />
            </Grid>
            <Grid item xs={3}>
              <SubscriptionCard
                count={organization.rseeProjectCounter}
                max={organization.maxRseeProjectCount}
                libelle='Nombre de documents RSEE'
              />
            </Grid>
            <Grid item xs={3}>
              <SubscriptionCard
                count={organization.licenceCounter}
                max={organization.maxLicenceCount}
                libelle='Nombre de collaborateurs'
              />
            </Grid>

            <Grid item xs={3}>
              <SubscriptionCard
                count={organization.invitedCounter}
                max={organization.maxInvitedCount}
                libelle="Nombre d'invités"
              />
            </Grid>
            {isMegaUser(user) && (
              <>
                <Grid item xs={4} />
                <Grid item xs={4}>
                  <Link to={pagesUrl.MY_ORGANIZATION_INVITE_USER_PAGE}>
                    <Button fullWidth variant='contained'>
                      Ajouter un collaborateur
                    </Button>
                  </Link>
                </Grid>
              </>
            )}
          </Grid>
        </>
      ) : (
        <Box>
          <Typography>Vous n'avez pas d'organisation</Typography>
        </Box>
      )}
    </Box>
  )
}
