import { Box, CssBaseline, ThemeProvider, createTheme, useTheme } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pagesUrl } from '../../../core/appConstants'
import { AppNavBarContext, NavbarTypeEnum } from '../../../core/context/nav-bar/AppNavBarContext'
import { resolveUrl } from '../../../core/services/http-service'
import { BSCalculationForm } from './BSCalculationForm/BSCalculationForm'

export function BSCreateCalculationPage(): React.JSX.Element {
  const { setTypeNavBar, setPreviousUrl, setStepHeader } = useContext(AppNavBarContext)
  const { bsProjectId } = useParams()

  const theme = useTheme()
  const modifiedTheme = createTheme({
    ...theme,
    palette: {
      ...theme.palette,
      background: {
        ...theme.palette.background,
        default: '#ffffff',
        paper: '#ffffff',
      },
    },
  })

  useEffect(() => {
    setTypeNavBar(NavbarTypeEnum.STEP_HEADER)
    setPreviousUrl(resolveUrl(pagesUrl.BEEM_SHOT_BIM_MODEL_CONTROL, [bsProjectId]))
    setStepHeader(2)
  }, [setPreviousUrl, setStepHeader, setTypeNavBar])

  return (
    <ThemeProvider theme={modifiedTheme}>
      <CssBaseline />
      <Box>
        <BSCalculationForm />
      </Box>
    </ThemeProvider>
  )
}
