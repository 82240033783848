import { Grid } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CodesExtraitsListContext, CodesExtraitsListStore } from '../../core/context/code-acv/codes-extraits-list-context'
import { CodeExtrait } from '../../core/dto/code-extrait/code-extrait'
import CodeExtraitListWithViewer from '../code-extrait-list-with-viewer/code-extrait-list-with-viewer'
import ViewerIFC from './ViewerIFC'

import { CachingHelper } from './helpers/CachingHelper'

export default function FullScreenViewer(): React.JSX.Element | null {
  const { fullScreenToken } = useParams()
  const { codesExtraits } = useContext<CodesExtraitsListStore>(CodesExtraitsListContext)
  const ifcDisplayerRef = useRef<HTMLDivElement>(null)
  const ifcDisplayerParentRef = useRef<any>(null)
  const [viewerIfc, setViewerIfc] = useState<ViewerIFC | undefined>(undefined)
  const [selectedCodeExtrait, setSelectedCodeExtrait] = useState<CodeExtrait | undefined>(selectNextUnfilledCode())

  /**
   * Handle the unload event of the window, this will delete the fullscreen token from the cache.
   */
  useEffect(() => {
    async function handleUnload(): Promise<void> {
      if (fullScreenToken) {
        await CachingHelper.deleteFullScreen(fullScreenToken)
        localStorage.removeItem(fullScreenToken)
      }
    }

    window.addEventListener('unload', handleUnload)

    return () => {
      window.removeEventListener('unload', handleUnload)
    }
  }, [])

  /**
   * Handle the storage event of the window, this will update the fullscreen token from the cache.
   */
  window.addEventListener('storage', async () => {
    if (localStorage.getItem(fullScreenToken ?? '') === 'true') {
      const data = await CachingHelper.getFullScreen(fullScreenToken ?? '')
      if (viewerIfc && data) {
        if (data.selectedCodeExtrait) {
          viewerIfc?.manager.subsetsManager.highlightCodeExtrait(data.selectedCodeExtrait)
        } else if (viewerIfc.manager.subsetsManager.selectedCodeExtrait)
          viewerIfc?.manager.subsetsManager.unHighlightCodeExtrait(viewerIfc.manager.subsetsManager.selectedCodeExtrait)
      }
    }
    localStorage.setItem(fullScreenToken ?? '', 'false')
  })

  /**
   * Handle the load event of the window, this will load the fullscreen token from the cache.
   */
  useEffect(() => {
    if (fullScreenToken) {
      CachingHelper.getFullScreen(fullScreenToken).then((fullScreen) => {
        if (fullScreen && fullScreen.selectedCodeExtrait) {
          const newCode = codesExtraits.find((code) => code.id === fullScreen.selectedCodeExtrait?.id)
          setSelectedCodeExtrait(newCode)
          localStorage.setItem(fullScreenToken ?? '', 'false')
        }
      })
    }
  }, [fullScreenToken])

  function selectNextUnfilledCode(): CodeExtrait | undefined {
    const nextCode = codesExtraits.find((item) => !item.filled)
    if (nextCode && viewerIfc) {
      return nextCode
    } else if (nextCode) {
      return nextCode
    } else {
      return undefined
    }
  }

  return (
    <Grid item xs={12}>
      <CodeExtraitListWithViewer
        selectedCodeExtrait={selectedCodeExtrait}
        setSelectedCodeExtrait={setSelectedCodeExtrait}
        viewer={viewerIfc}
        setViewerIFC={setViewerIfc}
        ifcDisplayerRef={ifcDisplayerRef}
        ifcDisplayerParentRef={ifcDisplayerParentRef}
        fullscreenButton={false}
        isInFullscreenTab
        fullScreenParamToken={fullScreenToken}
      />
    </Grid>
  )
}
