import { PhaseEnum } from '../../enum/phaseEnum'
import { stringToDate } from '../../services/date-service'

export class ProjectPhase {
  phase: PhaseEnum
  endingDate: Date | undefined
  description = ''
  currentPhase = false

  constructor(phase: PhaseEnum, endingDate: Date) {
    this.phase = phase
    this.endingDate = endingDate
  }

  static fromDto(phase: any): ProjectPhase {
    if (phase) {
      const endingDate = stringToDate(phase.endingDate) || new Date()
      const newProjectPhase = new ProjectPhase(phase.phase, endingDate)
      newProjectPhase.description = phase.description
      newProjectPhase.currentPhase = phase.currentPhase
      return newProjectPhase
    } else {
      return new ProjectPhase(PhaseEnum.ESQ, new Date())
    }
  }
}
