import { Sprite, SpriteMaterial, Texture, Vector3 } from 'three'
import { LabelColor } from './LabelColor'
import { LabelStyle } from './LabelStyle'
import { viewerConstants } from '../../../constants/ViewerConstants'

/**
 * Generator for text and label sprites
 */
export default class TextLabels {
  /**
   * Create a sprite in 3d space
   * @param message text
   * @param x x-position in 3d space
   * @param y y-position in 3d space
   * @param z z-position in 3d space
   * @param parameters canvas parameters
   * @returns a 3d canvas sprite
   */
  static makeTextSprite(message: string, x: number, y: number, z: number, parameters: LabelStyle): Sprite {
    const fontface = parameters.fontface ?? 'Arial'
    const fontsize = parameters.fontsize ?? 28
    const borderThickness = parameters.borderThickness ?? 0 //4;
    const borderColor = parameters.borderColor ?? undefined //{ r:0, g:0, b:0, a:0.0 };
    const fillColor = parameters.fillColor ?? { r: 255, g: 255, b: 255, a: 0.0 }
    const textColor = parameters.textColor ?? { r: 25, g: 25, b: 25, a: 1.0 }
    const padding = parameters.padding ?? 0
    const radius = parameters.radius ?? 0 // 6;
    const vAlign = parameters.vAlign ?? 'center'
    const hAlign = parameters.hAlign ?? 'center'

    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')

    // set a large-enough fixed-size canvas
    canvas.width = 3600
    canvas.height = 1800
    if (context == null) return new Sprite()
    context.font = `${fontsize}px ${fontface}`
    context.textBaseline = 'alphabetic'
    context.textAlign = 'left'

    // get size data (height depends only on font size)
    const lines: string[] = message.split('\n')
    const widths: Array<number> = []
    let numberOfRows = 0
    numberOfRows = TextLabels.getNumberOfRows(context, lines, numberOfRows, widths)
    widths.sort((a, b) => (a < b ? 1 : -1))
    const textWidth = widths[0]

    /*
        // need to ensure that our canvas is always large enough
        // to support the borders and justification, if any
        // Note that this will fail for vertical text (e.g. Japanese)
        // The other problem with this approach is that the size of the canvas
        // varies with the length of the text, so 72-point text is different
        // sizes for different text strings.  There are ways around this
        // by dynamically adjust the sprite scale etc. but not in this demo...
        // need to re-fetch and refresh the context after resizing the canvas
        */

    // find the center of the canvas and the half of the font width and height
    // we do it this way because the sprite's position is the CENTER of the sprite
    const cx = canvas.width / 2
    const cy = canvas.height / 2
    // assign position and also adjust position for the justification
    const ty = TextLabels.checkVerticalAlignment(vAlign, fontsize)
    const tx = TextLabels.checkHorizontalAlignment(hAlign, textWidth)

    // the DESCENDER_ADJUST is extra height factor for text below baseline: g,j,p,q. since we don't know the true bbox
    const borderProperties = {
      borderThickness,
      borderColor,
      fillColor,
    }
    TextLabels.roundRect(
      context,
      cx - tx,
      cy + ty + 0.255 * fontsize,
      textWidth + padding,
      fontsize * viewerConstants.measurement.DESCENDER_ADJUST * numberOfRows + padding,
      radius,
      borderProperties
    )

    // text color.  Note that we have to do this AFTER the round-rect as it also uses the "fillstyle" of the canvas
    context.fillStyle = TextLabels.getCanvasColor(textColor)
    context.lineWidth = 3
    context.strokeStyle = TextLabels.getCanvasColor(textColor)
    for (let i = 0; i < lines.length; i += 1) {
      context.fillText(lines[i], cx - tx + padding, cy + ty - fontsize * i + padding)
      context.strokeText(lines[i], cx - tx + padding, cy + ty - fontsize * i + padding)
    }

    // canvas contents will be used for a texture
    const texture = new Texture(canvas)
    texture.needsUpdate = true
    const alpha = TextLabels.computeAlphaValue(borderThickness, fillColor)
    const spriteMaterial = new SpriteMaterial({
      map: texture,
      alphaTest: alpha,
    })
    // create the sprite
    const sprite = new Sprite(spriteMaterial)
    sprite.renderOrder = 1
    // we MUST set the scale to 2:1.  The canvas is already at a 2:1 scale,
    // but the sprite itself is square: 1.0 by 1.0
    // Note also that the size of the scale factors controls the actual size of the text-label
    sprite.scale.set(4, 2, 1)

    // set the sprite's position.  Note that this position is in the CENTER of the sprite
    sprite.position.set(x, y, z)

    return sprite
  }

  /**
   * creates a sprite object to indicate point highlight
   * @param point position in 3d space
   * @returns sprite object
   */
  static createHighLight(point: Vector3): Sprite {
    // create the sprite
    const sprite = new Sprite(viewerConstants.measurement.textLabel.highLightMaterial)
    sprite.position.set(point.x, point.y, point.z)
    sprite.scale.set(0.3, 0.3, 0.3)
    sprite.renderOrder = 1
    return sprite
  }

  /**
   * Check vertical alignment
   * @param vAlign alignment type as string (top, bottom, center)
   * @param fontsize hight of font
   * @returns position value in y-axis
   */
  private static checkVerticalAlignment(vAlign: string, fontsize: number): number {
    let ty = fontsize / 2.0
    // then adjust for the justification
    if (vAlign === 'bottom') {
      ty = 0
    } else if (vAlign === 'top') {
      ty = fontsize
    }
    return ty
  }

  /**
   * Check horizontal alignment
   * @param hAlign alignment type as string (right, left, center)
   * @param width width of canvas
   * @returns position value in x-axis
   */
  private static checkHorizontalAlignment(hAlign: string, width: number): number {
    let tx = width / 2.0
    // then adjust for the justification
    if (hAlign === 'left') {
      tx = width
    } else if (hAlign === 'right') {
      tx = 0
    }
    return tx
  }

  /**
   * Define boundary and style of canvas 2d element
   * @param ctx context
   * @param xCoord x-coordinate
   * @param yCoord y-coordinate
   * @param width width
   * @param height hight
   * @param radius radius
   * @param borderProperties properties of canvas (border color, border thickness, fill color)
   */
  private static roundRect(
    ctx: any,
    xCoord: number,
    yCoord: number,
    width: number,
    height: number,
    radius: number,
    borderProperties: any
  ): void {
    const fillColor = borderProperties.fillColor
    const borderColor = borderProperties.borderColor
    const borderThickness = borderProperties.borderThickness
    // no point in drawing it if it isn't going to be rendered
    if (!fillColor && !borderColor) return

    const x = xCoord - borderThickness + radius
    const y = yCoord + borderThickness + radius
    const w = width + borderThickness * 2 + radius * 2
    const h = height + borderThickness * 2 + radius * 2

    ctx.beginPath()
    ctx.moveTo(x + radius, y)
    ctx.lineTo(x + w - radius, y)
    ctx.quadraticCurveTo(x + w, y, x + w, y - radius)
    ctx.lineTo(x + w, y - h + radius)
    ctx.quadraticCurveTo(x + w, y - h, x + w - radius, y - h)
    ctx.lineTo(x + radius, y - h)
    ctx.quadraticCurveTo(x, y - h, x, y - h + radius)
    ctx.lineTo(x, y - radius)
    ctx.quadraticCurveTo(x, y, x + radius, y)
    ctx.closePath()
    ctx.lineWidth = borderThickness

    // background color
    // border color

    // if the fill color is defined, then fill it
    if (fillColor) {
      ctx.fillStyle = TextLabels.getCanvasColor(fillColor)
      ctx.fill()
    }

    if (borderThickness > 0 && borderColor) {
      ctx.strokeStyle = TextLabels.getCanvasColor(borderColor)
      ctx.stroke()
    }
  }

  /**
   * Get color in string formate from rgba object
   * @param color given color
   * @returns color in string format
   */
  private static getCanvasColor(color: any): string {
    return `rgba(${color.r},${color.g},${color.b},${color.a})`
  }

  private static computeAlphaValue(borderThickness: number, fillColor: LabelColor): number {
    if ((borderThickness <= 0 || !borderThickness || !borderThickness) && fillColor.a > 0.16) return fillColor.a - 0.16
    // 0.1 as tolerance for alpha test
    else return 0
  }

  private static getNumberOfRows(context: any, lines: string[], numberOfRows: number, widths: Array<number>): number {
    let rowNumbers = numberOfRows
    for (const element of lines) {
      const lineMetrics = context.measureText(element)
      widths.push(lineMetrics.width)
      rowNumbers += 1
    }
    return rowNumbers
  }
}
