import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import { Box } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { pagesUrl } from '../../../core/appConstants'
import { BSProjectContext } from '../../../core/context/beem-shot/BSProject/BSProjectContext'
import { BSVariantContext } from '../../../core/context/beem-shot/BSVariant/BSVariantContext'
import { AppNavBarContext } from '../../../core/context/nav-bar/AppNavBarContext'
import { AppSideBarContext } from '../../../core/context/side-bar/side-bar-context'
import { BSProjectDetail } from '../components/BSProjectDetails'
import { CustomTabPanel, TabPanelProps } from '../components/CustomTabPanel'
import { BSDashboard } from './BSDashboard'

export function BeemShotProjectDetailPage(): React.JSX.Element {
  const { setShowAppSide } = useContext(AppSideBarContext)
  const { setTypeNavBar, setPreviousUrl, setStepHeader, setShowProjectStatus, setTitle } = useContext(AppNavBarContext)
  const { bsProject } = useContext(BSProjectContext)
  const { selectedVariant } = useContext(BSVariantContext)

  const location = useLocation()

  const [tabs, setTabs] = React.useState(0)

  useEffect(() => {
    const defaultTab = location.state?.defaultTab
    if (typeof defaultTab === 'number') {
      setTabs(defaultTab)
    }
    setPreviousUrl(pagesUrl.BEEM_SHOT_HOME)
    setShowProjectStatus(true)
    setTitle(bsProject?.name ?? '')

    return () => {
      setShowProjectStatus(false)
      setTitle('BEEM SHOT')
    }
  }, [location, bsProject?.name, setPreviousUrl, setShowProjectStatus, setStepHeader, setTitle, setTypeNavBar])

  useEffect(() => {
    setShowAppSide(true)
  }, [setShowAppSide, selectedVariant])

  function handleChange(event: React.SyntheticEvent, newValue: number): void {
    setTabs(newValue)
  }

  const tabTest: TabPanelProps[] = [
    {
      id: 1,
      Icon: FormatListBulletedIcon,
      label: 'Général',
      chips: undefined,
      content: <BSProjectDetail handleChange={handleChange} />,
    },
    {
      id: 2,
      Icon: FormatListBulletedIcon,
      label: 'Tableau de bord',
      chips: undefined,
      content: <BSDashboard />,
    },
  ]

  return (
    <Box>
      <CustomTabPanel panels={tabTest} tabs={tabs} handleChange={handleChange} />
    </Box>
  )
}
