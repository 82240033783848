import { Box, Button, CircularProgress, Container, Grid, Link, Paper, TextField, Typography } from '@mui/material'
import { FormEvent, useContext, useState } from 'react'
import { DomainNameValidatorContext } from '../../core/context/organization/domain-name-validator-context'
import { UserContext } from '../../core/context/user/user-context'
import { SupportForm } from '../../core/dto/support/support-form'
import { OrganizationRoleEnum } from '../../core/enum/user/organization-role-enum'
import { isEmailValid, isPhoneValid } from '../../core/services/user-service'
import AccountHeadingForm from '../../pages/account-page/account-heading-form'
import PhoneInput from '../inputs/phone-input/phone-input'

type IProps = {
  onSubmit(userForm: SupportForm): Promise<void>
}

export default function SupportFormComponent({ onSubmit }: IProps): React.JSX.Element {
  const { isDomainNameInvalid } = useContext(DomainNameValidatorContext)
  const { user } = useContext(UserContext)
  const [supportForm, setSupportForm] = useState<SupportForm>({
    firstName: user?.givenName ?? '',
    lastName: user?.familyName ?? '',
    phone: user?.phone ?? '',
    email: user?.email ?? '',
    organizationName: user?.organizationName ?? '',
    userOrganizationRole: user?.role ?? OrganizationRoleEnum.NORMAL_USER,
    message: '',
  })
  const [error, setError] = useState<any>({})
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  function validate(): boolean {
    const newError: Record<string, any> = {}
    let isValid = true

    if (!supportForm.firstName) {
      newError.firstName = 'Le prénom doit être renseigné'
      isValid = false
    }
    if (!supportForm.lastName) {
      newError.lastName = 'Le nom doit être renseigné'
      isValid = false
    }
    if (!supportForm.phone) {
      newError.phone = 'Le numéro de téléphone doit être renseigné'
      isValid = false
    } else if (!isPhoneValid(supportForm.phone)) {
      newError.phone =
        'Le numéro de téléphone doit être composé uniquement de chiffres et faire 10 caractères ou commencer par "+" et faire 12 caractères'
      isValid = false
    }
    if (!supportForm.email) {
      newError.email = "L'email doit être renseigné"
      isValid = false
    } else if (!isEmailValid(supportForm.email)) {
      newError.email = "L'adresse email ne respecte pas la forme classique : email@complement.fin"
      isValid = false
    } else if (isDomainNameInvalid(supportForm.email)) {
      newError.email = "Time To Beem est un service B2B. Merci d'utiliser une adresse email professionnelle"
      isValid = false
    }
    if (!supportForm.message) {
      newError.message = 'Le message doit être renseigné'
      isValid = false
    }

    setError(newError)
    return isValid
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const target = event.target

    setSupportForm({
      ...supportForm,
      [target.id]: target.value,
    })
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault()

    if (!validate()) {
      return
    }
    supportForm.email = supportForm.email.toLowerCase()
    setIsSubmitting(true)
    onSubmit(supportForm).finally(() => setIsSubmitting(false))
  }

  return (
    <>
      <Grid item xs={12}>
        <AccountHeadingForm title='CONTACTER LE SUPPORT' />
      </Grid>
      <Container maxWidth='md'>
        <Box component='form' onSubmit={handleSubmit}>
          <Paper variant='outlined' sx={{ my: { xs: 1, md: 1 }, p: { xs: 2, md: 2 } }}>
            <Grid container rowSpacing={3} columnSpacing={3}>
              <Grid item xs={12}>
                <AccountHeadingForm title='FORMULAIRE DE CONTACT' />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id='firstName'
                  variant='outlined'
                  label='Prénom'
                  value={supportForm?.firstName}
                  fullWidth
                  onChange={handleChange}
                  error={!!error.firstName}
                  helperText={error.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id='lastName'
                  variant='outlined'
                  label='Nom'
                  value={supportForm?.lastName}
                  fullWidth
                  onChange={handleChange}
                  error={!!error.lastName}
                  helperText={error.lastName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <PhoneInput
                  form={supportForm}
                  error={error}
                  handleChange={handleChange}
                  required
                  variant='outlined'
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id='email'
                  variant='outlined'
                  label='Email'
                  value={supportForm?.email}
                  fullWidth
                  onChange={handleChange}
                  error={!!error.email}
                  helperText={error.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id='message'
                  label='Message'
                  multiline
                  fullWidth
                  rows={6}
                  value={supportForm?.message}
                  onChange={handleChange}
                  error={!!error.message}
                  helperText={error.message}
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
              {isSubmitting ? (
                <CircularProgress />
              ) : (
                <Button variant='contained' type='submit'>
                  Envoyer
                </Button>
              )}
            </Box>
          </Paper>
          <Typography paragraph>
            Si le problème persiste, nous sommes là pour vous aider. Planifiez une session de support de 15 minutes via{' '}
            <Link href='https://calendly.com/g-lescoulier/15min' underline='always'>
              {'ce lien '}
            </Link>
            pour une assistance personnalisée.
          </Typography>
        </Box>
      </Container>
    </>
  )
}
