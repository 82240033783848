import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined'
import HeadphonesOutlinedIcon from '@mui/icons-material/HeadphonesOutlined'
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined'
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined'
import { Box, CSSObject, DialogContentText, Divider, Drawer, IconButton, Link, styled, Theme, useTheme } from '@mui/material'
import Fab from '@mui/material/Fab'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as ReactLogoTTB } from '../../../assets/LogoTTB_green.svg'
import { ReactComponent as ReactMiniLogoTTB } from '../../../assets/LogoTTB_mini_green.svg'
import { pagesUrl } from '../../core/appConstants'
import { AppSideBarContext, UrlStateEnum } from '../../core/context/side-bar/side-bar-context'
import { ProjectTypeEnum } from '../../core/enum/project/projectTypeEnum'
import { resolveUrl } from '../../core/services/http-service'
import './app-side-navbar.scss'
import DividerSideBar from './divider-side-navbar'
import { IconSideBar } from './icone-side-navbar'
import { InfoDialog } from '../dialog/InfoDialog'
import { OrganizationContext } from '../../core/context/organization/organization-context'
import { IconBeemMaster } from '../custom-icons/icon-beem-master'

const openedMixin = (theme: Theme, drawerWidth: number): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  background: '#070F27',
  scrollbarWidth: 'none',
  boxShadow: theme.shadows[5],
  borderRadius: theme.shape.borderRadius,
  height: `calc(100vh - 16px * 2)`,
  margin: theme.spacing(1),
  padding: theme.spacing(1),
  zIndex: 1200,
})

const openedFabMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('left', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  position: 'fixed',
  background: '#070F27',
  zIndex: 1300,
})

const closedFabMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('left', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  background: '#070F27',
  position: 'fixed',
  zIndex: 1300,
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  background: '#070F27',
  width: `calc(${theme.spacing(7)} + 1px)`,
  scrollbarWidth: 'none',
  boxShadow: theme.shadows[5],
  borderRadius: theme.shape.borderRadius,
  margin: theme.spacing(1),
  padding: theme.spacing(1),
  paddingBottom: theme.spacing(4),
  height: `calc(100vh - 16px * 2)`,
  zIndex: 1200,
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
}))

export function AppSideBar(): React.JSX.Element {
  const theme = useTheme()
  const navigate = useNavigate()
  const { organization } = useContext(OrganizationContext)
  const { showAppSide, drawerWidth, openAppSide, setOpenAppSide } = useContext(AppSideBarContext)
  const [isBeemMasterWarningOpen, setIsBeemMasterWarningOpen] = useState(false)
  const [isBeemPilotWarningOpen, setIsBeemPilotWarningOpen] = useState(false)

  function goToBeemMaster(): void {
    if (organization?.beemMasterOption) {
      navigate(resolveUrl(pagesUrl.PROJECTS_PAGE, [], { type: ProjectTypeEnum.BIM }))
    } else {
      setIsBeemMasterWarningOpen(true)
    }
  }

  function goToBeemPilot(): void {
    if (organization?.beemPilotOption) {
      navigate(resolveUrl(pagesUrl.PROJECTS_PAGE, [], { type: ProjectTypeEnum.RSEE }))
    } else {
      setIsBeemPilotWarningOpen(true)
    }
  }

  return (
    <Box hidden={!showAppSide} visibility={showAppSide ? 'visible' : 'hidden'}>
      <Fab
        color='primary'
        size='small'
        sx={{
          position: 'absolute',
          top: 25,
          left: openAppSide ? 230 : `calc(${theme.spacing(6)} + 1px)`,
          zIndex: 1300,
          width: 40,
          height: 40,
          ...(openAppSide
            ? {
                ...openedFabMixin(theme),
                '& .MuiDrawer-paper': openedFabMixin(theme),
              }
            : {
                ...closedFabMixin(theme),
                '& .MuiDrawer-paper': closedFabMixin(theme),
              }),
        }}>
        <IconButton onClick={() => setOpenAppSide(!openAppSide)} sx={{ color: 'white' }}>
          {openAppSide ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Fab>
      <Drawer
        variant='permanent'
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          whiteSpace: 'nowrap',
          boxSizing: 'border-box',
          overflowX: 'hidden',
          ...(openAppSide
            ? {
                ...openedMixin(theme, drawerWidth),
                '& .MuiDrawer-paper': openedMixin(theme, drawerWidth),
                height: 0,
              }
            : {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
                height: 0,
              }),
        }}>
        <DrawerHeader
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            pt: 3,
            pb: 3,
          }}>
          {openAppSide ? <ReactLogoTTB /> : <ReactMiniLogoTTB />}
        </DrawerHeader>
        <Box display='flex' flexDirection='column' height='100%' justifyContent='space-between' sx={{ color: 'white' }}>
          <Box>
            <DividerSideBar label='Projet Beem' open={openAppSide} />

            <IconSideBar
              action={() => navigate(resolveUrl(pagesUrl.BEEM_SHOT_HOME, []))}
              open={openAppSide}
              icon={<DonutSmallOutlinedIcon />}
              label={UrlStateEnum.BEEM_SHOT}
            />

            <IconSideBar action={goToBeemMaster} open={openAppSide} icon={<IconBeemMaster />} label='Beem Master' />
            <InfoDialog
              isOpen={isBeemMasterWarningOpen}
              title="Vous n'êtes pas encore un 'BEEM Master'"
              content={
                <>
                  <DialogContentText>
                    Le module Beem Master permet aux ingénieurs environnementaux d'utiliser votre estimation comme base de
                    leur calcul.
                  </DialogContentText>
                  <DialogContentText sx={{ pt: 2 }}>Pour un essai gratuit, contactez nous:</DialogContentText>
                  <DialogContentText sx={{ textAlign: 'center', pt: 3 }}>
                    <Link>contact@timetobeem.com</Link>
                  </DialogContentText>
                </>
              }
              handleClose={() => setIsBeemMasterWarningOpen(false)}
            />

            <DividerSideBar label='Projet Rsee' open={openAppSide} />

            <IconSideBar action={goToBeemPilot} open={openAppSide} icon={<AnalyticsOutlinedIcon />} label='Beem Pilot' />
            <InfoDialog
              isOpen={isBeemPilotWarningOpen}
              title="Vous n'êtes pas encore un 'BEEM Pilot'"
              content={
                <>
                  <DialogContentText>Le module Beem Pilot génère des tableaux de bord à partir d'un RSEE.</DialogContentText>
                  <DialogContentText>Il permet de piloter l'impact global de tous vos projets.</DialogContentText>
                  <DialogContentText sx={{ pt: 2 }}>Pour un essai gratuit, contactez nous:</DialogContentText>
                  <DialogContentText sx={{ textAlign: 'center', pt: 3 }}>
                    <Link>contact@timetobeem.com</Link>
                  </DialogContentText>
                </>
              }
              handleClose={() => setIsBeemPilotWarningOpen(false)}
            />

            <Divider sx={{ color: 'white' }} />
          </Box>

          <Box sx={{ pb: 3 }}>
            <DividerSideBar label='Aide' open={openAppSide} />

            <IconSideBar
              action={() => setOpenAppSide(!openAppSide)}
              open={openAppSide}
              icon={<QuizOutlinedIcon />}
              label='FAQ'
            />

            <IconSideBar
              action={() => navigate(resolveUrl(pagesUrl.SUPPORT_PAGE, [], {}))}
              open={openAppSide}
              icon={<HeadphonesOutlinedIcon />}
              label='Contacter le support'
            />
            <IconSideBar
              action={() => setOpenAppSide(!openAppSide)}
              open={openAppSide}
              icon={<SupportOutlinedIcon />}
              label='Tutoriels'
            />
          </Box>
        </Box>
      </Drawer>
    </Box>
  )
}
