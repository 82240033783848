import { Typography } from '@mui/material'
import React from 'react'
import { TextInput } from '../../../components/inputs/text-input/text-input'

interface IProps {
  form: Record<string, any>
  handleChange(event: React.ChangeEvent<HTMLInputElement>): void
  errors: Record<string, string | undefined>
}

export function BSBimModelInviter({ form, handleChange, errors }: Readonly<IProps>): React.JSX.Element {
  return (
    <>
      <Typography variant='h5' fontWeight={600}>
        Inviter un acteur à paramétrer et importer la maquette numérique
      </Typography>
      <Typography variant='body1'>
        Vous souhaitez inviter un acteur à paramétrer et importer la maquette numérique sur ce projet, sélectionnez un
        collaborateur de votre organisation ou renseignez l’adresse e-mail de votre invité puis envoyez l’invitation. Vous
        recevrez une notification lorsque la maquette sera importée.
      </Typography>
      <Typography variant='subtitle1'>A partir d'une adresse e-mail</Typography>
      <TextInput
        fieldName='email'
        label='nom@entreprise.fr'
        handleChange={handleChange}
        form={form}
        errors={errors}
        margin='none'
      />
    </>
  )
}
