import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined'
import QuestionMark from '@mui/icons-material/QuestionMark'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import { Typography } from '@mui/material'
import React, { ReactElement } from 'react'

type TitleLogoProps = {
  title: string
  logo: {
    id: string
  }
}

export default function TitleLogo(props: TitleLogoProps): React.JSX.Element {
  const { title, logo } = props

  const renderIcon = (id: string): ReactElement => {
    switch (id) {
      case 'account':
        return (
          <AccountBoxOutlinedIcon
            sx={{
              fontSize: '40px',
              marginRight: '16px',
            }}
          />
        )
      case 'support':
        return (
          <SupportAgentIcon
            sx={{
              fontSize: '40px',
              marginRight: '16px',
            }}
          />
        )
      default:
        return (
          <QuestionMark
            sx={{
              fontSize: '40px',
              marginRight: '16px',
            }}
          />
        )
    }
  }

  return (
    <Typography variant='h1' display='flex' flexDirection='row' alignItems='center'>
      {logo && renderIcon(logo?.id as string)}
      <Typography fontSize='48px' fontWeight='bold'>
        {title}
      </Typography>
    </Typography>
  )
}
