import { typeCodeAcv } from '../../enum/typeCodeAcvEnum'
import CodeCustom from '../code-acv-custom/code-custom'
import CodeReference from '../code-acv/code-reference'
import { CodeExtrait } from '../code-extrait/code-extrait'
import { Material } from '../material/material'

export class CodeCompletion {
  id: string | undefined = undefined
  variantId: string | undefined
  code = ''
  name = ''
  occurence = ''
  lot = ''
  sousLot = ''
  lotLabel = ''
  sousLotLabel = ''
  // amount vs number ? Je ne suis pas au clair de pourquoi il y a 2 champs. Je pense que c'est parce que dans
  // l'interface, quand la personne enlève le matériaux, puis le remet, on voulait que l'utilisateur retrouve son amount
  // Que le amount n'ai pas été écrasé
  number = 0
  descriptionFromCodeAcv = ''
  parentCodeExtraitId = ''
  unit: string | undefined = undefined
  amount = 0
  reuse = false
  material: Material | undefined = undefined
  materialId: string | undefined = undefined
  order = ''
  type: typeCodeAcv | undefined
  hypothesis = ''
  awsFileKey: string | undefined
  filename: string | undefined
  fileType: string | undefined

  static fromDto(codeCompletionDto: any): CodeCompletion {
    const codeCompletion = new CodeCompletion()

    codeCompletion.id = codeCompletionDto.id
    codeCompletion.variantId = codeCompletionDto.variantId
    codeCompletion.code = codeCompletionDto.code
    codeCompletion.name = codeCompletionDto.name
    codeCompletion.occurence = codeCompletionDto.occurence
    codeCompletion.lot = codeCompletionDto.lot
    codeCompletion.sousLot = codeCompletionDto.sousLot
    codeCompletion.sousLotLabel = codeCompletionDto.sousLotLabel
    codeCompletion.number = codeCompletionDto.number
    codeCompletion.descriptionFromCodeAcv = codeCompletionDto.descriptionFromCodeAcv
    codeCompletion.parentCodeExtraitId = codeCompletionDto.parentCodeExtraitId
    codeCompletion.unit = codeCompletionDto.unit
    codeCompletion.amount = codeCompletionDto.amount
    codeCompletion.reuse = codeCompletionDto.reuse
    codeCompletion.material = codeCompletionDto.material ? Material.fromCodeDto(codeCompletionDto.material) : undefined
    codeCompletion.materialId = codeCompletionDto.materialId
    codeCompletion.order = codeCompletionDto.order
    codeCompletion.type = codeCompletionDto.type
    codeCompletion.hypothesis = codeCompletionDto.hypothesis
    codeCompletion.awsFileKey = codeCompletionDto.awsFileKey
    codeCompletion.filename = codeCompletionDto.filename
    codeCompletion.fileType = codeCompletionDto.fileType

    return codeCompletion
  }

  static fromAcv(codeAcv: CodeReference, parentCode: CodeExtrait | CodeCompletion, variantId: string): CodeCompletion {
    const codeCompletion = new CodeCompletion()

    codeCompletion.code = codeAcv.code
    codeCompletion.name = codeAcv.name
    codeCompletion.variantId = variantId
    codeCompletion.occurence = parentCode.occurence
    codeCompletion.lot = codeAcv.lot
    codeCompletion.sousLot = codeAcv.sousLot
    codeCompletion.lotLabel = codeAcv.lotLabel
    codeCompletion.sousLotLabel = codeAcv.sousLotLabel
    codeCompletion.descriptionFromCodeAcv = codeAcv.description
    codeCompletion.order = codeAcv.order
    codeCompletion.number = CodeCompletion.getNumber(parentCode)
    codeCompletion.parentCodeExtraitId = parentCode.id ? parentCode.id : ''
    codeCompletion.parentCodeExtraitId = parentCode.id ? parentCode.id : ''

    return codeCompletion
  }

  static fromAcvCustom(codeCustom: CodeCustom, parentCode: CodeExtrait | CodeCompletion, variantId: string): CodeCompletion {
    const codeCompletion = new CodeCompletion()

    codeCompletion.code = codeCustom.id
    codeCompletion.name = codeCustom.name
    codeCompletion.variantId = variantId
    codeCompletion.occurence = parentCode.occurence
    codeCompletion.lot = codeCustom.lot
    codeCompletion.sousLot = codeCustom.sousLot
    codeCompletion.descriptionFromCodeAcv = codeCustom.description
    codeCompletion.number = CodeCompletion.getNumber(parentCode)
    codeCompletion.parentCodeExtraitId = parentCode.id ? parentCode.id : ''
    return codeCompletion
  }

  private static getNumber(parentCode: CodeExtrait | CodeCompletion): number {
    if ('overiddenQuantities' in parentCode && parentCode.overiddenQuantities.number !== undefined) {
      return parentCode.overiddenQuantities.number
    } else if ('extractedQuantities' in parentCode && parentCode.extractedQuantities.number !== undefined) {
      return parentCode.extractedQuantities.number
    } else if ('number' in parentCode && parentCode.number) {
      return parentCode.number
    } else {
      return 0
    }
  }
}
