import DeleteIcon from '@mui/icons-material/Delete'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import React from 'react'

export interface TableColumn {
  id: string
  label: string
  minWidth?: number
  format?: (value: number) => string
  sortDirection?: 'asc' | 'desc'
}

type WithId = {
  id: string
}

type IProps<T extends WithId> = {
  columns: TableColumn[]
  elementList: T[]
  getValueForColumn(columnId: string, element: T): string | React.JSX.Element
  updateElement?(element: T): Promise<void>
  deleteElement?(element: T): Promise<void>
  isEditButton: boolean
  isDeleteButton: boolean
  pageSize?: number
}

export default function BaseTable<T extends WithId>({
  columns,
  elementList,
  getValueForColumn,
  isEditButton,
  isDeleteButton,
  updateElement,
  deleteElement,
  pageSize = 5,
}: Readonly<IProps<T>>): React.JSX.Element {
  const [page, setPage] = React.useState(0)
  const [isDeleteDisable, setIsDeleteDisable] = React.useState(false)
  const [isEditDisable, setIsEditDisable] = React.useState(false)

  function handleChangePage(_event: unknown, newPage: number): void {
    setPage(newPage)
  }

  function updateElementAndDisable(element: T): void {
    if (updateElement) {
      setIsEditDisable(true)
      updateElement(element).finally(() => setIsEditDisable(false))
    }
  }

  function deleteElementAndDisable(element: T): void {
    if (deleteElement) {
      setIsDeleteDisable(true)
      deleteElement(element).finally(() => setIsDeleteDisable(false))
    }
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ minHeight: 200 }}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align='left'
                  style={{ minWidth: column.minWidth }}
                  sortDirection={column.sortDirection}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody className='notranslate'>
            {elementList.slice(page * pageSize, page * pageSize + pageSize).map((element: T) => (
              <TableRow hover tabIndex={-1} key={element.id}>
                {columns.map((column: TableColumn) => {
                  const value = element !== undefined && column !== undefined ? getValueForColumn(column.id, element) : ' '
                  return (
                    <TableCell key={column.id} align='left'>
                      {value !== 'action' ? (
                        value
                      ) : (
                        <Box>
                          {isEditButton && updateElement && (
                            <IconButton
                              aria-label='edit'
                              color='primary'
                              disabled={isEditDisable}
                              onClick={() => updateElementAndDisable(element)}>
                              <ModeEditIcon />
                            </IconButton>
                          )}
                          {isDeleteButton && deleteElement && (
                            <IconButton
                              aria-label='delete'
                              color='primary'
                              disabled={isDeleteDisable}
                              onClick={() => deleteElementAndDisable(element)}>
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </Box>
                      )}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{ display: 'flex', align: 'center', justifyContent: 'center', alignItems: 'center' }}
        component='div'
        rowsPerPageOptions={[]}
        count={elementList.length}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={handleChangePage}
      />
    </Paper>
  )
}
