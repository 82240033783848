import { Autocomplete, Box, TextField } from '@mui/material'
import React from 'react'
import { RoleEnum } from '../../core/enum/roleEnum'

type IProps = {
  options: RoleEnum[]
  actualRole: RoleEnum[] | []
  disabled: boolean
  label: string
}

export function RoleEnumPicker({ options, actualRole, disabled, label }: Readonly<IProps>): React.JSX.Element {
  function getLabelText(value: RoleEnum): string {
    if (value === RoleEnum.ADMINISTRATOR) return 'ADMINISTRATEUR'
    if (value === RoleEnum.ARCHITECT) return 'ARCHITECTE'
    if (value === RoleEnum.BE) return "BUREAU D'ÉTUDES"
    if (value === RoleEnum.CONSULTANT) return 'CONSULTANT'
    if (value === RoleEnum.MOA) return 'MOA'

    return ''
  }

  return (
    <Box>
      <Autocomplete
        multiple
        disablePortal
        disabled={disabled}
        id='comboGuestRole'
        value={actualRole}
        options={options}
        getOptionLabel={(value: RoleEnum) => getLabelText(value)}
        isOptionEqualToValue={(option, value) => option === value}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    </Box>
  )
}
