import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React, { useContext } from 'react'
import { AppSideBarContext } from '../../core/context/side-bar/side-bar-context'
import './app-side-navbar.scss'

type IProps = {
  label: string
  action(): void
  open: boolean
  icon: React.JSX.Element | null
}

export function IconSideBar({ label, action, open, icon }: IProps): React.JSX.Element {
  const { urlState } = useContext(AppSideBarContext)

  const isActive = urlState === label

  return (
    <ListItem
      disablePadding
      sx={{
        display: 'block',
        backgroundColor: isActive ? '#0BE2A0' : '',
        borderRadius: 2,
      }}>
      <ListItemButton
        onClick={action}
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          px: 2.5,
          color: isActive ? 'black' : 'white',
        }}>
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : 'auto',
            justifyContent: 'center',
            color: isActive ? 'black' : 'white',
          }}>
          {icon}
        </ListItemIcon>
        <ListItemText primary={label} sx={{ opacity: open ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
  )
}
