import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Children } from '../../../components/miscellianous/children'
import { pagesUrl } from '../../appConstants'

export enum NavbarTypeEnum {
  DEFAULT,
  STEP_HEADER,
  HEAD_WITHOUT_STEP,
  UNKNOWN,
}

export const AppNavBarContext = React.createContext<AppNavBarStore>({} as AppNavBarStore)

export function NavBarContextProvider({ children }: Children): React.JSX.Element {
  const location = useLocation()

  const [title, setTitle] = useState<string>('')
  const [icon, setIcon] = useState<React.ElementType | undefined>()
  const [typeNavBar, setTypeNavBar] = useState<NavbarTypeEnum>(NavbarTypeEnum.DEFAULT)
  const [previousUrl, setPreviousUrl] = useState<string>(pagesUrl.CONTROL_BOARD_HOME)
  const [stepHeader, setStepHeader] = useState<number>(0)
  const [showProjectStatus, setShowProjectStatus] = useState<boolean>(false)
  const [pagination, setPagination] = useState<string[]>(['Tableau de bord'])

  /* reset the navbar when the url change */
  useEffect(
    () => () => {
      setTypeNavBar(NavbarTypeEnum.DEFAULT)
      setPreviousUrl(`${location.pathname}${location?.search || ''}`)
    },
    [location]
  )

  const AppNavBarStore: AppNavBarStore = useMemo(
    () => ({
      title,
      setTitle,
      icon,
      setIcon,
      typeNavBar,
      setTypeNavBar,
      previousUrl,
      setPreviousUrl,
      stepHeader,
      setStepHeader,
      showProjectStatus,
      setShowProjectStatus,
      pagination,
      setPagination,
    }),
    [icon, pagination, previousUrl, showProjectStatus, stepHeader, title, typeNavBar]
  )

  return <AppNavBarContext.Provider value={AppNavBarStore}>{children}</AppNavBarContext.Provider>
}

export type AppNavBarStore = {
  title: string
  setTitle: React.Dispatch<React.SetStateAction<string>>
  icon: React.ElementType | undefined
  setIcon: React.Dispatch<React.SetStateAction<React.ElementType<any> | undefined>>
  typeNavBar: NavbarTypeEnum
  setTypeNavBar: React.Dispatch<React.SetStateAction<NavbarTypeEnum>>
  previousUrl: string
  setPreviousUrl: React.Dispatch<React.SetStateAction<string>>
  stepHeader: number
  setStepHeader: React.Dispatch<React.SetStateAction<number>>
  showProjectStatus: boolean
  setShowProjectStatus: React.Dispatch<React.SetStateAction<boolean>>
  pagination: string[]
  setPagination: React.Dispatch<React.SetStateAction<string[]>>
}
