import React, { useEffect, useState } from 'react'
import { PowerBIEmbed } from 'powerbi-client-react'
import { models } from 'powerbi-client'
import ReportDto from '../../core/dto/reportDto'
import { useHttp } from '../../core/hooks/use-http'
import './report-ilot-labro.css'

/**
 * @deprecated
 */
export function ReportIlotLabroR4(): React.JSX.Element | null {
  const [report, setReport] = useState<ReportDto>(new ReportDto())
  const { get } = useHttp()

  useEffect(() => {
    get('/azure/report-ilot-labro-r4').then((r) => {
      r.json().then((body: ReportDto) => {
        setReport(body)
      })
    })
  }, [get])

  if (report.id !== undefined) {
    return (
      <PowerBIEmbed
        embedConfig={{
          type: 'report',
          id: report.id,
          embedUrl: report.embedUrl,
          accessToken: report.embedToken,
          tokenType: models.TokenType.Embed,
          settings: undefined,
        }}
        eventHandlers={
          new Map([
            [
              'loaded',
              () => {
                console.warn('Report loaded')
              },
            ],
            [
              'rendered',
              () => {
                console.warn('Report rendered')
              },
            ],
            [
              'error',
              (event: any) => {
                if (event) {
                  console.error(event.detail)
                }
              },
            ],
          ])
        }
        cssClassName='powerBi-iframe'
        getEmbeddedComponent={(embeddedReport) => {
          const report1 = new ReportDto()
          report1.id = embeddedReport.getId()
          report1.embedUrl = embeddedReport.getDefaultEmbedUrlEndpoint()
          report1.reportType = embeddedReport.embedtype
          report1.name = report.name
          setReport(report1)
        }}
      />
    )
  } else {
    return null
  }
}
