import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'
import React, { Dispatch, FormEvent, SetStateAction, useContext, useEffect, useState } from 'react'
import SelectInput from '../../../../components/inputs/select-input/select-input'
import { RseeProjectContext } from '../../../../core/context/rsee/rsee-project-context'
import { RseeProject } from '../../../../core/dto/rsee/rsee-project'
import { RseeProjectForm } from '../../../../core/dto/rsee/rsee-project-form'
import { ProjectStatusEnum, projectStatusToLabel } from '../../../../core/enum/projectStatusEnum'
import { useRsee } from '../../../../core/hooks/rsee/use-rsee'
import { enumToArray } from '../../../../core/services/helper-service'

const selectOptions = enumToArray(ProjectStatusEnum)
  .filter(
    (status) =>
      status === ProjectStatusEnum.IN_PROGRESS ||
      status === ProjectStatusEnum.FINISHED ||
      status === ProjectStatusEnum.ARCHIVED
  )
  .map((enumm: ProjectStatusEnum) => ({
    value: enumm,
    label: projectStatusToLabel(enumm),
  }))

type IProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

function formFromProject(rseeProject: RseeProject | undefined): RseeProjectForm {
  return rseeProject?.id
    ? { nom: rseeProject.projectName, status: rseeProject.projectStatus }
    : { nom: '', status: ProjectStatusEnum.IN_PROGRESS }
}

export default function RseeProjectFormDialog({ open, setOpen }: IProps): React.JSX.Element {
  const { rseeProject, setRseeProject } = useContext(RseeProjectContext)
  const { updateRseeProject } = useRsee()

  const [rseeProjectForm, setRseeProjectForm] = useState<RseeProjectForm>(formFromProject(rseeProject))
  const [error, setError] = useState<Record<string, any>>({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    setRseeProjectForm(formFromProject(rseeProject))
  }, [formFromProject, rseeProject?.id])

  function isValid(): boolean {
    let valid = true
    if (!rseeProjectForm?.nom || rseeProjectForm.nom === '') {
      setError({ ...error, nom: 'Le nom est obligatoire' })
      valid = false
    } else if (rseeProjectForm?.nom.length > 500) {
      setError({ ...error, nom: 'Le nom est trop long' })
      valid = false
    } else {
      rseeProjectForm.nom = rseeProjectForm.nom.trim()
    }

    return valid
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const target = event.target
    const value = target.value

    setRseeProjectForm({
      ...rseeProjectForm,
      [target.id]: value,
    })
  }

  function handleChangeStatus(selectedStatus: ProjectStatusEnum): void {
    setRseeProjectForm({
      ...rseeProjectForm,
      status: selectedStatus,
    })
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault()

    setError({})

    if (!isSubmitting && isValid() && rseeProject?.id) {
      setIsSubmitting(true)
      updateRseeProject(rseeProject.id, rseeProjectForm)
        .then((newRseeProject) => {
          setRseeProject(newRseeProject)
          handleClose()
        })
        .finally(() => setIsSubmitting(false))
    }
  }

  function handleClose(): void {
    setOpen(false)
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='md'>
      <DialogTitle>Mise à jour du projet</DialogTitle>
      <DialogContent>
        {rseeProject?.id && (
          <Grid container rowSpacing={2} component='form' id='rsee-project-form' onSubmit={handleSubmit}>
            <Grid item xs={12}>
              <TextField
                id='nom'
                value={rseeProjectForm?.nom}
                label='RSEE'
                onChange={handleChange}
                fullWidth
                error={!!error.nom}
                helperText={error.nom}
                inputProps={{ maxLength: 500 }}
                margin='dense'
              />
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                id='status'
                mode='direct'
                handleChange={handleChangeStatus}
                label='Statut du projet'
                options={selectOptions}
                selectedOption={rseeProjectForm.status}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={handleClose}>
          Annuler
        </Button>
        {isSubmitting ? (
          <CircularProgress />
        ) : (
          <Button variant='contained' type='submit' form='rsee-project-form'>
            Valider
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
