import { Button, ButtonProps } from '@mui/material'
import { To } from '@remix-run/router/history'
import React from 'react'
import { Link } from 'react-router-dom'

interface IProps extends ButtonProps {
  path: To
  label: string
  fullwidth?: boolean
}

export default function NavigateButton({
  path,
  label,
  fullwidth = true,
  ...buttonProps
}: Readonly<IProps>): React.JSX.Element {
  return (
    <Link to={path} style={{ width: fullwidth ? '90%' : undefined }}>
      <Button variant='contained' sx={{ textAlign: 'center', width: fullwidth ? '100%' : undefined }} {...buttonProps}>
        {label}
      </Button>
    </Link>
  )
}
