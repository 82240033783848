import { FileDownloadOutlined, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import BuildIcon from '@mui/icons-material/Build'
import {
  Box,
  Button,
  CardHeader,
  CircularProgress,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { useMemo, useState } from 'react'
import { ReactComponent as ZipSvg } from '../../../../../../assets/zip.svg'
import { customPalette } from '../../../../../../theme'
import { TutorialVideoEnum } from '../../../../../core/enum/tutorialVideoEnum'
import { useTutorial } from '../../../../../core/hooks/bim-model/use-tutorial'
import BimModelVideoCard from './project-bim-model-video-card'

export const videosData = {
  1: [
    {
      title: 'Introduction',
      name: TutorialVideoEnum.REVIT_INTRODUCTION,
      thumbnail: 'https://ifcjs.github.io/info/img/logo.svg',
    },
    {
      title: 'Paramètres partagés',
      name: TutorialVideoEnum.REVIT_PARAMETRES_PARTAGES,
      thumbnail: 'https://ifcjs.github.io/info/img/logo.svg',
    },
    {
      title: 'Renseigner la maquette',
      name: TutorialVideoEnum.REVIT_RENSEIGNER_MAQUETTE,
      thumbnail: 'https://ifcjs.github.io/info/img/logo.svg',
    },
    {
      title: 'Exporter la maquette',
      name: TutorialVideoEnum.REVIT_EXPORT,
      thumbnail: 'https://ifcjs.github.io/info/img/logo.svg',
    },
  ],
  2: [
    {
      title: 'Exporter en ifc',
      name: TutorialVideoEnum.ARCHICAD_INTRODUCTION,
      thumbnail: 'https://ifcjs.github.io/info/img/logo.svg',
    },
  ],
}

export const revitVersions = [
  {
    value: 'V2023',
    label: '2023',
  },
  {
    value: 'V2022',
    label: '2022',
  },
  {
    value: 'V2021',
    label: '2021',
  },
]

export const archicadVersions = [
  {
    value: 'V27',
    label: '27',
  },
  {
    value: 'V26',
    label: '26',
  },
  {
    value: 'V25',
    label: '25',
  },
  {
    value: 'V24',
    label: '24',
  },
]

export default function TutorialBimModelVideo(): React.JSX.Element {
  const { fetchSoftwareDoc } = useTutorial()

  const [loading, setLoading] = useState<boolean>(false)
  const [tab, setTab] = useState<1 | 2>(1)
  const [open, setOpen] = useState(false)
  const [version, setVersion] = useState('V2023')
  const software = useMemo(() => ['REVIT', 'ARCHICAD'], [])

  async function handleDownloadSoftware(): Promise<void> {
    setLoading(true)
    const file = await fetchSoftwareDoc(software[tab - 1], version).finally(() => setLoading(false))
    if (file) {
      const element = document.createElement('a')
      element.href = URL.createObjectURL(file)
      element.download = file.name
      element.click()
    }
  }

  function onChangeVersion(e: SelectChangeEvent): void {
    setVersion(e.target.value)
  }

  function onChangeSoftware(index: number): void {
    const newTab = index === 0 ? 1 : 2
    if (newTab !== tab) {
      if (newTab === 1) {
        setVersion(revitVersions[0].value)
      } else {
        setVersion(archicadVersions[0].value)
      }
      setTab(newTab)
    }
  }

  return (
    <Box component='div'>
      <CardHeader
        title={<Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>Tutoriels modélisation Beem</Typography>}
        sx={{
          width: '350px',
          backgroundColor: 'black',
          color: 'white',
          py: '5px',
          borderRadius: '10px',
          mb: 1,
        }}
        action={
          <IconButton color='info' onClick={() => setOpen(!open)} aria-label='expand' size='small'>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        }
      />
      <Collapse in={open} timeout='auto' unmountOnExit>
        <Box component='div' display='flex' flexDirection='row' sx={{ marginTop: '12px' }}>
          {software.map((label, index) => (
            <Button
              key={label}
              sx={{
                backgroundColor: customPalette.default,
                fontSize: '16px',
                boxShadow: '0px 1px 2px 1px rgb(0 0 255 / 20%)',
                marginRight: '20px',
              }}
              variant={tab === index + 1 ? 'outlined' : 'text'}
              onClick={() => onChangeSoftware(index)}>
              <BuildIcon sx={{ marginRight: '12px', fontSize: '16px' }} />
              {label}
            </Button>
          ))}
        </Box>
        <Box component='div' sx={{ mt: 5 }}>
          <Grid container>
            <Grid item sm={5}>
              <Grid container columnSpacing='32px'>
                {videosData[tab].map((video) => (
                  <Grid item sm={3} key={video.title}>
                    <BimModelVideoCard title={video.title} video={video.name} thumbnail={video.thumbnail} />
                  </Grid>
                ))}
              </Grid>
              <Grid mt={3} container display='flex' flexDirection='row' alignItems='center'>
                <FormControl
                  style={{
                    width: '200px',
                  }}>
                  <InputLabel>Version du logiciel</InputLabel>
                  <Select size='small' fullWidth value={version} label='Version du logiciel' onChange={onChangeVersion}>
                    {software[tab - 1] === 'REVIT' &&
                      revitVersions.map((versionData) => (
                        <MenuItem key={versionData.value} value={versionData.value}>
                          {`${software[tab - 1]} ${versionData.label}`}
                        </MenuItem>
                      ))}
                    {software[tab - 1] === 'ARCHICAD' &&
                      archicadVersions.map((versionData) => (
                        <MenuItem key={versionData.value} value={versionData.value}>
                          {`${software[tab - 1]} ${versionData.label}`}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <IconButton sx={{ mx: 1 }} disabled={loading} onClick={() => handleDownloadSoftware()}>
                  {loading ? <CircularProgress size='20px' color='primary' /> : <FileDownloadOutlined />}
                </IconButton>
                <ZipSvg
                  style={{
                    width: 35,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </Box>
  )
}
