import { Box, Divider } from '@mui/material'
import React from 'react'
import { DefaultTypography } from '../typography/default-typography'
import './app-side-navbar.scss'

type IProps = {
  readonly label: string
  readonly open: boolean
}

export default function DividerSideBar({ label, open }: IProps): React.JSX.Element {
  return (
    <Box display='flex' alignItems='center' width='100%'>
      {open && <DefaultTypography label={label} color='#BDBDBD' fontWeight={0} fontSize='11px' />}
      <Divider
        variant='fullWidth'
        textAlign='left'
        sx={{
          color: '#BDBDBD',
          backgroundColor: '#BDBDBD ',
          width: '100%',
        }}
      />
    </Box>
  )
}
