import { Typography } from '@mui/material'
import React from 'react'

export interface ITextInputProps {
  label: string
}

export function TitleBimModelCardTypography({ label }: Readonly<ITextInputProps>): React.JSX.Element {
  return (
    <Typography
      sx={{
        fontFamily: 'Poppins',
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '32px',
        textAlign: 'left',
        color: '#4965AC',
      }}>
      {label}
    </Typography>
  )
}
