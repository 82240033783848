import { Dispatch, SetStateAction } from 'react'

export class TutorialEnabledStore {
  tutorialEnabled = false
  setTutorialEnabled: Dispatch<SetStateAction<boolean>>

  constructor(tutorialEnabled: boolean, setTutorialEnabled: Dispatch<SetStateAction<boolean>>) {
    this.tutorialEnabled = tutorialEnabled
    this.setTutorialEnabled = setTutorialEnabled
  }
}
