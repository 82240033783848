import React, { useMemo, useState } from 'react'
import { Children } from '../../components/miscellianous/children'
import MaterialRecord from '../dto/material/material-record'
import { MaterialDtoStore } from '../store/material-project-store'

export const ProjectMaterialContext = React.createContext<MaterialDtoStore>({} as MaterialDtoStore)

export default function ProjectMaterialContextProvider({ children }: Children): React.JSX.Element {
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<MaterialRecord[] | undefined>()
  const [error, setError] = useState<string | undefined>(undefined)

  const ProjectMaterialsStore = useMemo(
    () => new MaterialDtoStore(data, setData, loading, setLoading, error, setError),
    [error, loading, data]
  )
  return <ProjectMaterialContext.Provider value={ProjectMaterialsStore}>{children}</ProjectMaterialContext.Provider>
}
