import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { TestUser } from '../../../core/dto/user/test-users'
import { formatToFrench, formatToFrenchWithHours, stringToDate } from '../../../core/services/date-service'

const pageSize = 20

type Order = 'asc' | 'desc'

interface DisplayUserColumn {
  id:
    | 'name'
    | 'surname'
    | 'email'
    | 'phoneNumber'
    | 'status'
    | 'action'
    | 'organization'
    | 'createdDate'
    | 'lastConnection'
    | 'prospectOption'
  label: string
  minWidth?: number
  format?: (value: number) => string
}

const columns: DisplayUserColumn[] = [
  {
    id: 'name',
    label: 'Nom',
  },
  {
    id: 'surname',
    label: 'Prénom',
  },
  {
    id: 'email',
    label: 'E-mail',
  },
  {
    id: 'phoneNumber',
    label: 'N. de tel',
  },
  {
    id: 'organization',
    label: 'Entreprise',
  },
  {
    id: 'prospectOption',
    label: 'Prospect',
  },
  {
    id: 'createdDate',
    label: 'Date de création',
  },
  {
    id: 'lastConnection',
    label: 'Date de derniere connexion',
  },
]

type iProps = {
  page: number
  allList: TestUser[]
  handleChangePage: (event: unknown, newPage: number) => void
  isLoading: boolean
  changePropectOption: (userId: string) => void
}

export default function TestUserDataTable({
  page,
  allList,
  handleChangePage,
  isLoading,
  changePropectOption,
}: iProps): React.JSX.Element {
  const [orderBy, setOrderBy] = useState<DisplayUserColumn['id']>('createdDate')
  const [order, setOrder] = useState<Order>('desc')

  const [testUsersList, setTestUsersList] = useState<TestUser[]>(allList)

  useEffect(() => {
    setTestUsersList(allList.slice(page * pageSize, page * pageSize + pageSize))
  }, [allList, page])

  function getValueForCollumn(value: DisplayUserColumn | string, row: TestUser): any {
    switch (value) {
      case 'name':
        return row.familyName
      case 'surname':
        return row.givenName
      case 'email':
        return row.email
      case 'phoneNumber':
        return row.phone
      case 'organization':
        return row.organizationName
      case 'prospectOption':
        return (
          <FormControlLabel
            control={
              <Checkbox
                id='globalDashboardOption'
                checked={row.prospectOption}
                onChange={() => changePropectOption(row.cognitoUserId)}
              />
            }
            label={row.prospectOption ? 'Oui' : 'Non'}
          />
        )
      case 'createdDate':
        return formatToFrench(stringToDate(row?.createdDate)) || ''
      case 'lastConnection':
        return formatToFrenchWithHours(stringToDate(row?.lastConnection)) || ''
      default:
        return ''
    }
  }

  function handleRequestSort(property: DisplayUserColumn['id']): void {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    let tests: TestUser[] = []
    switch (property) {
      case 'name':
        order === 'desc'
          ? (tests = allList.sort((a, b) => b.familyName.localeCompare(a.familyName)))
          : (tests = allList.sort((a, b) => a.familyName.localeCompare(b.familyName)))
        setTestUsersList(tests)
        return
      case 'surname':
        order === 'desc'
          ? (tests = allList.sort((a, b) => b.givenName.localeCompare(a.givenName)))
          : (tests = allList.sort((a, b) => a.givenName.localeCompare(b.givenName)))
        setTestUsersList(tests)
        return
      case 'email':
        order === 'desc'
          ? (tests = allList.sort((a, b) => b.email.localeCompare(a.email)))
          : (tests = allList.sort((a, b) => a.email.localeCompare(b.email)))
        setTestUsersList(tests)
        return
      case 'phoneNumber':
        order === 'desc'
          ? (tests = allList.sort((a, b) => b.phone.localeCompare(a.phone)))
          : (tests = allList.sort((a, b) => a.phone.localeCompare(b.phone)))
        setTestUsersList(tests)
        break
      default:
    }
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ minHeight: 200 }}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  sortDirection={orderBy === column.id ? order : false}
                  key={column.id}
                  align='left'
                  style={{ minWidth: column.minWidth }}>
                  {column.id === 'name' ||
                  column.id === 'surname' ||
                  column.id === 'email' ||
                  column.id === 'phoneNumber' ? (
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={() => handleRequestSort(column.id)}>
                      {column.label}
                    </TableSortLabel>
                  ) : (
                    column.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {isLoading ? (
            <TableBody className='notranslate'>
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <Box
                    sx={{
                      display: 'flex',
                      align: 'center',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                    }}>
                    <CircularProgress />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody className='notranslate'>
              {testUsersList.map((testUser: TestUser) => (
                <TableRow hover role='checkbox' tabIndex={-1} key={testUser.cognitoUserId}>
                  {columns.map((column) => {
                    const value =
                      testUser !== undefined && column !== undefined ? getValueForCollumn(column.id, testUser) : ' '
                    return (
                      <TableCell key={column.id} align='left'>
                        {value}
                      </TableCell>
                    )
                  })}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        sx={{ display: 'flex', align: 'center', justifyContent: 'center', alignItems: 'center' }}
        component='div'
        rowsPerPageOptions={[]}
        count={allList.length}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={handleChangePage}
      />
    </Paper>
  )
}
