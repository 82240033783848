import { Alert, Snackbar } from '@mui/material'
import React, { useCallback, useState } from 'react'

export const InfoContext = React.createContext<(message: string) => void>(() => {
  // do nothing, function will be set later
})

type Props = {
  children: any
}

export default function InfoSnackbar({ children }: Props): React.JSX.Element {
  const [msg, setMsg] = useState<string | undefined>(undefined)
  const [open, setOpen] = useState<boolean>(false)

  function handleClose(): void {
    setMsg(undefined)
    setOpen(false)
  }

  const openInfoSnackbar = useCallback((message: string) => {
    setMsg(message)
    setOpen(true)
  }, [])

  return (
    <InfoContext.Provider value={openInfoSnackbar}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{ minHeight: '200px', zIndex: 999999999999 }}>
        <Alert onClose={handleClose} severity='info' sx={{ width: '100%' }}>
          {msg}
        </Alert>
      </Snackbar>
    </InfoContext.Provider>
  )
}
