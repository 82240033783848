import AddIcon from '@mui/icons-material/Add'
import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useLocation, useParams } from 'react-router-dom'
import { adminPagesUrl } from '../../../../core/appConstants'
import { AdminOrganizationContext } from '../../../../core/context/organization/admin-organization-context'
import { resolveUrl } from '../../../../core/services/http-service'
import { PageEnum } from './page-num'
import { PanelButton } from './panel-button'

export default function AdminOrganizationPanel(): React.JSX.Element {
  const navigate = useNavigate()
  const location = useLocation()
  const { organization } = useContext(AdminOrganizationContext)
  const { organizationId } = useParams()
  const [page, setPage] = useState<PageEnum | undefined>(undefined)

  useEffect(() => {
    if (location.pathname.includes('/user')) {
      setPage(PageEnum.USER)
    } else if (location.pathname.includes('/project')) {
      setPage(PageEnum.BIM_PROJECT)
    } else if (location.pathname.endsWith('/rsee')) {
      setPage(PageEnum.RSEE)
    } else if (location.pathname.includes('/material-lib')) {
      setPage(PageEnum.MATERIAL_LIB)
    } else {
      setPage(PageEnum.ORGANIZATION)
    }
  }, [location.pathname])

  return (
    <Grid container justifyContent='space-between' sx={{ mt: 2, mb: 2 }}>
      <Grid item xs={3}>
        <Box display='flex'>
          <AddIcon sx={{ fontSize: '40px', marginRight: '16px' }} />
          <Box>
            <Typography variant='h1' sx={{ fontSize: 30, fontWeight: 'bold' }}>
              {organizationId ? 'Modifier une organisation' : 'Créer une organisation'}
            </Typography>
            <Typography variant='h6'>{organization?.businessName}</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={1}>
        <Button variant='outlined' onClick={() => navigate(resolveUrl(adminPagesUrl.ORGANIZATION_LIST_PAGE, [], {}))}>
          Retour
        </Button>
      </Grid>
      {organizationId !== 'new' && (
        <Grid container columnSpacing={2} justifyContent='center'>
          <Grid item xs={12} sm={2}>
            <PanelButton
              isSelected={page === PageEnum.ORGANIZATION}
              toUrl={resolveUrl(adminPagesUrl.ADMIN_ORGANIZATION_PAGE, [organization?.id])}
              label='Infos générales'
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <PanelButton
              isSelected={page === PageEnum.USER}
              toUrl={resolveUrl(adminPagesUrl.ADMIN_USER_ORGANIZATION_PAGE, [organization?.id], {})}
              label='Gérer les utilisateurs'
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <PanelButton
              isSelected={page === PageEnum.BIM_PROJECT}
              toUrl={resolveUrl(adminPagesUrl.BIM_PROJECT_PAGE, [organization?.id], {})}
              label='Gérer les projets BIM'
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <PanelButton
              isSelected={page === PageEnum.RSEE}
              toUrl={resolveUrl(adminPagesUrl.PROJECT_RSEE_PAGE, [organization?.id], {})}
              label='Gérer les projets RSEE'
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <PanelButton
              isSelected={page === PageEnum.MATERIAL_LIB}
              toUrl={resolveUrl(adminPagesUrl.MY_MATERIAL_PAGE, [organization?.id], {})}
              label='Gérer la matériauthèque'
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
