import { Sprite, Vector3 } from 'three'
import { CameraProjections, IfcViewerAPI } from 'web-ifc-viewer'
import { PointData } from '../models/measurement/annotations/PointData'

export class MeasurementHelper {
  /**
   * Update label size based on camera projection
   * @param ifc ifc viewer
   * @param label label to update
   * @param textPosition position of label
   * @param size size of label
   * @returns updated label
   */
  static updateLabelSize(ifc: IfcViewerAPI, label: Sprite | any, textPosition: Vector3, size?: number): Sprite | any {
    if (label != null) {
      const xToyRatio = label.scale.x / label.scale.y
      const sceneCamera = ifc.context.getIfcCamera()
      let scale = 1
      if (sceneCamera != null) {
        if (sceneCamera?.projection === CameraProjections.Perspective) {
          if (size) {
            scale = size
          } else {
            scale = textPosition.distanceTo(sceneCamera.activeCamera.position) / 4
          }
        } else {
          const scaleFactor = 2
          scale = scaleFactor / sceneCamera.orthographicCamera.zoom
        }
      }
      label.scale.x = scale * xToyRatio
      label.scale.y = scale
      return label
    } else {
      return label
    }
  }

  /**
   * Obtain position to place text sprite at line's midpoint
   * @param startPoint line's start point
   * @param endPoint line's midpoint
   * @returns text position
   */
  static getTextPosition(startPoint: Vector3, endPoint: Vector3): Vector3 {
    const zDirection = new Vector3(0, -1, 0)
    const lineVector = endPoint.clone().sub(startPoint).normalize()
    let perpendicularVector
    if (lineVector.equals(zDirection) || lineVector.equals(zDirection.negate())) {
      perpendicularVector = lineVector.clone().cross(new Vector3(1, 0, 0)).normalize().cross(lineVector).normalize()
    } else {
      perpendicularVector = lineVector.clone().cross(zDirection).normalize().cross(lineVector).normalize()
    }
    const initialPosition = startPoint.clone().add(
      endPoint
        .clone()
        .sub(startPoint)
        .normalize()
        .multiplyScalar(0.5 * startPoint.distanceTo(endPoint))
    )
    return initialPosition.add(perpendicularVector.multiplyScalar(0.2))
  }

  /**
   * Get intersection point of a point's projection on a line
   * @param line line to project on
   * @param point point to project from
   * @returns intersection point (may extend the line)
   */
  static GetIntersectionBetweenPointAndLine(line: Array<PointData>, point: PointData): Vector3 {
    const vector: Vector3 = point.point.clone().sub(line[0].point)
    const lineVector = line[1].point.clone().sub(line[0].point)
    const projection =
      vector.dot(lineVector) /
      Math.sqrt(lineVector.x * lineVector.x + lineVector.y * lineVector.y + lineVector.z * lineVector.z)
    return line[0].point.clone().add(lineVector.clone().normalize().multiplyScalar(projection))
  }

  /**
   * Get the number of digits in a number including "-" and "."
   * @param x number
   * @returns number of digits
   */
  static numDigits(x: number): number {
    return String(x?.toFixed(3))?.replace('.', '')?.length
  }
}
