import { Box, Button, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import BaseTable, { TableColumn } from '../../../components/tables/base-table'
import { Statistic } from '../../../core/dto/statistics/statistic'
import { useUtils } from '../../../core/hooks/use-utils'
import { formatToFrench } from '../../../core/services/date-service'

const columns: TableColumn[] = [
  {
    id: 'totalUser',
    label: "Nombre total d'utilisateurs",
  },
  {
    id: 'userClientsAndProspects',
    label: 'Nombre clients et prospects',
  },
  {
    id: 'testUsers',
    label: 'Nombre de Test User',
  },
  {
    id: 'bimProjectTotal',
    label: 'Projets BIM',
  },
  {
    id: 'bimProjectForClientsAndProspects',
    label: 'Projet BIM clients et prospects',
  },
  {
    id: 'bimProjectForClientsAndProspectsInProgress',
    label: 'Projet BIM EN_COURS clients et prospects',
  },
  {
    id: 'rseeProjectTotal',
    label: 'Projets RSEE',
  },
  {
    id: 'rseeProjectFromClients',
    label: 'Projets RSEE des clients',
  },
  {
    id: 'rseeProjectFromClientsAndInProgress',
    label: 'Projets RSEE EN_COURS des clients',
  },
  {
    id: 'rseeDocumentTotal',
    label: 'Documents RSEE',
  },
  {
    id: 'rseeDocumentFromClients',
    label: 'Documents RSEE des clients',
  },
  {
    id: 'rseeDocumentFromClientsAndInProgress',
    label: 'Documents RSEE EN_COURS des clients',
  },
  {
    id: 'rseeDocumentFromTestUsers',
    label: 'Documents RSEE des test users',
  },
  {
    id: 'userIdsWhoModifiedBimProject',
    label: 'Utilisateurs qui ont modifiés un projet BIM au cours des 7 derniers jours',
  },
  {
    id: 'userIdsWhoModifiedRseeProject',
    label: 'Utilisateurs qui ont modifiés un projet RSEE au cours des 7 derniers jours',
  },
  {
    id: 'createdDate',
    label: 'Date de création',
  },
  {
    id: 'action',
    label: 'Action',
  },
]

function getValueForColumn(value: string | number, row: Statistic): string | React.JSX.Element {
  switch (value) {
    case 'totalUser':
      return `${row.totalUser !== undefined ? row.totalUser : ''}`
    case 'userClientsAndProspects':
      return `${row.userClientsAndProspects !== undefined ? row.userClientsAndProspects : ''}`
    case 'testUsers':
      return `${row.testUsers !== undefined ? row.testUsers : ''}`
    case 'bimProjectTotal':
      return `${row.bimProjectTotal !== undefined ? row.bimProjectTotal : ''}`
    case 'bimProjectForClientsAndProspects':
      return `${row.bimProjectForClientsAndProspects !== undefined ? row.bimProjectForClientsAndProspects : ''}`
    case 'bimProjectForClientsAndProspectsInProgress':
      return `${
        row.bimProjectForClientsAndProspectsInProgress !== undefined ? row.bimProjectForClientsAndProspectsInProgress : ''
      }`
    case 'rseeProjectTotal':
      return `${row.rseeProjectTotal !== undefined ? row.rseeProjectTotal : ''}`
    case 'rseeProjectFromClients':
      return `${row.rseeProjectFromClients !== undefined ? row.rseeProjectFromClients : ''}`
    case 'rseeProjectFromClientsAndInProgress':
      return `${row.rseeDocumentFromClientsAndInProgress !== undefined ? row.rseeDocumentFromClientsAndInProgress : ''}`
    case 'rseeDocumentTotal':
      return `${row.rseeDocumentTotal !== undefined ? row.rseeDocumentTotal : ''}`
    case 'rseeDocumentFromClients':
      return `${row.rseeDocumentFromClients !== undefined ? row.rseeDocumentFromClients : ''}`
    case 'rseeDocumentFromClientsAndInProgress':
      return `${row.rseeDocumentFromClientsAndInProgress !== undefined ? row.rseeDocumentFromClientsAndInProgress : ''}`
    case 'rseeDocumentFromTestUsers':
      return `${row.rseeDocumentFromTestUsers !== undefined ? row.rseeDocumentFromTestUsers : ''}`
    case 'userIdsWhoModifiedBimProject':
      return row.userEmailsWhoModifiedBimProject?.join(', ')
    case 'userIdsWhoModifiedRseeProject':
      return row.userEmailsWhoModifiedRseeProject?.join(', ')
    case 'createdDate':
      return formatToFrench(row.createdDate)
    case 'action':
      return 'action'
    default:
      return ''
  }
}

export default function AdminStatistics(): React.JSX.Element {
  const { generateStatistics, getAllStatistics, deleteStatistic } = useUtils()
  const [statisticsList, setStatisticsList] = useState<Statistic[]>([])

  useEffect(() => {
    refresh()
  }, [])

  function refresh(): void {
    if (statisticsList) {
      getAllStatistics().then((newList: Statistic[]) => setStatisticsList(newList))
    }
  }

  function getStatistics(): void {
    generateStatistics().finally(() => {
      refresh()
    })
  }

  function deleteElement(element: Statistic): Promise<void> {
    return deleteStatistic(element).then(() => refresh())
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 3 }}>
        <Typography variant='h6'>Récapitulatif</Typography>
        <Button variant='contained' onClick={getStatistics}>
          Créer une statistique
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 3,
        }}>
        <BaseTable
          columns={columns}
          elementList={statisticsList}
          getValueForColumn={getValueForColumn}
          deleteElement={deleteElement}
          isEditButton={false}
          isDeleteButton
          pageSize={30}
        />
      </Box>
    </Box>
  )
}
