import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react'
import { Children } from '../../../components/miscellianous/children'
import CodeReference from '../../dto/code-acv/code-reference'
import { useCode } from '../../hooks/use-code'
import { ProjectContext } from '../project/project-context'
import { CodesVersion } from '../../enum/codesVersionEnum'
import { ErrorContext } from '../../../components/layout/error-snackbar'

export const CodeAcvCompletionContext = React.createContext<CodeAcvCompletionStore>({} as CodeAcvCompletionStore)

export default function CodeAcvCompletionContextProvider({ children }: Children): React.JSX.Element {
  const openErrorSnackbar = useContext(ErrorContext)
  const { fetchCodeAcvForCompletionMap } = useCode()
  const { project } = useContext(ProjectContext)
  const [codesAcvForCompletions, setCodesAcvForCompletions] = useState<Map<string, CodeReference[]>>(new Map())
  const [currentCodesVersion, setCurrentCodesVersion] = useState<CodesVersion | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (project && project.id && project.codesVersion && project.codesVersion !== currentCodesVersion && !isLoading) {
      setIsLoading(true)
      fetchCodeAcvForCompletionMap(project.codesVersion)
        .then((res: Map<string, CodeReference[]>) => {
          setIsLoading(false)
          setCurrentCodesVersion(project.codesVersion)
          setCodesAcvForCompletions(res)
        })
        .catch((e) => {
          openErrorSnackbar(e)
          setIsLoading(false)
        })
    }
  }, [fetchCodeAcvForCompletionMap, project])

  const codeAcvStore = useMemo(
    () => new CodeAcvCompletionStore(codesAcvForCompletions, setCodesAcvForCompletions),
    [codesAcvForCompletions]
  )
  return <CodeAcvCompletionContext.Provider value={codeAcvStore}>{children} </CodeAcvCompletionContext.Provider>
}

export class CodeAcvCompletionStore {
  codesAcvForCompletions: Map<string, CodeReference[]>

  setCodesAcvForCompletions: Dispatch<SetStateAction<Map<string, CodeReference[]>>>

  constructor(
    codesAcvForCompletions: Map<string, CodeReference[]>,
    setCodesAcvForCompletions: Dispatch<SetStateAction<Map<string, CodeReference[]>>>
  ) {
    this.codesAcvForCompletions = codesAcvForCompletions
    this.setCodesAcvForCompletions = setCodesAcvForCompletions
  }
}
