import { ToggleButton } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import React, { useContext } from 'react'
import { ProjectContext } from '../../../../../core/context/project/project-context'
import { SelectedPhaseContext } from '../../../../../core/context/selected-phase-context'
import { PhaseEnum } from '../../../../../core/enum/phaseEnum'

type PhaseItemProps = {
  phaseEnum: PhaseEnum
}

export default function PhaseItem({ phaseEnum }: PhaseItemProps): React.JSX.Element {
  const { selectedPhase, setSelectedPhase } = useContext(SelectedPhaseContext)
  const { project } = useContext(ProjectContext)

  const ascendingPhase = [PhaseEnum.ESQ, PhaseEnum.APS, PhaseEnum.APD, PhaseEnum.DCE, PhaseEnum.PRO]

  function isCurrentPhase(): boolean {
    return phaseEnum === getCurrentedPhase()
  }

  function getCurrentedPhase(): PhaseEnum | undefined {
    const index = project.phases.findIndex((e) => e.currentPhase)
    if (index > -1) {
      return project.phases[index].phase
    }
    return undefined
  }

  function validatePhase(toValidate: PhaseEnum): boolean {
    let actualPhaseIndex
    let selectedPhaseIndex
    const actualPhaseLabel = getCurrentedPhase()
    const selectedPhaseLabel = toValidate

    if (actualPhaseLabel && selectedPhaseLabel) {
      actualPhaseIndex = ascendingPhase.indexOf(actualPhaseLabel)
      selectedPhaseIndex = ascendingPhase.indexOf(selectedPhaseLabel)
      return actualPhaseIndex >= selectedPhaseIndex
    }
    return false
  }

  return (
    <Box
      display='flex'
      flexDirection='row'
      justifyContent='center'
      alignItems='center'
      sx={{
        cursor: 'pointer',
        borderRadius: 2,
      }}>
      <Box display='colunm'>
        <Box display='colunm' justifyContent='center' alignItems='center'>
          <Box display='flex' alignItems='center' justifyContent='center'>
            <ToggleButton
              id='isCurrentPhase'
              value='check'
              selected={selectedPhase === phaseEnum}
              onClick={() => {
                setSelectedPhase(phaseEnum)
              }}
              disabled={!validatePhase(phaseEnum)}
              color='success'
              sx={{ mt: isCurrentPhase() ? 2 : 0 }}>
              {phaseEnum}
            </ToggleButton>
          </Box>

          <Typography
            display='flex'
            alignItems='center'
            sx={{
              fontSize: 12,
              mb: 1,
              mt: 1,
            }}>
            {isCurrentPhase() && 'Phase actuelle'}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
