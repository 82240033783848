export enum FoundationTypeEnum {
  PROFONDES = 'PROFONDES',
  SEMELLES_FILANTES = 'SEMELLES_FILANTES',
  RADIER = 'RADIER',
  SEMELLES_ISOLEES = 'SEMELLES_ISOLEES',
}

export const foundationTypeEnum = {
  [FoundationTypeEnum.PROFONDES]: 'Fondations profondes',
  [FoundationTypeEnum.SEMELLES_FILANTES]: 'Semelles filantes',
  [FoundationTypeEnum.RADIER]: 'Radier',
  [FoundationTypeEnum.SEMELLES_ISOLEES]: 'Semelles isolées',
}
