import { useCallback, useContext } from 'react'
import { CalculationCarbonFlateRateContext } from '../../context/calculation/calculation-carbon-flate-rate-data'
import { VariantContext } from '../../context/variant-context'
import FlatRates from '../../dto/calculation/flat-rates'
import { useHttp } from '../use-http'

type CalculCarbonInformationProjectUpdate = {
  loading: boolean
  updateCalculateCarbonFlateRate: (
    variableVariant1: UpdateCalculateCarbonFlateRateVariable,
    variableVariant2: UpdateCalculateCarbonFlateRateVariable
  ) => Promise<Response[]>
}

type UpdateCalculateCarbonFlateRateVariable = {
  variantId: string
} & FlatRates

export const useUpdateCalculateCarbonFlateRate = (): CalculCarbonInformationProjectUpdate => {
  const { put } = useHttp()
  const { loading, setLoading } = useContext(CalculationCarbonFlateRateContext)
  const { setVariant1, setVariant2 } = useContext(VariantContext)
  const updateCalculateCarbonFlateRate = useCallback(
    async (
      variableVariant1: UpdateCalculateCarbonFlateRateVariable,
      variableVariant2: UpdateCalculateCarbonFlateRateVariable
    ): Promise<Response[]> => {
      setLoading(true)
      return Promise.all(
        [variableVariant1, variableVariant2].map(async (variant) => {
          const { variantId, ...data } = variant
          const response = await put(`/calculation/variant/${variantId}/flat-rates`, {
            ...data,
          })
          return response.json()
        })
      )
        .then(([v1, v2]) => {
          setVariant1((prev) => ({
            ...prev,
            ...v1,
          }))
          setVariant2((prev) => ({
            ...prev,
            ...v2,
          }))
          return [v1, v2]
        })
        .then((res) => res)
        .finally(() => setLoading(false))
    },
    [put, setLoading, setVariant1, setVariant2]
  )

  return {
    loading,
    updateCalculateCarbonFlateRate,
  }
}
