export enum FunctionalUnitEnum {
  SQUARE_DECIMETER = 'dm²',
  SQUARE_METER = 'm²',
  KG = 'kg',
  CUBIC_METER = 'm³',
  METER = 'ml',
  MEGAJOULES = 'mJ',
  KILOWATT_PER_HOUR = 'kw/h',
  LITER = 'l',
  GRAM = 'g',
  CENTIMETER = 'cm',
  PERCENT = '%',
  PER_UNIT = 'u',
  KG_CO2_EQ = 'kg CO₂ eq',
  KG_SO2_EQ = 'kg SO₂ eq',
  KG_CFC11_EQ = 'kg CFC₁₁ eq',
  KG_C2H4_EQ = 'kg C₂H₄ eq',
  MILLIGRAM = 'mg',
  CUBIC_MILLIMETER = 'mm³',
  G_EQ_SO2 = 'g SO₂ eq',
  G_C2H4_EQ = 'g C₂H₄ eq',
  GRAM_PER_SQUARE_METER = 'g/m²',
  KG_SB_EQ = 'kg SB eq',
  M3_PER_UF = 'm3/uf',
  KG_PER_M2 = 'kg/m²',
  K_WHEP = 'kWhep',
  NO_UNIT = '',
  M3_AIR = 'm3 air',
  KG_PO4_3_EQ = 'kg PO4³⁻ eq',
  METRIC_TON = 't',
  M3_EAU = 'm³ eau',
  PERSON_RESERVE = 'réservé par personn',
  MOLE_EQ_H_PLUS = 'mol H⁺ eq',
  MOLE_EQ_N = 'mol N eq',
  KILOMETER = 'km',
  G_EQ_CO2 = 'g CO₂ eq',
  G_EQ_CFC11 = 'g CFC₁₁ eq',
  G_EQ_PO4_3 = 'g PO₄³⁻ eq',
  G_EQ_C2H4 = 'g C₂H₄ eq',
  G_EQ_H_PLUS = 'g H⁺ eq',
  CUBIC_DECIMETER = 'dm³',
  YEAR_MINUS_1 = 'année⁻¹',
  K_WC = 'kWc',
  METRIC_TON_KM = 't·km',
  DECA_NEWTON = 'daN',
  M3_PER_HOUR = 'm³/h',
  MILLIMETER = 'mm',
  KG_PER_M3 = 'kg/m³',
  KILOWATT = 'kW',
  DECIBEL = 'db',
  KELVIN_PER_WATT = 'K/W',
  W_PER_M2_K = 'W/m²·K',
  SQUARE_CENTIMETER = 'cm²',
  SQUARE_MILLIMETER = 'mm²',
  WC = 'Wc',
  FLOOR_NUMBER = 'étage(s)',
  M2_K_PER_WATT = 'm²·K/W',
  KILOGRAM_PER_METER = 'kg/m',
  UNKNOWN = 'unité inconnue',
}
