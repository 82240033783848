import React, { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { Children } from '../../../components/miscellianous/children'
import { NomenclatureFilterDto } from '../../dto/nomenclature-filter'

export const MaterialLibFilterContext = React.createContext<MaterialLibFilterStore>({} as MaterialLibFilterStore)

export default function MaterialLibFilterContextProvider({ children }: Children): React.JSX.Element {
  const [materialNomenclatureFilter, setMaterialNomenclatureFilter] = useState<NomenclatureFilterDto>({
    fdes: [],
    pep: [],
  })
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | undefined>(undefined)

  const materialLibFiltersStore: MaterialLibFilterStore = useMemo(
    () => ({ materialNomenclatureFilter, setMaterialNomenclatureFilter, loading, setLoading, error, setError }),
    [error, loading, materialNomenclatureFilter]
  )

  return <MaterialLibFilterContext.Provider value={materialLibFiltersStore}>{children}</MaterialLibFilterContext.Provider>
}

export type MaterialLibFilterStore = {
  materialNomenclatureFilter: NomenclatureFilterDto
  setMaterialNomenclatureFilter: Dispatch<SetStateAction<NomenclatureFilterDto>>
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  error: Error | undefined
  setError: Dispatch<SetStateAction<Error | undefined>>
}
