import { Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

export interface ITextInputProps {
  label: string
  url: string
}

export function UnderlineTypography({ label, url }: Readonly<ITextInputProps>): React.JSX.Element {
  return (
    <Link to={url}>
      <Typography
        textTransform='none'
        sx={{
          fontFamily: 'Poppins',
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '21px',
          color: '#070F27',
          textDecoration: 'underline',
          cursor: 'pointer',
        }}>
        {label}
      </Typography>
    </Link>
  )
}
