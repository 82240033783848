import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React, { ChangeEvent } from 'react'

import { ImageDisplayer } from './image-displayer'
import { PdfDisplayer } from './pdf-displayer'

export enum DisplayerMode {
  UPDATE = 'UPDATE',
  SAVE = 'SAVE',
}

type IProps = {
  file: File | undefined
  saveFile: () => void
  cancelUpload: () => void
  validateImage: boolean
  imageTitle: string
  imageTextError: string | undefined
  fileType: string
  open: boolean
  handleClose: () => void
  handleChangeFile?: (event: ChangeEvent<HTMLInputElement>) => void
  isloading: boolean
  displayMode?: DisplayerMode
}

export const fileTypeAccepted = '.png,.jpeg,.jpg,.pdf'
export const acceptedExtensions = ['.png', '.jpeg', '.jpg', '.pdf']

const extensionPdfFile = '.pdf'

export function UploadImageModal({
  file,
  saveFile,
  cancelUpload,
  validateImage,
  imageTitle,
  imageTextError,
  fileType,
  open,
  handleClose,
  handleChangeFile,
  isloading,
  displayMode,
}: IProps): React.JSX.Element {
  function isPdfFile(): boolean {
    return !!file?.name.endsWith(extensionPdfFile)
  }

  function downloadFile(): void {
    if (file) {
      const fileName = file.name
      const fileDownloadUrl = URL.createObjectURL(file)
      const a = document.createElement('a')

      a.href = fileDownloadUrl
      a.download = fileName

      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      URL.revokeObjectURL(fileDownloadUrl)
    }
  }

  return validateImage ? (
    <Dialog open={open} onClose={handleClose} maxWidth='md' fullWidth>
      <DialogTitle>{imageTitle}</DialogTitle>
      <DialogContent draggable sx={{ display: 'flex', justifyContent: 'center' }}>
        {isloading ? (
          <>
            {!isPdfFile() && <ImageDisplayer file={file} width={700} height={438} />}
            {isPdfFile() && <PdfDisplayer file={file} />}
          </>
        ) : (
          <Box display='flex' justifyContent='center'>
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelUpload}> annuler </Button>
        {handleChangeFile && displayMode === DisplayerMode.UPDATE && (
          <Box component='label' htmlFor='upload-photo'>
            <input
              accept={fileTypeAccepted}
              style={{ display: 'none' }}
              onChange={handleChangeFile}
              id='upload-photo'
              name='upload-photo'
              type='file'
            />
            <Button component='span'>Modifier</Button>
          </Box>
        )}
        {displayMode === DisplayerMode.SAVE && (
          <Button variant='contained' onClick={saveFile}>
            Enregistrer
          </Button>
        )}
        {displayMode === DisplayerMode.UPDATE && (
          <Button variant='contained' onClick={downloadFile}>
            Télécharger
          </Button>
        )}
      </DialogActions>
    </Dialog>
  ) : (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Valider la photo de couverture</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            border: 1,
            width: 400,
            p: 8,
            height: 250,
            backgroundColor: '#6c6c6c',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#ffffff',
          }}>
          {imageTextError}
        </Box>
        <input
          onChange={handleChangeFile}
          style={{ display: 'none' }}
          accept={fileTypeAccepted}
          id='file-upload'
          type='file'
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelUpload}> annuler </Button>
        <Box component='label' htmlFor='upload-photo'>
          <input
            accept={fileType}
            style={{ display: 'none' }}
            onChange={handleChangeFile}
            id='upload-photo'
            name='upload-photo'
            type='file'
          />
          <Button variant='contained' component='span'>
            Changer
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
