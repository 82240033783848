import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Children } from '../../../components/miscellianous/children'
import { RseeDocument } from '../../dto/rsee/rsee-document'
import { useRsee } from '../../hooks/rsee/use-rsee'

export const RseeDocumentContext = React.createContext<RseeDocumentStore>({} as RseeDocumentStore)

export default function RseeDocumentContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { rseeDocumentId } = useParams()
  const { fetchRseeDocument, fetchDashboardUrl, listenIsDocumentReady } = useRsee()

  const [rseeDocument, setRseeDocument] = useState<RseeDocument | undefined>()
  const [isRseeDocumentLoading, setIsRseeDocumentLoading] = useState<boolean>(false)
  const [dashboardUrl, setDashboardUrl] = useState<string>('')
  const [isDataReady, setIsDataReady] = useState<boolean>(false)
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null)

  const refreshUrl: () => void = useCallback(() => {
    if (rseeDocument?.id) {
      fetchDashboardUrl(rseeDocument.versionTtb).then((newDashboardUrl) => {
        setDashboardUrl(newDashboardUrl)
      })
    }
  }, [fetchDashboardUrl, rseeDocument])

  const refreshRseeDocument: () => Promise<void> = useCallback(() => {
    if (rseeDocumentId && rseeDocumentId !== 'new') {
      setIsRseeDocumentLoading(true)
      return fetchRseeDocument(rseeDocumentId)
        .then((newRseeDocument) => {
          setRseeDocument(newRseeDocument)
        })
        .finally(() => {
          setIsRseeDocumentLoading(false)
        })
    } else {
      return Promise.resolve()
    }
  }, [rseeDocumentId, fetchRseeDocument])

  useEffect(() => {
    try {
      refreshRseeDocument()
    } catch (e) {
      console.error('catch', e)
      throw e
    }
  }, [refreshRseeDocument])

  useEffect(() => {
    refreshUrl()
  }, [refreshUrl])

  const checkIfDocumentReady = useCallback((): void => {
    console.info('timer tick', rseeDocument?.id, intervalIdRef.current)
    if (rseeDocument?.id) {
      listenIsDocumentReady(rseeDocument?.id)
        .then((isReady: boolean) => {
          if (isReady) {
            clearTimer()
            setIsDataReady(isReady)
          }
        })
        .catch((e) => {
          console.error('catch erreur:', e)
          setIsDataReady(true)
          clearTimer()
        })
    }
  }, [listenIsDocumentReady, rseeDocument?.id])

  useEffect(() => {
    if (rseeDocument?.id) {
      listenIsDocumentReady(rseeDocument?.id)
        .then((isReady: boolean) => {
          setIsDataReady(isReady)
          if (!isReady && !intervalIdRef.current) {
            intervalIdRef.current = setInterval(checkIfDocumentReady, 5000)
            console.info('set timer', intervalIdRef.current) // Keep log until we are sure that the feature is 100% stable
          }
        })
        .catch((e) => {
          console.error('catch erreur:', e)
          setIsDataReady(true)
          clearTimer()
        })
    }

    return () => clearTimer()
  }, [listenIsDocumentReady, rseeDocument])

  function clearTimer(): void {
    console.info('clear timer', intervalIdRef.current)
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current)
      intervalIdRef.current = null
    }
  }

  const rseeDocumentHook: RseeDocumentStore = useMemo(
    (): RseeDocumentStore => ({
      rseeDocument,
      isRseeDocumentLoading,
      dashboardUrl,
      isDataReady,
      refreshUrl,
      refreshRseeDocument,
    }),
    [rseeDocument, isRseeDocumentLoading, dashboardUrl, isDataReady, refreshUrl, refreshRseeDocument]
  )

  return <RseeDocumentContext.Provider value={rseeDocumentHook}>{children}</RseeDocumentContext.Provider>
}

export type RseeDocumentStore = {
  rseeDocument?: RseeDocument
  isRseeDocumentLoading: boolean
  dashboardUrl: string
  isDataReady: boolean
  refreshUrl(): void
  refreshRseeDocument(): Promise<void>
}
