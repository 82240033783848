import { Button } from '@mui/material'
import InsertLinkIcon from '@mui/icons-material/InsertLink'
import React, { useContext } from 'react'
import { SuccessContext } from '../../layout/success-snackbar'
import { ErrorContext } from '../../layout/error-snackbar'

type IProps = {
  link: string | undefined
}

export function CopyLinkButton({ link }: IProps): React.JSX.Element {
  const openSuccessSnackbar = useContext(SuccessContext)
  const openErrorSnackbar = useContext(ErrorContext)

  function copyLink(): void {
    if (link) {
      navigator.clipboard
        .writeText(link)
        .then(() => {
          openSuccessSnackbar('Lien copié')
        })
        .catch((err) => {
          openErrorSnackbar(new Error('Erreur lors de la sauvegarde du lien dans le presse-papier'))
          console.error('Failed to copy the link: ', err)
        })
    }
  }

  return (
    <Button
      variant='outlined'
      onClick={copyLink}
      startIcon={<InsertLinkIcon />}
      sx={{ fontSize: '0.7rem', borderRadius: '30px' }}>
      Copier le lien
    </Button>
  )
}
