import ClearIcon from '@mui/icons-material/Clear'
import { Grid, IconButton, TextField } from '@mui/material'
import { CalendarPickerView, DatePicker } from '@mui/x-date-pickers'
import React from 'react'

type IProps = {
  label: string
  value: Date | undefined
  handleChange(date: Date | undefined): void
  views?: readonly CalendarPickerView[] | undefined
}

export function DateInput({ label, value, handleChange, views }: IProps): React.JSX.Element {
  function changeDate(date: Date | null): void {
    if (date === null) {
      return
    }
    handleChange(date)
  }

  function emptyDate(): void {
    handleChange(undefined)
  }

  return (
    <Grid container columnSpacing={1}>
      <Grid item xs={11}>
        <DatePicker
          label={label}
          onChange={changeDate}
          views={views}
          value={value || null}
          renderInput={(params) => <TextField variant='outlined' {...params} sx={{ mt: 2, mb: 2, width: '100%' }} />}
        />
      </Grid>
      <Grid item xs={1} container justifyContent='center' alignItems='center'>
        <IconButton onClick={emptyDate}>
          <ClearIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}
