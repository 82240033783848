import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Children } from '../../components/miscellianous/children'
import { Variant } from '../dto/calculation/variant'
import { RoleEnum } from '../enum/roleEnum'
import { useCalculation } from '../hooks/use-calculation'
import { isUltimateUser } from '../services/authentication-service'
import { CalculationContext } from './calculation/calculation-context'
import { ProjectRoleContext } from './user/project-role-context'
import { UserContext } from './user/user-context'

export const VariantContext = React.createContext<VariantStore>({} as VariantStore)

export default function VariantContextProvider({ children }: Children): React.JSX.Element {
  const { calculation } = useContext(CalculationContext)
  const { hasRole, isOrganizationMegaUser } = useContext(ProjectRoleContext)
  const { user } = useContext(UserContext)
  const isOrganizationUltimateUser = isUltimateUser(user)
  const { fetchVariant } = useCalculation()
  const [variant1, setVariant1] = useState<Variant>(new Variant())
  const [variant2, setVariant2] = useState<Variant>(new Variant())

  const hasReading: boolean = useMemo(
    () => hasRole([RoleEnum.ADMINISTRATOR, RoleEnum.BE]) || isOrganizationMegaUser || isOrganizationUltimateUser,
    [hasRole, isOrganizationMegaUser, isOrganizationUltimateUser]
  )

  const refreshVariant = useCallback(() => {
    if (calculation.id !== undefined && hasReading) {
      fetchVariant(calculation.variantId1).then((variant) => setVariant1(variant))
      fetchVariant(calculation.variantId2).then((variant) => setVariant2(variant))
    } else {
      setVariant1(new Variant())
      setVariant2(new Variant())
    }
  }, [calculation.id, calculation.variantId1, calculation.variantId2, fetchVariant, hasReading])

  useEffect(() => {
    refreshVariant()
  }, [refreshVariant])

  const variantStore: VariantStore = useMemo(
    () => ({ variant1, setVariant1, variant2, setVariant2, refreshVariant }),
    [variant1, variant2, refreshVariant]
  )
  return <VariantContext.Provider value={variantStore}>{children}</VariantContext.Provider>
}

export type VariantStore = {
  variant1: Variant
  setVariant1: Dispatch<SetStateAction<Variant>>
  variant2: Variant
  setVariant2: Dispatch<SetStateAction<Variant>>
  refreshVariant(): void
}
