import { Box, Grid } from '@mui/material'
import React from 'react'
import AccountInfosFormField from './account-info-form-field'
import AccountPasswordFormField from './account-password-form-field'
import TitleLogo from './account-title'

export default function AccountPage(): React.JSX.Element {
  return (
    <Box>
      <TitleLogo title='Mon compte' logo={{ id: 'account' }} />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <AccountInfosFormField />
        </Grid>
        <Grid item xs={6}>
          <AccountPasswordFormField />
        </Grid>
      </Grid>
    </Box>
  )
}
