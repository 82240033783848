export type QuantityKey = 'length' | 'height' | 'number' | 'surface'

export class Quantity {
  length: number | undefined = 0
  height: number | undefined = 0
  number: number | undefined = 0
  surface: number | undefined = 0

  static clone(quantity: Quantity): Quantity {
    const newQuantity = new Quantity()

    const strings: QuantityKey[] = Object.keys(newQuantity) as QuantityKey[]
    strings.forEach((key: QuantityKey) => {
      newQuantity[key] = quantity[key]
    })

    return newQuantity
  }

  static accumulate(code: string, quantity1: Quantity, quantity2: Quantity): void {
    const strings: QuantityKey[] = Object.keys(new Quantity()) as QuantityKey[]
    strings.forEach((key: QuantityKey) => {
      quantity1[key] = Quantity.addNotNull(quantity1[key], quantity2[key])
    })
  }

  static addNotNull(value1: number | undefined, value2: number | undefined): number | undefined {
    if (value1 !== undefined && value2 !== undefined) {
      return value1 + value2
    } else if (value1 === undefined || Number.isNaN(value1)) {
      return value2
    } else if (value2 === undefined || Number.isNaN(value1)) {
      return value1
    } else {
      return undefined
    }
  }

  static percent(quantity: Quantity, percent: number | undefined): Quantity | undefined {
    if (quantity === undefined || percent === undefined) {
      return undefined
    }
    const newQuantity = new Quantity()
    const coef = percent / 100

    const strings: QuantityKey[] = Object.keys(new Quantity()) as QuantityKey[]
    strings.forEach((key: QuantityKey) => {
      // @ts-ignore
      newQuantity[key] = quantity[key] !== undefined ? quantity[key] * coef : undefined
    })

    return newQuantity
  }
}
