import React from 'react'

type IProps = {
  style?: any
}

export default function IconAdd(props: IProps): React.JSX.Element {
  const { style } = props

  return (
    <span
      translate='no'
      style={{
        fontVariationSettings: `'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48`,
        fontSize: style ? style.fontSize : 'inherit',
        ...style,
      }}
      className='material-symbols-outlined'>
      add
    </span>
  )
}
