import { Box, Card, CardContent, Typography } from '@mui/material'
import React from 'react'
import { isNumber } from '../../core/services/helper-service'

type IProps = {
  count: number | undefined
  max: number | undefined
  libelle: string
}

function formatMax(max: number | undefined): number | string | React.JSX.Element {
  if (max === undefined || !isNumber(max)) {
    return 0
  } else if (max < 0) {
    // This means infinite licence available
    return <span style={{ fontWeight: 900 }}>∞</span>
  } else {
    return max
  }
}

function formatCount(count: number | undefined, max: number | undefined): number | string | React.JSX.Element {
  if (count === undefined || !isNumber(count)) {
    return formatMax(max)
  } else {
    return count
  }
}

export default function SubscriptionCard({ count, max, libelle }: IProps): React.JSX.Element {
  return (
    <Card sx={{ height: '100%', border: 1, borderRadius: 5 }}>
      <CardContent sx={{ height: '100%', display: 'flex', alignContent: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flexGrow: 1, width: '100%' }}>
          <Typography sx={{ fontSize: 30, fontWeight: 'bold' }} align='center'>
            {libelle}
          </Typography>
          <Typography sx={{ fontSize: 30, fontWeight: 'bold' }} align='center'>
            {formatCount(count, max)}/{formatMax(max)}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}
