import { Scene, Vector3 } from 'three'
import { IfcViewerAPI } from 'web-ifc-viewer'
import { PointData } from './annotations/PointData'
import { DimensionCommand } from './base/DimensionCommand'
import { AreaDimensionCommand } from './commands/AreaDimensionCommand'
import { EdgeToPointDimensionCommand } from './commands/EdgeToPointDimensionCommand'
import { TwoPointsDimensionCommand } from './commands/LineDimensionCommand'
import { PointCoordinatesCommand } from './commands/PointCoordinatesCommand'

export class DimensionFactory {
  /**
   * Create a command object for creating a new line dimension between two points
   * @param startPoint line's start point
   * @param endPoint line's end point
   * @param scene the scene of the application
   */
  static addTwoPointsDimension(startPoint: PointData, endPoint: PointData, scene: THREE.Scene, ifc: IfcViewerAPI): void {
    const dimension = new TwoPointsDimensionCommand(startPoint, endPoint, scene, ifc)
    dimension.execute()
  }

  /**
   * Create a command object for creating a new line dimension between a given edge and a point
   * @param line edge's line represented in an array of points(start and end points of line)
   * @param point point to add dimension
   * @param scene the scene of the application
   */
  static addEdgeToPointDimension(line: Array<PointData>, point: PointData, scene: Scene, ifc: IfcViewerAPI): void {
    const dimension = new EdgeToPointDimensionCommand(line, point, scene, ifc)
    dimension.execute()
  }

  /**
   * Create a command object for creating a new line dimension for area measurement after picking points
   * @param points array of points to add dimension
   * @param scene the scene of the application
   * @param ifc the ifc viewer
   */
  static addAreaPointsDimension(points: Array<PointData>, scene: THREE.Scene, ifc: IfcViewerAPI): void {
    const dimension = new AreaDimensionCommand(points, scene, ifc)
    dimension.execute()
  }

  /**
   * Create a command object for creating a new point coordinates
   * @param point coordinates of the point
   * @param textPoint coordinates of the text the user sees
   * @param scene the scene of the viewer
   * @param ifc the ifc viewer
   * @param expressID the expressID of the object in the viewer
   * @param modelID the modelID of the object in the viewer
   */
  static addOnePointCoordinates(
    point: Vector3,
    textPoint: Vector3,
    scene: THREE.Scene,
    ifc: IfcViewerAPI,
    expressID?: number,
    modelID?: number
  ): void {
    const dimension = new PointCoordinatesCommand(point, textPoint, scene, ifc, expressID, modelID)
    dimension.execute()
  }

  /**
   * hide lines that are outside all clipping planes
   * @param planes clipping planes
   */
  static hideLinesOutsideOfClippingView(planes?: Array<THREE.Plane>, scene?: Scene): void {
    const objs = DimensionCommand.addedLines
    if (planes != null && planes.length > 0 && objs.length > 0) {
      const point: Vector3 = new Vector3(0, 0, 0)
      const toBeShown = objs.filter((elem) =>
        planes.every(
          (elem2) =>
            elem.startPoint != null &&
            elem.startPoint.clone().sub(elem2.coplanarPoint(point)).dot(elem2.normal) >= 0 &&
            elem.endPoint != null &&
            elem.endPoint.clone().sub(elem2.coplanarPoint(point)).dot(elem2.normal) >= 0
        )
      )
      const toBeHidden = objs.filter((o) => !toBeShown.includes(o))
      for (const o of toBeHidden) {
        o.canBeVisible = false
        scene?.remove(o)
      }
      for (const p of toBeShown) {
        p.canBeVisible = true
        scene?.add(p)
      }
    }
  }

  /**
   * hide points that are outside all clipping planes
   * @param planes clipping planes
   */
  static showAllPoints(scene?: Scene): void {
    for (const p of DimensionCommand.addedLines) {
      p.canBeVisible = true
      scene?.add(p)
    }
  }
}
