import { Dispatch, SetStateAction, useCallback, useContext } from 'react'
import { appConstants } from '../../appConstants'
import { IniesRecordContext } from '../../context/inies-context'
import IniesRecord from '../../dto/material/inies-record'
import { useHttp } from '../use-http'
import { DeclarationFilter } from '../../enum/declarationFilter'

export class IniesRecordFilter {
  containedInName: string | undefined = undefined
  classificationLvl0: DeclarationFilter | undefined = undefined
  classificationLvl1: number | undefined = undefined
  classificationLvl2: number | undefined = undefined
  classificationLvl3: number | undefined = undefined
  classificationLvl4: number | undefined = undefined
  responsibleOrganism: string | undefined = undefined
  declarationType: string | undefined = undefined
}

export type UseIniesRecordsFilteredVariable = {
  filter: IniesRecordFilter
  lastFdesName?: string
}

type IniesRecordsFilteredHook = {
  loading: boolean
  search: ({ filter, lastFdesName }: UseIniesRecordsFilteredVariable) => Promise<IniesRecord[]>
  iniesRecords: IniesRecord[]
  reset: () => void
  limit: number
  setLimit: Dispatch<SetStateAction<number>>
}

export function useIniesRecordsFiltered(): IniesRecordsFilteredHook {
  const { post } = useHttp()

  const { iniesRecords, setIniesRecords, loading, setLoading, limit, setLimit } = useContext(IniesRecordContext)

  const reset = useCallback((): void => setIniesRecords([]), [setIniesRecords])

  const search = useCallback(
    async ({ filter, lastFdesName }: UseIniesRecordsFilteredVariable): Promise<IniesRecord[]> => {
      setLoading(true)
      let params = [
        {
          key: 'limit',
          value: String(limit),
        },
      ]

      if (lastFdesName) {
        params = [
          ...params,
          {
            key: 'lastFdesName',
            value: encodeURI(lastFdesName),
          },
        ]
      }

      if (!filter?.declarationType) delete filter.declarationType
      if (!filter?.containedInName) delete filter.containedInName
      if (!filter?.responsibleOrganism) delete filter.responsibleOrganism

      if (!filter?.classificationLvl1) delete filter.classificationLvl1
      if (!filter?.classificationLvl2) delete filter.classificationLvl2
      if (!filter?.classificationLvl3) delete filter.classificationLvl3
      if (!filter?.classificationLvl4) delete filter.classificationLvl4

      return post(appConstants.apiEndpoints.INIES_RECORDS_FILTERS_PAGINATED, filter, params)
        .then((response) => response.json())
        .then((res: any[]) => {
          const iniesArray: IniesRecord[] = res.map((record) => IniesRecord.fromDto(record))
          setIniesRecords(iniesArray)
          return iniesArray
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [limit, post, setIniesRecords, setLoading]
  )

  return {
    loading,
    search,
    iniesRecords,
    reset,
    limit,
    setLimit,
  }
}
