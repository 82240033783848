import { Button, CircularProgress } from '@mui/material'
import React from 'react'

type IProps = {
  action(): void
  label: string
  isSubmiting?: boolean
  isDisabled?: boolean
}

export function ValidateButton({ action, label, isSubmiting, isDisabled = false }: Readonly<IProps>): React.JSX.Element {
  if (isSubmiting) {
    return <CircularProgress />
  } else {
    return (
      <Button variant='contained' color='primary' size='medium' onClick={action} disabled={isDisabled}>
        {label}
      </Button>
    )
  }
}
