import TextureIcon from '@mui/icons-material/Texture'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import IniesIcon from '../../../../../../assets/icons/inies.png'
import { customPalette } from '../../../../../../theme'

type ImportIniesSectionProps = {
  handleClick: (v: 1 | 2) => void
  tab: 1 | 2
  importFicheConfiguree: () => void
}
export default function ImportIniesSection({
  handleClick,
  tab,
  importFicheConfiguree,
}: ImportIniesSectionProps): React.JSX.Element {
  return (
    <>
      <Box display='flex' flexDirection='row' justifyContent='center' mb='16px'>
        <Typography
          component='span'
          textAlign='center'
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'bold',
          }}>
          importer une déclaration depuis
        </Typography>
      </Box>
      <Box display='flex'>
        <Button
          onClick={() => (tab === 1 ? undefined : handleClick(1))}
          component='label'
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            px: '16px',
            py: '25px',
            justifyContent: 'center',
            flex: 1,
            border: tab === 1 ? 1 : 'none',
            boxShadow: '1px 2px 5px #d6d2d2',
            backgroundColor: customPalette.default,
            mr: '12px',
            textTransform: 'none',
          }}>
          <Typography component='span' fontWeight='bold'>
            La base
          </Typography>
          <img
            src={IniesIcon}
            alt={IniesIcon}
            height='30px'
            width='max-content'
            style={{
              marginLeft: 15,
              marginTop: -8,
            }}
          />
        </Button>
        <Button
          onClick={() => (tab === 2 ? undefined : handleClick(2))}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            border: tab === 2 ? 1 : 'none',
            px: '16px',
            py: '25px',
            boxShadow: '1px 2px 5px #d6d2d2',
            backgroundColor: customPalette.default,
            ml: '12px',
            textTransform: 'none',
          }}>
          <Typography component='span' fontWeight='bold' textAlign='left'>
            Matériauthèque projet
          </Typography>
          <TextureIcon
            fontSize='large'
            sx={{
              ml: 2,
            }}
          />
        </Button>
      </Box>
      <Box display='flex' sx={{}}>
        <Button
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            px: '16px',
            py: '10px',
            mt: 3,
            boxShadow: '1px 2px 5px #d6d2d2',
            backgroundColor: customPalette.default,
            textTransform: 'none',
          }}
          onClick={importFicheConfiguree}>
          <Typography component='span' fontWeight='bold'>
            Importer une fiche configurée (.xml)
          </Typography>
          <UploadFileIcon
            fontSize='large'
            sx={{
              ml: 2,
            }}
          />
        </Button>
      </Box>
    </>
  )
}
