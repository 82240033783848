import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Children } from '../../../../../components/miscellianous/children'
import { BSProjectContext } from '../../../../../core/context/beem-shot/BSProject/BSProjectContext'
import { BSVariantContext } from '../../../../../core/context/beem-shot/BSVariant/BSVariantContext'
import { CalculStatusEnum } from '../../../../../core/enum/calculStatusEnum'
import { useBSProject } from '../../../../../core/hooks/beem-shot/useBSProject'

export const CalculationStatusContext = React.createContext<CalculationStatusStore>({} as CalculationStatusStore)

export function CalculationStatusContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { bsProject } = useContext(BSProjectContext)
  const { selectedVariant, fetchAllBsVariants } = useContext(BSVariantContext)
  const { listenIsCalculationReady } = useBSProject()

  const [isDataReady, setIsDataReady] = useState<boolean>(false)
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null)

  const clearTimer = useCallback((): void => {
    console.info('clear timer', intervalIdRef.current)
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current)
      intervalIdRef.current = null
    }
  }, [])

  const checkIfDashboardReady = useCallback((): void => {
    console.info('timer tick', selectedVariant?.id)
    if (selectedVariant) {
      listenIsCalculationReady(selectedVariant)
        .then((isReady: boolean) => {
          if (isReady && bsProject?.id) {
            clearTimer()
            setIsDataReady(isReady)
            fetchAllBsVariants(bsProject?.id)
          }
        })
        .catch((e) => {
          console.error('catch erreur:', e)
          setIsDataReady(true)
          clearTimer()
        })
    }
  }, [selectedVariant, bsProject?.id, listenIsCalculationReady, clearTimer, fetchAllBsVariants])

  useEffect(() => {
    if (selectedVariant?.calculStatus === CalculStatusEnum.CALCUL_IN_PROGRESS) {
      listenIsCalculationReady(selectedVariant)
        .then((isReady: boolean) => {
          if (bsProject?.id) {
            setIsDataReady(isReady)
            fetchAllBsVariants(bsProject?.id)
            if (!isReady && !intervalIdRef.current) {
              intervalIdRef.current = setInterval(checkIfDashboardReady, 3000)
              console.info('set timer', intervalIdRef.current) // Keep log until we are sure that the feature is 100% stable
            }
          }
        })
        .catch((e) => {
          console.error('catch erreur:', e)
          setIsDataReady(true)
          clearTimer()
        })
    }

    return () => clearTimer()
  }, [bsProject, checkIfDashboardReady, clearTimer, listenIsCalculationReady])

  const calculationStatusStore: CalculationStatusStore = useMemo(
    () => ({
      isDataReady,
    }),
    [isDataReady]
  )

  return <CalculationStatusContext.Provider value={calculationStatusStore}>{children}</CalculationStatusContext.Provider>
}

export type CalculationStatusStore = {
  isDataReady: boolean
}
