import AddIcon from '@mui/icons-material/Add'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Modal, TextField, Typography } from '@mui/material'
import React, { ChangeEvent, SetStateAction, useContext, useEffect, useMemo, useState } from 'react'
import {
  acceptedExtensions,
  DisplayerMode,
  fileTypeAccepted,
  UploadImageModal,
} from '../../../../components/image-displayer/upload-image-modal'
import { NumberInput } from '../../../../components/inputs/number-input/number-input'
import { ErrorContext } from '../../../../components/layout/error-snackbar'
import { ProjectContext, ProjectStore } from '../../../../core/context/project/project-context'
import { CodeExtrait } from '../../../../core/dto/code-extrait/code-extrait'
import IniesRecord from '../../../../core/dto/material/inies-record'
import { Material } from '../../../../core/dto/material/material'
import MaterialRecord from '../../../../core/dto/material/material-record'
import { UnitEnum } from '../../../../core/enum/unitEnum'
import { useCode } from '../../../../core/hooks/use-code'
import { useMaterial } from '../../../../core/hooks/use-material'
import { getLabelForIniesUnit } from '../../../../core/services/unit-service'
import AssignMaterialCalculationModalFormModal from '../components/material-page-modal-add-inies/assign-material-calculation-modal'
import { MaterialPageCard } from '../components/material-page-modal-add-inies/material-page-card'
import { AffectMaterialInput } from '../../../../components/inputs/affect-material-input/affect-material-input'

type IProps = {
  readonly selectedCodeExtrait: CodeExtrait
  readonly setSelectedCodeExtrait: React.Dispatch<React.SetStateAction<CodeExtrait | undefined>>
  readonly variantCategory: 1 | 2
  sendCodeExtraitUpdate(): void
  readonly disabled: boolean
}

export function validateReuseImage(
  size: number,
  filename: string,
  setFileTextError: (value: React.SetStateAction<string | undefined>) => void,
  setValidateFile: (value: React.SetStateAction<boolean>) => void
): void {
  if (size > 3 * 1024 * 1024) {
    setFileTextError('Le fichier est trop volumineux. La taille maximale est de 3 Mo.')
    setValidateFile(false)
  } else if (!acceptedExtensions.includes(filename.toLowerCase().substring(filename.lastIndexOf('.')))) {
    setFileTextError(`Le format du fichier n'est pas valide. Voici les extensions acceptés: '${acceptedExtensions}'`)
    setValidateFile(false)
  } else {
    setValidateFile(true)
  }
}

export default function FormAddMaterialToExtracted({
  selectedCodeExtrait,
  setSelectedCodeExtrait,
  variantCategory,
  sendCodeExtraitUpdate,
  disabled,
}: Readonly<IProps>): React.JSX.Element {
  const materialIdFieldName = useMemo(
    () => (variantCategory === 1 ? 'materialIdVariant1' : 'materialIdVariant2'),
    [variantCategory]
  )
  const materialFieldName = useMemo(
    () => (variantCategory === 1 ? 'materialVariant1' : 'materialVariant2'),
    [variantCategory]
  )
  const reuseFieldName = useMemo(() => (variantCategory === 1 ? 'reuseVariant1' : 'reuseVariant2'), [variantCategory])
  const amountFieldName = useMemo(() => (variantCategory === 1 ? 'amountVariant1' : 'amountVariant2'), [variantCategory])

  const { project } = useContext<ProjectStore>(ProjectContext)
  const { addIniesToProject } = useMaterial()
  const { sendExtractCodeMaterialReuseImage, fetchReuseImageExtractedCode: fetchReuseImage } = useCode()

  const [isAssignMaterialModalOpen, setIsAssignMaterialModalOpen] = useState(false)
  const [filterMaterialIdSelected, setFilterMaterialIdSelected] = useState<string>('')

  const [displayMaterialModalOpen, setDisplayMaterialModalOpen] = useState(false)

  const [file, setFile] = useState<File | undefined>(undefined)
  const [imageLoading, setImageLoading] = useState<boolean>(false)
  const [openModalImage, setOpenModalImage] = useState<boolean>(false)
  const [validateFile, setValidateFile] = useState<boolean>(true)
  const [fileTextError, setFileTextError] = useState<string>()

  const [displayerMode, setDisplayerMode] = useState<DisplayerMode>(DisplayerMode.SAVE)

  const openErrorSnackbar = useContext(ErrorContext)

  useEffect(() => {
    if (variantCategory === 1 && selectedCodeExtrait.awsFileKey1 !== null) {
      setDisplayerMode(DisplayerMode.UPDATE)
    }
    if (variantCategory === 2 && selectedCodeExtrait.awsFileKey2 !== null) {
      setDisplayerMode(DisplayerMode.UPDATE)
    }
  }, [selectedCodeExtrait.awsFileKey1, selectedCodeExtrait.awsFileKey2, variantCategory, selectedCodeExtrait])

  function closeAssignMaterialModal(): void {
    setIsAssignMaterialModalOpen(false)
    setFilterMaterialIdSelected('')
  }

  function openAssignMaterialModal(): void {
    if (selectedCodeExtrait?.id) {
      setFilterMaterialIdSelected(selectedCodeExtrait.id)
    }
    setIsAssignMaterialModalOpen(true)
  }

  function getRetainedQuantity(functionalUnit: UnitEnum | undefined): number {
    if (functionalUnit === UnitEnum.PER_UNIT) {
      return selectedCodeExtrait?.overiddenQuantities?.number ?? 0
    }
    if (functionalUnit === UnitEnum.METER) {
      return selectedCodeExtrait?.overiddenQuantities?.length ?? 0
    }
    if (functionalUnit === UnitEnum.SQUARE_METER) {
      return selectedCodeExtrait?.overiddenQuantities?.surface ?? 0
    }
    return 0
  }

  async function handleAddMaterialToCodeExtrait(newValue: IniesRecord | MaterialRecord, isMaterial = false): Promise<void> {
    const functionalUnit: UnitEnum = newValue.functionalUnit
    if (project?.id) {
      let material: Material
      if (!isMaterial) {
        material = await addIniesToProject(project.id, `${newValue.iniesId}`)
      } else {
        material = Material.from(newValue, project)
      }

      setMaterialId(material.id)
      setMaterial(material)
      setReuse(false)
      setAmount(getRetainedQuantity(functionalUnit))

      sendCodeExtraitUpdate()
      closeAssignMaterialModal()
    }
  }

  function isReuseChecked(): boolean {
    if (selectedCodeExtrait) {
      return selectedCodeExtrait[reuseFieldName]
    } else {
      return false
    }
  }

  function removeMaterial(): void {
    setMaterialId(undefined)
    setMaterial(undefined)
    setAmount(0)
    setReuse(false)
    sendCodeExtraitUpdate()
  }

  function handleReuseMaterial(checked: boolean): void {
    if (checked) {
      // Set to reuse true
      setReuse(true)
      setMaterialId(undefined)
      setMaterial(undefined)
      setAmount(0)
    } else {
      setReuse(false)
    }
    sendCodeExtraitUpdate()
  }

  function handleUpdateAmount(value: string): void {
    setAmount(parseFloat(value))
  }

  function handleUpdateHypothesis(e: ChangeEvent<HTMLInputElement>): void {
    setHypothesis(e.target.value)
  }

  function saveAmount(): void {
    sendCodeExtraitUpdate()
  }

  function saveHypothesis(): void {
    sendCodeExtraitUpdate()
  }

  function setReuse(isReuse: boolean): void {
    if (selectedCodeExtrait.sameValuesV1V2 && variantCategory === 1) {
      selectedCodeExtrait.reuseVariant1 = isReuse
      selectedCodeExtrait.reuseVariant2 = isReuse
    } else if (selectedCodeExtrait.sameValuesV1V2 && variantCategory === 2) {
      selectedCodeExtrait.reuseVariant2 = isReuse
      selectedCodeExtrait.sameValuesV1V2 = false
    } else {
      selectedCodeExtrait[reuseFieldName] = isReuse
    }
  }

  function setMaterialId(materialId: string | undefined): void {
    if (selectedCodeExtrait.sameValuesV1V2 && variantCategory === 1) {
      selectedCodeExtrait.materialIdVariant1 = materialId
      selectedCodeExtrait.materialIdVariant2 = materialId
    } else if (selectedCodeExtrait.sameValuesV1V2 && variantCategory === 2) {
      selectedCodeExtrait.materialIdVariant2 = materialId
      selectedCodeExtrait.sameValuesV1V2 = false
    } else {
      selectedCodeExtrait[materialIdFieldName] = materialId
    }
  }

  function setMaterial(material: Material | undefined): void {
    if (selectedCodeExtrait.sameValuesV1V2 && variantCategory === 1) {
      selectedCodeExtrait.materialVariant1 = material
      selectedCodeExtrait.materialVariant2 = material
    } else if (selectedCodeExtrait.sameValuesV1V2 && variantCategory === 2) {
      selectedCodeExtrait.materialVariant1 = material
      selectedCodeExtrait.sameValuesV1V2 = false
    } else {
      selectedCodeExtrait[materialFieldName] = material
    }
  }

  function setAmount(amount: number): void {
    if (selectedCodeExtrait.sameValuesV1V2 && variantCategory === 1) {
      selectedCodeExtrait.amountVariant1 = amount
      selectedCodeExtrait.amountVariant2 = amount
    } else if (selectedCodeExtrait.sameValuesV1V2 && variantCategory === 2) {
      selectedCodeExtrait.amountVariant2 = amount
      selectedCodeExtrait.sameValuesV1V2 = false
    } else {
      selectedCodeExtrait[amountFieldName] = amount
    }
  }

  function setHypothesis(hypothesis: string): void {
    if (selectedCodeExtrait.sameValuesV1V2 && variantCategory === 1) {
      setSelectedCodeExtrait({ ...selectedCodeExtrait, hypothesisVariant1: hypothesis, hypothesisVariant2: hypothesis })
    } else if (selectedCodeExtrait.sameValuesV1V2 && variantCategory === 2) {
      setSelectedCodeExtrait({ ...selectedCodeExtrait, hypothesisVariant2: hypothesis })
      setSelectedCodeExtrait({ ...selectedCodeExtrait, sameValuesV1V2: false })
    } else if (!selectedCodeExtrait.sameValuesV1V2 && variantCategory === 1) {
      setSelectedCodeExtrait({ ...selectedCodeExtrait, hypothesisVariant1: hypothesis })
    } else if (!selectedCodeExtrait.sameValuesV1V2 && variantCategory === 2) {
      setSelectedCodeExtrait({ ...selectedCodeExtrait, hypothesisVariant2: hypothesis })
    }
  }

  function handleChangeFile(event: React.ChangeEvent<HTMLInputElement>): void {
    if (event?.target?.files?.[0]) {
      setDisplayerMode(DisplayerMode.SAVE)
      onChange(event.target.files[0])
      event.target.value = ''
    }
  }

  function onChange(uploadedFile: File): void {
    const image = new Image()
    image.src = URL.createObjectURL(uploadedFile)
    validateReuseImage(uploadedFile.size, uploadedFile.name, setFileTextError, setValidateFile)
    setOpenModalImage(true)
    setImageLoading(true)
    setFile(uploadedFile)
  }

  function cancelUpload(): void {
    if (!file) {
      setFile(undefined)
    }
    setOpenModalImage(false)
  }

  function saveFile(): void {
    if (!file) {
      openErrorSnackbar(new Error("Erreur: il n'y a pas de fichier chargé"))
      return
    }
    if (!project.id) {
      openErrorSnackbar(new Error("Aucun projet n'est sélectionné."))
      return
    }
    if (!selectedCodeExtrait.id) {
      openErrorSnackbar(new Error("Aucun code n'est sélectionné."))
      return
    }
    if (selectedCodeExtrait.sameValuesV1V2 && variantCategory === 1) {
      sendExtractCodeMaterialReuseImage(file, project.id, selectedCodeExtrait.id, '1')
        .then(() => {
          selectedCodeExtrait.filename1 = file.name

          if (!project.id) {
            openErrorSnackbar(new Error("Aucun projet n'est sélectionné."))
            return
          }
          if (!selectedCodeExtrait.id) {
            openErrorSnackbar(new Error("Aucun code n'est sélectionné."))
            return
          }
          sendExtractCodeMaterialReuseImage(file, project?.id, selectedCodeExtrait.id, '2').then((response) => {
            setSelectedCodeExtrait(response)
            selectedCodeExtrait.filename2 = file.name
          })
        })
        .then(() => sendCodeExtraitUpdate())
        .catch(() => setFile(undefined))
    } else if (selectedCodeExtrait.sameValuesV1V2 && variantCategory === 2) {
      sendExtractCodeMaterialReuseImage(file, project.id, selectedCodeExtrait.id, '2')
        .then((response) => {
          setSelectedCodeExtrait({ ...response, sameValuesV1V2: false, filename2: file.name })
          selectedCodeExtrait.filename2 = file.name
          selectedCodeExtrait.sameValuesV1V2 = false
        })
        .then(() => sendCodeExtraitUpdate())
        .catch(() => setFile(undefined))
    } else if (!selectedCodeExtrait.sameValuesV1V2 && variantCategory === 1) {
      sendExtractCodeMaterialReuseImage(file, project.id, selectedCodeExtrait.id, '1')
        .then((response) => {
          setSelectedCodeExtrait(response)
          selectedCodeExtrait.filename1 = file.name
        })
        .then(() => sendCodeExtraitUpdate())
        .catch(() => setFile(undefined))
    } else if (!selectedCodeExtrait.sameValuesV1V2 && variantCategory === 2) {
      sendExtractCodeMaterialReuseImage(file, project.id, selectedCodeExtrait.id, '2')
        .then((response) => {
          setSelectedCodeExtrait(response)
          selectedCodeExtrait.filename2 = file.name
        })
        .then(() => sendCodeExtraitUpdate())
        .catch(() => setFile(undefined))
    }
    setOpenModalImage(false)
  }

  function reuseImageModal(): void {
    if (selectedCodeExtrait.id) {
      fetchReuseImage(selectedCodeExtrait.id, variantCategory.toString())
        .then(async (blob) => {
          if (blob) {
            const fileName = variantCategory === 1 ? selectedCodeExtrait.filename1 : selectedCodeExtrait.filename2
            let newFile: SetStateAction<File | undefined>
            if (fileName) {
              const fileType = variantCategory === 1 ? selectedCodeExtrait.fileType1 : selectedCodeExtrait.fileType2
              newFile = new File([blob], fileName, { type: fileType })
            }
            setFile(newFile)
            setValidateFile(true)
            setDisplayerMode(DisplayerMode.UPDATE)
          }
        })
        .then(() => {
          setImageLoading(true)
        })
      setOpenModalImage(true)
    }
  }

  function handleCloseReuseModalImage(): void {
    setOpenModalImage(false)
    setFile(undefined)
    setImageLoading(false)
  }

  function getHypothesis(): string {
    const hypothesis =
      variantCategory === 1 ? selectedCodeExtrait.hypothesisVariant1 : selectedCodeExtrait.hypothesisVariant2
    return hypothesis ?? ''
  }

  function refreshSelectedCodeExtrait(materialDto: Material): void {
    setSelectedCodeExtrait({ ...selectedCodeExtrait, materialVariant1: materialDto, materialVariant2: materialDto })
  }

  return (
    <>
      {/*Modal to assign material on a code*/}
      <AssignMaterialCalculationModalFormModal
        handleAssignMaterial={handleAddMaterialToCodeExtrait}
        isAssignMaterialModalOpen={isAssignMaterialModalOpen}
        closeAssignMaterialModal={closeAssignMaterialModal}
        materialId={filterMaterialIdSelected}
      />

      <UploadImageModal
        file={file}
        saveFile={saveFile}
        cancelUpload={cancelUpload}
        validateImage={validateFile}
        handleChangeFile={handleChangeFile}
        imageTextError={fileTextError}
        fileType={fileTypeAccepted}
        open={openModalImage}
        handleClose={handleCloseReuseModalImage}
        imageTitle='Matériau de réemploi'
        isloading={imageLoading}
        displayMode={displayerMode}
      />

      <Modal
        open={displayMaterialModalOpen}
        onClose={() => setDisplayMaterialModalOpen(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        aria-labelledby='add-material-modal'
        aria-describedby='modal-modal-description'>
        <Box>
          <MaterialPageCard
            material={variantCategory === 1 ? selectedCodeExtrait?.materialVariant1 : selectedCodeExtrait?.materialVariant2}
            isMaterial
            setDisplayMaterialModalOpen={setDisplayMaterialModalOpen}
            refreshSelectedCodeExtrait={refreshSelectedCodeExtrait}
          />
        </Box>
      </Modal>

      <Box
        component='div'
        display='flex'
        flexDirection='column'
        sx={{ pr: 3, pl: 3, pt: 4, pb: 4, backgroundColor: '#ebebeb' }}>
        <Typography sx={{ textTransform: 'uppercase', fontWeight: 'bold', mb: 4 }}>données extraites</Typography>
        <Grid container>
          <Grid item sx={{ minHeight: '350px' }}>
            <AffectMaterialInput
              openAssignMaterialModal={openAssignMaterialModal}
              disabled={disabled}
              removeMaterial={removeMaterial}
              selectedCode={selectedCodeExtrait}
              variantCategory={variantCategory}
            />

            {selectedCodeExtrait[materialFieldName] && (
              <Button
                className='notranslate'
                onClick={() => setDisplayMaterialModalOpen(true)}
                style={{ textTransform: 'none' }}
                endIcon={<ArrowOutwardIcon />}
                sx={{ mb: 2, border: 1, borderWidth: 2 }}>
                {selectedCodeExtrait[materialFieldName]?.fdesName}
              </Button>
            )}

            <Box component='div' display='flex' flexDirection='column'>
              <FormGroup
                sx={{
                  display: 'flex',
                  alignContent: 'space-around',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isReuseChecked()}
                      onChange={(_, checked) => handleReuseMaterial(checked)}
                      disabled={disabled}
                    />
                  }
                  label="Il s'agit d'un matériau de réemploi"
                />
              </FormGroup>

              {isReuseChecked() && (
                <Box component='div' sx={{ display: 'flex', flexDirection: 'row', marginLeft: '20px' }}>
                  <Box component='label' htmlFor='upload-photo'>
                    <input
                      accept={fileTypeAccepted}
                      style={{ display: 'none' }}
                      onChange={handleChangeFile}
                      id='upload-photo'
                      name='upload-photo'
                      type='file'
                    />
                    <Button disabled={disabled} style={{ textTransform: 'none' }} sx={{ mb: 2 }} component='span'>
                      <AddIcon sx={{ mr: 2 }} />
                      Affecter une image
                    </Button>
                  </Box>
                </Box>
              )}

              {(variantCategory === 1 ? selectedCodeExtrait.filename1 : selectedCodeExtrait.filename2) && isReuseChecked() && (
                <Button
                  className='notranslate'
                  onClick={reuseImageModal}
                  style={{ textTransform: 'none' }}
                  sx={{ mb: 2, border: 1, borderWidth: 2 }}
                  disabled={disabled}>
                  {variantCategory === 1 ? selectedCodeExtrait.filename1 : selectedCodeExtrait.filename2}
                </Button>
              )}

              {selectedCodeExtrait[materialFieldName]?.functionalUnit && (
                <div onBlur={saveAmount}>
                  <NumberInput
                    id={amountFieldName}
                    label='Quantité'
                    variant='outlined'
                    size='small'
                    value={selectedCodeExtrait[amountFieldName]}
                    decimalScale={2}
                    disabled={
                      selectedCodeExtrait[materialFieldName]?.functionalUnit === UnitEnum.PER_UNIT ||
                      selectedCodeExtrait[materialFieldName]?.functionalUnit === UnitEnum.METER ||
                      selectedCodeExtrait[materialFieldName]?.functionalUnit === UnitEnum.SQUARE_METER ||
                      disabled
                    }
                    handleChange={handleUpdateAmount}
                    shrink
                    unit={getLabelForIniesUnit(selectedCodeExtrait[materialFieldName]?.functionalUnit)}
                  />
                </div>
              )}
              <div onBlur={saveHypothesis}>
                <TextField
                  sx={{ mt: 2, width: '100%' }}
                  value={getHypothesis()}
                  onChange={handleUpdateHypothesis}
                  label='Hypothèse BE'
                  multiline
                  rows={4}
                  disabled={disabled}
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
