export enum baseQuantityEnum {
  height = 'Height',
  width = 'Width',
  length = 'Length',
  area = 'Area',
  netArea = 'NetArea',
  netVolume = 'NetVolume',
  netSideArea = 'NetSideArea',
  grossWeight = 'GrossWeight',
  crossSectionArea = 'CrossSectionArea',
  outerSurfaceArea = 'OuterSurfaceArea',
  grossFootprintArea = 'GrossFootprintArea',
  grossSideArea = 'GrossSideArea',
  grossArea = 'GrossArea',
  grossVolume = 'GrossVolume',
  perimeter = 'Perimeter',
  totalSurfaceArea = 'TotalSurfaceArea',
}
