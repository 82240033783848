import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import React from 'react'
import IniesRecord from '../../../../../../core/dto/material/inies-record'
import MaterialRecord from '../../../../../../core/dto/material/material-record'
import { getDeclarationTypeBackground } from '../../../../../../core/services/declaration-type-service'
import MaterialRow from './material-row'

type MaterialPageTableProps = {
  onSelected(row: IniesRecord | MaterialRecord | undefined): void
  selectedRow: IniesRecord | MaterialRecord | undefined
  records: IniesRecord[] | MaterialRecord[] | []
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  rowsPerPage: number
  setRowsPerPage(newRowsPerPage: number): void
  handleSearchPaginated(event: React.MouseEvent | null, newPage: number): void
  tab: 1 | 2
}

export default function MaterialPageTable({
  onSelected,
  selectedRow,
  records,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  handleSearchPaginated,
  tab,
}: Readonly<MaterialPageTableProps>): React.JSX.Element {
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <TableContainer sx={{ mt: 4 }}>
      <Table sx={{ minWidth: 650, height: 500 }} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>
              Typologie <br /> de déclaration
            </TableCell>
            <TableCell align='left'>Nom du produit</TableCell>
            <TableCell align='left'>Fournisseur & réference</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {records?.map((record) => (
            <MaterialRow
              key={record.id}
              onSelected={onSelected}
              record={record}
              isSelected={selectedRow?.id === record.id}
              getTypologyBackground={getDeclarationTypeBackground}
              tab={tab}
            />
          ))}

          {records?.length < rowsPerPage && (
            <TableRow
              style={{
                height: (600 / rowsPerPage) * (rowsPerPage - records.length),
              }}>
              <TableCell colSpan={12} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        sx={{
          mt: 1,
          display: 'flex',
          justifyContent: 'center',
        }}
        component='div'
        count={records?.length === rowsPerPage ? -1 : records?.length}
        page={page}
        onPageChange={handleSearchPaginated}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50]}
        labelDisplayedRows={({ from, to }) =>
          `${from < rowsPerPage ? from : `... ${from}`}-${records.length === rowsPerPage ? ` ${to} ...` : from + to} `
        }
        labelRowsPerPage='Ligne par page:'
      />
    </TableContainer>
  )
}
