import { format, isValid } from 'date-fns'

export function formatToFrench(date: Date | undefined): string {
  // Despite the warning "date instanceof Date" is important because sometimes it's string or else
  // And then we get the error "Invalid time" which crash the app. Typescript is not good enough to ensure the type
  if (date && date instanceof Date) {
    return format(date, 'dd/MM/yyyy')
  } else {
    return ''
  }
}

export function formatToLocalDateString(date: Date | undefined): string {
  // Despite the warning "date instanceof Date" is important because sometimes it's string or else
  // And then we get the error "Invalid time" which crash the app. Typescript is not good enough to ensure the type
  if (date && date instanceof Date) {
    return format(date, 'yyyy-MM-dd')
  } else {
    return ''
  }
}

function formatNumberWithZero(number: number): string {
  return number.toString().padStart(2, '0')
}

export function formatToFrenchWithHours(date: Date | undefined): string {
  if (date && date instanceof Date) {
    const hours = formatNumberWithZero(date.getHours())
    const minutes = formatNumberWithZero(date.getMinutes())
    const seconds = formatNumberWithZero(date.getSeconds())
    return `${format(date, 'dd/MM/yyyy')} à ${hours}:${minutes}:${seconds}`
  } else {
    return ''
  }
}

export function stringToDate(date: string | undefined): Date | undefined {
  if (date) {
    return new Date(date)
  } else {
    return undefined
  }
}

export function isDateValid(date: Date | undefined): boolean {
  // Despite the warning "date instanceof Date" is important because sometimes it's string or else
  // And then we get the error "Invalid time" which crash the app. Typescript is not good enough to ensure the type
  return !!date && date instanceof Date && isValid(date) && date.getFullYear() > 1000 && date.getFullYear() < 2100
}
