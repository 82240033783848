export function getEnvProperty(propertyName: string): string {
  // @ts-ignore: This is a global mecanism to handle env properties in each environment
  if (window?.environment && window.environment[propertyName]) {
    // @ts-ignore: This is a global mecanism to handle env properties in each environment
    return window.environment[propertyName]
  } else {
    // @ts-ignore: This is a global mecanism to handle env properties in each environment
    return process.env[propertyName]
  }
}

export const PROPERTIES = {
  REACT_APP_NOINDEX: 'REACT_APP_NOINDEX',
  REACT_APP_ENVIRONMENT: 'REACT_APP_ENVIRONMENT',
  REACT_APP_API_URL: 'REACT_APP_API_URL',
  REACT_APP_AWS_REGION: 'REACT_APP_AWS_REGION',
  REACT_APP_USER_POOL_ID: 'REACT_APP_USER_POOL_ID',
  REACT_APP_USER_POOL_WEB_CLIENT_ID: 'REACT_APP_USER_POOL_WEB_CLIENT_ID',
  REACT_APP_COOKIE_STORAGE_DOMAIN: 'REACT_APP_COOKIE_STORAGE_DOMAIN',
  REACT_APP_BASE_URL: 'REACT_APP_BASE_URL',
  REACT_APP_COOKIE_SECURE: 'REACT_APP_COOKIE_SECURE',
  REACT_APP_WASM_PATH: 'REACT_APP_WASM_PATH',
  REACT_APP_TABLEAU_URL: 'REACT_APP_TABLEAU_URL',
  REACT_APP_TABLEAU_RSEE_URL: 'REACT_APP_TABLEAU_RSEE_URL',
  REACT_APP_FEATURE_DELETE_RSEE: 'REACT_APP_FEATURE_DELETE_RSEE',
  REACT_APP_RECAPTCHA_SITE_KEY: 'REACT_APP_RECAPTCHA_SITE_KEY',
  REACT_APP_KAIRNIAL_DASHBOARD_URL: 'REACT_APP_KAIRNIAL_DASHBOARD_URL',
}
