import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export function IconBeemMaster(props: SvgIconProps): React.JSX.Element {
  return (
    <SvgIcon {...props}>
      {/*  SVG from https://tablericons.com/ */}
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g id='icon-components'>
          <g id='Vector'>
            <path
              d='M3 12L6 15L9 12L6 9L3 12Z'
              stroke='white'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M15 12L18 15L21 12L18 9L15 12Z'
              stroke='white'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path d='M9 6L12 9L15 6L12 3L9 6Z' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            <path
              d='M9 18L12 21L15 18L12 15L9 18Z'
              stroke='white'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  )
}
