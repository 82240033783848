import { Typography } from '@mui/material'

type AccountHeadingFormProps = {
  title: string
}

export default function AccountHeadingForm(props: AccountHeadingFormProps): React.JSX.Element {
  const { title } = props
  return (
    <Typography variant='h5' fontSize='1rem' gutterBottom fontWeight='bold'>
      {title}
    </Typography>
  )
}
