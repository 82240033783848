import { Dispatch, SetStateAction } from 'react'

export class CalculationCarbonFlateRateStore {
  flateRate1: any
  setFlateRate1: Dispatch<SetStateAction<any>>
  flateRate2: any
  setFlateRate2: Dispatch<SetStateAction<any>>
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  error: string | undefined
  setError: Dispatch<SetStateAction<string | undefined>>

  constructor(
    flateRate1: any,
    setFlateRate1: Dispatch<SetStateAction<any>>,
    flateRate2: any,
    setFlateRate2: Dispatch<SetStateAction<any>>,
    loading: boolean,
    setLoading: Dispatch<SetStateAction<boolean>>,
    error: string | undefined,
    setError: Dispatch<SetStateAction<string | undefined>>
  ) {
    this.flateRate1 = flateRate1
    this.setFlateRate1 = setFlateRate1
    this.flateRate2 = flateRate2
    this.setFlateRate2 = setFlateRate2
    this.loading = loading
    this.setLoading = setLoading
    this.error = error
    this.setError = setError
  }
}
