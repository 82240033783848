import { Button } from '@mui/material'
import React from 'react'

type IProps<T> = {
  value: T
  currentValue: T
  label: string
  onClick: (mode: T) => void
}

export default function FilterButton<T>({ value, currentValue, label, onClick }: IProps<T>): React.JSX.Element {
  return (
    <Button
      color='info'
      variant='contained'
      sx={{ width: '100%', height: '100%', backgroundColor: value === currentValue ? '#e0e0e0' : undefined }}
      onClick={() => onClick(value)}>
      {label}
    </Button>
  )
}
