import { PhaseEnum } from '../../enum/phaseEnum'
import FlatRates from './flat-rates'
import WorksiteIc from './worksite-ic'

export class Variant {
  id?: string = undefined

  bimModelId = ''

  flatRates: FlatRates = new FlatRates()

  icChantier: WorksiteIc = new WorksiteIc()

  projectId = ''

  name = ''

  phase: PhaseEnum = PhaseEnum.ESQ
}
