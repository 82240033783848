import { Dispatch, SetStateAction, useCallback, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { appConstants } from '../../appConstants'
import { MaterialLibContext } from '../../context/material/material-lib-context'
import { UserContext } from '../../context/user/user-context'
import MaterialRecord from '../../dto/material/material-record'
import { isUltimateUser } from '../../services/authentication-service'
import { useHttp } from '../use-http'
import { DeclarationFilter } from '../../enum/declarationFilter'

export class OrganizationRecordFilter {
  containedInName: string | undefined = undefined
  classificationLvl0: DeclarationFilter | undefined = undefined
  classificationLvl1: number | undefined = undefined
  classificationLvl2: number | undefined = undefined
  classificationLvl3: number | undefined = undefined
  classificationLvl4: number | undefined = undefined
  responsibleOrganism: string | undefined = undefined
  declarationType: string | undefined = undefined
}

export type UseOrganizationRecordsFilteredVariable = {
  filter: OrganizationRecordFilter
  lastFdesName?: string
}

type UseOrganizationRecordsFilteredReturn = {
  loading: boolean
  search: ({ filter, lastFdesName }: UseOrganizationRecordsFilteredVariable) => Promise<MaterialRecord[]>
  materialRecords: MaterialRecord[]
  reset: () => void
  limit: number
  setLimit: Dispatch<SetStateAction<number>>
}

export const useOrganizationRecordsFiltered = (): UseOrganizationRecordsFilteredReturn => {
  const { post } = useHttp()
  const { user } = useContext(UserContext)
  const { organizationId } = useParams()
  const isOrganizationUltimateUser = isUltimateUser(user)
  const { materialRecords, setMaterialRecords, loading, setLoading, limit, setLimit } = useContext(MaterialLibContext)

  const reset = useCallback((): void => setMaterialRecords([]), [setMaterialRecords])

  const search = useCallback(
    async ({ filter, lastFdesName }: UseOrganizationRecordsFilteredVariable): Promise<MaterialRecord[]> => {
      setLoading(true)

      let params = [
        {
          key: 'limit',
          value: String(limit),
        },
      ]

      if (lastFdesName) {
        params = [
          ...params,
          {
            key: 'lastFdesName',
            value: encodeURI(lastFdesName),
          },
        ]
      }
      if (organizationId) {
        params = [
          ...params,
          {
            key: 'organizationId',
            value: organizationId,
          },
        ]
      }

      return isOrganizationUltimateUser
        ? post(appConstants.apiEndpoints.ORGANIZATION_RECORDS_FILTERS_PAGINATED_ADMIN, filter, params)
            .then((response) => response.json())
            .then((res: any[]) => {
              const materialArray: MaterialRecord[] = res.map((record) => MaterialRecord.fromDto(record))
              setMaterialRecords(materialArray)
              return materialArray
            })
        : post(appConstants.apiEndpoints.ORGANIZATION_RECORDS_FILTERS_PAGINATED, filter, params)
            .then((response) => response.json())
            .then((res: any[]) => {
              const materialArray: MaterialRecord[] = res.map((record) => MaterialRecord.fromDto(record))
              setMaterialRecords(materialArray)
              return materialArray
            })
            .finally(() => {
              setLoading(false)
            })
    },
    [setLoading, organizationId, isOrganizationUltimateUser, post, setMaterialRecords]
  )

  return {
    loading,
    search,
    materialRecords,
    reset,
    limit,
    setLimit,
  }
}
