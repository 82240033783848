import { Box, Button, Typography } from '@mui/material'
import { IconPencil } from '../../components/custom-icons/icon-pencil'

export function DownloadRevitPlugin(): React.JSX.Element {
  return (
    <Box display='flex' flexDirection='column' borderRadius={4} gap={3} sx={{ background: '#C9FFE8', p: 3 }}>
      <Box display='flex' gap={1}>
        <IconPencil />
        <Typography variant='subtitle1'>Installer le plugin REVIT</Typography>
      </Box>

      <Typography variant='body1'>
        Repoussez les limites de votre travail avec ce plugin gratuit qui vous fera gagner du temps
      </Typography>

      <Button variant='contained' sx={{ width: '40%', borderRadius: 3 }}>
        Installer le plugin
      </Button>
    </Box>
  )
}
