import UploadFileIcon from '@mui/icons-material/UploadFile'
import { Box, Button, FormLabel, Grid, Link, Paper } from '@mui/material'
import React, { useRef, useState } from 'react'
import { appConstants } from '../../../core/appConstants'
import { ImageDisplayer } from '../../image-displayer/image-displayer'
import { DefaultTypography } from '../../typography/default-typography'

type IProps = {
  onChange: (modelFile: File) => void
  cancelUpload: () => void
  deleteImage: () => void
  file: File | undefined
  disabled: boolean
  fileType: string
}

export default function BsFileInputImage({ onChange, deleteImage, file, disabled, fileType }: IProps): React.JSX.Element {
  const [isDragOver, setIsDragOver] = useState(false)
  const [isMouseOver, setIsMouseOver] = useState(false)
  const dragEnterCount = useRef(0)

  function handleChangeFile(event: React.ChangeEvent<HTMLInputElement>): void {
    if (event?.target?.files && event.target.files[0]) {
      onChange(event.target.files[0])
      event.target.value = ''
    }
  }

  function stopDefaults(e: any): void {
    e.stopPropagation()
    e.preventDefault()
  }

  const dragEvents = {
    onMouseEnter: () => {
      setIsMouseOver(true)
    },
    onMouseLeave: () => {
      setIsMouseOver(false)
    },
    onDragEnter: (e: React.DragEvent) => {
      stopDefaults(e)
      dragEnterCount.current += 1
      if (dragEnterCount.current === 1) {
        setIsDragOver(true)
      }
    },
    onDragLeave: (e: React.DragEvent) => {
      stopDefaults(e)
      dragEnterCount.current -= 1
      if (dragEnterCount.current === 0) {
        // Drag has actually left the container
        setIsDragOver(false)
      }
    },
    onDragOver: stopDefaults,
    onDrop: (e: React.DragEvent) => {
      stopDefaults(e)
      setIsDragOver(false)
      dragEnterCount.current = 0
      const files = e?.dataTransfer?.files
      if (files?.length && files[0]) {
        onChange(e.dataTransfer.files[0])
      }
    },
  }

  return file ? (
    <Grid container rowSpacing={2} columnSpacing={3} justifyContent='center'>
      <Grid item xs={12} lg={9} display='flex' justifyContent='center'>
        <FormLabel>Modifier la photo de couverture</FormLabel>
      </Grid>
      <Grid item xs={0} lg={3} />
      <Grid item xs={12} lg={9} display='flex' justifyContent='center'>
        <ImageDisplayer
          file={file}
          width={appConstants.miscellaneous.PROJECT_IMG_WIDTH}
          height={appConstants.miscellaneous.PROJECT_IMG_HEIGTH}
        />
        <input
          accept={fileType}
          style={{ display: 'none' }}
          onChange={handleChangeFile}
          id='upload-photo'
          name='upload-photo'
          type='file'
        />
      </Grid>
      <Grid item xs={12} lg={3} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
        <input
          accept={fileType}
          style={{ display: 'none' }}
          onChange={handleChangeFile}
          id='upload-photo-button'
          name='upload-photo-button'
          type='file'
        />
        {!disabled && (
          <>
            <Box component='label' htmlFor='upload-photo-button'>
              <Button variant='contained' component='span'>
                Changer
              </Button>
            </Box>
            <Link onClick={deleteImage} sx={{ cursor: 'pointer' }}>
              Supprimer la photo
            </Link>
          </>
        )}
      </Grid>
    </Grid>
  ) : (
    <Box flexDirection='column'>
      <Box sx={{ mb: 2 }}>
        <DefaultTypography label='Image de couverture' />
      </Box>
      <Paper
        variant='outlined'
        sx={{
          display: 'flex',
          justifyContent: 'center',
          border: '2px dashed #cccccc',
          alignItems: 'center',
          width: '100%',
          height: '50%',
        }}>
        <input
          onChange={handleChangeFile}
          style={{ display: 'none' }}
          accept={fileType}
          id='file-upload'
          type='file'
          disabled={disabled}
        />
        <Box
          component='label'
          htmlFor='file-upload'
          {...dragEvents}
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: disabled ? '' : 'pointer',
            textAlign: 'center',
            noMouseEvent: {
              pointerEvents: 'none',
            },
            opacity: isMouseOver || isDragOver ? 1 : 1.3,
          }}>
          <UploadFileIcon sx={{ color: '#0BE2A0' }} fontSize='large' />
          <DefaultTypography label='Selectionnez' color='#0BE2A0' textDecoration='underline' fontSize='16px' />
          <DefaultTypography label='ou déposez votre fichier' fontSize='12px' />
          <DefaultTypography label='Format' color='#8F8F8F' fontSize='12px' />
        </Box>
      </Paper>
    </Box>
  )
}
