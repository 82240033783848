import React from 'react'
import { ConfirmationDialog } from '../../dialog/confirmation-dialog'

type iProps = {
  openDeleteUser: boolean
  handleClose(): void
  onDelete(): Promise<void>
}

export default function UserTableDialog({ openDeleteUser, handleClose, onDelete }: iProps): React.JSX.Element {
  return (
    <ConfirmationDialog
      id='deleteUser'
      title='Confirmation de la suppression'
      contentText=' Êtes-vous sûr de vouloir supprimer cette utilisateur ?'
      handleClose={handleClose}
      action={onDelete}
      open={openDeleteUser}
    />
  )
}
