import { BufferGeometry, Line, Material, Vector3 } from 'three'
import { PointData } from './measurement/annotations/PointData'

/**
 * Ray line that was detected during ray casting, contains the vectors and the geometry object
 */
export class RayLine {
  vectors: Vector3[]
  object: Line<BufferGeometry, Material | Material[]>
  points: PointData[] = []

  constructor(vectors: Vector3[], object: Line<BufferGeometry, Material | Material[]>) {
    this.vectors = vectors
    this.object = object
  }
}
