import { Box, CircularProgress, Tab, Tabs, Typography } from '@mui/material'
import React from 'react'
import { SubsetInfo } from '../../../../components/ifc-displayer/models/SubsetInfo'
import { CodeExtrait } from '../../../../core/dto/code-extrait/code-extrait'
import { codeStateEnum } from '../../../../core/enum/codeStateEnum'
import { CodeDisplayer } from './CodeDisplayer'
import { ExtractedCodeDisplayer } from './ExtractedCodeDisplayer'
import { ExtractedMissingCodeDisplayer } from './ExtractedMissingCodeDisplayer'

type iProps = {
  variant: codeStateEnum
  displayedCodeExtrait: CodeExtrait[]
  handleClickAcv?: (codeExtract: CodeExtrait) => void
  handleClickedCodeManquant?: (subset: SubsetInfo) => void
  selectedCodeExtrait?: CodeExtrait
  selectedCodeManquantElement?: SubsetInfo | undefined
  codeManquantElements: SubsetInfo[]
  updateCodeState: (newState: codeStateEnum, unhighlight?: boolean) => void
  progress: number
}

export function ExtractedTabPanel({
  handleClickAcv,
  selectedCodeExtrait,
  selectedCodeManquantElement,
  displayedCodeExtrait,
  codeManquantElements,
  updateCodeState,
  handleClickedCodeManquant,
  variant,
  progress,
}: Readonly<iProps>): React.JSX.Element {
  function getValue(): number {
    switch (variant) {
      case codeStateEnum.CODE_ACV:
        return 0
      case codeStateEnum.CODE_INVALIDE:
        return 1
      case codeStateEnum.CODE_MANQUANT:
        return 2

      default:
        return 0
    }
  }

  return (
    <>
      <Box display='flex' justifyContent='center' width='80%'>
        <Tabs
          value={getValue()}
          aria-label='Tabs exemple'
          variant='fullWidth'
          TabIndicatorProps={{ style: { display: 'none' } }}
          textColor='inherit'
          sx={{ p: 0.2, my: 3, backgroundColor: '#ffffff', borderRadius: 2, height: '1vh', boxShadow: 1 }}>
          <Tab
            label='Objets renseignés'
            id='tab-0'
            onClick={() => updateCodeState(codeStateEnum.CODE_ACV, true)}
            sx={{
              backgroundColor: variant === codeStateEnum.CODE_ACV ? '#E6F0FB' : '',
              borderRadius: '15px',
              height: '1vh',
              color: variant === codeStateEnum.CODE_ACV ? '#40548D' : '',
            }}
          />
          <Tab
            label='Erreurs'
            id='tab-1'
            onClick={() => updateCodeState(codeStateEnum.CODE_INVALIDE, true)}
            sx={{
              backgroundColor: variant === codeStateEnum.CODE_INVALIDE ? '#E6F0FB' : '',
              borderRadius: '15px',
              height: '1vh',
              color: variant === codeStateEnum.CODE_INVALIDE ? '#40548D' : '',
            }}
          />
          <Tab
            label='Objets non renseignés'
            id='tab-2'
            onClick={() => updateCodeState(codeStateEnum.CODE_MANQUANT, true)}
            sx={{
              backgroundColor: variant === codeStateEnum.CODE_MANQUANT ? '#E6F0FB' : '',
              borderRadius: '15px',
              height: '1vh',
              color: variant === codeStateEnum.CODE_MANQUANT ? '#40548D' : '',
            }}
          />
        </Tabs>
      </Box>

      {progress !== 100 && (
        <Box>
          <CircularProgress />
        </Box>
      )}
      {progress === 100 && variant === codeStateEnum.CODE_ACV && (
        <ExtractedCodeDisplayer
          codesExtraits={displayedCodeExtrait}
          handleClickAcv={handleClickAcv}
          selectedCodeExtrait={selectedCodeExtrait}
        />
      )}

      {progress === 100 && variant === codeStateEnum.CODE_INVALIDE && (
        <Box borderRadius={3} boxShadow={1} width='90%' sx={{ background: 'white', p: 2 }}>
          {displayedCodeExtrait.map((codeExtrait) => (
            <CodeDisplayer
              key={codeExtrait.id}
              highlightBorder={
                codeExtrait.code + codeExtrait.occurence ===
                `${selectedCodeExtrait ? selectedCodeExtrait.code + selectedCodeExtrait.occurence : ''}`
              }
              codeExtrait={codeExtrait}
              codeTitle={codeExtrait.descriptionFromCodeAcv || codeExtrait.descriptionFromBimModel}
              codeColor={codeExtrait.color}
              handleClickAcv={() => {
                if (handleClickAcv) handleClickAcv(codeExtrait)
              }}
            />
          ))}

          {displayedCodeExtrait.length === 0 && <Typography variant='body1'> Aucune erreur n'a été detecté</Typography>}
        </Box>
      )}

      {progress === 100 && variant === codeStateEnum.CODE_MANQUANT && (
        <ExtractedMissingCodeDisplayer
          subsetInfo={codeManquantElements}
          handleClickedCodeManquant={handleClickedCodeManquant}
          selectedCodeManquantElement={selectedCodeManquantElement}
        />
      )}
    </>
  )
}
