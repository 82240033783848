import { Box, Grid } from '@mui/material'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import SupportFormComponent from '../../components/forms/support-form-component'
import { SuccessContext } from '../../components/layout/success-snackbar'
import { pagesUrl } from '../../core/appConstants'

import { SupportForm } from '../../core/dto/support/support-form'
import { useUser } from '../../core/hooks/use-user'
import TitleLogo from '../account-page/account-title'

export default function SupportPage(): React.JSX.Element {
  const navigate = useNavigate()
  const openSuccessSnackbar = useContext(SuccessContext)
  const { sendSupportForm } = useUser()

  function sendMessageToSupportTeam(supportForm: SupportForm): Promise<void> {
    sendSupportForm(supportForm)
      .then(() => {
        openSuccessSnackbar("Message envoyé. L'équipe support va prendre contact avec vous.")
        navigate(pagesUrl.PROJECTS_PAGE)
      })
      .catch((error: any) => {
        console.error('Issue with supportForm :', error)
      })
    return Promise.resolve()
  }

  return (
    <Box>
      <Box>
        <TitleLogo title='Contact' logo={{ id: 'support' }} />
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SupportFormComponent onSubmit={sendMessageToSupportTeam} />
        </Grid>
      </Grid>
    </Box>
  )
}
