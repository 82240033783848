import Box from '@mui/material/Box'
import React, { useContext } from 'react'
import PhaseItem from './phase-item'
import { ProjectContext } from '../../../../core/context/project/project-context'
import { PhaseEnum, phaseList } from '../../../../core/enum/phaseEnum'

export default function ListPhaseItem(): React.JSX.Element {
  const { project } = useContext(ProjectContext)

  function inProject(phase: PhaseEnum): boolean {
    if (project.phases !== undefined) {
      const index = project.phases.findIndex((projectPhase) => projectPhase.phase === phase)
      return index !== -1
    }
    return false
  }

  return (
    <Box display='flex' justifyContent='space-around' height='100%' sx={{ border: '2px solid', borderRadius: 1, pt: 0 }}>
      {phaseList.map((phase) => inProject(phase) && <PhaseItem key={phase} phaseEnum={phase} />)}
    </Box>
  )
}
