import React, { useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { pagesUrl } from '../../core/appConstants'
import { UserContext } from '../../core/context/user/user-context'
import { OrganizationRoleEnum } from '../../core/enum/user/organization-role-enum'

type IProps = {
  children: React.JSX.Element | null
}
export default function RequireAuthUltimate({ children }: IProps): React.JSX.Element | null {
  const location = useLocation()
  const { user, userLoading, isFromLogoutButton, setIsFromLogoutButton } = useContext(UserContext)

  if ((user === undefined || user === null || user.role !== OrganizationRoleEnum.ULTIMATE_USER) && !userLoading) {
    let state
    // Redirect them to the "sign in" page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    if (!isFromLogoutButton) {
      //we don't want to come back to the page if its the user choice to disconnect
      const pathname: string = location.pathname
      const search: string | undefined = location?.search
      state = { from: `${pathname}${search || ''}` }
    } else {
      setIsFromLogoutButton(false)
    }
    return <Navigate to={pagesUrl.SIGN_IN_PAGE} state={state} replace />
  }

  return children
}
