import { BottomNavigation, Paper } from '@mui/material'
import React, { useState } from 'react'
import { ValidateButton } from '../../components/buttons/validate-button/validate-button'
import { useKairnial } from './useKairnial'

type IProps = {
  secureToken: string
  clientId: string
}

export function KairnialRefreshNavigation({ secureToken, clientId }: Readonly<IProps>): React.JSX.Element {
  const { refreshData } = useKairnial()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  return (
    <Paper elevation={24}>
      <BottomNavigation
        sx={{
          position: 'fixed',
          bottom: 20,
          right: 20,
          pr: 2,
          borderRadius: 3,
          display: 'flex',
          alignItems: 'center',
          height: '8vh',
          justifyContent: 'flex-end',
          alignContent: 'center',
          gap: 2,
        }}>
        <ValidateButton
          action={() => {
            setIsSubmitting(true)
            refreshData(secureToken, clientId).finally(() => setIsSubmitting(false))
          }}
          label='Rafraîchir'
          isSubmiting={isSubmitting}
        />
      </BottomNavigation>
    </Paper>
  )
}
