import React, { useEffect, useState } from 'react'
import { isAfter, startOfDay } from 'date-fns'
import BaseTable, { TableColumn } from '../base-table'
import { useProjects } from '../../../core/hooks/projects/use-projects'
import { ShareLink } from '../../../core/dto/rsee/share-link'
import { RseeProject } from '../../../core/dto/rsee/rsee-project'
import { formatToFrench } from '../../../core/services/date-service'
import { CopyLinkButton } from '../../buttons/CopyLinkButton/CopyLinkButton'
import { licenseNumberToString } from '../../../core/services/helper-service'
import ShareLinkUpdateModal from '../../../pages/project-page/rsee/components/share-link-update-modal'
import { RseeDocument } from '../../../core/dto/rsee/rsee-document'

const columns: TableColumn[] = [
  {
    id: 'comment',
    label: 'Commentaire',
  },
  {
    id: 'user',
    label: 'Créé par',
  },
  {
    id: 'usageCounter',
    label: "Nombre actuel d'utilisations",
  },
  {
    id: 'maxUsageCount',
    label: "Nombre maximal d'utilisations",
  },
  {
    id: 'validityDate',
    label: 'Fin de validité',
  },
  {
    id: 'status',
    label: 'Statut',
  },
  {
    id: 'sharePageUrl',
    label: 'Lien',
  },
  {
    id: 'action',
    label: '',
  },
]

function statusToLabel(shareLink: ShareLink): string | React.JSX.Element {
  if (shareLink.maxUsageCount !== -1 && shareLink.maxUsageCount < shareLink.usageCounter) {
    return <span style={{ color: 'red' }}>Utilisé</span>
  } else if (shareLink.validityDate !== undefined) {
    const validityDay = startOfDay(shareLink.validityDate)
    const today = startOfDay(Date.now())
    if (isAfter(today, validityDay)) {
      return <span style={{ color: 'red' }}>Périmé</span>
    }
  }
  return <span style={{ color: 'green' }}>Actif</span>
}

function getValueForColumn(value: string, row: ShareLink): string | React.JSX.Element {
  switch (value) {
    case 'comment':
      return row.comment
    case 'user':
      return row.createdByUserName
    case 'usageCounter':
      return `${row.usageCounter}`
    case 'maxUsageCount':
      return licenseNumberToString(row.maxUsageCount)
    case 'validityDate':
      return row.validityDate !== undefined ? formatToFrench(row.validityDate) : 'Illimité'
    case 'status':
      return statusToLabel(row)
    case 'sharePageUrl':
      return <CopyLinkButton link={row.sharePageUrl} />
    case 'action':
      return 'action'
    default:
      return ''
  }
}

type IProps = {
  rseeProject: RseeProject
  rseeDocument?: RseeDocument
  isWriting: boolean
}

export function ProjectLinkTable({ rseeProject, rseeDocument, isWriting }: IProps): React.JSX.Element {
  const { fetchAllRseeProjectShareLink, fetchAllRseeDocumentShareLink, deleteRseeShareToken } = useProjects()
  const [shareInformationList, setShareInformationList] = useState<ShareLink[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const [selectedShareLink, setSelectedShareLink] = useState<ShareLink | undefined>(undefined)

  useEffect(() => {
    refresh()
  }, [])

  function refresh(): void {
    if (rseeDocument?.id) {
      fetchAllRseeDocumentShareLink(rseeDocument.id).then((newList) => {
        setShareInformationList(newList)
      })
    } else if (rseeProject?.id) {
      fetchAllRseeProjectShareLink(rseeProject.id).then((newList) => {
        setShareInformationList(newList)
      })
    }
  }

  function updateShareInformation(element: ShareLink): Promise<void> {
    setSelectedShareLink(element)
    setOpen(true)
    return Promise.resolve()
  }

  function deleteShareInformation(element: ShareLink): Promise<void> {
    return deleteRseeShareToken(element.id).then(() => refresh())
  }

  return (
    <>
      <BaseTable
        columns={columns}
        elementList={shareInformationList}
        getValueForColumn={getValueForColumn}
        updateElement={updateShareInformation}
        deleteElement={deleteShareInformation}
        isEditButton={isWriting}
        isDeleteButton={isWriting}
      />
      <ShareLinkUpdateModal open={open} setOpen={setOpen} shareLink={selectedShareLink} refresh={refresh} />
    </>
  )
}
