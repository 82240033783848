import { IfcViewerAPI } from 'web-ifc-viewer'
import { PointCoordinatesCommand } from '../models/measurement/commands/PointCoordinatesCommand'

export class SectionsManager {
  viewer: IfcViewerAPI
  isCreatingSectionPlane?: boolean
  openMessage?: (message: string) => void

  constructor(viewer: IfcViewerAPI, openViewerMessage?: (message: string) => void) {
    this.viewer = viewer
    this.openMessage = openViewerMessage
    this.viewer.clipper.active = true
  }

  /**
   * Creates a new section plane creation request that will be handled
   * by the ifc service click event.
   */
  createSectionPlane() {
    if (this.viewer.clipper.active) {
      this.openMessage!('Veuillez sélectionner une face pour créer un plan de coupe')
      this.isCreatingSectionPlane = true
    } else {
      this.openMessage!('Les plans de coupe ne sont pas actifs')
    }
  }

  /**
   * Delete all section planes from the scene.
   */
  deleteAllSectionPlanes() {
    if (this.viewer.clipper.active) {
      if (this.viewer.clipper.planes.length > 0) {
        this.viewer.clipper.deleteAllPlanes()
        this.openMessage!('Les plans de coupe sont maintenant supprimés')
      } else {
        this.openMessage!('Pas de plans de coupe à supprimer')
      }
    } else {
      this.openMessage!('Les plans de coupe ne sont pas actifs')
    }
  }

  /**
   * Set the section planes active or inactive.
   * @param  {boolean} active true if the section planes should be active, false otherwise
   */
  setActive(active: boolean) {
    this.viewer.clipper.active = active
    if (active) {
      PointCoordinatesCommand.hidePointsOutsideOfClippingView(
        this.viewer?.context.getClippingPlanes(),
        this.viewer?.context.getScene()
      )
    } else {
      PointCoordinatesCommand.showAllPoints(this.viewer?.context.getScene())
    }

    this.deleteAllHelperPlanes()
  }

  /**
   * Returns true if the section planes are active, false otherwise.
   * @returns {boolean} true if the section planes are active, false otherwise
   */
  isActive(): boolean {
    if (this.viewer) {
      return this.viewer.clipper.active
    } else return false
  }

  /**
   * Delete all helper planes from the scene.
   */
  deleteAllHelperPlanes() {
    this.viewer.clipper.planes.forEach((plane) => {
      if (plane.helper) {
        plane.helper.visible = false
      }
    })
  }
}
