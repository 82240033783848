import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useKairnial } from '../../kairnial/useKairnial'
import { KairnialProject } from '../../../core/dto/kairnial/KairnialProject'
import { BaseButton } from '../../../components/buttons/BaseButton'
import { KairnialDialog } from './KairnialDialog'

export function KairnialProjectsComponent(): React.JSX.Element {
  const { fetchKairnialProjectList } = useKairnial()
  const [isKairnialDialogOpen, setIsKairnialDialogOpen] = useState<boolean>(false)
  const [kairnialProjectList, setKairnialProjectList] = useState<KairnialProject[]>([])
  const [kairnialProject, setKairnialProject] = useState<KairnialProject | null>(null)

  useEffect(() => {
    fetchKairnialProjectList().then((kairnialProjects) => {
      setKairnialProjectList(kairnialProjects)
    })
  }, [fetchKairnialProjectList])

  function openKairnialDialog(): void {
    setIsKairnialDialogOpen(true)
  }

  function handleClose(): void {
    setIsKairnialDialogOpen(false)
  }

  function handleChange(event: React.SyntheticEvent<Element, Event>, value: KairnialProject | null, reason: string): void {
    setKairnialProject(value)
  }

  return (
    <Box sx={{ pl: 20, pr: 20 }}>
      <Typography fontWeight='bold' variant='h5' fontSize={20} sx={{ mt: 4, mb: 2 }}>
        PROJET KAIRNIAL
      </Typography>
      <BaseButton onClick={openKairnialDialog} label='Kairnial Projects' />
      <KairnialDialog
        isKairnialDialogOpen={isKairnialDialogOpen}
        handleClose={handleClose}
        kairnialProject={kairnialProject}
        kairnialProjectList={kairnialProjectList}
        handleChange={handleChange}
      />
    </Box>
  )
}
