import React, { useContext, useMemo } from 'react'
import { UserContext } from '../../core/context/user/user-context'
import { isMegaUser } from '../../core/services/authentication-service'
import { MaterialPage } from '../../components/material-lib/material-page'

export default function OrganizationMaterialLibPage(): React.JSX.Element {
  const { user } = useContext(UserContext)

  const isWriting = useMemo(() => isMegaUser(user), [user])

  return <MaterialPage isWriting={isWriting} />
}
