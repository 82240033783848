import { TextField } from '@mui/material'
import React, { ChangeEvent, Dispatch, ReactNode, SetStateAction, useMemo } from 'react'

export interface ITextInputProps {
  fieldName: string
  label: string
  form: Record<string, any>
  errors: Record<string, string | undefined>
  setForm?: Dispatch<SetStateAction<any>>
  setErrors?: Dispatch<SetStateAction<Record<string, string | undefined>>>
  InputProps?: any
  InputLabelProps?: any
  rows?: number
  type?: string
  margin?: 'normal' | 'dense' | 'none'
  required?: boolean
  formHelperText?: ReactNode
  backgroundColor?: string
  disabled?: boolean
  alternativeValue?: string

  handleChange?(event: ChangeEvent<HTMLInputElement>): void
}

export function TextInput({
  fieldName,
  label,
  form,
  errors,
  setErrors,
  setForm,
  rows,
  InputProps,
  InputLabelProps,
  type,
  margin = 'normal',
  handleChange,
  required = true,
  formHelperText,
  backgroundColor,
  disabled = false,
  alternativeValue = '',
}: Readonly<ITextInputProps>): React.JSX.Element {
  const actualLabel: string = useMemo(() => (required ? `${label} *` : label), [required, label])

  const actualHandleChange =
    handleChange ??
    ((event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target
      const name = target.id

      if (setForm) {
        setForm({
          ...form,
          [name]: target.value,
        })

        if (setErrors && errors[name]) {
          setErrors({ ...errors, [name]: undefined })
        }
      }
    })

  return (
    <TextField
      id={fieldName}
      variant='outlined'
      disabled={disabled}
      label={actualLabel}
      type={type}
      value={form[fieldName] ?? alternativeValue}
      margin={margin}
      fullWidth
      multiline={rows !== undefined}
      rows={rows}
      onChange={actualHandleChange}
      error={!!errors[fieldName]}
      helperText={formHelperText ?? errors[fieldName]}
      InputProps={InputProps}
      InputLabelProps={InputLabelProps}
      sx={{ background: disabled ? '#ebebeb' : backgroundColor, color: disabled ? '#ff47d8' : '' }}
    />
  )
}
