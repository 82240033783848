import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import React, { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react'
import { RoleEnumPicker } from '../../components/autocomplete/role-enum-picker'
import { OrganizationContext } from '../../core/context/organization/organization-context'
import { Address } from '../../core/dto/address'
import { Organization } from '../../core/dto/organization/organization'
import { RoleEnum } from '../../core/enum/roleEnum'
import { useOrganization } from '../../core/hooks/use-organization'
import { OrganizationUpdate } from '../../core/dto/organization/organization-update'
import PhoneInput from '../../components/inputs/phone-input/phone-input'
import ListInput from '../../components/inputs/list-input/list-input'

type OrganizationForm = {
  businessName: string
  email: string
  addressString: string
  phone: string
  vatNumber: string
  siret: string
  address: Address
  roles: RoleEnum[]
}

function getOrganizationAddress(form: Address): string {
  let completeAddress = ''

  if (form.street !== undefined) {
    completeAddress += `${form.street}`
  }
  if (form.city !== undefined && completeAddress !== '') {
    completeAddress += `, `
  }
  if (form.city !== undefined) {
    completeAddress += `${form.city}`
  }
  if (form.zipCode !== undefined && completeAddress !== '') {
    completeAddress += `, `
  }
  if (form.zipCode !== undefined) {
    completeAddress += `${form.zipCode}`
  }
  return completeAddress === '' ? ' ' : completeAddress
}

function formFromOrganization(organization: Organization | undefined): OrganizationForm {
  if (!organization) {
    return {
      businessName: '',
      email: '',
      addressString: '',
      phone: '',
      vatNumber: '',
      siret: '',
      address: new Address(),
      roles: [],
    }
  } else {
    return {
      businessName: organization.businessName,
      email: organization.email,
      phone: organization.phone,
      vatNumber: organization.vatNumber,
      siret: organization.siret,
      addressString: getOrganizationAddress(organization.address),
      address: organization.address,
      roles: organization.roles,
    }
  }
}

function dtoFromForm(organizationForm: OrganizationForm): OrganizationUpdate {
  return {
    businessName: organizationForm.businessName,
    email: organizationForm.email,
    phone: organizationForm.phone,
    siret: organizationForm.siret,
    vatNumber: organizationForm.vatNumber,
    address: organizationForm.address,
  }
}

const addressInstance = new Address()
function isAddressKey(stringToTest: string): boolean {
  return Object.hasOwn(addressInstance, stringToTest)
}

type IProps = {
  isWriting: boolean
}

export default function OrganizationDetail({ isWriting }: IProps): React.JSX.Element {
  const { organization, setOrganization } = useContext(OrganizationContext)

  const { updateOrganization } = useOrganization()

  const [organizationForm, setOrganizationForm] = useState<OrganizationForm>(formFromOrganization(organization))
  const [error, setError] = useState<Record<string, string>>({})
  const [isUpdating, setIsUpdating] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setOrganizationForm(formFromOrganization(organization))
  }, [organization])
  const guestRole = useMemo(() => [RoleEnum.MOA, RoleEnum.ARCHITECT, RoleEnum.BE, RoleEnum.CONSULTANT], [])

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const fieldName = target.id

    if (isAddressKey(fieldName)) {
      setOrganizationForm({
        ...organizationForm,
        address: { ...organizationForm.address, [fieldName]: value },
      })
    } else {
      setOrganizationForm({
        ...organizationForm,
        [fieldName]: value,
      })
    }
  }

  const handleSubmit = (): void => {
    if (!isUpdating) {
      setIsUpdating(true)
    } else {
      const dto: OrganizationUpdate = dtoFromForm(organizationForm)
      setIsLoading(true)
      updateOrganization(dto)
        .then((response) => {
          setOrganization(response)
        })
        .finally(() => {
          setIsLoading(false)
          setIsUpdating(false)
        })
    }
  }
  return (
    <Box sx={{ pl: 20, pr: 20 }}>
      <Typography fontWeight='bold' variant='h5' fontSize={20} sx={{ mt: 4, mb: 2 }}>
        INFORMATIONS GÉNÉRALES
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            id='businessName'
            value={organizationForm.businessName}
            label="Nom de l'organisation"
            variant='outlined'
            onChange={handleChange}
            fullWidth
            disabled={!isUpdating}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id='email'
            label='E-mail'
            value={organizationForm.email}
            variant='outlined'
            onChange={handleChange}
            fullWidth
            disabled={!isUpdating}
          />
        </Grid>
        {!isUpdating && (
          <Grid item xs={12} sm={6}>
            <TextField
              id='siegeSocial'
              value={getOrganizationAddress(organizationForm.address)}
              label='Siège social'
              variant='outlined'
              fullWidth
              disabled
            />
          </Grid>
        )}
        {isUpdating && (
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                id='street'
                value={organizationForm.address?.street}
                label='Adresse'
                onChange={handleChange}
                required
                type='string'
                variant='outlined'
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id='additional'
                value={organizationForm.address?.additional}
                label="Complément d'adresse"
                onChange={handleChange}
                required
                type='string'
                variant='outlined'
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id='city'
                value={organizationForm.address?.city}
                label='Ville'
                onChange={handleChange}
                required
                type='string'
                variant='outlined'
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id='zipCode'
                value={organizationForm.address?.zipCode}
                label='Code postal'
                onChange={handleChange}
                required
                type='string'
                variant='outlined'
                fullWidth
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={6}>
          <TextField
            id='siret'
            value={organizationForm.siret}
            label='Siret'
            variant='outlined'
            onChange={handleChange}
            fullWidth
            disabled={!isUpdating}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PhoneInput
            form={organizationForm}
            error={error}
            handleChange={handleChange}
            required={false}
            disabled={!isUpdating}
            variant='outlined'
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id='vatNumber'
            value={organizationForm.vatNumber}
            label='TVA'
            variant='outlined'
            fullWidth
            onChange={handleChange}
            disabled={!isUpdating}
          />
        </Grid>
        {isWriting && (
          <Grid item xs={12} sm={12} display='flex' justifyContent='center'>
            <Button color='primary' variant='contained' onClick={handleSubmit} disabled={isLoading || !organization}>
              {!isUpdating ? 'Modifier mon organisation' : 'Enregistrer'}
            </Button>
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <RoleEnumPicker options={guestRole} actualRole={organizationForm.roles} disabled label='Rôles' />
        </Grid>
        <Grid item xs={12} sm={6}>
          {organization && (
            <ListInput
              values={organization.domainNames}
              label={organization.domainNames.length > 1 ? 'Noms de domaine' : 'Nom de domaine'}
              disabled
            />
          )}
        </Grid>
      </Grid>
    </Box>
  )
}
