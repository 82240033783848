import CalculateIcon from '@mui/icons-material/CalculateOutlined'
import { Box, Chip, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import BaseTable, { TableColumn } from '../../../../components/tables/base-table'
import { pagesUrl } from '../../../../core/appConstants'
import { RseeImportLog } from '../../../../core/dto/rsee/rsee-import-log'
import { ErrorLevelEnum, errorLevelToLabel } from '../../../../core/enum/rsee/errorLevelEnum'
import { RseeCategoryErrorEnum } from '../../../../core/enum/rsee/rseeCategoryErrorEnum'
import { useRsee } from '../../../../core/hooks/rsee/use-rsee'
import { resolveUrl } from '../../../../core/services/http-service'
import RseeHeader from '../components/rsee-header'
import { useQuery } from '../../../../core/hooks/miscellaneous/use-query'
import { RseeSummaryTypeEnum } from '../../../../core/enum/rsee/rseeSummaryTypeEnum'
import { ImportStateEnum } from '../../../../core/enum/rsee/importStateEnum'
import { RseeImportSummary } from '../../../../core/dto/rsee/rsee-import-summary'

const columns: TableColumn[] = [
  {
    id: 'errorMessage',
    label: 'Message',
    sortDirection: 'asc',
  },
  {
    id: 'errorLevel',
    label: 'Importance',
    sortDirection: 'desc',
  },
]

function getValueForColumn(value: string | number, row: RseeImportLog): string | React.JSX.Element {
  switch (value) {
    case 'errorMessage':
      return `${row.errorMessage}`
    case 'errorLevel':
      return errorLevelToLabel(row.errorLevelEnum)
    case 'rseeProjectId':
      return `${row.projectId}`
    case 'rseeDocumentId':
      return `${row.documentId}`
    default:
      return ''
  }
}

export default function RseeDocumentImportLog(): React.JSX.Element {
  const { rseeProjectId, rseeDocumentId } = useParams()
  const query = useQuery()
  const { fetchRseeImportLogList } = useRsee()

  const [summaryImport, setSummaryImport] = useState<RseeImportSummary>(new RseeImportSummary())
  const [isSummaryLoaded, setIsSummaryLoaded] = useState<boolean>(false)
  const [importState, setImportState] = useState<ImportStateEnum>(ImportStateEnum.UNKNOWN)
  const [dataCompState, setDataCompState] = useState<ImportStateEnum>(ImportStateEnum.UNKNOWN)
  const [rsetState, setRsetState] = useState<ImportStateEnum>(ImportStateEnum.UNKNOWN)
  const [rsenvState, setRsenvState] = useState<ImportStateEnum>(ImportStateEnum.UNKNOWN)
  const [fromPage, setFromPage] = useState<RseeSummaryTypeEnum>(RseeSummaryTypeEnum.RSEE_DASHBOARD)

  useEffect(() => {
    if (isSummaryLoaded) {
      let newImportErrorLevel: ImportStateEnum = ImportStateEnum.SUCCESS
      let newDataCompErrorLevel: ImportStateEnum = ImportStateEnum.SUCCESS
      let newRsetErrorLevel: ImportStateEnum = ImportStateEnum.SUCCESS
      let newRsenvErrorLevel: ImportStateEnum = ImportStateEnum.SUCCESS

      newImportErrorLevel = updateErrorLevel(summaryImport.datacomp, newImportErrorLevel)
      newImportErrorLevel = updateErrorLevel(summaryImport.rset, newImportErrorLevel)
      newImportErrorLevel = updateErrorLevel(summaryImport.rsenv, newImportErrorLevel)

      newDataCompErrorLevel = updateErrorLevel(summaryImport.datacomp, newDataCompErrorLevel)
      newRsetErrorLevel = updateErrorLevel(summaryImport.rset, newRsetErrorLevel)
      newRsenvErrorLevel = updateErrorLevel(summaryImport.rsenv, newRsenvErrorLevel)

      setImportState(newImportErrorLevel)
      setDataCompState(newDataCompErrorLevel)
      setRsetState(newRsetErrorLevel)
      setRsenvState(newRsenvErrorLevel)
    }
  }, [summaryImport, isSummaryLoaded])

  useEffect(() => {
    refreshSummary()
  }, [])

  function updateErrorLevel(importLogList: RseeImportLog[], currentImportState: ImportStateEnum): ImportStateEnum {
    let state: ImportStateEnum = currentImportState
    importLogList.forEach((logItem) => {
      if (logItem.errorLevelEnum === ErrorLevelEnum.WARNING && state === ImportStateEnum.SUCCESS) {
        state = ImportStateEnum.WARNING
      } else if (logItem.errorLevelEnum === ErrorLevelEnum.CRITICAL) {
        state = ImportStateEnum.CRITICAL
      }
    })
    return state
  }

  useEffect(() => {
    const typeString = query.get('from')
    let from: RseeSummaryTypeEnum
    switch (typeString) {
      case RseeSummaryTypeEnum.RSEE_DASHBOARD:
        from = RseeSummaryTypeEnum.RSEE_DASHBOARD
        break
      case RseeSummaryTypeEnum.RSEE_DOWNLOADING:
        from = RseeSummaryTypeEnum.RSEE_DOWNLOADING
        break
      default:
        from = RseeSummaryTypeEnum.RSEE_DASHBOARD
    }
    setFromPage(from)
  }, [query])

  function refreshSummary(): void {
    if (rseeProjectId && rseeDocumentId) {
      fetchRseeImportLogList(rseeProjectId, rseeDocumentId).then((newImportList: RseeImportLog[]) => {
        const summary = new RseeImportSummary()
        newImportList.forEach((importLog) => {
          if (importLog.rseeCategoryErrorEnum === RseeCategoryErrorEnum.DATAS_COMP) {
            summary.datacomp.push(importLog)
          } else if (importLog.rseeCategoryErrorEnum === RseeCategoryErrorEnum.RSET) {
            summary.rset.push(importLog)
          } else if (importLog.rseeCategoryErrorEnum === RseeCategoryErrorEnum.RSENV) {
            summary.rsenv.push(importLog)
          } else {
            summary.unknown.push(importLog)
          }
        })
        setSummaryImport(summary)
        setIsSummaryLoaded(true)
      })
    }
  }

  function navigateBackUrl(): string {
    if (fromPage === RseeSummaryTypeEnum.RSEE_DASHBOARD) {
      return resolveUrl(pagesUrl.RSEE_PROJECT_DOCUMENT, [rseeProjectId, rseeDocumentId])
    } else {
      return resolveUrl(pagesUrl.RSEE_PROJECT_DOCUMENT_UPDATE, [rseeProjectId, rseeDocumentId])
    }
  }

  function getMainMessage(): React.ReactNode {
    if (importState === ImportStateEnum.SUCCESS) {
      return <Chip label='Le fichier RSEE a été importé avec succès' color='success' />
    } else if (importState === ImportStateEnum.WARNING) {
      return <Chip label="Le fichier RSEE a été importé, mais il y a des points d'attention à vérifier" color='warning' />
    } else if (importState === ImportStateEnum.CRITICAL) {
      return (
        <Chip
          label="Le fichier RSEE a été importé mais des informations importantes n'ont pas été récupérées"
          color='error'
        />
      )
    } else {
      return undefined
    }
  }

  function getErrorLevelChip(errorLevel: ImportStateEnum): React.ReactNode {
    if (errorLevel === ImportStateEnum.SUCCESS) {
      return <Chip label='Cette partie a été importée avec succès' color='success' />
    } else if (errorLevel === ImportStateEnum.WARNING) {
      return <Chip label="Cette partie a été importée, mais il y a des points d'attention à vérifier" color='warning' />
    } else if (errorLevel === ImportStateEnum.CRITICAL) {
      return (
        <Chip
          label="Cette partie a été importée, mais des informations importantes n'ont pas été récupérées"
          color='error'
        />
      )
    } else {
      return undefined
    }
  }

  return (
    <Grid container rowSpacing={3} columnSpacing={2}>
      <RseeHeader
        title="Synthèse de l'import"
        action={{ path: resolveUrl(pagesUrl.RSEE_PROJECT_DOCUMENT, [rseeProjectId, rseeDocumentId]) }}
        navigateBackPath={navigateBackUrl()}
        actionLabel='Tableau de bord'
        icon={<CalculateIcon sx={{ fontSize: '40px' }} />}
        isWriting
      />
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
          color: '#47B27C',
        }}>
        {getMainMessage()}
      </Grid>
      <Grid item xs={12}>
        <Box display='flex' flexDirection='row' alignItems='center' gap={2} sx={{ mt: 4, mb: 2 }}>
          <Typography fontWeight='bold' variant='h5' fontSize={20}>
            INFORMATIONS GÉNÉRALES
          </Typography>
          {getErrorLevelChip(dataCompState)}
        </Box>
        <BaseTable
          columns={columns}
          elementList={summaryImport.datacomp}
          getValueForColumn={getValueForColumn}
          isEditButton={false}
          isDeleteButton={false}
          pageSize={15}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display='flex' flexDirection='row' alignItems='center' gap={2} sx={{ mt: 4, mb: 2 }}>
          <Typography fontWeight='bold' variant='h5' fontSize={20}>
            PARTIE ENERGIE (THERMIQUE)
          </Typography>
          {getErrorLevelChip(rsetState)}
        </Box>
        <BaseTable
          columns={columns}
          elementList={summaryImport.rset}
          getValueForColumn={getValueForColumn}
          isEditButton={false}
          isDeleteButton={false}
          pageSize={15}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display='flex' flexDirection='row' alignItems='center' gap={2} sx={{ mt: 4, mb: 2 }}>
          <Typography fontWeight='bold' variant='h5' fontSize={20}>
            PARTIE ENVIRONNEMENT (CARBONE)
          </Typography>
          {getErrorLevelChip(rsenvState)}
        </Box>
        <BaseTable
          columns={columns}
          elementList={summaryImport.rsenv}
          getValueForColumn={getValueForColumn}
          isEditButton={false}
          isDeleteButton={false}
          pageSize={15}
        />
      </Grid>
    </Grid>
  )
}
