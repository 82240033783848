import { Dispatch, SetStateAction, useCallback, useContext } from 'react'
import { appConstants } from '../../appConstants'
import { MaterialLibContext } from '../../context/material/material-lib-context'
import MaterialRecord from '../../dto/material/material-record'
import { useHttp } from '../use-http'
import { ProjectContext } from '../../context/project/project-context'
import { DeclarationFilter } from '../../enum/declarationFilter'

export class ProjectRecordFilter {
  containedInName: string | undefined = undefined
  classificationLvl0: DeclarationFilter | undefined = undefined
  classificationLvl1: number | undefined = undefined
  classificationLvl2: number | undefined = undefined
  classificationLvl3: number | undefined = undefined
  classificationLvl4: number | undefined = undefined
  responsibleOrganism: string | undefined = undefined
  declarationType: string | undefined = undefined
}

export type UseProjectRecordsFilteredVariable = {
  filter: ProjectRecordFilter
  lastFdesName?: string
}

type UseProjectRecordsFilteredReturn = {
  loading: boolean
  materialRecords: MaterialRecord[]
  search({ filter, lastFdesName }: UseProjectRecordsFilteredVariable): Promise<MaterialRecord[]>
  reset(): void
  limit: number
  setLimit: Dispatch<SetStateAction<number>>
}

export const useProjectRecordsFiltered = (): UseProjectRecordsFilteredReturn => {
  const { project } = useContext(ProjectContext)
  const { post } = useHttp()

  const { materialRecords, setMaterialRecords, loading, setLoading, limit, setLimit } = useContext(MaterialLibContext)

  const reset = useCallback((): void => setMaterialRecords([]), [setMaterialRecords])

  const search = useCallback(
    ({ filter, lastFdesName }: UseProjectRecordsFilteredVariable): Promise<MaterialRecord[]> => {
      if (project?.id) {
        setLoading(true)
        let params = [
          {
            key: 'limit',
            value: String(limit),
          },
        ]

        params = [
          ...params,
          {
            key: 'projectId',
            value: project.id,
          },
        ]

        if (lastFdesName) {
          params = [
            ...params,
            {
              key: 'lastFdesName',
              value: encodeURI(lastFdesName),
            },
          ]
        }

        return post(appConstants.apiEndpoints.PROJECT_RECORDS_FILTERS_PAGINATED, filter, params)
          .then((response) => response.json())
          .then((response: any[]) => {
            const materialArray: MaterialRecord[] = response.map((record) => MaterialRecord.fromDto(record))
            setMaterialRecords(materialArray)
            return materialArray
          })
          .finally(() => {
            setLoading(false)
          })
      } else {
        return Promise.resolve([])
      }
    },
    [post, project?.id, setMaterialRecords, setLoading, limit]
  )

  return {
    loading,
    search,
    materialRecords,
    reset,
    limit,
    setLimit,
  }
}
