import { BufferGeometry, EdgesGeometry, Line, Line3, Plane, Points, Vector3 } from 'three'
import { CameraProjections, IfcViewerAPI } from 'web-ifc-viewer'
import { IfcCamera } from 'web-ifc-viewer/dist/components/context/camera/camera'
import { viewerConstants } from '../constants/ViewerConstants'
import { DimensionMode } from '../enums/DimensionMode'
import { RaySelectMode } from '../enums/RaySelectMode'
import { EventsEmitter } from '../models/EventsEmitter'
import { IfcSurface } from '../models/IfcSurface'
import { RayLine } from '../models/RayLine'
import { LabelStyle } from '../models/measurement/annotations/LabelStyle'
import { PointData } from '../models/measurement/annotations/PointData'
import TextLabels from '../models/measurement/annotations/TextLabelsGenerator'
import { MathHelper } from './MathHelper'
import { MeasurementHelper } from './MeasurementHelper'
import { UnitsHelper } from './UnitsHelper'

/**
 * Ray cast geometries, obtain edges and vertices
 */
export class RayCastingHelper extends EventsEmitter {
  static instance: RayCastingHelper | null = null
  ifcViewer?: IfcViewerAPI
  ifc?: IfcViewerAPI
  camera: any
  ifcCamera?: IfcCamera
  scene?: THREE.Scene
  ifcLoader: any
  HighlightedPoint: any
  marker?: any
  pointsList?: Array<PointData>
  selectMode: RaySelectMode = RaySelectMode.NONE
  intersectionId?: number
  prevIntersectionId?: number
  line?: RayLine
  linePoints?: Array<PointData>
  pervSubSetEdges: any
  prevModelID?: number
  modelID?: number
  dimensionMode: DimensionMode | undefined
  pointsMarkers: any
  label: any
  areaLines: RayLine[]
  areaLabels: any[]
  areaPointsMarkers: any[]
  minimunLineLength = 0.5

  constructor(ifcSetvice: IfcViewerAPI) {
    super()
    // instansiate base class
    RayCastingHelper.instance = this

    this.ifc = ifcSetvice
    this.camera = this.ifc.context.getCamera()
    this.ifcCamera = this.ifc.context.getIfcCamera()
    this.scene = this.ifc.context.getScene()
    this.ifcLoader = this.ifc.IFC.loader

    this.marker = viewerConstants.measurement.marker
    this.marker.name = 'VertexSnippingMarker'
    this.marker.renderOrder = 1
    this.dimensionMode = DimensionMode.ONE_POINT
    this.pointsList = []
    this.linePoints = []
    this.areaLines = []
    this.areaPointsMarkers = []
    this.areaLabels = []
  }

  /**
   * When mouse move
   * @param event Mouse mouve event
   */
  static mouseMove(): void {
    RayCastingHelper.instance?.hover()
  }

  /**
   * Mouse scroll event
   */
  static mouseWheel(): void {
    if (RayCastingHelper.instance) {
      const object = RayCastingHelper.instance.scene?.getObjectByName('VertexSnippingMarker')
      if (object != null) {
        RayCastingHelper.instance.scene?.remove(object)
      }
    }
  }

  /**
   * Recalculate coordinates of an object if intersected by clipping plane
   * @param plane plane of intersecting clipping plane
   * @param points vertices of intersected object
   * @param vectors edges of intersected object
   */
  private static recalculateCoordinates(plane: Plane, points: Array<Vector3>, vectors: Array<Array<Vector3>>): void {
    for (let i = 0; i < vectors.length; i += 1) {
      const line = new Line3(vectors[i][0], vectors[i][1])
      if (plane.intersectsLine(line)) {
        const point = plane.intersectLine(line, new Vector3(0, 0, 0))
        if (point != null) {
          points.push(point)
          const vec1 = point.clone().sub(vectors[i][0])
          const lineVec = vectors[i][1].clone().sub(vectors[i][0])
          if (vec1.dot(lineVec) > 0) {
            vectors[i] = [vectors[i][0], point]
          } else {
            vectors[i] = [point, vectors[i][1]]
          }
        }
      }
    }
  }

  /**
   * Calculate shortest (orthogonal) distance between point and line
   * @param point point to clculate distance to
   * @param linePoint1 line first point
   * @param linePoint2 line end point
   * @returns distance
   */
  private static calculateDistanceBetweenLineAndPoint(
    point: THREE.Vector3,
    linePoint1: THREE.Vector3,
    linePoint2: THREE.Vector3
  ): number {
    const diff1 = new Vector3(0, 0, 0).subVectors(point, linePoint1)
    const diff2 = new Vector3(0, 0, 0).subVectors(point, linePoint2)
    const cross = new Vector3(0, 0, 0).crossVectors(diff1, diff2)
    const diff3 = new Vector3(0, 0, 0).subVectors(linePoint2, linePoint1)
    return cross.length() / diff3.length()
  }

  /**
   * Check if a point projection lies inside the boundaries of a line
   * @param point point to be checkes (intersection point)
   * @param linePoint1 first point of line
   * @param linePoint2 second point of line
   * @returns true if point projection is inside line, else false
   */
  private static checkPointOnLine(point: THREE.Vector3, linePoint1: THREE.Vector3, linePoint2: THREE.Vector3): boolean {
    const pointVector = point.clone().sub(linePoint1)
    const lineVector = linePoint2.clone().sub(linePoint1)
    if (lineVector.dot(pointVector) > 0 && lineVector.dot(pointVector) <= lineVector.lengthSq()) {
      return true
    }
    return false
  }

  /**
   * Get all vertices and edges' coordinates of an Ifc object subset from a list of it's lineGeometries
   * @param intersection Ifc object subset
   * @param edges Line geometry edges
   * @param planes intersecting planes (if any)
   * @returns an object containing all vertices and edges of object
   */
  private static getPointsAndEdgesFromSubset(intersection: any, edges: any, planes: Array<THREE.Plane> | undefined): any {
    const points: Array<THREE.Vector3> = []
    const vectors: Array<Array<THREE.Vector3>> = []
    const vertices = edges.attributes.position.array
    const numSegments = vertices.length / 6

    for (let i = 0; i < numSegments; i += 1) {
      const startIndex = i * 6

      const start = new Vector3(vertices[startIndex], vertices[startIndex + 1], vertices[startIndex + 2])

      const end = new Vector3(vertices[startIndex + 3], vertices[startIndex + 4], vertices[startIndex + 5])

      points.push(start)
      points.push(end)
      vectors.push([start, end])
    }

    if (planes != null && planes.length > 0) {
      for (const plane of planes) {
        RayCastingHelper.recalculateCoordinates(plane, points, vectors)
      }
    }
    vectors.sort((a, b) =>
      RayCastingHelper.calculateDistanceBetweenLineAndPoint(intersection.point, a[0], a[1]) >
      RayCastingHelper.calculateDistanceBetweenLineAndPoint(intersection.point, b[0], b[1])
        ? 1
        : -1
    )
    points.sort((a, b) => (a.distanceTo(intersection.point) > b.distanceTo(intersection.point) ? 1 : -1))
    return { points, lines: vectors }
  }

  /**
   * Mouse hover on objects
   */
  hover(): void {
    if (this.scene === null || this.camera === null || this.marker === null) return

    const planes: Array<THREE.Plane> | undefined = this.ifcViewer?.context.getClippingPlanes()
    // Cast a ray
    const intersection = this.ifc?.context.castRayIfc()
    if (intersection != null) {
      const edges = this.getIntersectionSubsetForIFCModels(intersection)
      const geometry = RayCastingHelper.getPointsAndEdgesFromSubset(intersection, edges, planes)
      const point = geometry.points[0]
      const line = geometry.lines[0]
      if (this.selectMode !== RaySelectMode.LINE && this.selectMode !== RaySelectMode.OBJECT_EDGES) {
        if (this.selectMode === RaySelectMode.AREA) {
          this.drawAreaHighLightPoint(intersection, point)
        } else if (this.selectMode === RaySelectMode.FACE) {
          this.drawHilightFace(intersection, geometry.lines)
        } else {
          this.drawHighLightPoint(intersection, point)
        }
      } else {
        this.scene?.remove(this.marker)
        this.removeLineFromScene()
        if (this.selectMode === RaySelectMode.OBJECT_EDGES) {
          this.drawHighLightObjectLines(intersection, geometry.lines)
        } else {
          this.drawHighLightLine(intersection, line)
        }
      }
    } else {
      this.scene?.remove(this.marker)
      let removePoints = true
      if (this.dimensionMode === DimensionMode.TWO_POINTS || this.dimensionMode === DimensionMode.AREA) removePoints = false
      this.removeLineFromScene(false, removePoints)
    }
  }

  /**
   * When mouse click
   * @param event Mouse click event
   */
  mouseDown(event: any): void {
    if (event.button === 0) {
      this.resetPoints()
      const object = this.scene?.getObjectByName('VertexSnippingMarker')
      if (object != null && this.selectMode !== RaySelectMode.FACE) {
        this.pointsList?.push(new PointData(object.position.clone(), this.modelID, this.intersectionId))
        if (this.dimensionMode === DimensionMode.AREA) {
          if (this.line) {
            const line = new RayLine(this.line.vectors, this.line.object.clone())
            this.scene?.add(line.object)
            this.areaLines.push(line)
          }

          if (this.pointsList && this.pointsList.length > 0) {
            const pointsGeom = new BufferGeometry().setFromPoints([this.pointsList[this.pointsList.length - 1].point])
            const pointMarker = new Points(pointsGeom, viewerConstants.measurement.pointMaterial)
            this.areaPointsMarkers.push(pointMarker)
            this.scene?.add(pointMarker)
          }

          this.triggerAreaDimentioning()
        }
      }

      const line = this.scene?.getObjectByName('EdgeLineHighlight')
      if (line != null && RayCastingHelper.instance && !this.isAreaMeasurement()) {
        RayCastingHelper.instance.selectMode = RaySelectMode.ONE_POINT
        RayCastingHelper.instance.trigger('LineSelected')
      }
      if (this.dimensionMode === DimensionMode.OBJECT_EDGES && this.areaLines.length > 0) {
        this.trigger('ObjectEdgesSelected')
      }
      if (line != null && this.pointsList?.length === 1 && !this.isAreaMeasurement()) {
        this.scene?.remove(this.marker)
        this.trigger('LineAndPointSelected')
      } else if (this.pointsList?.length === 2 && !this.isAreaMeasurement()) {
        this.scene?.remove(this.marker)
        this.trigger('TwoPointsSelected')
      } else if (this.pointsList?.length === 1 && !this.isAreaMeasurement()) {
        if (this.dimensionMode === DimensionMode.TWO_POINTS) {
          this.prevIntersectionId = this.intersectionId
          this.prevModelID = this.modelID
          const geom = new BufferGeometry().setFromPoints([this.pointsList[0].point])
          this.pointsMarkers = new Points(geom, viewerConstants.measurement.pointMaterial)
          this.scene?.add(this.pointsMarkers)
        }
        this.scene?.remove(this.marker)
        this.trigger('OnePointSelected')
      } else if (this.pointsList && this.pointsList?.length > 1 && this.dimensionMode === DimensionMode.FACE_AREA) {
        this.pointsList.pop()
        this.selectMode = RaySelectMode.NONE
        this.trigger('FacePointsSelected')
      }

      if (this.areaLines.length > 0 && this.dimensionMode === DimensionMode.FACE_SEGMENTS) {
        this.selectMode = RaySelectMode.NONE
        this.trigger('FaceSegmentsSelected')
      }

      this.triggerAreaDimentioning()
    }
  }

  isAreaMeasurement(): boolean {
    return this.dimensionMode === DimensionMode.AREA || this.dimensionMode === DimensionMode.FACE_AREA
  }

  triggerAreaDimentioning(): void {
    if (
      this.pointsList &&
      this.pointsList?.length > 1 &&
      MathHelper.pointsEqual(this.pointsList[0].point, this.pointsList[this.pointsList.length - 1].point) &&
      this.dimensionMode === DimensionMode.AREA
    ) {
      if (MathHelper.pointsEqual(this.pointsList[0].point, this.pointsList[this.pointsList.length - 1].point)) {
        this.pointsList.pop()
      }
      for (const l of this.areaLines) {
        this.scene?.remove(l.object)
        l.object.geometry.dispose()
      }
      if (this.areaPointsMarkers) {
        for (const l of this.areaPointsMarkers) {
          if (l.geometry) l.geometry.dispose()
          this.scene?.remove(l)
        }
      }
      this.scene?.remove(this.marker)
      this.removeLineFromScene(true)
      this.trigger('AreaPointsSelected')
    }
  }

  disable(): void {
    this.removeLineFromScene(true)
    this.removeTempLinesFromScene()
    this.scene?.remove(this.marker)
    this.selectMode = RaySelectMode.NONE
    this.pointsList = []
    this.linePoints = []
  }

  /**
   * Remove all temp lines from scene of pre measurement placement
   */
  removeTempLinesFromScene(): void {
    for (const line of this.areaLines) {
      this.scene?.remove(line.object)
      if (line?.object.geometry) line.object.geometry.dispose()
    }
    for (const label of this.areaLabels) {
      this.scene?.remove(label)
      if (label !== null) label.clear()
    }
    this.areaLines = []
    this.areaLabels = []
  }

  /**
   * Add highlight point if mouse is over a coordinate (vertex)
   * @param intersection intersected object
   * @param point point of nearest coordinate
   */
  private drawHighLightPoint(intersection: any, point: THREE.Vector3): void {
    let tolerance = viewerConstants.measurement.pointDistanceTolerance
    if (this.dimensionMode === DimensionMode.TWO_POINTS && this.pointsList?.length === 1) {
      tolerance = viewerConstants.measurement.pointFarDistanceTolerance
    }
    if (intersection.point.distanceTo(point) < tolerance && this.ifcCamera != null) {
      this.marker.position.set(point.x, point.y, point.z)
      this.marker.lookAt(this.ifcCamera.activeCamera.position)
      let scale = 1

      if (this.ifcCamera?.projection === CameraProjections.Perspective) {
        scale = intersection.distance / 18
      } else {
        const scaleFactor = 2
        scale = scaleFactor / (this.ifcCamera?.orthographicCamera.zoom ?? 1)
      }
      this.marker.scale.set(scale, scale, 1)
      this.scene?.add(this.marker)
      // add line in case dimensionMode is TwoPoints
      if (
        (this.dimensionMode === DimensionMode.TWO_POINTS && this.pointsList?.length === 1) ||
        (this.pointsList && this.dimensionMode === DimensionMode.AREA && this.pointsList?.length > 0)
      ) {
        // remove previous lines
        this.removeLineFromScene(true, false)
        // add new line
        const line = [this.pointsList[this.pointsList.length - 1].point, point]
        this.drawHighLightLine(intersection, line, true)
      }
    } else {
      this.scene?.remove(this.marker)
      if (this.dimensionMode !== DimensionMode.ONE_POINT) this.removeLineFromScene(true, false)
    }
  }

  private drawHilightFace(intersection: any, lines: Array<Array<Vector3>>): void {
    this.removeTempLinesFromScene()

    const surface = IfcSurface.getSurface(lines, intersection)

    if (surface) {
      this.pointsList = []
      for (const point of surface.polygon) {
        this.pointsList?.push(new PointData(new Vector3(point.x, point.y, point.z), this.modelID, this.intersectionId))
      }
      for (const curve of surface.curveLoop) {
        const vectors = curve.toVector3Array()
        const geom = new BufferGeometry().setFromPoints(vectors)
        const line = new RayLine(vectors, new Line(geom, viewerConstants.measurement.lineMaterial))
        line.object.renderOrder = 1
        line.object.computeLineDistances()
        line.object.name = 'EdgeLineHighlight'
        line.vectors = curve.points.map((x) => new Vector3(x.x, x.y, x.z))
        const pointDatatA = new PointData(line.vectors[0], this.modelID, this.intersectionId)
        const pointDatatB = new PointData(line.vectors[1], this.modelID, this.intersectionId)
        line.points = [pointDatatA, pointDatatB]
        this.areaLines.push(line)
        this.scene?.add(line.object)
      }
    }
  }

  private async drawAreaHighLightPoint(intersection: any, point: THREE.Vector3): Promise<void> {
    const tolerance = viewerConstants.measurement.pointFarDistanceTolerance
    const isDeforming =
      this.areaLines.some((line) => RayCastingHelper.checkPointOnLine(point, line.vectors[0], line.vectors[0])) &&
      !this.pointsList?.some((x) => x.point.equals(point))

    const points: Vector3[] = []
    if (this.pointsList && this.pointsList.length > 0) {
      if (this.pointsList.length > 1) {
        for (let i = 1; i < this.pointsList.length; i += 1) {
          points.push(this.pointsList[i].point)
        }
      } else if (this.pointsList.length === 1) {
        points.push(this.pointsList[0].point)
      }
    }

    if (
      this.ifc &&
      intersection.point.distanceTo(point) < tolerance &&
      this.ifcCamera != null &&
      !isDeforming &&
      !points.some((x) => x.equals(point))
    ) {
      this.marker.position.set(point.x, point.y, point.z)
      this.marker.lookAt(this.ifcCamera.activeCamera.position)
      let scale = 1

      if (this.ifcCamera?.projection === CameraProjections.Perspective) {
        scale = intersection.distance / 18
      } else {
        const scaleFactor = 2
        scale = scaleFactor / (this.ifcCamera?.orthographicCamera.zoom ?? 1)
      }
      this.marker.scale.set(scale, scale, 1)
      this.scene?.add(this.marker)
      // add line in case dimensionMode is TwoPoints
      if (
        (this.dimensionMode === DimensionMode.TWO_POINTS && this.pointsList?.length === 1) ||
        (this.pointsList && this.dimensionMode === DimensionMode.AREA && this.pointsList?.length > 0)
      ) {
        // remove previous lines
        this.removeLineFromScene(true, false)
        // add new line
        const line = [this.pointsList[this.pointsList.length - 1].point, point]
        if (MathHelper.pointsEqual(this.pointsList[0].point, point) && this.pointsList.length > 1) {
          const textPosition = this.pointsList[0].point
          const label = TextLabels.makeTextSprite(
            `  ${(
              MathHelper.calculateArea(this.pointsList.map((x) => new Vector3(x.point.x, x.point.y, x.point.z))) || 0
            ).toFixed(1)} [m²]`,
            textPosition.x,
            textPosition.y,
            textPosition.z,
            LabelStyle.defaultStyle
          )

          this.label = MeasurementHelper.updateLabelSize(this.ifc, label, textPosition)
          this.label.material.depthtest = false
          this.scene?.add(this.label)
        }

        this.drawHighLightLine(intersection, line, true, true)
      }
    } else {
      this.scene?.remove(this.marker)
      if (this.dimensionMode !== DimensionMode.ONE_POINT) this.removeLineFromScene(true, false)
    }
  }

  /**
   * Add highlighted line if mouse is hovered on an edge
   * @param intersection intersected object
   * @param line point of nearest coordinate
   * @param noPoints indicates that line will be drawn without outlined points in case of true
   */
  private async drawHighLightLine(
    intersection: any,
    line: Array<Vector3>,
    noPoints?: boolean,
    noText?: boolean
  ): Promise<void> {
    if (
      this.ifc != null &&
      this.modelID != null &&
      line != null &&
      RayCastingHelper.calculateDistanceBetweenLineAndPoint(intersection.point, line[0], line[1]) <
        viewerConstants.measurement.lineDistanceTolerance &&
      RayCastingHelper.checkPointOnLine(intersection.point, line[0], line[1])
    ) {
      const geom = new BufferGeometry().setFromPoints(line)
      this.line = new RayLine(line, new Line(geom, viewerConstants.measurement.lineMaterial))
      this.line.object.renderOrder = 1
      this.line.object.computeLineDistances()
      this.line.object.name = 'EdgeLineHighlight'
      const textPosition = line[0].clone().add(line[1]).multiplyScalar(0.5).add(new Vector3(0, 0.2, 0))

      if (!noPoints) {
        const pointsGeom = new BufferGeometry().setFromPoints(line)
        this.pointsMarkers = new Points(pointsGeom, viewerConstants.measurement.pointMaterial)
        this.scene?.add(this.pointsMarkers)
      }
      if (this.dimensionMode === DimensionMode.TWO_POINTS) {
        this.linePoints?.push(new PointData(line[0], this.prevModelID, this.prevIntersectionId))
      } else {
        this.linePoints?.push(new PointData(line[0], this.modelID, this.intersectionId))
      }
      if (this.dimensionMode !== DimensionMode.EDGE_AND_POINT) {
        const units = await UnitsHelper.getConversionUnits(this.ifc, this.modelID)
        const precision = UnitsHelper.setNumericalPrecision(units.projectUnit)
        if (!noText) {
          const label = TextLabels.makeTextSprite(
            `${line[0].distanceTo(line[1] || 0).toFixed(precision)} [${units.unitSymbol}]`,
            textPosition.x,
            textPosition.y,
            textPosition.z,
            LabelStyle.defaultStyle
          )
          this.label = MeasurementHelper.updateLabelSize(this.ifc, label, textPosition)
          this.label.material.depthtest = false
          this.scene?.add(this.label)
        }
      }
      this.linePoints?.push(new PointData(line[1], this.modelID, this.intersectionId))
      this.scene?.add(this.line.object)
    }
  }

  private async drawHighLightObjectLines(intersection: any, lines: Array<Array<Vector3>>): Promise<void> {
    if (this.ifc && this.modelID !== null) {
      const tolerance = this.minimunLineLength
      const curves = [...lines].filter((line) => line[0].distanceTo(line[1]) > tolerance)
      for (const curve of curves) {
        const line = new RayLine(curve, new Line())
        line.vectors = curve
        const pointDatatA = new PointData(curve[0], this.modelID, this.intersectionId)
        const pointDatatB = new PointData(curve[1], this.modelID, this.intersectionId)
        line.points = [pointDatatA, pointDatatB]
        this.areaLines.push(line)
      }
      this.ifc.IFC.selector.prePickIfcItem()
    }
  }

  /**
   * Get intersecting subset object of ifc model
   * @param intersection intersecting object
   * @returns intersecting subset
   */
  private getIntersectionSubsetForIFCModels(intersection: any): EdgesGeometry {
    let edges
    const index = intersection.faceIndex
    const objectGeometry = intersection.object.geometry
    const id = this.ifcLoader.ifcManager.getExpressId(objectGeometry, index)
    if (this.intersectionId === id && this.pervSubSetEdges != null && this.modelID === intersection.object.modelID) {
      edges = this.pervSubSetEdges
    } else {
      const obj = this.ifcLoader.ifcManager.createSubset({
        modelID: intersection.object.modelID,
        ids: [id],
        scene: this.scene,
        removePrevious: true,
      })
      edges = new EdgesGeometry(obj.geometry)
    }

    this.modelID = intersection.object.modelID
    this.intersectionId = id
    this.pervSubSetEdges = edges
    this.ifcLoader.ifcManager.removeSubset(intersection.object.modelID)
    return edges
  }

  /**
   * Remove all point from scene
   */
  private resetPoints(): void {
    if (this.pointsList != null && this.pointsList.length >= 2 && !this.isAreaMeasurement()) {
      this.pointsList = []
    }
  }

  /**
   * Remove line highlight from scene
   * @param remove bool, force remove if true
   */
  private removeLineFromScene(removeLine = false, removePoints = true): void {
    if (this.selectMode === RaySelectMode.LINE || this.selectMode === RaySelectMode.OBJECT_EDGES || removeLine) {
      if (this.line) this.scene?.remove(this.line.object)
      if (this.line?.object.geometry) this.line.object.geometry.dispose()

      this.scene?.remove(this.label)
      if (this.label != null) this.label.clear()

      this.linePoints = []

      if (this.selectMode === RaySelectMode.OBJECT_EDGES) {
        this.removeTempLinesFromScene()
      }
    }
    if (removePoints) {
      if (this.pointsMarkers?.geometry) this.pointsMarkers.geometry.dispose()
      this.scene?.remove(this.pointsMarkers)
      for (const pm of this.areaPointsMarkers) {
        if (pm?.geometry) pm.geometry.dispose()
        this.scene?.remove(pm)
      }
      this.areaPointsMarkers = []
    }
  }
}
