// prettier-ignore
// @ts-ignore: this library is pure javacript, no types
// eslint-disable-next-line import/no-unresolved
import * as t from 'https://public.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js'
import React from 'react'

export const tableau = t
export const TableauViz = t.TableauViz
export const FilterUpdateType = t.FilterUpdateType
export const TableauEventType: ITableauEventType = t.TableauEventType
export const SheetType = t.SheetType // {Dashboard: 'dashboard', Story: 'story', Worksheet: 'worksheet'}
export const Dashboard = t.Dashboard
export const FilterAddType = t.FilterAddType
export const FilterRemoveType = t.FilterRemoveType
export const Workbook = t.Workbook
export const SelectionUpdateType = t.SelectionUpdateType

export interface ITableauEventType {
  FilterChanged: string
  FirstInteractive: string
}

// Types for tableau js lib
export interface TableauViz {
  addEventListener(type: ITableauEventType, fct: (event: any) => Promise<void>): void
}

export const tableauService = {
  /**
   * example: clickOnWorksheet(vizRef, 'test-param', 'Batiment Id')
   */
  clickOnWorksheet(vizRef: React.MutableRefObject<any>, worksheetName: string, fieldName: string): void {
    const targetWorksheet = vizRef.current.workbook?.activeSheet?.worksheets?.find((w: any) => w.name === worksheetName)
    targetWorksheet?.selectMarksByValueAsync([{ fieldName, value: [1] }], SelectionUpdateType.Replace)
  },
  /**
   * example: clickOnWorksheet(vizRef, 'test-param', 'Batiment Id')
   */
  findWorksheetByName(vizRef: React.MutableRefObject<any>, worksheetName: string): any {
    vizRef.current.workbook?.activeSheet?.worksheets?.find((w: any) => w.name === worksheetName)
  },
  findAllFilters(vizRef: React.MutableRefObject<any>): any {
    const filtersInfo = []
    const workbook = vizRef.current.workbook
    const activeSheet = workbook.activeSheet
    if (activeSheet.worksheets) {
      // Get all worksheets in the dashboard
      const worksheets = activeSheet.worksheets
      for (const worksheet of worksheets) {
        worksheet.getFiltersAsync().then((filters: any) => {
          filters.forEach((filter: any, index: number) => {
            filtersInfo.push({
              worksheetName: worksheet.name,
              fieldName: filter.fieldName,
              filterType: filter.filterType,
              values: filter.appliedValues,
            })
          })
        })
      }
    } else {
      // If it's a single worksheet
      activeSheet.getFiltersAsync().then((filters: any) => {
        filters.forEach((filter: any) => {
          filtersInfo.push({
            worksheetName: activeSheet.name,
            fieldName: filter.fieldName,
            filterType: filter.filterType,
            values: filter.appliedValues,
          })
        })
      })
    }
  },
  addListenerToGetDataOfAChartWhenFiltered(vizRef: React.MutableRefObject<any>, chartName: string) {
    vizRef.current.addEventListener(TableauEventType.FilterChanged, async (event: any) => {
      if (event?.detail?.sheet?.name === chartName) {
        const worksheet = event.detail.sheet
        const options = {
          maxRows: 0, // 0 means no limit
          ignoreAliases: false,
          ignoreSelection: true,
          includeAllColumns: true,
        }

        worksheet.getSummaryDataAsync(options).then((summaryData: any) => {
          const data = summaryData.data.map((row: any) => {
            const rowData: any = {}
            summaryData.columns.forEach((col: any, index: number) => {
              rowData[col.fieldName] = row[index].formattedValue
            })
            return rowData
          })

          const codeOccListJson: string = JSON.stringify(
            data.map((element: { codeOccurrence: string }) => element.codeOccurrence)
          )

          console.info('codeOccListJson', codeOccListJson)
        })
      }
    })
  },
}
