import { currentCodeVersion } from '../../appConstants'
import { ProjectStatusEnum } from '../../enum/projectStatusEnum'
import { stringToDate } from '../../services/date-service'
import { ProjectPhase } from './project-phase'

export class Project {
  id: string | undefined = undefined
  name = ''
  phases: ProjectPhase[] = []
  street = ''
  additional = ''
  city = ''
  zipCode = ''
  typology = ''
  organizationId = ''
  businessCode = ''
  licenceDate = new Date()
  codesVersion = currentCodeVersion
  status?: ProjectStatusEnum
  awsFileKey = ''
  fileName = ''
  fileType = ''
  /**
   * @deprecated This property is deprecated and will be removed in future versions.
   */
  powerBiId = ''
  /**
   * @deprecated This property is deprecated and will be removed in future versions.
   */
  earlyProject = false

  static from(project: any): Project {
    const newProject = new Project()

    if (project) {
      newProject.id = project.id
      newProject.name = project.name
      newProject.phases = project.phases.map((phase: any) => ProjectPhase.fromDto(phase))
      newProject.street = project.street
      newProject.additional = project.additional
      newProject.city = project.city
      newProject.zipCode = project.zipCode
      newProject.typology = project.typology
      newProject.organizationId = project.organizationId
      newProject.businessCode = project.businessCode
      newProject.licenceDate = stringToDate(project.licenceDate) || new Date()
      newProject.earlyProject = project.earlyProject
      newProject.powerBiId = project.powerBiId
      newProject.status = project.status
      newProject.codesVersion = project.codesVersion
      newProject.awsFileKey = project.awsFileKey
      newProject.fileName = project.fileName
      newProject.fileType = project.fileType
    }

    return newProject
  }
}
