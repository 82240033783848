import { DeclarationTypeEnum } from '../../enum/declarationTypeEnum'
import { OriginRecordStatus } from '../../enum/materialLib/originRecordStatus'
import { UnitEnum } from '../../enum/unitEnum'
import { stringToDate } from '../../services/date-service'
import { Project } from '../project/project'
import IniesRecord from './inies-record'
import MaterialRecord from './material-record'
import { Parameters } from './parameters'

interface MaterialStatus {
  text: string
  color: string
}

export enum materialStatusLabel {
  UP_TO_DATE = 'A jour',
  OUTDATED = 'Mise à jour disponible',
  DELETED = 'Supprimé',
}

export function originMaterialRecordToLabel(record: MaterialRecord | Material | undefined): MaterialStatus {
  if (record instanceof MaterialRecord || record instanceof Material) {
    switch (record.originRecordStatus) {
      case OriginRecordStatus.UP_TO_DATE:
        return { text: materialStatusLabel.UP_TO_DATE, color: 'green' }

      case OriginRecordStatus.OUTDATED:
        return { text: materialStatusLabel.OUTDATED, color: 'orange' }

      case OriginRecordStatus.DELETED:
        return { text: materialStatusLabel.DELETED, color: 'red' }

      default:
        break
    }
  }
  return { text: '', color: '' }
}

export class Material {
  id = ''
  projectId = ''
  iniesId: number | undefined
  fdesName = ''
  codeAcv = '' // Used to create an hash map codeAcv -> material
  functionalUnit: UnitEnum | undefined
  totalLifeCycleCarbonImpact: number | undefined = undefined
  referenceLifeTime: number | undefined = undefined
  declarationType!: DeclarationTypeEnum
  description!: string
  re2020CarbonImpact: number | undefined
  expirationDate?: Date
  configuratorId!: string
  parameters!: Parameters[]
  lastIniesUpdate?: Date
  lastModifiedDate?: Date
  lastModifiedUserName!: string
  originRecordStatus: OriginRecordStatus | undefined

  static from(material: IniesRecord | MaterialRecord, project: Project): Material {
    const result = Material.fromDtoNProjectId(material, project?.id, material?.expirationDate)
    return result || new Material()
  }

  static fromCodeDto(material: any): Material | undefined {
    if (material === undefined) {
      return undefined
    } else {
      return Material.fromDtoNProjectId(material, material?.projectId, stringToDate(material?.expirationDate))
    }
  }

  static fromDtoNProjectId(material: any, projectId: string | undefined, expirationDate: Date | undefined): Material {
    if (!material) {
      return new Material()
    }
    const newMaterial = new Material()
    newMaterial.id = material.id
    newMaterial.projectId = projectId ?? ''
    newMaterial.iniesId = material.iniesId
    newMaterial.fdesName = material.fdesName
    newMaterial.functionalUnit = material.functionalUnit
    newMaterial.totalLifeCycleCarbonImpact = material.totalLifeCycleCarbonImpact
    newMaterial.referenceLifeTime = material.referenceLifeTime
    newMaterial.declarationType = material.declarationType
    newMaterial.description = material.description
    newMaterial.re2020CarbonImpact = material.re2020CarbonImpact
    newMaterial.expirationDate = expirationDate

    newMaterial.parameters = material.parameters
    newMaterial.lastIniesUpdate = stringToDate(material.lastIniesUpdate)
    newMaterial.lastModifiedDate = stringToDate(material.lastModifiedDate)
    newMaterial.lastModifiedUserName = material.lastModifiedUserName
    newMaterial.originRecordStatus = material.originRecordStatus

    return newMaterial
  }
}
