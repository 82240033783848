export enum ParkingEnum {
  SOUTERRAIN = 'SOUTERRAIN',
  SUPER_STRUCTURE = 'SUPER_STRUCTURE',
  EXTERIEUR = 'EXTERIEUR',
  SANS = 'SANS',
}

export const parkingEnumLabels = {
  [ParkingEnum.SOUTERRAIN]: 'En souterrain',
  [ParkingEnum.SUPER_STRUCTURE]: 'En superstructure',
  [ParkingEnum.EXTERIEUR]: 'En extérieur',
  [ParkingEnum.SANS]: 'Sans',
}
