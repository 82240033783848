import { Box } from '@mui/material'
import React from 'react'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import { TitleCalculTypography } from '../TitleCalculTypography'
import { BodyTypography } from '../../../../../components/typography/body-typography'
import { CalculationStatusContextProvider } from './CalculationStatusContext'

export function WaitingCalculationCard(): React.JSX.Element {
  return (
    <CalculationStatusContextProvider>
      <WaitingCalculationCardCore />
    </CalculationStatusContextProvider>
  )
}
export function WaitingCalculationCardCore(): React.JSX.Element {
  return (
    <>
      <TitleCalculTypography label="Calcul de l'impact carbone" />
      <Box
        display='flex'
        justifyContent='center'
        borderRadius={2}
        flexDirection='column'
        alignItems='center'
        alignContent='center'
        m={3}
        p={3}
        gap={[0, 2]}
        sx={{ background: '#E6F0FB' }}>
        <AccessTimeOutlinedIcon fontSize='large' />
        <BodyTypography label='Calcul en cours...' textAlign='center' />
        <BodyTypography
          label='Encore un peu de patience, tes résultats seront prêts dans quelques instants.'
          textAlign='center'
        />
      </Box>
    </>
  )
}
