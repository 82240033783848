import { Box, CircularProgress, Grid, GridSize, Paper, Stack, Typography } from '@mui/material'
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { BimModelContext } from '../../core/context/bim-model/bim-model-context'
import { BimModelFileContext } from '../../core/context/bim-model/bim-model-file-context'
import { CodesExtraitsListContext, CodesExtraitsListStore } from '../../core/context/code-acv/codes-extraits-list-context'
import { CodeExtrait } from '../../core/dto/code-extrait/code-extrait'
import { formatToFrench } from '../../core/services/date-service'
import ViewerIFC from '../ifc-displayer/ViewerIFC'
import { ViewerTypes } from '../ifc-displayer/enums/ViewerTypes'
import { CachingHelper } from '../ifc-displayer/helpers/CachingHelper'
import IfcDisplayer from '../ifc-displayer/ifc-displayer'
import TableDataModelList from './extracted-code-list'
import { CodeExtraitDisplay } from '../../core/dto/code-extrait/CodeExtraitDisplay'
import { codeToKey } from '../../core/services/code-service'

type IProps = {
  readonly selectedCodeExtrait: CodeExtrait | undefined
  setSelectedCodeExtrait(codeExtrait: CodeExtrait | undefined): void
  readonly viewer: ViewerIFC | undefined
  readonly setViewerIFC: Dispatch<SetStateAction<ViewerIFC | undefined>>
  readonly ifcDisplayerRef: React.RefObject<HTMLDivElement>
  readonly ifcDisplayerParentRef: React.MutableRefObject<null>
  readonly fullscreenButton?: boolean
  readonly fullscreenTabButton?: boolean
  readonly isInFullscreenTab?: boolean
  readonly isForDashBoardScreen?: boolean
  readonly fullScreenParamToken?: string
}

export default function CodeExtraitListWithViewer({
  selectedCodeExtrait,
  setSelectedCodeExtrait,
  viewer,
  setViewerIFC,
  ifcDisplayerRef,
  ifcDisplayerParentRef,
  fullscreenButton,
  fullscreenTabButton = false,
  isInFullscreenTab = false,
  isForDashBoardScreen,
  fullScreenParamToken,
}: Readonly<IProps>): React.JSX.Element | null {
  const { file, loading: loadingFile } = useContext(BimModelFileContext)
  const { bimModel } = useContext(BimModelContext)
  const { codesExtraits, isCodeExtraitLoaded } = useContext<CodesExtraitsListStore>(CodesExtraitsListContext)
  const [currentFullScreenToken, setCurrentFullScreenToken] = useState<string | undefined>(undefined)
  const [sizeApplied, setSizeApplied] = useState<boolean>(false)
  const hostViewerIdentifier = 'main'
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
  const [isSettingColorsIfc, setIsSettingColorsIfc] = useState<boolean>(false)

  useEffect(() => {
    if (codesExtraits.length && viewer && isFirstLoad) {
      setIsFirstLoad(false)
      if (isInFullscreenTab) {
        const { current } = ifcDisplayerParentRef as unknown as React.MutableRefObject<HTMLDivElement>
        if (!sizeApplied) {
          const newHeight = current?.clientHeight ? current.clientHeight - 120 : undefined
          viewer?.manager.cameras.resizeViewer(current?.clientWidth, newHeight)
          setSizeApplied(true)
        }
      }
    }
  }, [codesExtraits.length, viewer])

  const [isListInitialized, setIsListInitialized] = useState<boolean>(false)

  useEffect(() => {
    if (codesExtraits.length > 0 && !isListInitialized) {
      setIsListInitialized(true)
      const selected: CodeExtrait | undefined = selectNextUnfilledCode()
      setSelectedCodeExtrait(selected)
    }
  }, [codesExtraits])

  useEffect(() => {
    if (!isInFullscreenTab && currentFullScreenToken) {
      // set the current selected code extrait in the fullscreen tab
      CachingHelper.setFullScreen(currentFullScreenToken, selectedCodeExtrait)
    } else if (isInFullscreenTab) {
      const key = `${fullScreenParamToken}-${hostViewerIdentifier}'`
      CachingHelper.setFullScreen(key, selectedCodeExtrait)
    }
  }, [currentFullScreenToken, isInFullscreenTab, selectedCodeExtrait?.id])

  function selectNextUnfilledCode(): CodeExtrait | undefined {
    const nextCode = codesExtraits.find((item) => !item.filled)
    if (nextCode && viewer) {
      return nextCode
    } else if (nextCode) {
      return nextCode
    } else {
      return undefined
    }
  }

  /**
   * listen to the storage event to get the selected code extrait from the main tab
   */
  window.addEventListener('storage', async () => {
    const key = `${currentFullScreenToken}-${hostViewerIdentifier}'`
    if (localStorage.getItem(key) === 'true') {
      const data = await CachingHelper.getFullScreen(key)
      if (viewer && data) {
        const newCodeExtrait: CodeExtrait | undefined = codesExtraits.find(
          (codeExtrait) => data?.selectedCodeExtrait?.id === codeExtrait.id
        )
        setSelectedCodeExtrait(newCodeExtrait)
      }
    }
    localStorage.setItem(key, 'false')
  })

  /**
   * Update the viewer colors with the new "codes extraits" we got from here,
   * the method should be passed to ifc-displayer for the onChangeModel param.
   * Note: this will automatically update the viewer colors when the code extraits
   * contains element ids and colors coming from the backend.
   * @param {number} modelId the model id in the viewer
   * @param {ViewerIFC} viewerIfc the viewer ifc object
   */
  function onChangeModel(modelId: number, viewerIfc: ViewerIFC): void {
    setViewerIFC(viewerIfc)
    if (codesExtraits.length === 0) {
      return
    }
    if (!isSettingColorsIfc) {
      setIsSettingColorsIfc(true)
      viewerIfc.manager.subsetsManager
        .setCodeColors(modelId, codesExtraits)
        .then(() => {
          if (selectedCodeExtrait) {
            viewerIfc?.manager.subsetsManager.highlightCodeExtrait(selectedCodeExtrait)
          }
        })
        .finally(() => setIsSettingColorsIfc(false))
    }
  }

  function handleClickAcv(code: CodeExtraitDisplay, disableViewerHighlight?: boolean): void {
    if (
      !disableViewerHighlight &&
      `${code.code + code.occurence}` !==
        `${selectedCodeExtrait ? selectedCodeExtrait.code + selectedCodeExtrait.occurence : ''}`
    ) {
      viewer?.manager.subsetsManager.highlightCodeExtrait(code)
    }

    const newCodeExtrait: CodeExtrait | undefined = codesExtraits.find(
      (codeExtrait) => codeToKey(code) === codeToKey(codeExtrait)
    )
    setSelectedCodeExtrait(newCodeExtrait)
  }

  /**
   * Handle the set current fullscreen token
   * @param token the token of the current fullscreen tab
   */
  function handleSetCurrentFullScreenToken(token: string): void {
    setCurrentFullScreenToken(token)
  }

  function getGridSize(type: 'ifcPreview' | 'tableModelList'): GridSize {
    if (isInFullscreenTab) {
      return type === 'ifcPreview' ? 12 : 1
    } else if (isForDashBoardScreen) {
      return type === 'ifcPreview' ? 9 : 3
    }

    return 6
  }

  const renderIfcPreview = (): React.JSX.Element => {
    let height: string
    if (isInFullscreenTab) {
      height = '100vh'
    } else {
      height = '500px'
    }
    return (
      <Box id='renderIfc' ref={ifcDisplayerParentRef} style={{ height }}>
        {!file && !isCodeExtraitLoaded && (
          <Paper
            sx={{
              height,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              textAlign: 'center',
              width: '100%',
            }}>
            {loadingFile ? <CircularProgress /> : <Typography>Modèle BIM</Typography>}
          </Paper>
        )}
        {file && isCodeExtraitLoaded && (
          <IfcDisplayer
            ref={ifcDisplayerRef}
            file={file}
            codesExtraits={codesExtraits}
            onChangeModel={onChangeModel}
            setSelectedCodeExtrait={handleClickAcv}
            type={ViewerTypes.CALCULATION}
            fullscreenButton={fullscreenButton}
            fullscreenTabButton={fullscreenTabButton}
            setTabFullScreenToken={handleSetCurrentFullScreenToken}
            height={height}
          />
        )}
        <Grid container columnSpacing={3}>
          {bimModel.createdDate && (
            <Grid item xs={6}>
              <span>
                <Typography fontSize={14} fontWeight='500' display='inline'>
                  {"Date d'import : "}
                </Typography>
                <Typography color='#CFCFCF' fontSize={14} display='inline'>
                  {formatToFrench(bimModel.createdDate)}
                </Typography>
              </span>
            </Grid>
          )}
          {bimModel.createdByUser && (
            <Grid item xs={6} display='flex' justifyContent='flex-end'>
              <span>
                <Typography fontSize={14} fontWeight='500' display='inline'>
                  {'Créé par : '}
                </Typography>
                <Typography color='#CFCFCF' fontSize={14} display='inline'>
                  {bimModel.createdByUser}
                </Typography>
              </span>
            </Grid>
          )}
        </Grid>
      </Box>
    )
  }

  return (
    <Grid container columnSpacing={isInFullscreenTab ? 1 : 2}>
      {!isInFullscreenTab && (
        <Grid item xs={getGridSize('tableModelList')} sx={{ paddingRight: '32px' }}>
          <Stack>
            <TableDataModelList
              selectedCodeExtrait={selectedCodeExtrait}
              codeExtrait={codesExtraits}
              handleClickAcv={handleClickAcv}
            />
          </Stack>
        </Grid>
      )}
      <Grid item xs={getGridSize('ifcPreview')} sx={{ width: '100%', height: 'auto', maxHeight: '500px' }}>
        {renderIfcPreview()}
      </Grid>
    </Grid>
  )
}
