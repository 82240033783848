import { appConstants } from '../../../core/appConstants'

export class IfcPropertyHelper {
  /**
   * Receives a string and checks if a property value is specially encoded with a value matches the given REGEX expression,
   * and if it does, it will convert the unicode values to normal characters.
   * @param s string that will be decoded
   */
  static decodeIfcProperty(s: string): string {
    let resultString = s
    let match = appConstants.regex.ifcUnicodeRegEx.exec(s)
    while (match) {
      const unicodeChar = String.fromCharCode(parseInt(match[1], 16))
      resultString = resultString.replace(match[0], unicodeChar)
      match = appConstants.regex.ifcUnicodeRegEx.exec(s)
    }

    return this.fixUnicodeString(resultString)
  }

  /**
   * Some cases in IFC files, the unicode characters are encoded in a different way, so this function will fix those cases.
   * @param str string that will be decoded
   * @returns decoded string
   */
  static fixUnicodeString(str: string): string {
    const matches = str.match(appConstants.regex.ifcUnicodeAccentMatcherRegEx)
    if (matches === null) {
      return str
    }
    let result = str
    for (const match of matches) {
      const hexChars = match.substr(-2)
      const uni = String.fromCharCode(parseInt(hexChars, 16))
      const replacement = `\\X\\${hexChars}`
      result = result.replace(replacement, uni)
    }
    return result.replace(appConstants.regex.ifcUnicodeAccentReplacerRegEx, (_, pair) =>
      String.fromCharCode(parseInt(pair, 16))
    )
  }
}
