import { Button } from '@mui/material'
import React from 'react'

interface IProps {
  label: string

  onClick(): void
}

export function BaseButton({ onClick, label }: IProps): React.JSX.Element {
  return (
    <Button variant='contained' onClick={onClick}>
      {label}
    </Button>
  )
}
