export enum UsageEnum {
  MAISONS_INDIVIDUELLES_OU_ACCOLEES = 'MAISONS_INDIVIDUELLES_OU_ACCOLEES',
  LOGEMENTS_COLLECTIFS = 'LOGEMENTS_COLLECTIFS',
  BUREAUX = 'BUREAUX',
  ENSEIGNEMENT_PRIMAIRE_OU_SECONDAIRE = 'ENSEIGNEMENT_PRIMAIRE_OU_SECONDAIRE',
}

export const usageEnumLabels = {
  [UsageEnum.MAISONS_INDIVIDUELLES_OU_ACCOLEES]: 'Maisons individuelles ou accolées',
  [UsageEnum.LOGEMENTS_COLLECTIFS]: 'Logements Collectifs',
  [UsageEnum.BUREAUX]: 'Bureaux',
  [UsageEnum.ENSEIGNEMENT_PRIMAIRE_OU_SECONDAIRE]: 'Enseignement primaire ou secondaire',
}

export const usagesLabels = [
  {
    label: 'Maisons individuelles ou accolées',
    value: UsageEnum.MAISONS_INDIVIDUELLES_OU_ACCOLEES,
  },
  { label: 'Logements collectifs', value: UsageEnum.LOGEMENTS_COLLECTIFS },
  {
    label: 'Enseignement primaire ou secondaire',
    value: UsageEnum.ENSEIGNEMENT_PRIMAIRE_OU_SECONDAIRE,
  },
  { label: 'Bureaux', value: UsageEnum.BUREAUX },
]
