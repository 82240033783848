import AccessTimeIcon from '@mui/icons-material/AccessTime'
import RotateRightIcon from '@mui/icons-material/RotateRight'
import { Box } from '@mui/material'
import React, { useMemo } from 'react'
import { BSProjectCardDto } from '../../core/dto/beem-shot/BSProject/BSProjectCardDto'
import { SeuilEnum } from '../../core/enum/beem-shot/SeuilEnum'
import { CalculStatusEnum, calculStatusToLabel } from '../../core/enum/calculStatusEnum'
import { DefaultTypography } from '../typography/default-typography'

type IProps = {
  bsProjectCardInfo: BSProjectCardDto
}

export function BsProjectCardSeuil({ bsProjectCardInfo }: IProps): React.JSX.Element {
  const seuiValide = useMemo(
    () => bsProjectCardInfo.higherSeuilValide === SeuilEnum.INVALIDE,
    [bsProjectCardInfo.higherSeuilValide]
  )

  function getSeuil(seuil: SeuilEnum): number {
    switch (seuil) {
      case SeuilEnum.INVALIDE:
      case SeuilEnum.SEUIL_2022:
        return bsProjectCardInfo?.diffIc2022 ?? 0.0
      case SeuilEnum.SEUIL_2025:
        return bsProjectCardInfo?.diffIc2025 ?? 0.0
      case SeuilEnum.SEUIL_2028:
        return bsProjectCardInfo?.diffIc2028 ?? 0.0
      case SeuilEnum.SEUIL_2031:
        return bsProjectCardInfo?.diffIc2031 ?? 0.0

      default:
        return 0.0
    }
  }

  function getSeuilBackgroundColor(): string {
    switch (bsProjectCardInfo.calculStatus) {
      case CalculStatusEnum.WAITING_FOR_MODEL:
      case CalculStatusEnum.WAITING_FOR_CONTROL:
      case CalculStatusEnum.WAITING_FOR_CONFIGURATION:
      case CalculStatusEnum.CALCUL_IN_PROGRESS:
        return '#FFF3E0'
      case CalculStatusEnum.READY:
        return seuiValide ? '#FEEBEE' : '#E5FFF4'
      default:
        return '#FFF3E0'
    }
  }

  function getTypologyColor(): JSX.Element | undefined {
    switch (bsProjectCardInfo.calculStatus) {
      case CalculStatusEnum.WAITING_FOR_MODEL:
      case CalculStatusEnum.WAITING_FOR_CONTROL:
      case CalculStatusEnum.WAITING_FOR_CONFIGURATION:
        return (
          <>
            <RotateRightIcon fontSize='medium' sx={{ color: '#E65100', pr: 1 }} />
            <DefaultTypography
              label={calculStatusToLabel(bsProjectCardInfo.calculStatus)}
              fontSize='14px'
              lineHeight='20.02px'
              fontWeight={400}
              color='#E65100'
            />
          </>
        )

      case CalculStatusEnum.CALCUL_IN_PROGRESS:
        return (
          <>
            <AccessTimeIcon fontSize='medium' sx={{ color: '#E65100', pr: 1 }} />
            <DefaultTypography
              label={calculStatusToLabel(bsProjectCardInfo.calculStatus)}
              fontSize='14px'
              lineHeight='20.02px'
              fontWeight={400}
              color='#E65100'
            />
          </>
        )
      case CalculStatusEnum.READY:
        return (
          <DefaultTypography
            label='Calcul terminé'
            fontSize='14px'
            lineHeight='20.02px'
            fontWeight={400}
            color={seuiValide ? '#F00C0C' : '#009069'}
          />
        )
      default:
        return (
          <>
            <RotateRightIcon fontSize='medium' sx={{ color: '#E65100', pr: 1 }} />
            <DefaultTypography
              label="En attente d'une variante"
              fontSize='14px'
              lineHeight='20.02px'
              fontWeight={400}
              color='#E65100'
            />
          </>
        )
    }
  }

  function getBProjectCardStatus(): JSX.Element {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        minHeight={50}
        borderRadius={2}
        sx={{ width: '100%', backgroundColor: getSeuilBackgroundColor() }}>
        {getTypologyColor()}
      </Box>
    )
  }

  return getBProjectCardStatus()
}
