import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined'
import { Box, Grid, Step, StepLabel, Stepper, styled, Toolbar, Tooltip, tooltipClasses, TooltipProps } from '@mui/material'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { theme } from '../../../theme'
import { BSProjectContext } from '../../core/context/beem-shot/BSProject/BSProjectContext'
import { BSVariantContext } from '../../core/context/beem-shot/BSVariant/BSVariantContext'
import { AppNavBarContext } from '../../core/context/nav-bar/AppNavBarContext'
import { CustomTooltipDescription } from '../tooltip/CustomTooltipDescription'
import './app-navbar-v2.scss'

export function StepHeaderNavbar(): React.JSX.Element {
  const { previousUrl, stepHeader } = useContext(AppNavBarContext)
  const { bsProject } = useContext(BSProjectContext)
  const { selectedVariant } = useContext(BSVariantContext)

  const navigate = useNavigate()

  const steps = ['Importer la maquette', 'Contrôler la maquette', 'Vérifier les informations']

  return (
    <Toolbar
      disableGutters
      sx={{
        color: 'white',
        borderRadius: 5,
        backgroundColor: '#070F27',
        zIndex: -5000,
        ...theme.mixins.toolbar,
      }}>
      <Grid container display='flex' justifyContent='space-between' alignContent='center'>
        <Grid container item xs={2} display='flex' justifyContent='space-around' alignContent='center' alignItems='center'>
          <ArrowBackOutlinedIcon onClick={() => navigate(previousUrl)} fontSize='medium' sx={{ cursor: 'pointer' }} />
          <Box display='flex' alignItems='center' sx={{ borderRadius: 2, background: '#b7ffe3', p: 1, m: 1 }}>
            <DonutSmallOutlinedIcon fontSize='large' sx={{ color: '#0BE2A0', fontWeight: '2px' }} />
          </Box>
          <Box>
            <Box gap={1}>
              {selectedVariant?.name}
              {selectedVariant?.description && (
                <sup>
                  <CustomTooltipDescription description='Description du calcul' title={selectedVariant?.description} />
                </sup>
              )}
            </Box>
            <Box> BEEM SHOT</Box>
          </Box>
        </Grid>

        <Grid item xs={8} display='flex' justifyContent='center' alignContent='center'>
          <Stepper
            alternativeLabel
            activeStep={stepHeader}
            sx={{
              m: 1,

              width: '100%',
              '& .MuiStepConnector-line': {
                borderColor: '#D3D3D3',
                borderWidth: 4,
                m: 0,
              },
              [`& .MuiStepConnector-lineHorizontal`]: {
                borderColor: '#D3D3D3', // Grey by default
              },
              [`& .Mui-completed .MuiStepConnector-line`]: {
                borderColor: '#0BE2A0', // Green if step is completed
              },
              [`& .Mui-active .MuiStepConnector-line`]: {
                borderColor: '#0BE2A0', // Green if step is active
              },
            }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel
                  sx={{
                    textAlign: 'center',
                    '& .MuiSvgIcon-root ': {
                      textAlign: 'center',
                      color: '#FFFFFF61',
                      border: 1,
                      p: '2px',
                      borderRadius: '50px',
                      m: 0,
                    },
                    '& .MuiSvgIcon-root.Mui-active': { textAlign: 'center', color: '#0BE2A0', zIndex: 9999 },
                    '& .MuiSvgIcon-root.Mui-completed': { textAlign: 'center', color: '#0BE2A0', zIndex: 9999 },
                    '& .MuiStepLabel-label': { textAlign: 'center', color: '#FFFFFF61' },
                    '& .MuiStepLabel-label.Mui-completed': { textAlign: 'center', color: '#FFFFFF61' },
                    '& .MuiStepLabel-label.Mui-active': { textAlign: 'center', color: '#ffffff' },
                  }}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>

        <Grid item xs={2} display='flex' justifyContent='center' />
      </Grid>
    </Toolbar>
  )
}
