import { useMemo } from 'react'
import { useHttp } from '../../core/hooks/use-http'
import { resolveUrl } from '../../core/services/http-service'
import { appConstants } from '../../core/appConstants'
import { DashboardInfos } from '../../core/dto/kairnial/DashboardInfos'
import { TableauJwt } from '../../core/dto/tableau-jwt'
import { KairnialProject } from '../../core/dto/kairnial/KairnialProject'

type KairnialHook = {
  getDashboardInfos(secureToken: string, clientId: string): Promise<DashboardInfos>
  fetchTableauJwt(): Promise<TableauJwt>
  fetchKairnialProjectList(): Promise<KairnialProject[]>
  refreshData(secureToken: string, clientId: string): Promise<void>
}

export function useKairnial(): KairnialHook {
  const { get } = useHttp()

  return useMemo(
    () => ({
      getDashboardInfos(secureToken: string, clientId: string): Promise<DashboardInfos> {
        return get(resolveUrl(appConstants.apiEndpoints.KAIRNIAL_DASHBOARD_INFO, [], { secureToken, clientId })).then(
          (response) => response.json()
        )
      },
      fetchTableauJwt(): Promise<TableauJwt> {
        return get(appConstants.apiEndpoints.KAIRNIAL_JWT).then((response) => response.json())
      },
      fetchKairnialProjectList(): Promise<KairnialProject[]> {
        return get(appConstants.apiEndpoints.KAIRNIAL_PROJECTS).then((response) => response.json())
      },
      refreshData(secureToken: string, clientId: string): Promise<void> {
        return get(resolveUrl(appConstants.apiEndpoints.KAIRNIAL_REFRESH, [], { secureToken, clientId })).then((response) =>
          response.json()
        )
      },
    }),
    [get]
  )
}
