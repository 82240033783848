export default class ReportDto {
  id: string | undefined = undefined

  reportType = ''

  name = ''

  webUrl = ''

  embedUrl = ''

  isFromPbix = ''

  datasetId = ''

  datasetWorkspaceId = ''

  embedToken = ''
}
