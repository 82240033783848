import { Typography } from '@mui/material'
import React from 'react'

export interface ITextInputProps {
  label: string
  color?: string
  textAlign?: string
}

export function BodyTypography({
  label,
  color = '#070F27',
  textAlign = 'left',
}: Readonly<ITextInputProps>): React.JSX.Element {
  return (
    <Typography
      sx={{
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        textAlign,
        color,
      }}>
      {label}
    </Typography>
  )
}
