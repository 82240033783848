import { useMemo } from 'react'
import { appConstants } from '../../appConstants'
import { CodeExtraitCreation } from '../../dto/code-extrait/code-extrait-creation'
import { useHttp } from '../use-http'

type CodeHook = {
  postCodeExtrait(bsVariantId: string, codeExtrait: CodeExtraitCreation[], updateVersion: boolean): Promise<void>
}

export function useBSCode(): CodeHook {
  const { post } = useHttp()

  return useMemo(
    () => ({
      postCodeExtrait(bsVariantId, codeExtrait, updateVersion) {
        return post(`${appConstants.apiEndpoints.BEEM_SHOT}/bsVariantId/${bsVariantId}/codes-extraits`, codeExtrait, [
          {
            key: 'updateVersion',
            value: updateVersion.toString(),
          },
        ]).then(() => undefined)
      },
    }),
    [post]
  )
}
