import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Children } from '../../../components/miscellianous/children'
import { CodeExtrait } from '../../dto/code-extrait/code-extrait'
import { PhaseEnum } from '../../enum/phaseEnum'
import { useCode } from '../../hooks/use-code'
import { ProjectContext } from '../project/project-context'
import { SelectedPhaseContext } from '../selected-phase-context'
import { sortCodeExtrait } from '../../services/code-service'

export const CodesExtraitsListContext = React.createContext<CodesExtraitsListStore>({} as CodesExtraitsListStore)

export default function CodesExtraitsListContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { project } = useContext(ProjectContext)
  const { selectedPhase } = useContext(SelectedPhaseContext)
  const { fetchCodesExtraits } = useCode()
  const [codesExtraits, setCodesExtraits] = useState<CodeExtrait[]>([])
  const [isCodeExtraitLoaded, setIsCodeExtraitLoaded] = useState<boolean>(false)

  const refreshCodeExtrait: () => Promise<CodeExtrait[] | undefined> = useCallback(() => {
    if (project?.id && selectedPhase) {
      return fetchCodesExtraits(project.id, selectedPhase).then((codeExtraits) => {
        if (codeExtraits) {
          codeExtraits.sort(sortCodeExtrait)
        }
        setCodesExtraits(codeExtraits)
        setIsCodeExtraitLoaded(true)
        return codeExtraits
      })
    } else {
      return Promise.resolve(undefined)
    }
  }, [fetchCodesExtraits, project.id, selectedPhase])

  useEffect(() => {
    refreshCodeExtrait()
  }, [refreshCodeExtrait])

  const codesExtraitsStore: CodesExtraitsListStore = useMemo(
    () => ({
      codesExtraits,
      setCodesExtraitsList: setCodesExtraits,
      isCodeExtraitLoaded,
      fetchCodesExtraits,
      refreshCodeExtrait,
    }),
    [codesExtraits, fetchCodesExtraits, isCodeExtraitLoaded, refreshCodeExtrait]
  )
  return <CodesExtraitsListContext.Provider value={codesExtraitsStore}>{children}</CodesExtraitsListContext.Provider>
}

export type CodesExtraitsListStore = {
  codesExtraits: CodeExtrait[]
  setCodesExtraitsList: Dispatch<SetStateAction<CodeExtrait[]>>
  isCodeExtraitLoaded: boolean
  fetchCodesExtraits: (projectId: string, phase: PhaseEnum) => Promise<CodeExtrait[]>
  refreshCodeExtrait(): Promise<CodeExtrait[] | undefined>
}
