import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Children } from '../../../components/miscellianous/children'
import { Organization } from '../../dto/organization/organization'
import { useOrganization } from '../../hooks/use-organization'
import { UserContext } from '../user/user-context'
import { OrganizationRoleEnum } from '../../enum/user/organization-role-enum'

export const OrganizationContext = React.createContext<OrganizationStore>({} as OrganizationStore)

export default function OrganizationContextProvider({ children }: Children): React.JSX.Element {
  const { user } = useContext(UserContext)
  const [organization, setOrganization] = useState<Organization | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const { fetchMyOrganization } = useOrganization()

  const refreshOrganization = useCallback(
    (): Promise<Organization | undefined> => {
      if (user?.role && user.role !== OrganizationRoleEnum.TEST_USER && user.role !== OrganizationRoleEnum.ULTIMATE_USER) {
        if (!loading) {
          setLoading(true)
          return fetchMyOrganization()
            .then((newOrganization) => {
              setOrganization(newOrganization)
              return newOrganization
            })
            .finally(() => {
              setLoading(false)
            })
        }
      } else {
        setOrganization(undefined)
      }

      return Promise.resolve(undefined)
    },
    //loading
    [fetchMyOrganization, user]
  )

  useEffect(() => {
    refreshOrganization()
  }, [refreshOrganization, user])

  const organizationStore: OrganizationStore = useMemo(
    () => ({ organization, isOrganizationLoading: loading, setOrganization, refreshOrganization }),
    [organization, loading, refreshOrganization]
  )
  return <OrganizationContext.Provider value={organizationStore}>{children}</OrganizationContext.Provider>
}

export type OrganizationStore = {
  organization: Organization | undefined
  isOrganizationLoading: boolean
  setOrganization(organization: Organization): void
  refreshOrganization(): Promise<Organization | undefined>
}
