import React, { ChangeEvent, FormEvent, useContext, useState } from 'react'

import { Button, Grid, TextField } from '@mui/material'
import { appConstants } from '../../core/appConstants'
import { UserContext, UserStore } from '../../core/context/user/user-context'
import { isPhoneValid, roleMyAccountSelectOptions } from '../../core/services/user-service'
import AccountHeadingForm from './account-heading-form'
import { MyUserUpdateDto } from '../../core/dto/user/my-user-update-dto'
import PhoneInput from '../../components/inputs/phone-input/phone-input'
import SelectInput from '../../components/inputs/select-input/select-input'

type UserForm = {
  email: string
  givenName: string
  familyName: string
  phone: string
  organizationName: string
}

export default function AccountInfosFormField(): React.JSX.Element {
  const { user, updateUserContext, userLoading } = useContext<UserStore>(UserContext)

  const [userForm, setUserForm] = useState<UserForm>({
    email: user?.email ?? '',
    givenName: user?.givenName ?? '',
    familyName: user?.familyName ?? '',
    phone: user?.phone ?? '',
    organizationName: user?.organizationName ?? '',
  })

  const [error, setError] = useState<Record<string, any>>({})

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.id

    setUserForm({ ...userForm, [name]: value })
  }

  function onSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault()
    const isValid = validate()

    if (isValid && user?.cognitoUserId) {
      const userUpdateDto: MyUserUpdateDto = {
        cognitoUserId: user.cognitoUserId,
        organizationId: user.organizationId,
        email: userForm.email.toLowerCase(),
        givenName: userForm.givenName,
        familyName: userForm.familyName,
        phone: userForm.phone,
      }
      updateUserContext(userUpdateDto)
    }
  }

  function validate(): boolean {
    let isValid = true
    if (userForm.email === undefined || userForm.email === '') {
      setError({ ...error, email: 'Ce champ est requis' })
      isValid = false
    } else if (!appConstants.regex.email.test(userForm.email)) {
      setError({ ...error, email: "L'adresse email ne respecte pas la forme classique : email@complement.fin" })
      isValid = false
    }

    if (userForm.givenName === undefined || userForm.givenName === '') {
      setError({ ...error, givenName: 'Ce champ est requis' })
      isValid = false
    }

    if (userForm.familyName === undefined || userForm.familyName === '') {
      setError({ ...error, familyName: 'Ce champ est requis' })
      isValid = false
    }

    if (userForm.phone === '') {
      setError({ ...error, phone: 'Ce champ est requis' })
      isValid = false
    } else if (!isPhoneValid(userForm.phone)) {
      setError({
        ...error,
        phone:
          'Le numéro de téléphone doit être composé uniquement de chiffres et faire 10 caractères ou commencer par "+" et faire 12 caractères',
      })
      isValid = false
    }

    return isValid
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  function doNothing(selectedValue: string): void {}

  return (
    // <Box component='form' onSubmit={onSubmit} display='flex' sx={{ flex: 1, mr: 5 }} flexDirection='column'>
    <Grid container component='form' onSubmit={onSubmit} spacing={2}>
      <Grid item xs={12}>
        <AccountHeadingForm title='MES INFORMATIONS' />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id='familyName'
          sx={{ flex: 1, mr: 6 }}
          defaultValue={userForm.familyName}
          label='Nom'
          variant='outlined'
          error={error?.familyName}
          helperText={error?.familyName}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id='givenName'
          sx={{ flex: 1 }}
          defaultValue={userForm.givenName}
          label='Prénom'
          variant='outlined'
          error={error?.givenName}
          helperText={error.givenName}
          onChange={handleChange}
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          sx={{ mt: 2, mb: 2 }}
          value={userForm.organizationName}
          disabled
          label='Entreprise'
          variant='outlined'
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <PhoneInput form={userForm} error={error} handleChange={handleChange} required variant='outlined' fullWidth />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='email'
          sx={{ mt: 2, mb: 2 }}
          defaultValue={userForm.email}
          label='Adresse email'
          variant='outlined'
          error={!!error?.email}
          helperText={error.email?.message}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <SelectInput
          id='role'
          mode='direct'
          disabled
          label="Type d'utilisateur"
          selectedOption={user?.role}
          options={roleMyAccountSelectOptions}
          handleChange={doNothing}
        />
      </Grid>
      <Grid item xs={12} justifyContent='center' display='flex'>
        <Button
          disabled={userLoading}
          sx={{ mt: 3, height: 50, alignSelf: 'center', width: 300 }}
          variant='contained'
          type='submit'>
          {userLoading ? 'Modification...' : 'Modifier'}
        </Button>
      </Grid>
    </Grid>
  )
}
