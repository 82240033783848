import { Button } from '@mui/material'
import React from 'react'

type IProps = {
  action(): void
  label: string | undefined
}

export default function CancelButton({ action, label }: IProps): React.JSX.Element {
  return (
    <Button variant='contained' color='secondary' size='medium' onClick={action}>
      {label}
    </Button>
  )
}
