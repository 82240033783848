import { IfcViewerAPI } from 'web-ifc-viewer'
import { IfcUnit } from '../enums/IfcUnit'
import { UnitsInfo } from '../models/UnitsInfo'

export class UnitsHelper {
  /**
   * Returns a unit given a name and a prefix which is comming from the IFC file.
   * @param  {string} name The name of the unit
   * @param  {string=""} prefix The prefix of the unit
   * @returns Promise with the unit
   */
  static getUnitFromName(name: string, prefix: string): IfcUnit {
    if (name === 'METRE' && prefix === '') return IfcUnit.METER
    else if (name === 'FOOT' && prefix === '') return IfcUnit.FOOT
    else if (name === 'INCH' && prefix === '') return IfcUnit.INCH
    else if (name === 'METRE' && prefix === 'CENTI') return IfcUnit.CENTIMETER
    else if (name === 'METRE' && prefix === 'MILLI') return IfcUnit.MILLIMETER
    else if (name === 'SQUARE_METRE' && prefix === '') return IfcUnit.SQUARE_METER
    else if (name === 'SQUARE FOOT' && prefix === '') return IfcUnit.SQUARE_FOOT
    else if (name === 'SQUARE INCH' && prefix === '') return IfcUnit.SQUARE_INCH
    else if (name === 'SQUARE_METRE' && prefix === 'CENTI') return IfcUnit.SQUARE_CENTIMETER
    else if (name === 'SQUARE_METRE' && prefix === 'MILLI') return IfcUnit.SQUARE_MILLIMETER
    else if (name === 'CUBIC_METRE' && prefix === '') return IfcUnit.CUBIC_METER
    else if (name === 'CUBIC FOOT' && prefix === '') return IfcUnit.CUBIC_FOOT
    else if (name === 'CUBIC INCH' && prefix === '') return IfcUnit.CUBIC_INCH
    else if (name === 'CUBIC_METRE' && prefix === 'CENTI') return IfcUnit.CUBIC_CENTIMETER
    else if (name === 'CUBIC_METRE' && prefix === 'MILLI') return IfcUnit.CUBIC_MILLIMETER
    else if (name === 'CUBIC_METRE' && prefix === 'DECI') return IfcUnit.CUBIC_DECIMETER
    else return IfcUnit.UNKNOWN
  }

  /**
   * Returns the unit from the name
   * @param  {IfcUnit} unit The unit
   * @returns string The unit name
   */
  static getUnitSuffix(unit: IfcUnit): string {
    if (unit === IfcUnit.METER) return 'm'
    else if (unit === IfcUnit.CENTIMETER) return 'cm'
    else if (unit === IfcUnit.MILLIMETER) return 'mm'
    else if (unit === IfcUnit.FOOT) return 'ft'
    else if (unit === IfcUnit.INCH) return 'in'
    else if (unit === IfcUnit.SQUARE_METER) return 'm²'
    else if (unit === IfcUnit.SQUARE_CENTIMETER) return 'cm²'
    else if (unit === IfcUnit.SQUARE_MILLIMETER) return 'mm²'
    else if (unit === IfcUnit.SQUARE_FOOT) return 'ft²'
    else if (unit === IfcUnit.SQUARE_INCH) return 'in²'
    else if (unit === IfcUnit.CUBIC_METER) return 'm³'
    else if (unit === IfcUnit.CUBIC_CENTIMETER) return 'cm³'
    else if (unit === IfcUnit.CUBIC_MILLIMETER) return 'mm³'
    else if (unit === IfcUnit.CUBIC_FOOT) return 'ft³'
    else if (unit === IfcUnit.CUBIC_INCH) return 'in³'
    else if (unit === IfcUnit.CUBIC_DECIMETER) return 'L'
    else return ''
  }

  static async getModelUnits(viewer: IfcViewerAPI, modelID: number): Promise<UnitsInfo> {
    // the unit system is broken since web-ifc-viewer@1.0.216 we assume meters and keep
    // the method for the time being. ref: https://discord.com/channels/799990228336115742/1110597358846226546
    const length = IfcUnit.METER
    const area = IfcUnit.SQUARE_METER
    const volume = IfcUnit.UNKNOWN

    return {
      length,
      area,
      volume,
    }
  }

  static async getConversionUnits(viewer: IfcViewerAPI, modelId: number): Promise<any> {
    const modelUnit = await UnitsHelper.getModelUnit(viewer, modelId)
    const modelUnitSymbol = await UnitsHelper.getUnitSymbol(modelUnit)

    return { projectUnit: modelUnit, drawingUnit: modelUnit, unitSymbol: modelUnitSymbol }
  }

  static setNumericalPrecision(unit: IfcUnit | undefined): 1 | 2 | 3 {
    if (unit === IfcUnit.CENTIMETER || unit === IfcUnit.INCH) return 2
    else if (unit === IfcUnit.MILLIMETER) return 1
    else return 2
  }

  private static async getModelUnit(viewer: IfcViewerAPI, modelID: number): Promise<IfcUnit> {
    if (modelID !== null) {
      return (await UnitsHelper.getModelUnits(viewer, modelID))?.length
    }
    return IfcUnit.UNKNOWN
  }

  private static async getUnitSymbol(unit: IfcUnit): Promise<string> {
    let unitSymbol = ''
    if (unit !== null && unit !== undefined) {
      unitSymbol = UnitsHelper.getUnitSuffix(unit)
    }
    return unitSymbol
  }
}
