export class SpringHttpError {
  error = ''
  timestamp: Date = new Date(0)
  status = 0
  path = ''
  message = ''

  constructor(err: any) {
    this.error = err.error
    this.timestamp = new Date(err.timestamp)
    this.status = err.status
    this.path = err.path
    this.message = err.message
  }
}
