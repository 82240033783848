import CheckIcon from '@mui/icons-material/Check'
import CircleIcon from '@mui/icons-material/Circle'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Grid, Table, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { CodeExtrait } from '../../core/dto/code-extrait/code-extrait'
import TreeList from '../tree-list/tree-list'
import './extracted-code-list.css'

type ProjectTableModelListProps = {
  codeExtrait: CodeExtrait[]
  handleClickAcv: (codeExtract: CodeExtrait) => void
  selectedCodeExtrait?: CodeExtrait
}
export default function ProjectTableModelList({
  codeExtrait,
  handleClickAcv,
  selectedCodeExtrait,
}: Readonly<ProjectTableModelListProps>): React.JSX.Element {
  const tableRef = useRef<HTMLTableElement>(null)

  useEffect(() => {
    if (tableRef) {
      const rowsList = tableRef.current?.querySelectorAll('tr')
      rowsList?.forEach((row) => {
        if (row.id === selectedCodeExtrait?.id) {
          row.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
        }
      })
    }
  }, [selectedCodeExtrait])

  return (
    <Box component='div' sx={{ overflow: 'auto' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                p: 0,
                width: '50%',
                textAlign: 'center',
              }}>
              Code ACV
            </TableCell>
            <TableCell
              sx={{
                p: 0,
                width: '25%',
                textAlign: 'center',
              }}>
              Couleurs
            </TableCell>
            <TableCell
              sx={{
                p: 0,
                width: '25%',
                textAlign: 'center',
              }}>
              FDES Renseignées
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
      <Box sx={{ overflow: 'auto', maxHeight: '491px' }}>
        <TreeList
          codesExtraits={codeExtrait}
          handleClickAcv={handleClickAcv}
          selectedCodeExtrait={selectedCodeExtrait}
          renderCodeElement={(code: CodeExtrait) => (
            <Grid id={`component-${code.code}${code.occurence}`} container>
              <Grid item xs={8} display='flex' justifyContent='flex-start' alignItems='center'>
                <Typography variant='subtitle1' fontSize={14} align='center'>{`${code.code}${code.occurence}`}</Typography>
              </Grid>
              <Grid item xs={4} display='flex' justifyContent='flex-end' alignItems='center'>
                <CircleIcon sx={{ fontSize: '40px', color: code.color, mx: '5px' }} />
                {code?.filled ? <CheckIcon color='success' /> : <CloseIcon color='error' />}
              </Grid>
            </Grid>
          )}
        />
      </Box>
    </Box>
  )
}
