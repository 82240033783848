import { Outlet, Route } from 'react-router-dom'
import { BSLayout } from './app/components/layout/BSLayout'
import RequireAuth from './app/components/redirect/require-auth'
import { pagesUrl } from './app/core/appConstants'
import BsModelContextProvider from './app/core/context/beem-shot/BSBimModel/BSBimModelContext'
import BsModelFileContextProvider from './app/core/context/beem-shot/BSBimModel/BSBimModelFileContext'
import { BsImportContextProvider } from './app/core/context/beem-shot/BSBimModel/BSBimModelImportContext'
import BSModelInvitationContextProvider from './app/core/context/beem-shot/BSBimModel/BSModelInvitationContextProvider'
import { BSCategoriesContextProvider } from './app/core/context/beem-shot/BSCategory/BSCategoriesContext'
import { BSInputContextProvider } from './app/core/context/beem-shot/BSInputContext/BSInputContext'
import { BSItemContextProvider } from './app/core/context/beem-shot/BSItems/BSItemContext'
import { BSProjectContextProvider } from './app/core/context/beem-shot/BSProject/BSProjectContext'
import { BsProjectListContextProvider } from './app/core/context/beem-shot/BSProject/BSProjectListContext'
import { BSVariantContextProvider } from './app/core/context/beem-shot/BSVariant/BSVariantContext'
import LotContextProvider from './app/core/context/lot-context'
import { NavBarContextProvider } from './app/core/context/nav-bar/AppNavBarContext'
import OrganizationContextProvider from './app/core/context/organization/organization-context'
import SideBarContextProvider from './app/core/context/side-bar/side-bar-context'
import { BSBimModelControl } from './app/pages/beem-shot/BSBimModelPage/BSBimModelControl'
import { BSInvitationConfirmationPage } from './app/pages/beem-shot/BSBimModelPage/BSInvitationConfirmationPage'
import { BSUploadPage } from './app/pages/beem-shot/BSBimModelPage/BSUploadPage'
import { BSCalculationPage } from './app/pages/beem-shot/BSCalculationPage/BSCalculationPage'
import { BSCreateCalculationPage } from './app/pages/beem-shot/BSCalculationPage/BSCreateCalculationPage'
import { BSHomePage } from './app/pages/beem-shot/BSHomePage'
import { BeemShotProjectDetailPage } from './app/pages/beem-shot/BSProjectDetailsPage/BSProjectDetailsPage'

export const BSRoutes = (
  <Route
    element={
      <OrganizationContextProvider>
        <RequireAuth>
          <BSProjectContextProvider>
            <BSVariantContextProvider>
              <SideBarContextProvider>
                <NavBarContextProvider>
                  <BSLayout />
                </NavBarContextProvider>
              </SideBarContextProvider>
            </BSVariantContextProvider>
          </BSProjectContextProvider>
        </RequireAuth>
      </OrganizationContextProvider>
    }>
    <Route
      path={pagesUrl.BEEM_SHOT_HOME}
      element={
        <LotContextProvider>
          <Outlet />
        </LotContextProvider>
      }>
      <Route
        path={pagesUrl.BEEM_SHOT_HOME}
        element={
          <BsProjectListContextProvider>
            <BSHomePage />
          </BsProjectListContextProvider>
        }
      />
      <Route
        element={
          <BsModelContextProvider>
            <BSInputContextProvider>
              <Outlet />
            </BSInputContextProvider>
          </BsModelContextProvider>
        }>
        <Route
          element={
            <BsModelFileContextProvider>
              <BSItemContextProvider>
                <BSModelInvitationContextProvider>
                  <BsImportContextProvider>
                    <BSCategoriesContextProvider>
                      <Outlet />
                    </BSCategoriesContextProvider>
                  </BsImportContextProvider>
                </BSModelInvitationContextProvider>
              </BSItemContextProvider>
            </BsModelFileContextProvider>
          }>
          <Route path={pagesUrl.BEEM_SHOT_CALCUL} element={<BSCalculationPage />} />
          <Route path={pagesUrl.BEEM_SHOT_UPLOAD_PAGE} element={<BSUploadPage />} />
          <Route path={pagesUrl.BEEM_SHOT_INVITATION_CONFIRMATION} element={<BSInvitationConfirmationPage />} />
          <Route path={pagesUrl.BEEM_SHOT_BIM_MODEL_CONTROL} element={<BSBimModelControl />} />
          <Route path={pagesUrl.BEEM_SHOT_CREATE_CALCUL} element={<BSCreateCalculationPage />} />
        </Route>
        <Route path={pagesUrl.BEEM_SHOT_PROJECT} element={<BeemShotProjectDetailPage />} />
      </Route>
    </Route>
  </Route>
)
