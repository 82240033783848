export enum UnitEnum {
  METER = 'METER',
  SQUARE_METER = 'SQUARE_METER',
  CUBIC_METER = 'CUBIC_METER',
  KG = 'KG',
  PER_UNIT = 'PER_UNIT',
  EXTRACTED = 'EXTRACTED',
  TO_BE_CHOSEN = 'TO_BE_CHOSEN',
  UNKNOWN = 'UNKNOWN',
  NO_UNIT = 'NO_UNIT',
  SQUARE_DECIMETER = 'SQUARE_DECIMETER',
  MEGAJOULES = 'MEGAJOULES',
  KILOWATT_PER_HOUR = 'KILOWATT_PER_HOUR',
  LITER = 'LITER',
  GRAM = 'GRAM',
  CENTIMETER = 'CENTIMETER',
  PERCENT = 'PERCENT',
  KG_CO2_EQ = 'KG_CO2_EQ',
  KG_SO2_EQ = 'KG_SO2_EQ',
  KG_CFC11_EQ = 'KG_CFC11_EQ',
  KG_C2H4_EQ = 'KG_C2H4_EQ',
  MILLIGRAM = 'MILLIGRAM',
  CUBIC_MILLIMETER = 'CUBIC_MILLIMETER',
  G_EQ_SO2 = 'G_EQ_SO2',
  G_C2H4_EQ = 'G_C2H4_EQ',
  GRAM_PER_SQUARE_METER = 'GRAM_PER_SQUARE_METER',
  KG_SB_EQ = 'KG_SB_EQ',
  M3_PER_UF = 'M3_PER_UF',
  KG_PER_M2 = 'KG_PER_M2',
  K_WHEP = 'K_WHEP',
  M3_AIR = 'M3_AIR',
  KG_PO4_3_EQ = 'KG_PO4_3_EQ',
  METRIC_TON = 'METRIC_TON',
  M3_EAU = 'M3_EAU',
  PERSON_RESERVE = 'PERSON_RESERVE',
  MOLE_EQ_H_PLUS = 'MOLE_EQ_H_PLUS',
  MOLE_EQ_N = 'MOLE_EQ_N',
  KILOMETER = 'KILOMETER',
  G_EQ_CO2 = 'G_EQ_CO2',
  G_EQ_CFC11 = 'G_EQ_CFC11',
  G_EQ_PO4_3 = 'G_EQ_PO4_3',
  G_EQ_C2H4 = 'G_EQ_C2H4',
  G_EQ_H_PLUS = 'G_EQ_H_PLUS',
  CUBIC_DECIMETER = 'CUBIC_DECIMETER',
  YEAR_MINUS_1 = 'YEAR_MINUS_1',
  K_WC = 'K_WC',
  METRIC_TON_KM = 'METRIC_TON_KM',
  DECA_NEWTON = 'DECA_NEWTON',
  M3_PER_HOUR = 'M3_PER_HOUR',
  MILLIMETER = 'MILLIMETER',
  KG_PER_M3 = 'KG_PER_M3',
  KILOWATT = 'KILOWATT',
  DECIBEL = 'DECIBEL',
  KELVIN_PER_WATT = 'KELVIN_PER_WATT',
  W_PER_M2_K = 'W_PER_M2_K',
  SQUARE_CENTIMETER = 'SQUARE_CENTIMETER',
  SQUARE_MILLIMETER = 'SQUARE_MILLIMETER',
  WC = 'WC',
  FLOOR_NUMBER = 'FLOOR_NUMBER',
  M2_K_PER_WATT = 'M2_K_PER_WATT',
  KILOGRAM_PER_METER = 'KILOGRAM_PER_METER',
  KG_P_EQ = 'KG_P_EQ',
  KG_N_EQ = 'KG_N_EQ',
  KG_COVNM = 'KG_COVNM',
  DISEASE_INCIDENCE = 'DISEASE_INCIDENCE',
  KG_BQ_U235_EQ = 'KG_BQ_U235_EQ',
  CTU_E = 'CTU_E',
  CTU_H = 'CTU_H',
  M3_EQ_PRIV_WORLD = 'M3_EQ_PRIV_WORLD',
}

export function fromIniesId(iniesUnitId: number) {
  switch (iniesUnitId) {
    case 1:
      return UnitEnum.SQUARE_DECIMETER
    case 2:
      return UnitEnum.SQUARE_METER
    case 3:
      return UnitEnum.KG
    case 4:
      return UnitEnum.CUBIC_METER
    case 5:
    case 11:
    case 38:
      return UnitEnum.METER
    case 6:
      return UnitEnum.MEGAJOULES
    case 7:
      return UnitEnum.KILOWATT_PER_HOUR
    case 8:
      return UnitEnum.LITER
    case 9:
      return UnitEnum.GRAM
    case 10:
      return UnitEnum.CENTIMETER
    case 12:
      return UnitEnum.PERCENT
    case 13:
      return UnitEnum.PER_UNIT
    case 14:
      return UnitEnum.KG_CO2_EQ
    case 15:
      return UnitEnum.KG_SO2_EQ
    case 16:
      return UnitEnum.KG_CFC11_EQ
    case 17:
    case 29:
      return UnitEnum.KG_C2H4_EQ
    case 18:
      return UnitEnum.MILLIGRAM
    case 19:
      return UnitEnum.CUBIC_MILLIMETER
    case 20:
      return UnitEnum.G_EQ_SO2
    case 21:
      return UnitEnum.G_C2H4_EQ
    case 22:
      return UnitEnum.GRAM_PER_SQUARE_METER
    case 23:
    case 34:
      return UnitEnum.KG_SB_EQ
    case 24:
      return UnitEnum.M3_PER_UF
    case 25:
      return UnitEnum.KG_PER_M2
    case 26:
      return UnitEnum.K_WHEP
    case 27:
      return UnitEnum.NO_UNIT
    case 28:
      return UnitEnum.M3_AIR
    case 30:
      return UnitEnum.KG_PO4_3_EQ
    case 31:
      return UnitEnum.METRIC_TON
    case 32:
      return UnitEnum.M3_EAU
    case 33:
      return UnitEnum.PERSON_RESERVE
    case 35:
      return UnitEnum.MOLE_EQ_H_PLUS
    case 36:
      return UnitEnum.MOLE_EQ_N
    case 37:
      return UnitEnum.KILOMETER
    case 39:
      return UnitEnum.G_EQ_CO2
    case 40:
      return UnitEnum.G_EQ_CFC11
    case 41:
      return UnitEnum.G_EQ_PO4_3
    case 42:
      return UnitEnum.G_EQ_C2H4
    case 43:
      return UnitEnum.G_EQ_H_PLUS
    case 44:
      return UnitEnum.CUBIC_DECIMETER
    case 45:
      return UnitEnum.YEAR_MINUS_1
    case 46:
    case 53:
      return UnitEnum.K_WC
    case 47:
      return UnitEnum.METRIC_TON_KM
    case 48:
      return UnitEnum.DECA_NEWTON
    case 49:
      return UnitEnum.M3_PER_HOUR
    case 50:
      return UnitEnum.MILLIMETER
    case 51:
      return UnitEnum.KG_PER_M3
    case 52:
      return UnitEnum.KILOWATT
    case 54:
      return UnitEnum.DECIBEL
    case 55:
      return UnitEnum.KELVIN_PER_WATT
    case 56:
      return UnitEnum.W_PER_M2_K
    case 57:
      return UnitEnum.SQUARE_CENTIMETER
    case 58:
      return UnitEnum.SQUARE_MILLIMETER
    case 59:
      return UnitEnum.WC
    case 60:
      return UnitEnum.FLOOR_NUMBER
    case 61:
      return UnitEnum.M2_K_PER_WATT
    case 62:
      return UnitEnum.KILOGRAM_PER_METER
    case 63:
      return UnitEnum.KG_P_EQ
    case 64:
      return UnitEnum.KG_N_EQ
    case 65:
      return UnitEnum.KG_COVNM
    case 66:
      return UnitEnum.DISEASE_INCIDENCE
    case 67:
      return UnitEnum.KG_BQ_U235_EQ
    case 68:
      return UnitEnum.CTU_E
    case 69:
      return UnitEnum.CTU_H
    case 70:
      return UnitEnum.M3_EQ_PRIV_WORLD
    default:
      return UnitEnum.UNKNOWN
  }
}
export function toLabel(unitEnum: UnitEnum): string {
  if (!unitEnum) {
    return 'unité inconnue'
  }
  switch (unitEnum) {
    case 'SQUARE_DECIMETER':
      return 'dm²'
    case 'SQUARE_METER':
      return 'm²'
    case 'KG':
      return 'kg'
    case 'CUBIC_METER':
      return 'm³'
    case 'METER':
      return 'm'
    case 'MEGAJOULES':
      return 'MJ'
    case 'KILOWATT_PER_HOUR':
      return 'kWh'
    case 'LITER':
      return 'l'
    case 'GRAM':
      return 'g'
    case 'CENTIMETER':
      return 'cm'
    case 'PERCENT':
      return '%'
    case 'PER_UNIT':
      return 'unité'
    case 'KG_CO2_EQ':
      return 'kg CO₂ eq'
    case 'KG_SO2_EQ':
      return 'kg SO₂ eq'
    case 'KG_CFC11_EQ':
      return 'kg CFC₁₁ eq'
    case 'KG_C2H4_EQ':
      return 'kg C₂H₄ eq'
    case 'MILLIGRAM':
      return 'mg'
    case 'CUBIC_MILLIMETER':
      return 'mm³'
    case 'G_EQ_SO2':
      return 'g SO₂ eq'
    case 'G_C2H4_EQ':
      return 'g C₂H₄ eq'
    case 'GRAM_PER_SQUARE_METER':
      return 'g/m²'
    case 'KG_SB_EQ':
      return 'kg SB eq'
    case 'M3_PER_UF':
      return 'm³/UF'
    case 'KG_PER_M2':
      return 'kg/m²'
    case 'K_WHEP':
      return 'kWhep'
    case 'NO_UNIT':
      return ''
    case 'M3_AIR':
      return 'm³ air'
    case 'KG_PO4_3_EQ':
      return 'kg PO4³⁻ eq'
    case 'METRIC_TON':
      return 't'
    case 'M3_EAU':
      return 'm³ eau'
    case 'PERSON_RESERVE':
      return 'réservé par personne'
    case 'MOLE_EQ_H_PLUS':
      return 'mol H⁺ eq'
    case 'MOLE_EQ_N':
      return 'mol N eq'
    case 'KILOMETER':
      return 'km'
    case 'G_EQ_CO2':
      return 'g CO₂ eq'
    case 'G_EQ_CFC11':
      return 'g CFC₁₁ eq'
    case 'G_EQ_PO4_3':
      return 'g PO₄³⁻ eq'
    case 'G_EQ_C2H4':
      return 'g C₂H₄ eq'
    case 'G_EQ_H_PLUS':
      return 'g H⁺ eq'
    case 'CUBIC_DECIMETER':
      return 'dm³'
    case 'YEAR_MINUS_1':
      return 'année⁻¹'
    case 'K_WC':
      return 'kWc'
    case 'METRIC_TON_KM':
      return 't·km'
    case 'DECA_NEWTON':
      return 'daN'
    case 'M3_PER_HOUR':
      return 'm³/h'
    case 'MILLIMETER':
      return 'mm'
    case 'KG_PER_M3':
      return 'kg/m³'
    case 'KILOWATT':
      return 'kW'
    case 'DECIBEL':
      return 'dB'
    case 'KELVIN_PER_WATT':
      return 'K/W'
    case 'W_PER_M2_K':
      return 'W/m²·K'
    case 'SQUARE_CENTIMETER':
      return 'cm²'
    case 'SQUARE_MILLIMETER':
      return 'mm²'
    case 'WC':
      return 'Wc'
    case 'FLOOR_NUMBER':
      return 'étage(s)'
    case 'M2_K_PER_WATT':
      return 'm²·K/W'
    case 'KILOGRAM_PER_METER':
      return 'kg/m'
    default:
      return 'unité inconnue'
  }
}
