import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import React from 'react'
import IniesRecord from '../../core/dto/material/inies-record'
import MaterialRecord from '../../core/dto/material/material-record'
import { DeclarationTypeEnum } from '../../core/enum/declarationTypeEnum'
import MaterialRow from './material-page-table/material-row/material-row'

type MaterialPageTableProps = {
  handleSetSelectedRow(row: IniesRecord | MaterialRecord | undefined): void
  getTypologyBackground(typologie: DeclarationTypeEnum): string
  records: IniesRecord[] | MaterialRecord[] | []
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  rowsPerPage: number
  setRowsPerPage(newlimit: number): void
  handleSearchPaginated(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void
}

export default function MaterialPageTable({
  handleSetSelectedRow,
  getTypologyBackground,
  records,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  handleSearchPaginated,
}: Readonly<MaterialPageTableProps>): React.JSX.Element {
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <TableContainer sx={{ mt: 4 }}>
      <Table sx={{ minWidth: 650, height: 600 }} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>Typologie de déclaration</TableCell>
            <TableCell>Nom du produit</TableCell>
            <TableCell>Fournisseur & réference</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {records.map((record) => (
            <MaterialRow
              key={record.id}
              onSelected={(row: IniesRecord | MaterialRecord | undefined): void => handleSetSelectedRow(row)}
              record={record}
              getTypologyBackground={getTypologyBackground}
            />
          ))}

          {records.length < rowsPerPage && (
            <TableRow
              style={{
                height: (600 / rowsPerPage) * (rowsPerPage - records.length),
              }}>
              <TableCell colSpan={12} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        sx={{
          mt: 1,
          display: 'flex',
          justifyContent: 'center',
        }}
        component='div'
        count={records.length === rowsPerPage ? -1 : records.length}
        page={page}
        onPageChange={handleSearchPaginated}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50]}
        labelDisplayedRows={({ from, to }) =>
          `${from < rowsPerPage ? from : `... ${from}`}-${records.length === rowsPerPage ? ` ${to} ...` : from + to} `
        }
        labelRowsPerPage='Ligne par page:'
      />
    </TableContainer>
  )
}
