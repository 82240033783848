import { Button } from '@mui/material'

type IProps = {
  onClick(): void
  label: string
}

function capitalizeFirstLetter(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
}

export default function TextButton({ onClick, label }: IProps): React.JSX.Element {
  const formattedLabel = capitalizeFirstLetter(label)

  return (
    <Button
      variant='text'
      color='secondary'
      size='large'
      onClick={onClick}
      sx={{ textDecoration: 'underline', textTransform: 'none' }}>
      {formattedLabel}
    </Button>
  )
}
