import { BufferGeometry, Line, Points } from 'three'
import { IfcViewerAPI } from 'web-ifc-viewer'
import { viewerConstants } from '../../../constants/ViewerConstants'
import { DimensionMode } from '../../../enums/DimensionMode'
import { MeasurementHelper } from '../../../helpers/MeasurementHelper'
import { PointData } from '../annotations/PointData'
import { DimensionCommand } from '../base/DimensionCommand'

export class EdgeToPointDimensionCommand extends DimensionCommand {
  point: PointData
  endPoint: PointData
  linePoints: Array<PointData>
  labelpoints: Points

  constructor(line: Array<PointData>, point: PointData, scene: THREE.Scene, ifc: IfcViewerAPI) {
    super(scene, ifc)
    this.point = point
    this.linePoints = line
    this.endPoint = new PointData(
      MeasurementHelper.GetIntersectionBetweenPointAndLine(line, point),
      line[0].modelId,
      line[0].expressId
    )
    this.points = [this.point.point, this.endPoint.point]

    this.lineGeom = new BufferGeometry().setFromPoints(this.points)
    this.line = new Line(this.lineGeom, viewerConstants.measurement.lineDimension.lineMaterial)
    this.line.renderOrder = 1
    this.line.name = DimensionMode.ONE_EDGE.toString()
    this.distance = this.point.point.distanceTo(this.endPoint.point)
    this.textPosition = MeasurementHelper.getTextPosition(this.point.point, this.endPoint.point)

    this.pointsGeometry = new BufferGeometry().setFromPoints(this.points)
    this.labelpoints = new Points(this.pointsGeometry, viewerConstants.measurement.lineDimension.pointMaterial)

    let nearerPoint = this.linePoints[0].point
    if (
      this.linePoints[0].point.distanceTo(this.endPoint.point) > this.linePoints[1].point.distanceTo(this.endPoint.point)
    ) {
      nearerPoint = this.linePoints[1].point
    }

    this.subLine = new Line(
      new BufferGeometry().setFromPoints([nearerPoint, this.endPoint.point]),
      viewerConstants.measurement.edgeDimension.subLinematerial
    )

    this.setUpGroupData()
  }

  async updateText(): Promise<void> {
    if (this.group && this.label) {
      this.scene.remove(this.label)
      this.group.remove(this.label)
      this.label.material.dispose()
      this.label.clear()
      await this.defineTextLabel(this.point.modelId ?? 0)
      this.group.add(this.label)
    }
  }

  /**
   * Set up the instance's geometric 3d object's group and it's properties
   */
  private async setUpGroupData(): Promise<void> {
    await this.defineTextLabel(this.point.modelId ?? 0)
    if (this.group && this.line && this.label && this.labelpoints && this.subLine) {
      this.group.assosiatedObject = this
      this.group.add(this.line)
      this.updateTextSize('1')
      this.group.add(this.label)
      this.group.add(this.labelpoints)
      this.group.add(this.subLine)
      this.group.startPoint = this.point.point
      this.group.endPoint = this.endPoint.point
      this.group.startPointExpressId = this.point.expressId
      this.group.endPointExpressId = this.endPoint.expressId
      this.group.startPointModelId = this.point.modelId
      this.group.endPointModelId = this.endPoint.modelId
    }
  }
}
