import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { OrganizationContext } from '../../core/context/organization/organization-context'
import { UserContext } from '../../core/context/user/user-context'
import { Collaborator } from '../../core/dto/user/collaborator'
import { OrganizationRoleEnum } from '../../core/enum/user/organization-role-enum'
import TitleLogo from '../account-page/account-title'
import OrganizationCollaborators from './organization-collaborators'
import OrganizationContact from './organization-contact'
import OrganizationDetail from './organization-detail'
import SubscriptionCards from './subscription-cards'
import { ICADE_SIRET } from '../../core/appConstants'
import { KairnialProjectsComponent } from './components/KairnialProjectsComponent'

export default function MyOrganization(): React.JSX.Element {
  const [collabList, setCollabList] = useState<Collaborator[]>([])
  const { user } = useContext(UserContext)
  const { refreshOrganization, organization } = useContext(OrganizationContext)

  useEffect(() => {
    refreshOrganization()
  }, [refreshOrganization])

  const isWriting: boolean = user?.role === OrganizationRoleEnum.MEGA_USER

  return (
    <Box>
      <TitleLogo logo={{ id: 'account' }} title='Mon organisation' />
      <OrganizationDetail isWriting={isWriting} />

      {organization?.siret === ICADE_SIRET && <KairnialProjectsComponent />}
      <SubscriptionCards />
      <OrganizationCollaborators dataCollab={collabList} setCollabList={setCollabList} />
      <OrganizationContact />
      {/* <OrganizationBill /> */}
    </Box>
  )
}
