import { useCallback, useContext } from 'react'
import { appConstants } from '../../appConstants'
import { CalculationCarbonInformationProjectContext } from '../../context/calculation/calculation-carbon-information-project'
import { CalculationContext } from '../../context/calculation/calculation-context'
import { CalculationTypesEnum } from '../../enum/calculationTypeEnum'
import { PhaseEnum } from '../../enum/phaseEnum'
import { UsageEnum } from '../../enum/usageEnum'
import { useHttp } from '../use-http'

export type CalculationCreate = {
  variantName1: string
  variantName2: string
  usage?: UsageEnum
  climaticZone: string
  altitude: number
  projectId: string
  phase: string
  calculationType?: CalculationTypesEnum
}

export type CalculationForm = CalculationCreate

type CalculationUpdate = {
  id: string
  phase: PhaseEnum
  calculationType: CalculationTypesEnum
  climaticZone: string
  altitude: number
  usage: UsageEnum
  variantName1: string
  variantName2: string
}

type CalculCarbonInformationProjectUpdate = {
  loading: boolean
  updateCalculCarbonInformationProject: (calculationUpdate: CalculationUpdate, projectId: string) => Promise<Response>
  createCalculCarbonInformationProject: (calculationCreate: CalculationCreate) => Promise<Response>
}

export const useUpdateCalculCarbonInformationProject = (): CalculCarbonInformationProjectUpdate => {
  const { post, put } = useHttp()
  const { loading, setLoading } = useContext(CalculationCarbonInformationProjectContext)
  const { setCalculation } = useContext(CalculationContext)

  const createCalculCarbonInformationProject = useCallback(
    async (calculationCreate: CalculationCreate): Promise<Response> => {
      setLoading(true)

      return post(`${appConstants.apiEndpoints.CALCULATION}`, {
        ...calculationCreate,
      })
        .then(async (response) => {
          const calculation = await response.json()
          if (calculation) {
            setCalculation(calculation)
          }
          return calculation
        })
        .then((res) => res)
        .finally(() => {
          setLoading(false)
        })
    },
    [setLoading, post, setCalculation]
  )

  const updateCalculCarbonInformationProject = useCallback(
    async (variables: CalculationUpdate, projectId: string): Promise<Response> => {
      setLoading(true)

      return put(
        `${appConstants.apiEndpoints.CALCULATION}`,
        {
          ...variables,
        },
        [
          {
            key: 'projectId',
            value: projectId,
          },
        ]
      )
        .then(async (response) => {
          const calculation = await response.json()
          if (calculation) {
            setCalculation(calculation)
          }
          return calculation
        })
        .then((res) => res)
        .finally(() => {
          setLoading(false)
        })
    },
    [setLoading, put, setCalculation]
  )

  return {
    loading,
    updateCalculCarbonInformationProject,
    createCalculCarbonInformationProject,
  }
}
