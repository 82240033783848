import { useCallback, useContext } from 'react'
import { useParams } from 'react-router'
import { appConstants } from '../../appConstants'
import { MaterialLibProjectIndicatorContext } from '../../context/material/material-lib-project-indicator-context'
import { MaterialLibIndicator } from '../../dto/organization/organization-indicator'
import { useHttp } from '../use-http'

type useProjectMaterialIndicatorReturn = {
  fetchMaterialProjectIndicator: () => Promise<void>
  reset: () => void
}

export const useProjectMaterialIndicator = (): useProjectMaterialIndicatorReturn => {
  const { get } = useHttp()

  const { projectId } = useParams()

  const { setProjectMaterialIndicator, setLoading, setError } = useContext(MaterialLibProjectIndicatorContext)

  const reset = useCallback(
    (): void => setProjectMaterialIndicator(new MaterialLibIndicator()),
    [setProjectMaterialIndicator]
  )

  const fetchMaterialProjectIndicator = useCallback(async (): Promise<void> => {
    setLoading(true)

    return get(appConstants.apiEndpoints.PROJECT_MATERIAL_INDICATOR, [
      {
        key: 'projectId',
        value: projectId ?? '',
      },
    ])
      .then((response) => response.json())
      .then((res) => {
        setProjectMaterialIndicator(res)
      })
      .finally(() => {
        setLoading(false)
      })
      .catch((err) => {
        setError(err)
        throw err
      })
  }, [get, projectId, setProjectMaterialIndicator, setError, setLoading])

  return {
    reset,
    fetchMaterialProjectIndicator,
  }
}
