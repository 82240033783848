import React, { ChangeEvent, useState } from 'react'
import { IconButton, InputAdornment } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { CaptchaActions } from './captcha-field-utils'
import BasicField from './basic-field'

type IProps = {
  fieldName: CaptchaActions
  label: string
  form: any
  error: Record<string, any>
  required: boolean
  handleChange(event: ChangeEvent<HTMLInputElement>): void
}

export default function PasswordField({ fieldName, label, form, error, required, handleChange }: IProps): React.JSX.Element {
  const [showPassword, setShowPassword] = useState<boolean>(false)

  function helperText(): React.JSX.Element {
    return (
      <>
        Le mot de passe doit respecter les règles suivantes:{' '}
        <ul>
          {' '}
          <li>Longueur minimum: 8 caractères</li> <li>Contient au minimum 1 chiffre</li>{' '}
          <li>
            Contient au minimum un caractère spécial (^ $ * . [ ] {} ( ) ? - " ! @ # % & / \ , &lt; &gt; ' : ; | _ ~ ` + =)
          </li>
          <li>Une lettre minuscule</li>
          <li>Une lettre majuscule</li>
        </ul>
      </>
    )
  }

  function endAdornment(): React.JSX.Element {
    return (
      <InputAdornment position='end'>
        <IconButton aria-label='toggle password visibility' onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    )
  }

  return (
    <BasicField
      fieldName={fieldName}
      label={label}
      form={form}
      error={error}
      required={required}
      type={showPassword ? 'text' : 'password'}
      handleChange={handleChange}
      helperText={helperText()}
      endAdornment={endAdornment()}
    />
  )
}
