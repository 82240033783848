import { Vector3 } from 'three'

export class PointData {
  expressId: number | undefined
  modelId: number | undefined
  point: Vector3

  constructor(point: Vector3, modelId?: number, expressId?: number) {
    this.expressId = expressId
    this.modelId = modelId
    this.point = point
  }

  clone(): PointData {
    return new PointData(this.point.clone(), this.modelId, this.expressId)
  }
}
