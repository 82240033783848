import { Download } from '@mui/icons-material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import InfoIcon from '@mui/icons-material/Info'
import LinkIcon from '@mui/icons-material/Link'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Paper,
  PaperProps,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { styled } from '@mui/system'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { customPalette } from '../../../../theme'
import { ErrorContext } from '../../layout/error-snackbar'
import { SuccessContext } from '../../layout/success-snackbar'
import { appConstants } from '../../../core/appConstants'
import IniesRecord from '../../../core/dto/material/inies-record'
import { originMaterialRecordToLabel } from '../../../core/dto/material/material'
import MaterialRecord from '../../../core/dto/material/material-record'
import MaterialRecordCreation from '../../../core/dto/material/material-record-creation'
import { DeclarationTypeEnum } from '../../../core/enum/declarationTypeEnum'
import { FunctionalUnitEnum } from '../../../core/enum/functionalUnitEnum'
import { MaterialLibPage } from '../../../core/enum/materialLibPage'
import { fromIniesId, toLabel } from '../../../core/enum/unitEnum'
import { useAddMaterial } from '../../../core/hooks/material-lib/use-add-material'
import { useMaterialLibOrganizationIndicator } from '../../../core/hooks/material-lib/use-fetch-material-lib-organization-indicator'
import { useOrganizationRecordsFiltered } from '../../../core/hooks/material-lib/use-organisation-records-filtered'
import { useUpdateMaterial } from '../../../core/hooks/material-lib/use-update-material-lib'
import { useMaterial } from '../../../core/hooks/use-material'
import { formatToFrench } from '../../../core/services/date-service'
import { getDeclarationTypeLabel } from '../../../core/services/declaration-type-service'
import MaterialPageConfirmationModal from './material-page-confirmation-modal'

const DefaultButton = styled(Paper)<PaperProps>(() => ({
  minWidth: 200,
  minHeight: 90,
  color: 'black',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'white',
  textTransform: 'none',
}))

type IProps = {
  selectedRow: IniesRecord | MaterialRecord
  setSelectedRow: React.Dispatch<React.SetStateAction<IniesRecord | MaterialRecord | undefined>>
  getTypologyBackground: (typologie: DeclarationTypeEnum) => string
  isEditable: boolean
  isDeletable: boolean
  isModalCard: boolean
  typeMaterialLib: MaterialLibPage
  tab: 1 | 2
  handleCloseMaterialCardModal: () => void
}

export default function MaterialCard({
  selectedRow,
  setSelectedRow,
  getTypologyBackground,
  isEditable,
  isDeletable,
  isModalCard,
  typeMaterialLib,
  tab,
  handleCloseMaterialCardModal,
}: Readonly<IProps>): React.JSX.Element {
  const theme = useTheme()
  const { fetchMaterialOrganizationIndicator } = useMaterialLibOrganizationIndicator()
  const { search: searchOrganization } = useOrganizationRecordsFiltered()

  const openErrorSnackbar = useContext(ErrorContext)
  const { deleteMaterial, updateMaterial, loading: loadingDelete, updateProjectMaterial } = useUpdateMaterial()

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)

  const openSuccessSnackbar: (message: string) => void = useContext(SuccessContext)

  const { addOrganizationMaterialToProject, addIniesToProject, addToOrganization } = useAddMaterial()

  const { getFicheConfigureeFile } = useMaterial()

  const { projectId } = useParams()

  const successfullyAddMaterialLabel = 'Le materiau a bien été ajouté !'

  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [isEditName, setIsEditName] = useState<boolean>(false)

  const [materialCreation, setMaterialCreation] = useState<MaterialRecordCreation>(new MaterialRecordCreation())

  const isProjectMaterial = useMemo(() => typeMaterialLib === MaterialLibPage.MATERIAL_LIB_PROJECT, [typeMaterialLib])

  function addMaterial(row: IniesRecord | MaterialRecord | undefined): void {
    if (!row) {
      openErrorSnackbar(new Error('Veuillez selectionnez un matériau.'))
    } else {
      if (typeMaterialLib === MaterialLibPage.MATERIAL_LIB_PROJECT && tab === 1) {
        if (projectId && row instanceof IniesRecord) {
          addIniesToProject(row.iniesId, projectId).then(() => {
            openSuccessSnackbar(successfullyAddMaterialLabel)
          })
        }
      }

      if (typeMaterialLib === MaterialLibPage.MATERIAL_LIB_PROJECT && tab === 2) {
        if (projectId && row instanceof MaterialRecord) {
          addOrganizationMaterialToProject(row.id, projectId).then(() => {
            openSuccessSnackbar(successfullyAddMaterialLabel)
          })
        }
      }

      if (row && typeMaterialLib === MaterialLibPage.MATERIAL_LIB_ORGANIZATION) {
        if (row instanceof IniesRecord) {
          const iniesToOrganization: MaterialRecordCreation = {
            id: row.id,
            iniesId: row.iniesId,
            codeAcv: undefined,
            provider: materialCreation?.provider || '',
            productReference: materialCreation?.productReference || '',
            fdesName: materialCreation?.fdesName || '',
          }

          addToOrganization(iniesToOrganization).then(() => {
            openSuccessSnackbar(successfullyAddMaterialLabel)
          })
        }
      }
    }
    handleCloseMaterialCardModal()
  }

  const handleCloseConfirmation = useCallback(() => {
    setIsConfirmationOpen(false)
  }, [setIsConfirmationOpen])

  const handleDeleteMaterial = useCallback(() => {
    if (selectedRow?.id) {
      deleteMaterial(selectedRow.id)
        .then(() => {
          setSelectedRow(undefined)
          fetchMaterialOrganizationIndicator()
          searchOrganization({
            filter: {
              containedInName: undefined,
              classificationLvl0: undefined,
              classificationLvl1: undefined,
              classificationLvl2: undefined,
              classificationLvl3: undefined,
              classificationLvl4: undefined,
              responsibleOrganism: undefined,
              declarationType: undefined,
            },
            lastFdesName: undefined,
          })
        })
        .catch((error) => {
          throw error
        })
    }
    setIsConfirmationOpen(false)
    handleCloseMaterialCardModal()
  }, [
    selectedRow.id,
    handleCloseMaterialCardModal,
    deleteMaterial,
    setSelectedRow,
    fetchMaterialOrganizationIndicator,
    searchOrganization,
  ])

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm()

  useEffect(() => {
    setIsEditMode(false)
    resetMaterialCreation()
  }, [selectedRow, setSelectedRow])

  function resetMaterialCreation(): void {
    if (setMaterialCreation && selectedRow) {
      const tmpResetMaterialCreation: MaterialRecordCreation = {
        id: undefined,
        iniesId: 0,
        codeAcv: undefined,
        provider: '',
        productReference: '',
        fdesName: '',
      }
      setMaterialCreation({ ...tmpResetMaterialCreation })
    }
  }

  function onSubmit(): void {
    if (isProjectMaterial) {
      onChangeProjectMaterial()
    } else {
      onChangeOrganizationMaterial()
    }
  }

  function onChangeProjectMaterial(): void {
    if (selectedRow?.id && projectId) {
      updateProjectMaterial(
        selectedRow.id,
        materialCreation.provider,
        materialCreation.productReference,
        materialCreation.fdesName,
        projectId
      )
        .then((record: MaterialRecord) => setSelectedRow(MaterialRecord.fromDto(record)))
        .finally(() => {
          setIsEditName(false)
          setMaterialCreation(new MaterialRecordCreation())
        })
    }
  }

  function onChangeOrganizationMaterial(): void {
    if (selectedRow?.id && setIsEditMode) {
      updateMaterial(selectedRow.id, materialCreation.provider, materialCreation.productReference)
        .then((record: MaterialRecord) => setSelectedRow(MaterialRecord.fromDto(record)))
        .finally(() => {
          setIsEditMode(false)
          if (setMaterialCreation) {
            setMaterialCreation(new MaterialRecordCreation())
          }
        })
    }
  }

  function downloadFicheConfiguree(): void {
    if (selectedRow?.id) {
      getFicheConfigureeFile(selectedRow.id).then((file) => {
        if (file) {
          const fileName = selectedRow.fdesName
          const element = document.createElement('a')
          element.href = URL.createObjectURL(file)
          element.download = `${fileName}.xml`
          element.click()
        }
      })
    }
  }

  function getAllParameters(): string {
    let chaineConcatenee = `${selectedRow.description || ''}`
    if (selectedRow instanceof MaterialRecord && selectedRow.parameters.length > 0) {
      chaineConcatenee += '<br/><br/><b> Paramètres : </b> <ul>'
      selectedRow.parameters.forEach((chaine) => {
        chaineConcatenee += ` <li><b> ${chaine.name} : </b>  ${chaine.value} ${toLabel(
          fromIniesId(chaine.paramUnitId)
        )}  </li>
      `
        return chaine
      })
      chaineConcatenee += '</ul>'
    }
    return chaineConcatenee
  }

  const functionalUnit: FunctionalUnitEnum =
    FunctionalUnitEnum[selectedRow.functionalUnit as unknown as keyof typeof FunctionalUnitEnum]

  return (
    <Box>
      <Card sx={{ width: 1200, background: '#f6f6f6' }}>
        <Box display='flex'>
          <IconButton sx={{ marginLeft: 'auto', p: 2 }} onClick={handleCloseMaterialCardModal}>
            <CloseOutlinedIcon />
          </IconButton>
        </Box>
        <CardContent sx={{ p: 2, pl: 0 }}>
          <Box display='flex' flexDirection='row' flex={1}>
            <Box flex={0.12} display='flex' flexDirection='column' alignItems='center'>
              {typeMaterialLib === MaterialLibPage.MATERIAL_LIB_ORGANIZATION && isEditable && (
                <>
                  {isDeletable && (
                    <IconButton onClick={() => setIsConfirmationOpen(true)}>
                      <DeleteOutlineIcon />
                    </IconButton>
                  )}
                  <IconButton
                    disabled={isEditMode}
                    onClick={() => {
                      setMaterialCreation({
                        ...materialCreation,
                        provider: selectedRow instanceof MaterialRecord ? selectedRow.provider : '',
                        productReference: selectedRow instanceof MaterialRecord ? selectedRow.productReference : '',
                      })
                      setIsEditMode(true)
                    }}>
                    <ModeEditIcon />
                  </IconButton>
                </>
              )}

              {selectedRow.declarationType === DeclarationTypeEnum.FICHE_CONFIGUREE ? (
                <>
                  <IconButton onClick={downloadFicheConfiguree}>
                    <Download />
                  </IconButton>
                  {isEditable && (
                    <IconButton
                      disabled={isEditName}
                      onClick={() => {
                        setMaterialCreation({
                          ...materialCreation,
                          fdesName: selectedRow.fdesName,
                        })
                        setIsEditName(true)
                      }}>
                      <ModeEditIcon />
                    </IconButton>
                  )}
                </>
              ) : (
                <IconButton
                  href={`${appConstants.web.baseInie}${selectedRow ? `?id=${selectedRow.iniesId}` : ''}`}
                  target='_blank'
                  rel='noopener noreferrer'>
                  <LinkIcon />
                </IconButton>
              )}
            </Box>
            <Box component='form' onSubmit={handleSubmit(onSubmit)} flex={0.88} flexDirection='column' alignItems='flex-end'>
              <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{ mb: 2 }}>
                <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                  <Typography
                    component='span'
                    sx={{
                      backgroundColor: getTypologyBackground(selectedRow?.declarationType),
                      color: customPalette.default,
                      padding: 1,
                      borderRadius: '28px',
                      fontSize: 14,
                    }}>
                    {getDeclarationTypeLabel(selectedRow?.declarationType)}
                  </Typography>
                  {isEditName ? (
                    <TextField
                      {...register('fdesName', {
                        required: 'Ce champ est requis',
                      })}
                      sx={{ backgroundColor: 'white', padding: '4px' }}
                      value={materialCreation?.fdesName}
                      onChange={(e) => {
                        setMaterialCreation({
                          ...materialCreation,
                          fdesName: e.target.value,
                        })
                      }}
                      variant='outlined'
                      error={Boolean(errors?.fdesName)}
                      helperText={Boolean(errors?.fdesName?.message) && `${errors.fdesName?.message}`}
                    />
                  ) : (
                    <Box display='flex' flexDirection='column'>
                      <Typography
                        component='span'
                        flex={1}
                        sx={{
                          fontWeight: 'bold',
                          ml: '12px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}>
                        {selectedRow.fdesName}
                      </Typography>
                      <Typography
                        component='span'
                        sx={{
                          fontWeight: 'bold',
                          ml: '12px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          color: selectedRow instanceof MaterialRecord ? originMaterialRecordToLabel(selectedRow).color : '',
                        }}>
                        {`${
                          selectedRow instanceof MaterialRecord ? `( ${originMaterialRecordToLabel(selectedRow).text} )` : ''
                        }`}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Tooltip title={<Typography variant='body2'>{selectedRow.description}</Typography>} placement='top'>
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Box>

              <Box sx={{ mt: 1, mb: 3 }}>
                <Box display='flex' flexDirection='row'>
                  <Box
                    display='flex'
                    flexDirection='column'
                    sx={{
                      flex: 2,
                      marginRight: '16px',
                    }}>
                    {isEditMode ? (
                      <TextField
                        {...register('provider', {
                          required: 'Ce champ est requis',
                        })}
                        sx={{ backgroundColor: 'white', padding: '8px' }}
                        label='Fournisseur'
                        value={materialCreation?.provider}
                        onChange={(e) => {
                          setMaterialCreation({
                            ...materialCreation,
                            provider: e.target.value,
                          })
                        }}
                        variant='outlined'
                        error={Boolean(errors?.provider)}
                        helperText={Boolean(errors?.provider?.message) && `${errors.provider?.message}`}
                      />
                    ) : (
                      <>
                        <Box component='span' fontSize={15} sx={{ mb: 0.5 }}>
                          Fournisseur
                        </Box>
                        <Box
                          component='span'
                          fontSize={14}
                          sx={{
                            backgroundColor: 'white',
                            padding: '8px',
                            minHeight: 58,
                          }}>
                          {selectedRow instanceof IniesRecord ? materialCreation?.provider : selectedRow.provider}
                        </Box>
                      </>
                    )}
                  </Box>
                  <Box
                    display='flex'
                    flexDirection='column'
                    sx={{
                      flex: 4,
                    }}>
                    {isEditMode ? (
                      <TextField
                        {...register('productReference', {
                          required: 'Ce champ est requis',
                        })}
                        sx={{ backgroundColor: 'white', padding: '8px' }}
                        value={materialCreation?.productReference}
                        label='Reference Produit'
                        onChange={(e) => {
                          setMaterialCreation({
                            ...materialCreation,
                            productReference: e.target.value,
                          })
                        }}
                        variant='outlined'
                        error={Boolean(errors?.productReference)}
                        helperText={Boolean(errors?.productReference?.message) && `${errors.productReference?.message}`}
                      />
                    ) : (
                      <>
                        <Box component='span' fontSize={15} sx={{ mb: 0.5 }}>
                          Reference Produit
                        </Box>
                        <Box
                          component='span'
                          fontSize={14}
                          sx={{
                            backgroundColor: 'white',
                            padding: '8px',
                            minHeight: 58,
                          }}>
                          {selectedRow instanceof IniesRecord
                            ? materialCreation?.productReference
                            : selectedRow?.productReference}
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
                <Box display='flex' flexDirection='row' sx={{ mt: 1 }}>
                  <Box
                    display='flex'
                    flexDirection='column'
                    fontSize={15}
                    sx={{
                      flex: 2,
                      marginRight: '16px',
                    }}>
                    <Box component='span' fontSize={15} sx={{ mb: 0.5 }}>
                      Organisme déclarant
                    </Box>
                    <Box
                      component='span'
                      fontSize={14}
                      sx={{
                        backgroundColor: 'white',
                        padding: '8px',
                      }}>
                      {selectedRow.responsibleOrganism}
                    </Box>
                  </Box>
                  <Box
                    display='flex'
                    flexDirection='row'
                    sx={{
                      flex: 4,
                    }}>
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='flex-end'
                      sx={{
                        flex: 1,
                        marginRight: '16px',
                      }}>
                      <Box component='span' fontSize={15} sx={{ mb: 0.5 }}>
                        Identifiant
                      </Box>
                      <Box
                        component='span'
                        fontSize={14}
                        sx={{
                          backgroundColor: 'white',
                          padding: '8px',
                        }}>
                        {selectedRow instanceof MaterialRecord &&
                        selectedRow.declarationType === DeclarationTypeEnum.FICHE_CONFIGUREE
                          ? selectedRow.configuratorId
                          : selectedRow.iniesId}
                      </Box>
                    </Box>
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='flex-end'
                      sx={{
                        flex: 1,
                        marginRight: '16px',
                      }}>
                      <Box component='span' fontSize={15} sx={{ mb: 0.5 }}>
                        Unité fonctionnelle:
                      </Box>
                      <Box
                        component='span'
                        fontSize={14}
                        sx={{
                          backgroundColor: 'white',
                          padding: '8px',
                        }}>
                        {functionalUnit}
                      </Box>
                    </Box>
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='flex-end'
                      sx={{
                        flex: 1,
                        marginRight: '16px',
                      }}>
                      <Box component='span' fontSize={15} sx={{ mb: 0.5 }}>
                        Durée de vie
                      </Box>
                      <Box
                        fontSize={14}
                        component='span'
                        sx={{
                          backgroundColor: 'white',
                          padding: '8px',
                        }}>
                        {selectedRow.referenceLifeTime}
                      </Box>
                    </Box>
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='flex-end'
                      sx={{
                        flex: 1,
                      }}>
                      <Box component='span' fontSize={15} sx={{ mb: 0.5 }}>
                        Dernière MaJ
                      </Box>
                      <Box
                        component='span'
                        fontSize={14}
                        sx={{
                          backgroundColor: 'white',
                          padding: '8px',
                        }}>
                        {formatToFrench(selectedRow.lastIniesUpdate)}
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box display='flex' flexDirection='row' sx={{ mt: 1 }}>
                  <Box
                    display='flex'
                    flexDirection='column'
                    sx={{
                      flex: 2,
                      marginRight: '16px',
                    }}>
                    <Box component='span' fontSize={15} sx={{ mb: 0.5 }}>
                      Caractéristiques
                    </Box>
                    <Box
                      fontSize={14}
                      sx={{
                        backgroundColor: 'white',
                        padding: '8px',
                        minHeight: 58,
                        maxHeight: 150,
                        overflow: 'hidden',
                        overflowY: 'scroll',
                      }}>
                      <Typography variant='body1' dangerouslySetInnerHTML={{ __html: getAllParameters() }} />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box display='flex' flexDirection='row' justifyContent='space-between'>
                <DefaultButton
                  variant='elevation'
                  sx={{
                    bgcolor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                  }}>
                  <Typography>Total du cycle de vie</Typography>
                  <Typography sx={{ fontWeight: 'bold' }}>{selectedRow.totalLifeCycleCarbonImpact?.toFixed(1)}</Typography>
                </DefaultButton>
                <DefaultButton variant='elevation'>
                  <Typography>Total Impact selon RE2020</Typography>
                  <Typography sx={{ fontWeight: 'bold' }}>{selectedRow.re2020CarbonImpact?.toFixed(1)}</Typography>
                </DefaultButton>
                <DefaultButton variant='elevation'>
                  <Typography>Module D</Typography>
                  <Typography sx={{ fontWeight: 'bold' }}>{selectedRow.moduleD.toFixed(1)}</Typography>
                </DefaultButton>
                <DefaultButton variant='elevation'>
                  <Typography>Carbone biogénique</Typography>
                  <Typography sx={{ fontWeight: 'bold' }}>{selectedRow.carbonBiogenic?.toFixed(1)}</Typography>
                </DefaultButton>
              </Box>

              <Typography color='#acacac' display='flex' justifyContent='flex-end' sx={{ mt: 3 }}>
                Modifiée le
                {selectedRow instanceof IniesRecord
                  ? ` ${formatToFrench(selectedRow?.lastIniesUpdate)}depuis la base INIES.`
                  : ` ${formatToFrench(selectedRow?.lastModifiedDate)} par ${selectedRow?.lastModifiedUserName}.`}
              </Typography>
              <Typography color='#acacac' display='flex' justifyContent='flex-end'>
                {selectedRow.expirationDate ? ` Expire le ${formatToFrench(selectedRow.expirationDate)}` : ''}
              </Typography>
              {isModalCard && (
                <Box
                  sx={{
                    flex: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    mt: 3,
                  }}>
                  <Button
                    onClick={() => addMaterial(selectedRow)}
                    sx={{
                      backgroundColor: 'success.main',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: 'success.main',
                        color: 'white',
                      },
                    }}>
                    {isProjectMaterial ? 'ajouter au projet' : 'ajouter à ma matériauthèque'}
                  </Button>
                </Box>
              )}

              {isEditMode && !isModalCard && (
                <Box display='flex' justifyContent='flex-end' sx={{ mt: 3 }}>
                  <Button type='submit' variant='contained' color='success' size='large' sx={{ color: 'white' }}>
                    Enregistrer
                  </Button>
                </Box>
              )}
              {isEditName && (
                <Box display='flex' justifyContent='flex-end' sx={{ mt: 3 }}>
                  <Button type='submit' variant='contained' color='success' size='large' sx={{ color: 'white' }}>
                    Enregistrer
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
          <MaterialPageConfirmationModal
            isOpen={isConfirmationOpen}
            handleClose={handleCloseConfirmation}
            title='Etes vous sûr de vouloir retirer ce produit de votre matériauthèque ?'>
            <Box display='flex' justifyContent='space-evenly' width={350} sx={{ mt: 3 }}>
              <Button variant='contained' color='info' size='large' onClick={handleCloseConfirmation}>
                Non
              </Button>
              <Button disabled={loadingDelete} variant='contained' color='error' size='large' onClick={handleDeleteMaterial}>
                Oui
              </Button>
            </Box>
          </MaterialPageConfirmationModal>
        </CardContent>
      </Card>
    </Box>
  )
}
