import { Alert, Snackbar } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { getErrorMessage } from '../../core/error/error-handler'

export const ErrorContext = React.createContext<(err: Error) => void>(() => {
  // do nothing, function will be set later
})

type Props = {
  children: any
}

export default function ErrorSnackbar({ children }: Readonly<Props>): React.JSX.Element {
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  const [open, setOpen] = useState<boolean>(false)

  function handleClose(): void {
    setErrorMsg(undefined)
    setOpen(false)
  }

  const openErrorSnackbar: (err: Error) => void = useCallback((err: Error) => {
    if (err) {
      const errorMessage = getErrorMessage(err)
      setErrorMsg(errorMessage)
    } else {
      setErrorMsg("Une erreur s'est produite")
    }
    setOpen(true)
  }, [])

  return (
    <ErrorContext.Provider value={openErrorSnackbar}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleClose} severity='error' variant='filled' sx={{ width: '100%', textAlign: 'center' }}>
          {errorMsg}
        </Alert>
      </Snackbar>
    </ErrorContext.Provider>
  )
}
