import {
  Color,
  DoubleSide,
  LineBasicMaterial,
  LineDashedMaterial,
  Material,
  MeshStandardMaterial,
  PointsMaterial,
  SpriteMaterial,
  TextureLoader,
} from 'three'
import { SubsetInfo } from '../models/SubsetInfo'
import PointHilightTexture from '../../../../assets/textures/pointHighlight.png'

export class MaterialHelper {
  /**
   * Get the material of a subset based on its color hash
   * @param {string} color the color
   * @param {number} opacity the opacity of the material, the default value is 1
   * @returns {MeshStandardMaterial} the material to be used
   */
  static getSubsetMaterial(color: string, opacity?: number): MeshStandardMaterial {
    return new MeshStandardMaterial({
      color: new Color(color),
      opacity: opacity ?? 1.0,
      transparent: true,
      side: DoubleSide, //set the materials for DoubleSide always or some element will be invisible since web-ifc-viewe@1.0.216
      emissiveIntensity: 1,
      roughness: 1,
      metalness: 0.05,
    })
  }

  /**
   * update the opacity of a subset
   * @param subset  the subset to update opacity
   * @param opacity  the opacity to set
   */
  static async updateSubsetOpacity(subset: SubsetInfo, opacity: number): Promise<void> {
    if (subset) {
      const clone = MaterialHelper.cloneMaterial(subset.ifcSubset?.material)
      if (clone) {
        MaterialHelper.updateMaterialOpacity(clone, opacity)
        if (subset.ifcSubset) {
          subset.ifcSubset.material = clone
        }
      }
    }
  }

  /**
   * Update the material of a subset based on its color hash and opacity
   * @param {Material} material the material to be updated
   * @param {string} color the color
   * @param {number} opacity the opacity of the material, the default value is 1
   * @returns {void}
   */
  static updateMaterialOpacity(material: Material | Material[], opacity: number): void {
    if (!material) return
    if (Array.isArray(material)) {
      material.forEach((m) => {
        m.opacity = opacity
        m.depthWrite = false
        m.transparent = true
        m.needsUpdate = true
      })
    } else {
      material.opacity = opacity
      material.depthWrite = false
      material.transparent = true
      material.needsUpdate = true
    }
  }

  /**
   * Clone a material or an array of materials to a new one
   * @param material the material to be cloned
   * @returns
   */
  static cloneMaterial(material: Material | Material[] | undefined): Material | Material[] | undefined {
    if (!material) return undefined
    if (Array.isArray(material)) {
      return material.map((m) => m.clone())
    } else {
      return material.clone()
    }
  }

  /**
   * Colors in three.js are represented as a hexadecimal number #RRGGBB
   * but sometimes the incoming colors are not same and need to be fixed
   * @param color the color to fix
   * @returns the fixed color if needed to be fixed
   */
  static fixColor(color: string): string {
    if (color.startsWith('#') && color.length < 7) {
      const difference = 7 - color.length
      return color + '0'.repeat(difference)
    } else {
      return color
    }
  }

  static getMeasureHilightMaterial(color: string): SpriteMaterial {
    return new SpriteMaterial({
      map: new TextureLoader().load(PointHilightTexture),
      depthTest: false,
      color,
    })
  }

  static getMeasurePointMaterial(color: string, size?: number): PointsMaterial {
    return new PointsMaterial({
      color: new Color(color),
      sizeAttenuation: false,
      size: size ?? 6,
      depthTest: false,
      transparent: true,
      opacity: 0.85,
    })
  }

  static getMeasureLineMaterial(color: string, lineWidth?: number): LineBasicMaterial {
    return new LineBasicMaterial({
      color: new Color(color),
      depthTest: false,
      transparent: true,
      linewidth: lineWidth ?? 1,
      opacity: 0.95,
    })
  }

  static getBeforeMeasureLineMaterial(color: string, lineWidth?: number): LineBasicMaterial {
    return new LineDashedMaterial({
      color: new Color(color),
      linewidth: lineWidth ?? 10,
      scale: 1,
      dashSize: 0.05,
      gapSize: 0.05,
      depthTest: false,
      transparent: true,
      opacity: 0.95,
    })
  }
}
