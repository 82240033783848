import React, { Dispatch, SetStateAction, useContext, useState } from 'react'
import { TextField, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import DeleteDialog from '../../../../../components/dialog/delete-dialog'
import { ProjectStatusEnum } from '../../../../../core/enum/projectStatusEnum'
import { resolveUrl } from '../../../../../core/services/http-service'
import { pagesUrl } from '../../../../../core/appConstants'
import { ProjectTypeEnum } from '../../../../../core/enum/project/projectTypeEnum'
import { OrganizationContext } from '../../../../../core/context/organization/organization-context'
import { useRsee } from '../../../../../core/hooks/rsee/use-rsee'
import { SuccessContext } from '../../../../../components/layout/success-snackbar'
import { RseeProjectForm } from '../../../../../core/dto/rsee/rsee-project-form'
import { RseeProjectContext } from '../../../../../core/context/rsee/rsee-project-context'

type IProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export default function DeleteRseeProjectDialog({ open, setOpen }: IProps): React.JSX.Element {
  const navigate = useNavigate()
  const { updateRseeProject } = useRsee()
  const { deleteRseeProject } = useRsee()
  const openSuccessSnackbar = useContext(SuccessContext)
  const { refreshOrganization } = useContext(OrganizationContext)
  const { rseeProject, setRseeProject } = useContext(RseeProjectContext)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [error, setError] = useState<Record<string, string | undefined>>({})
  const [rseeNameToDelete, setRseeNameToDelete] = useState<string>('')

  function handleChangeDeleteDialog(event: React.ChangeEvent<HTMLInputElement>): void {
    const target = event.target

    setError({ ...error, rseeNameToDelete: undefined })
    setRseeNameToDelete(target.value)
  }

  function validateProjectNameToDelete(): boolean {
    const newError: Record<string, string> = {}
    let isValid = true
    const nameValue = rseeNameToDelete?.trim()

    if (!nameValue?.length) {
      newError.rseeNameToDelete = 'Veuillez remplir ce champ'
      isValid = false
    }

    if (nameValue !== rseeProject?.projectName) {
      newError.rseeNameToDelete = 'Le nom du projet est incorrect'
      isValid = false
    }

    if (nameValue.length > 1000) {
      newError.rseeNameToDelete = '1000 caractères maximum'
      isValid = false
    }

    setError(newError)
    return isValid
  }

  function handleDeleteRseeProject(): void {
    if (rseeProject?.id && rseeProject.projectStatus !== ProjectStatusEnum.ARCHIVED) {
      updateStatusToArchived()
    } else if (validateProjectNameToDelete() && rseeProject?.id && !isDeleting) {
      setIsDeleting(true)
      deleteRseeProject(rseeProject.id)
        .then(() => refreshOrganization()) // Refresh organization to refresh the projects counter
        .then(() => {
          openSuccessSnackbar('Suppression du projet RSEE réalisé avec succès')
          setOpen(false)
          navigate(resolveUrl(pagesUrl.PROJECTS_PAGE, [], { type: ProjectTypeEnum.RSEE }))
        })
        .finally(() => {
          setIsDeleting(false)
        })
    }
  }

  function updateStatusToArchived(): void {
    if (rseeProject?.id) {
      const rseeProjectDto: RseeProjectForm = {
        status: ProjectStatusEnum.ARCHIVED,
        nom: rseeProject.projectName,
      }
      updateRseeProject(rseeProject.id, rseeProjectDto)
        .then((updateToArchived) => {
          setRseeProject(updateToArchived)
          openSuccessSnackbar("Statut du projet mis à jour à l'état Archivé")
        })
        .finally(() => {
          setOpen(false)
        })
    }
  }

  return (
    <DeleteDialog
      id='deleteRseeProject'
      title={`Suppression du projet "${rseeProject?.projectName}"`}
      contentText={
        rseeProject?.projectStatus !== ProjectStatusEnum.ARCHIVED ? (
          `La suppression du projet RSEE n'est possible qu'à l'état Archivé. Voulez-vous passer le statut du projet à Archivé ?`
        ) : (
          <>
            <Typography variant='h6' sx={{ fontSize: 19 }}>
              Pour confirmer la suppression, tapez "{rseeProject.projectName}" ci-dessous (sans les guillemets)
            </Typography>
            <TextField
              required
              id='rseeNameToDelete'
              onChange={handleChangeDeleteDialog}
              value={rseeNameToDelete}
              variant='outlined'
              fullWidth
              error={!!error.rseeNameToDelete}
              helperText={error.rseeNameToDelete}
            />
          </>
        )
      }
      handleClose={() => setOpen(false)}
      action={handleDeleteRseeProject}
      open={open}
    />
  )
}
