import { Vector3 } from 'three'
import { IfcPoint } from './IfcPoint'

/**
 * represents a curve in 3D space
 */
export class IfcCurve {
  start: IfcPoint
  end: IfcPoint
  points: IfcPoint[]
  id: string
  processed: boolean

  /**
   * creates a new IfcCurve from a list of points as Vector3 objects coming from three.js
   */
  constructor(vectors: Vector3[]) {
    const points = vectors.map((vector) => new IfcPoint(vector.x, vector.y, vector.z))
    this.start = points[0]
    this.end = points[points.length - 1]
    this.points = points
    this.id = this.toString()
    this.processed = false
  }

  /**
   * convert a list of Vector3 that represents a line into an IfcCurve objects
   */
  static toIfcCurve(points: Vector3[]): IfcCurve {
    return new IfcCurve(points)
  }

  /**
   * create a list of IfcCurve objects from a list of Vector3 arrays each couple of Vector3 represents a line
   */
  static toIfcCurves(lines: Vector3[][]): IfcCurve[] {
    return lines.map((line) => IfcCurve.toIfcCurve(line))
  }

  /**
   * converts the curve into a Vector3 array representing a curve where index 0 is the start point and index 1 is the end point
   * @returns the coordinates of the curve as a list of Vector3 objects
   */
  toVector3Array(): Vector3[] {
    return [new Vector3(this.start.x, this.start.y, this.start.z), new Vector3(this.end.x, this.end.y, this.end.z)]
  }

  angleTo(otherCurve: IfcCurve): number {
    const p1 = this.points[0]
    const p2 = this.points[1]
    const p3 = otherCurve.points[0]
    const p4 = otherCurve.points[1]

    const v1 = new IfcPoint(p2.x - p1.x, p2.y - p1.y, p2.z - p1.z)
    const v2 = new IfcPoint(p4.x - p3.x, p4.y - p3.y, p4.z - p3.z)

    const angle = v1.angleBetween(v2)

    // Calculate normal vectors
    const n1 = v1.normal()
    const n2 = v2.normal()

    // Use dot product of normal vectors to get direction
    const direction = n1.dot(n2) > 0 ? 1 : -1

    return angle * direction
  }

  /**
   * converts the coordinates to a readable string
   */
  private toString(): string {
    return `(${this.start.x},${this.start.y},${this.start.z}-${this.end.x},${this.end.y},${this.end.z})`
  }
}
