import { CircularProgress } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import UserFormComponent from '../../../components/forms/user-invitation-form/user-form-component'
import { ErrorContext } from '../../../components/layout/error-snackbar'
import { SuccessContext } from '../../../components/layout/success-snackbar'
import { adminPagesUrl } from '../../../core/appConstants'
import { AdminOrganizationContext } from '../../../core/context/organization/admin-organization-context'
import { OrganizationsListContext } from '../../../core/context/organization/organization-list-context'
import { User } from '../../../core/dto/user/user'
import { UserForm } from '../../../core/dto/user/user-form'
import { UserUpdateDto } from '../../../core/dto/user/user-update-dto'
import { OrganizationRoleEnum } from '../../../core/enum/user/organization-role-enum'
import { useUser } from '../../../core/hooks/use-user'
import { resolveUrl } from '../../../core/services/http-service'
import { roleUpdateSelectOptions } from '../../../core/services/user-service'

export default function AdminUpdateUserForm(): React.JSX.Element {
  const navigate = useNavigate()
  const openErrorSnackbar = useContext(ErrorContext)
  const openSuccessSnackbar = useContext(SuccessContext)
  const { refreshOrganizationList } = useContext(OrganizationsListContext)
  const { organization } = useContext(AdminOrganizationContext)
  const { adminUpdateUser, adminGetUser } = useUser()
  const { userId: userIdToEdit } = useParams()
  const [isLoadingUserToUpdate, setIsLoadingUserToUpdate] = useState<boolean>(true)
  const [userToUpdate, setUserToUpdate] = useState<User | undefined>(undefined)

  useEffect(() => {
    if (userIdToEdit) {
      setIsLoadingUserToUpdate(true)
      adminGetUser(userIdToEdit)
        .then((user) => {
          setUserToUpdate(user)
        })
        .finally(() => setIsLoadingUserToUpdate(false))
    }
  }, [userIdToEdit, adminGetUser])

  function sendInvitationFromAdminUser(userForm: UserForm): Promise<void> {
    if (userIdToEdit === undefined) {
      openErrorSnackbar(new Error("Pas d'utilisateur sélectionné"))
      navigate(resolveUrl(adminPagesUrl.ADMIN_ORGANIZATION_PAGE, [organization?.id]))
      return Promise.resolve()
    }

    const userUpdateDto: UserUpdateDto = {
      cognitoUserId: userIdToEdit,
      givenName: userForm.firstName,
      familyName: userForm.lastName,
      phone: userForm.phone,
      email: userForm.email,
      role: userForm.userOrganizationRole,
    }

    if (organization?.id) {
      return adminUpdateUser(userUpdateDto)
        .then(() => refreshOrganizationList())
        .then(() => {
          openSuccessSnackbar("Utilisateur mis à envoyée à l'utilisateur")
          navigate(resolveUrl(adminPagesUrl.ADMIN_ORGANIZATION_PAGE, [organization?.id]))
        })
    } else {
      return Promise.resolve()
    }
  }

  if (isLoadingUserToUpdate) {
    return <CircularProgress />
  } else {
    return (
      <UserFormComponent
        onSubmit={sendInvitationFromAdminUser}
        selectOptions={roleUpdateSelectOptions}
        defaultRole={OrganizationRoleEnum.MEGA_USER}
        initUser={userToUpdate}
      />
    )
  }
}
