import { useCallback, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { appConstants } from '../../appConstants'
import { MaterialLibOrganizationIndicatorContext } from '../../context/material/material-lib-organization-indicator-context'
import { UserContext } from '../../context/user/user-context'
import { MaterialLibIndicator } from '../../dto/organization/organization-indicator'
import { isUltimateUser } from '../../services/authentication-service'
import { useHttp } from '../use-http'

type useMaterialLibOrganizationIndicatorReturn = {
  fetchMaterialOrganizationIndicator: () => Promise<void>
  reset: () => void
}

export const useMaterialLibOrganizationIndicator = (): useMaterialLibOrganizationIndicatorReturn => {
  const { get } = useHttp()

  const { setOrganizationIndicator, setLoading, setError } = useContext(MaterialLibOrganizationIndicatorContext)

  const reset = useCallback((): void => setOrganizationIndicator(new MaterialLibIndicator()), [setOrganizationIndicator])

  const { organizationId } = useParams()
  const { user } = useContext(UserContext)
  const isOrganizationUltimateUser = isUltimateUser(user)

  const fetchMaterialOrganizationIndicator = useCallback(async (): Promise<void> => {
    setLoading(true)

    return isOrganizationUltimateUser && organizationId
      ? get(appConstants.apiEndpoints.MATERIAL_ORGANIZATION_INDICATOR_ADMIN, [
          { key: 'organizationId', value: organizationId },
        ])
          .then((response) => response.json())
          .then((res) => {
            setOrganizationIndicator(res)
          })
      : get(appConstants.apiEndpoints.MATERIAL_ORGANIZATION_INDICATOR)
          .then((response) => response.json())
          .then((res) => {
            setOrganizationIndicator(res)
          })
          .finally(() => {
            setLoading(false)
          })
          .catch((err) => {
            setError(err)
            throw err
          })
  }, [setLoading, isOrganizationUltimateUser, get, setOrganizationIndicator, setError])

  return {
    reset,
    fetchMaterialOrganizationIndicator,
  }
}
