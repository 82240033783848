import Clear from '@mui/icons-material/Clear'
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import React, { useCallback, useMemo, useState } from 'react'

import { NomenclatureFilterDto } from '../../../../../core/dto/nomenclature-filter'
import { DeclarationFilter } from '../../../../../core/enum/declarationFilter'
import { useFetchMaterialLibDeclarationType } from '../../../../../core/hooks/material-lib/use-fetch-material-lib-declaration-type'
import './material-page.css'
import { getDeclarationTypeLabel } from '../../../../../core/services/declaration-type-service'

type MaterialPageFormProps = {
  selectedLvl0: DeclarationFilter | undefined
  setSelectedLvl0: (selected: DeclarationFilter | undefined) => void
  nomenclatureFilterData: NomenclatureFilterDto
  selectedLvl1: string | undefined
  selectedLvl2: string | undefined
  selectedLvl3: string | undefined
  selectedLvl4: string | undefined
  selectedTypologieDeclaration: string | undefined
  setSelectedLvl1: (event: SelectChangeEvent) => void
  setSelectedLvl2: (event: SelectChangeEvent) => void
  setSelectedLvl3: (event: SelectChangeEvent) => void
  setSelectedLvl4: (event: SelectChangeEvent) => void
  setResponsibleOrganism: React.Dispatch<React.SetStateAction<string | undefined>>
  handleTypologieDeclaration: (event: SelectChangeEvent) => void
  responsibleOrganism: string | undefined
  handleChangeResponsibleOrganism: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

export function MaterialPageFilterFormCalculation({
  selectedLvl0,
  setSelectedLvl0,
  nomenclatureFilterData,
  selectedLvl1,
  selectedLvl2,
  selectedLvl3,
  selectedLvl4,
  selectedTypologieDeclaration,
  setSelectedLvl1,
  setSelectedLvl2,
  setSelectedLvl3,
  setSelectedLvl4,
  handleTypologieDeclaration,
  handleChangeResponsibleOrganism,
  responsibleOrganism,
  setResponsibleOrganism,
}: MaterialPageFormProps): React.JSX.Element {
  const { declarationTypes } = useFetchMaterialLibDeclarationType()
  const [materialProvider, setMaterialProvider] = useState('')
  const [materialReference, setMaterialReference] = useState('')
  const categories = useMemo(
    () =>
      (selectedLvl0 === DeclarationFilter.FDES ? nomenclatureFilterData.fdes : nomenclatureFilterData.pep).sort((a, b) =>
        a.name.localeCompare(b.name)
      ),
    [selectedLvl0, nomenclatureFilterData.fdes, nomenclatureFilterData.pep]
  )
  const category = useMemo(
    () => categories.find((c) => c.iniesId.toString() === selectedLvl1?.toString()),
    [categories, selectedLvl1]
  )

  const subCategories = useMemo(
    () => (category?.children || []).sort((a, b) => a.name.localeCompare(b.name)),
    [category?.children]
  )

  const subCategory = useMemo(
    () => category?.children.find((subc) => subc.iniesId.toString() === selectedLvl2?.toString()),
    [category?.children, selectedLvl2]
  )
  const material = useMemo(
    () => subCategory?.children.find((m) => m.iniesId.toString() === selectedLvl3?.toString()),
    [selectedLvl3, subCategory?.children]
  )

  const endAdornment = (
    value: string | undefined,
    onClick: (e: any) => void,
    type: 'text' | 'select' = 'text'
  ): React.JSX.Element | undefined => {
    if (value) {
      return (
        <InputAdornment
          position='end'
          sx={{
            mr: type === 'text' ? 0 : 2,
          }}>
          <IconButton onClick={onClick} size='small'>
            <Clear fontSize='small' />
          </IconButton>
        </InputAdornment>
      )
    }

    return undefined
  }
  const handleResetOrganism = useCallback(() => setResponsibleOrganism(''), [setResponsibleOrganism])

  const handleResetMaterialProvider = (): void => {
    setMaterialProvider('')
  }

  const handleResetMaterialReference = (): void => {
    setMaterialReference('')
  }
  return (
    <Box className='partial-border' display='flex' flexDirection='column' sx={{ mt: 4 }}>
      <FormControl sx={{ m: 1, minWidth: 120, margin: '8px 0' }}>
        <InputLabel sx={{ backgroundColor: 'white' }}>TYPE</InputLabel>
        <Select
          label='type'
          value={selectedLvl0 || ''}
          onChange={(e) => setSelectedLvl0(e.target.value as DeclarationFilter | undefined)}
          endAdornment={endAdornment(
            selectedLvl0,
            (v) => {
              // It might seem weird to put undefined but v is always undefined. If we refactor someday, the clear method could be simplified. "v" param is useless
              setSelectedLvl0(undefined)
              setSelectedLvl1(v)
              setSelectedLvl2(v)
              setSelectedLvl3(v)
              setSelectedLvl4(v)
            },
            'select'
          )}>
          <MenuItem key={DeclarationFilter.FDES} value={DeclarationFilter.FDES}>
            Produits
          </MenuItem>
          <MenuItem key={DeclarationFilter.PEP} value={DeclarationFilter.PEP}>
            Équipement (PEP)
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 120, margin: '8px 0' }}>
        <InputLabel id='selectedLvl1' sx={{ textTransform: 'uppercase', backgroundColor: 'white' }}>
          catégorie
        </InputLabel>
        <Select
          label='catégorie'
          value={selectedLvl1 || ''}
          id='selectedLvl1'
          name='selectedLvl1'
          labelId='selectedLvl1'
          onChange={setSelectedLvl1}
          disabled={!selectedLvl0}
          endAdornment={endAdornment(
            selectedLvl1,
            (v) => {
              setSelectedLvl1(v)
              setSelectedLvl2(v)
              setSelectedLvl3(v)
              setSelectedLvl4(v)
            },
            'select'
          )}>
          {categories.map((categoryItem) => (
            <MenuItem key={categoryItem.iniesId} value={categoryItem.iniesId}>
              {categoryItem.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 120, margin: '8px 0' }}>
        <InputLabel id='selectedLvl2' sx={{ textTransform: 'uppercase', backgroundColor: 'white' }}>
          sous-catégorie
        </InputLabel>
        <Select
          label='sous-catégorie'
          value={selectedLvl2 || ''}
          id='selectedLvl2'
          labelId='selectedLvl2'
          name='selectedLvl2'
          disabled={!selectedLvl1}
          endAdornment={endAdornment(
            selectedLvl2,
            (v) => {
              setSelectedLvl2(v)
              setSelectedLvl3(v)
              setSelectedLvl4(v)
            },
            'select'
          )}
          onChange={setSelectedLvl2}>
          {subCategories.map((subCategoryItem) => (
            <MenuItem key={subCategoryItem.iniesId} value={subCategoryItem.iniesId}>
              {subCategoryItem.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ m: 1, minWidth: 120, margin: '8px 0' }}>
        <InputLabel sx={{ textTransform: 'uppercase', backgroundColor: 'white' }}>
          {selectedLvl0 === DeclarationFilter.PEP ? 'Type' : 'matériaux'}
        </InputLabel>
        <Select
          label={selectedLvl0 === DeclarationFilter.PEP ? 'Type' : 'matériaux'}
          value={selectedLvl3 || ''}
          onChange={setSelectedLvl3}
          disabled={!selectedLvl2}
          endAdornment={endAdornment(
            selectedLvl3,
            (v) => {
              setSelectedLvl3(v)
              setSelectedLvl4(v)
            },
            'select'
          )}>
          {subCategory?.children
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((m) => (
              <MenuItem key={m.iniesId} value={m.iniesId}>
                {m.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      {selectedLvl0 === DeclarationFilter.PEP && (
        <FormControl sx={{ m: 1, minWidth: 120, margin: '8px 0' }}>
          <InputLabel sx={{ textTransform: 'uppercase', backgroundColor: 'white' }}>spécification</InputLabel>
          <Select label='spécification' value={selectedLvl4 || ''} onChange={setSelectedLvl4} disabled={!selectedLvl3}>
            endAdornment={endAdornment(selectedLvl4, setSelectedLvl4, 'select')}
            {material?.children
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((m) => (
                <MenuItem key={m.iniesId} value={m.iniesId}>
                  {m.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}

      <TextField
        sx={{ m: 1, minWidth: 120, margin: '8px 0' }}
        label='ORGANISME DECLARANT'
        variant='outlined'
        value={responsibleOrganism}
        onChange={handleChangeResponsibleOrganism}
        InputProps={{ endAdornment: endAdornment(responsibleOrganism, handleResetOrganism) }}
      />

      <FormControl sx={{ m: 1, minWidth: 120, margin: '8px 0' }}>
        <InputLabel sx={{ textTransform: 'uppercase', backgroundColor: 'white' }}>typologie de déclaration</InputLabel>
        <Select
          label='material'
          value={selectedTypologieDeclaration || ''}
          endAdornment={endAdornment(selectedTypologieDeclaration, handleTypologieDeclaration, 'select')}
          onChange={handleTypologieDeclaration}>
          {declarationTypes
            .sort((a, b) => a.localeCompare(b))
            .map((typologie) => (
              <MenuItem key={typologie} value={typologie}>
                {getDeclarationTypeLabel(typologie)}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <TextField
        InputProps={{ endAdornment: endAdornment(materialProvider, handleResetMaterialProvider) }}
        value={materialProvider}
        onChange={(e) => setMaterialProvider(e.target.value)}
        sx={{ m: 1, minWidth: 120, margin: '8px 0' }}
        label='FOURNISSEUR'
        variant='outlined'
      />
      <TextField
        InputProps={{ endAdornment: endAdornment(materialReference, handleResetMaterialReference) }}
        value={materialReference}
        onChange={(e) => setMaterialReference(e.target.value)}
        sx={{ m: 1, minWidth: 120, margin: '8px 0' }}
        label='REFERENCE'
        variant='outlined'
      />
    </Box>
  )
}
