import { BufferGeometry, Line, Points, Vector3 } from 'three'
import { IfcViewerAPI } from 'web-ifc-viewer'
import { viewerConstants } from '../../../constants/ViewerConstants'
import { DimensionMode } from '../../../enums/DimensionMode'
import { MathHelper } from '../../../helpers/MathHelper'
import { MeasurementHelper } from '../../../helpers/MeasurementHelper'
import { PointData } from '../annotations/PointData'
import { DimensionCommand } from '../base/DimensionCommand'

/**
 * Class that represents a command to create an area dimension after performing ray casting operations
 */
export class AreaDimensionCommand extends DimensionCommand {
  polygon: Array<Vector3> = []
  lines: Array<Line> = []
  startPoint: PointData
  endPoint: PointData

  constructor(points: Array<PointData>, scene: THREE.Scene, ifc: IfcViewerAPI) {
    super(scene, ifc)
    this.dimensionMode = DimensionMode.AREA
    this.polygon = points.map((point) => point.point)
    this.startPoint = points[0]
    this.endPoint = points[Math.floor(points.length / 2)]
    const vectorLines = AreaDimensionCommand.setupLinesFromPolygon(points)
    for (const vectorLine of vectorLines) {
      const lineGeom = new BufferGeometry().setFromPoints(vectorLine)
      const line = new Line(lineGeom, viewerConstants.measurement.lineDimension.lineMaterial)
      line.renderOrder = 1
      line.name = DimensionMode.AREA.toString()
      this.lines.push(line)
    }

    this.distance = MathHelper.calculateArea(this.polygon.map((point) => new Vector3(point.x, point.y, point.z)))
    this.textPosition = MeasurementHelper.getTextPosition(this.startPoint.point, this.startPoint.point)
    this.defineTextLabel(this.startPoint.modelId ?? 0)

    this.pointsGeometry = new BufferGeometry().setFromPoints(points.map((point) => point.point))
    this.labelpoints = new Points(this.pointsGeometry, viewerConstants.measurement.lineDimension.pointMaterial)

    this.setUpGroupData()
  }

  private static setupLinesFromPolygon(polygon: Array<PointData>): Array<Array<Vector3>> {
    const lines: Array<Array<Vector3>> = []
    for (let i = 0; i < polygon.length; i += 1) {
      if (i + 1 < polygon.length) {
        const p = polygon[i]
        const q = polygon[i + 1]
        lines.push([p.point, q.point])
      } else {
        const p = polygon[i]
        const q = polygon[0]
        if (!p.point.equals(q.point)) {
          lines.push([p.point, q.point])
        }
      }
    }

    return lines
  }

  async updateText(): Promise<void> {
    if (this.group && this.label) {
      this.scene.remove(this.label)
      this.group.remove(this.label)
      this.label.material.dispose()
      this.label.clear()
      await this.defineTextLabel(this.startPoint.modelId ?? 0)
      this.group.add(this.label)
    }
  }

  private async setUpGroupData(): Promise<void> {
    await this.defineTextLabel(this.startPoint.modelId ?? 0)
    if (this.group && this.label && this.lines.length > 0 && this.labelpoints) {
      for (const line of this.lines) {
        this.group.add(line)
      }
      this.updateTextSize()
      this.group.add(this.label)
      this.group.add(this.labelpoints)
      this.group.assosiatedObject = this
      this.group.startPoint = this.startPoint.point
      this.group.endPoint = this.endPoint.point
      this.group.startPointExpressId = this.startPoint.expressId
      this.group.endPointExpressId = this.endPoint.expressId
      this.group.startPointModelId = this.startPoint.modelId
      this.group.endPointModelId = this.endPoint.modelId
    }
  }
}
