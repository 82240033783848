import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import { useState } from 'react'
import city from '../../../assets/img/city.png'
import { IconBox } from '../../components/custom-icons/icon-box'
import { IconPencil } from '../../components/custom-icons/icon-pencil'
import { PageCenter } from '../../core/enum/help-center/helpCenterEnum'
import { useTutorial } from '../../core/hooks/bim-model/use-tutorial'

type IProps = {
  handleChange(toChange: PageCenter): void
  handleClose(): void
}

export function HelperCenterPage({ handleChange, handleClose }: IProps): React.JSX.Element {
  const { fetchConventionBeem } = useTutorial()

  const [acceptConditions, setAcceptConditions] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  async function handleDownloadFile(): Promise<void> {
    if (acceptConditions) {
      setError(false)
      const file = await fetchConventionBeem()
      if (file) {
        const element = document.createElement('a')
        element.href = URL.createObjectURL(file)
        element.download = file.name
        element.click()
      }
      handleCloseDownloadModal()
    } else {
      setError(true)
    }
  }

  function handleCloseDownloadModal(): void {
    setOpenModal(false)
  }

  function handleCheckbox(event: React.ChangeEvent<HTMLInputElement>): void {
    setError(false)
    setAcceptConditions(event.target.checked)
  }

  return (
    <>
      <Box display='flex' justifyContent='flex-end' p={1}>
        <IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close'>
          <CloseIcon />
        </IconButton>
      </Box>
      <Grid container height='100%'>
        <Grid item xs={2} />

        <Grid
          container
          item
          xs={8}
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          alignContent='center'
          gap={5}>
          <Typography variant='h5' fontWeight={600}>
            Laissez vous guider !
          </Typography>
          <Typography variant='body1' display='flex' justifyContent='center' textAlign='center' color='black'>
            Vous avez besoin de paramétrer une maquette numérique ou apporter des informations à votre équipe ? <br />
            Vous êtes au bon endroit 😊
          </Typography>
          <Grid container display='flex' justifyContent='space-between' borderRadius={4} sx={{ background: '#C9FFE8' }}>
            <Grid item xs={8} display='flex' flexDirection='column' justifyContent='space-between' gap={3} sx={{ p: 5 }}>
              <Typography variant='h6'>Appliquer la methode Time To Beem</Typography>
              <Typography variant='body1'>
                Consultez la documentation Time To Beem pour renseigner correctement les codes ACV dans les objets modélisés
                de la maquette numérique.
              </Typography>

              <Grid container item xs={6}>
                <Button
                  variant='contained'
                  sx={{ borderRadius: 3, color: 'white', backgroundColor: '#009069' }}
                  onClick={() => setOpenModal(true)}>
                  Télécharger la méthode Time To Beem
                </Button>
              </Grid>
            </Grid>

            <Grid item xs={4} p={3}>
              <img
                src={city}
                alt={city}
                height='200px'
                width='max-content'
                style={{
                  marginLeft: 15,
                  marginTop: -8,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={10}>
            <Grid item xs={6}>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='space-between'
                gap={2}
                p={3}
                borderRadius={3}
                sx={{ background: 'white' }}>
                <IconPencil />
                <Typography variant='h6'>Télécharger les outils d'aide</Typography>
                <Typography variant='body1'>
                  Paramétrez les logiciels REVIT et ARCHICAD et consultez les tutoriels vidéo.
                </Typography>
                <Grid container item xs={6}>
                  <Button
                    variant='contained'
                    onClick={() => handleChange(PageCenter.AIDE_PARAMETRAGE)}
                    sx={{ borderRadius: 3, color: '#009069', backgroundColor: '#C9FFE8' }}>
                    Parcourir
                  </Button>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='space-between'
                gap={2}
                p={3}
                borderRadius={3}
                sx={{ background: 'white' }}>
                <IconBox />
                <Typography variant='h6'>Tester notre maquette d’exemple</Typography>
                <Typography variant='body1'>
                  Consultez un exemple dans lequel notre équipe a paramétré une maquette numérique pour Time To Beem.
                </Typography>
                <Grid container item xs={6}>
                  <Button
                    variant='contained'
                    onClick={() => handleChange(PageCenter.TUTORIAL_EXEMPLE)}
                    sx={{ borderRadius: 3, color: '#009069', backgroundColor: '#C9FFE8' }}>
                    Parcourir
                  </Button>
                </Grid>
              </Box>
            </Grid>
          </Grid>

          <Dialog open={openModal} onClose={handleCloseDownloadModal} maxWidth='lg' fullWidth>
            <DialogTitle>Appliquer la méthode Time To Beem</DialogTitle>
            <IconButton
              aria-label='close'
              onClick={handleCloseDownloadModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}>
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <Grid
                container
                display='flex'
                justifyContent='space-between'
                alignContent='center'
                alignItems='center'
                borderRadius={4}
                sx={{ background: '#C9FFE8' }}>
                <Grid item xs={8} display='flex' flexDirection='column' justifyContent='space-between' gap={3} sx={{ p: 5 }}>
                  <Typography variant='body1'>
                    Consultez la documentation Time To Beem pour renseigner correctement les codes ACV dans les objets
                    modélisés de la maquette numérique.
                  </Typography>
                  <FormControl error={error}>
                    <FormControlLabel
                      control={<Checkbox checked={acceptConditions} onChange={handleCheckbox} />}
                      label="J'accepte les conditions d'utilisation Time To Beem "
                    />
                    {error && <FormHelperText>Vous devez accepter les conditions d'utilisation</FormHelperText>}
                  </FormControl>

                  <Grid container item xs={6}>
                    <Button
                      variant='contained'
                      sx={{ borderRadius: 3, color: 'white', backgroundColor: '#009069' }}
                      onClick={() => handleDownloadFile()}>
                      Télécharger le fichier ( .PDF )
                    </Button>
                  </Grid>
                </Grid>

                <Grid item xs={4} p={3}>
                  <img
                    src={city}
                    alt={city}
                    height='200px'
                    width='max-content'
                    style={{
                      marginLeft: 15,
                      marginTop: -8,
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </Grid>

        <Grid item xs={2} />
      </Grid>
    </>
  )
}
