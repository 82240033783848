import { CodesVersion } from '../../../enum/codesVersionEnum'
import { stringToDate } from '../../../services/date-service'

export class BSBimModel {
  id: string | undefined = undefined
  fileName = ''
  version = ''
  codesVersion = CodesVersion.VERSION_1_8
  projectId = ''
  errors: string[] = []
  surfaceMoyenneLogement: number | undefined
  modelHashFile?: string
  createdDate?: Date
  createdByUser?: string

  static fromDto(data: any): BSBimModel {
    const bsBimModel = new BSBimModel()
    if (data === undefined) {
      return new BSBimModel()
    }
    bsBimModel.id = data.id
    bsBimModel.fileName = data.fileName ?? ''
    bsBimModel.projectId = data.projectId ?? ''
    bsBimModel.version = data.version ?? ''
    bsBimModel.errors = data.errors ?? []
    bsBimModel.surfaceMoyenneLogement = data.surfaceMoyenneLogement
    bsBimModel.modelHashFile = data.modelHashFile
    bsBimModel.codesVersion = data.codesVersion ?? CodesVersion.VERSION_1_8
    bsBimModel.createdDate = stringToDate(data.createdDate)
    bsBimModel.createdByUser = data.createdByUser
    return bsBimModel
  }
}
