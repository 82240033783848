import { CalculationStatusEnum } from '../../enum/calculationStatusEnum'
import { CalculationTypesEnum } from '../../enum/calculationTypeEnum'
import { UsageEnum } from '../../enum/usageEnum'

export class Calculation {
  id: string | undefined
  projectId: string | undefined
  version = ''
  phase = ''
  calculationType: CalculationTypesEnum = CalculationTypesEnum.RE2020
  mainModelId = ''
  climaticZone = ''
  altitude = ''
  variantId1 = ''
  variantId2 = ''
  sameFlatRatesV1V2 = true
  sameWorksiteV1V2 = true
  sameAdditionalV1V2 = true
  usage: UsageEnum = UsageEnum.MAISONS_INDIVIDUELLES_OU_ACCOLEES
  calculationStatus = CalculationStatusEnum.CREATED
}
