import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Children } from '../../../components/miscellianous/children'

export enum UrlStateEnum {
  BEEM_SHOT = 'Beem Shot',
}

export const AppSideBarContext = React.createContext<AppSideBarStore>({} as AppSideBarStore)

export default function SideBarContextProvider({ children }: Children): React.JSX.Element {
  const location = useLocation()

  const [showAppSide, setShowAppSide] = useState<boolean>(false)
  const [drawerWidth, setDrawerWidth] = useState<number>(0)
  const [openAppSide, setOpenAppSide] = useState<boolean>(false)
  const [urlState, setUrlState] = useState<UrlStateEnum | undefined>()

  useEffect(() => {
    setUrlState(getUrlState())
    return () => {
      setShowAppSide(false)
    }
  }, [location])

  function getUrlState(): UrlStateEnum | undefined {
    const path = location.pathname
    if (path.includes('/beem-shot')) {
      return UrlStateEnum.BEEM_SHOT
    } else {
      return undefined
    }
  }

  const AppSideBarStore: AppSideBarStore = useMemo(
    () => ({ showAppSide, setShowAppSide, urlState, setUrlState, drawerWidth, setDrawerWidth, openAppSide, setOpenAppSide }),
    [drawerWidth, openAppSide, showAppSide, urlState]
  )

  return <AppSideBarContext.Provider value={AppSideBarStore}>{children}</AppSideBarContext.Provider>
}

export type AppSideBarStore = {
  showAppSide: boolean
  setShowAppSide: React.Dispatch<React.SetStateAction<boolean>>
  urlState: UrlStateEnum | undefined
  setUrlState: React.Dispatch<React.SetStateAction<UrlStateEnum | undefined>>
  drawerWidth: number
  setDrawerWidth: React.Dispatch<React.SetStateAction<number>>
  openAppSide: boolean
  setOpenAppSide: React.Dispatch<React.SetStateAction<boolean>>
}
