import { Box, Typography } from '@mui/material'
import React, { useCallback, useContext, useEffect } from 'react'
import { Collaborator } from '../../core/dto/user/collaborator'
import { useOrganization } from '../../core/hooks/use-organization'
import DataGridOrganization, { DataGridType } from '../../components/tables/display-user/data-grid-organization'
import { OrganizationContext } from '../../core/context/organization/organization-context'

type IProps = {
  dataCollab: Collaborator[]
  setCollabList: React.Dispatch<React.SetStateAction<Collaborator[]>>
}

export default function OrganizationCollaborators({ dataCollab, setCollabList }: IProps): React.JSX.Element {
  const { refreshOrganization } = useContext(OrganizationContext)
  const { fetchCollaborators } = useOrganization()

  const refreshCollab = useCallback(() => {
    refreshOrganization()
    fetchCollaborators().then((collab) => {
      setCollabList(collab)
    })
  }, [fetchCollaborators, setCollabList])

  useEffect(() => {
    refreshCollab()
  }, [refreshCollab])

  return (
    <Box sx={{ pl: 20, pr: 20 }}>
      <Typography fontWeight='bold' variant='h5' fontSize={20} sx={{ mt: 4, mb: 2 }}>
        COLLABORATEURS
      </Typography>

      <DataGridOrganization
        dataCollab={dataCollab}
        dataContact={[]}
        type={DataGridType.COLLAB}
        refreshCollab={refreshCollab}
        isForUltimateUser={false}
      />
    </Box>
  )
}
