export enum ErrorLevelEnum {
  INFO = 'INFO',
  WARNING = 'WARNING',
  CRITICAL = 'CRITICAL',
}

export function errorLevelToLabel(status: ErrorLevelEnum): string {
  switch (status) {
    case ErrorLevelEnum.INFO:
      return 'Information'
    case ErrorLevelEnum.WARNING:
      return 'Alerte'
    case ErrorLevelEnum.CRITICAL:
      return 'Critique'
    default:
      return 'Inconnue'
  }
}
