import DeleteIcon from '@mui/icons-material/Delete'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import { Grid, IconButton, Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import BackButton from '../../../components/buttons/back-button/back-button'
import { adminPagesUrl, pagesUrl } from '../../../core/appConstants'
import { ProjectContext } from '../../../core/context/project/project-context'
import { ProjectTypeContext } from '../../../core/context/project/project-type-context'
import { ProjectRoleContext, RoleStore } from '../../../core/context/user/project-role-context'
import { UserContext } from '../../../core/context/user/user-context'
import { ProjectTypeEnum } from '../../../core/enum/project/projectTypeEnum'
import { getLabelText, RoleEnum } from '../../../core/enum/roleEnum'
import { OrganizationRoleEnum } from '../../../core/enum/user/organization-role-enum'
import { resolveUrl } from '../../../core/services/http-service'
import ProjectFormDialog from './components/project-form-dialog'
import DeleteProject from './delete-project'

type ControlBoardHeaderHomeProps = {
  projectName: string
}
export default function ControlBoardHeaderHome({ projectName }: ControlBoardHeaderHomeProps): React.JSX.Element {
  const { currentProjectType } = useContext(ProjectTypeContext)
  const { user } = useContext(UserContext)
  const { organizationId } = useParams()
  const { role, hasRole } = useContext<RoleStore>(ProjectRoleContext)
  const { project } = useContext(ProjectContext)

  const [openFormDialog, setOpenFormDialog] = useState<boolean>(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)

  const toolTipText = "La suppression du projet n'est possible qu'à l'état Archivé"

  function handleEditFormDialog(): void {
    setOpenFormDialog(true)
  }

  function handleEditDeleteDialog(): void {
    setOpenDeleteDialog(true)
  }

  function getRoles(): string {
    let toReturn = ''
    let i = 0
    for (i = 0; i < role.length; i += 1) {
      toReturn += `${getLabelText(role[i])}`
      if (role.length > i + 1) {
        toReturn += ' - '
      }
    }
    return toReturn
  }

  function getNavigateBack(): string {
    if (user?.role === OrganizationRoleEnum.ULTIMATE_USER) {
      return resolveUrl(adminPagesUrl.ADMIN_USER_ORGANIZATION_PAGE, [organizationId], { type: ProjectTypeEnum.RSEE })
    } else if (currentProjectType) {
      return resolveUrl(pagesUrl.PROJECTS_PAGE, [], { type: currentProjectType })
    } else {
      return resolveUrl(pagesUrl.PROJECTS_PAGE, [], { type: ProjectTypeEnum.RSEE })
    }
  }

  return (
    <>
      <Box display='flex' justifyContent='space-between' flex={1} sx={{ mt: 5, mb: 5 }}>
        <Grid container columnSpacing={2} sx={{ alignItems: 'center' }}>
          <Grid
            item
            xs={9.5}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}>
            <Grid
              item
              xs={1}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
              {hasRole([RoleEnum.ADMINISTRATOR]) && project?.id && (
                <IconButton onClick={handleEditFormDialog}>
                  <ModeEditIcon />
                </IconButton>
              )}
              {hasRole([RoleEnum.ADMINISTRATOR]) && project?.id && (
                <Tooltip title={toolTipText}>
                  <span>
                    <IconButton onClick={handleEditDeleteDialog}>
                      <DeleteIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            </Grid>
            <Grid
              item
              xs={11}
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}>
              <Typography variant='h1' sx={{ fontSize: 30, fontWeight: 700 }}>
                Tableau de pilotage
              </Typography>
              <Typography
                className='notranslate'
                sx={{ textTransform: 'uppercase', fontSize: 16, fontWeight: 'bold', color: '#4d4d4d' }}>
                {getRoles()}
              </Typography>
              <Typography
                className='notranslate'
                sx={{ textTransform: 'uppercase', fontSize: 14, fontWeight: 'bold', color: '#4d4d4d' }}>
                {projectName}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={2.5}
            display='flex'
            textAlign='center'
            flexDirection='row'
            justifyContent='flex-end'
            alignItems='center'>
            <Grid container columnSpacing={1} rowSpacing={2}>
              <Grid item xs={6} />
              <Grid item xs={6} display='flex' justifyContent='flex-end'>
                <BackButton path={getNavigateBack()} fullwidth={false} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <ProjectFormDialog open={openFormDialog} setOpen={setOpenFormDialog} />
      <DeleteProject openDeleteDialog={openDeleteDialog} setOpenDeleteDialog={setOpenDeleteDialog} />
    </>
  )
}
