import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react'
import { Children } from '../../../components/miscellianous/children'
import { useCode } from '../../hooks/use-code'
import { ProjectContext } from '../project/project-context'
import { CodeCustomPerParentCode } from '../../data-structures/code-custom-per-parent-code'

export const CodeCustomContext = React.createContext<CodeCustomStore>({} as CodeCustomStore)

export default function CodeCustomContextProvider({ children }: Children): React.JSX.Element {
  const { project } = useContext(ProjectContext)
  const { fetchCodeAcvCustomMap } = useCode()
  const [codeCustomPerParentCode, setCodeCustomPerParentCode] = useState<CodeCustomPerParentCode>({})

  useEffect(() => {
    if (project?.id) {
      fetchCodeAcvCustomMap(project.id).then((res: CodeCustomPerParentCode) => {
        setCodeCustomPerParentCode(res)
      })
    }
  }, [fetchCodeAcvCustomMap, project?.id])

  const codeCustomStore = useMemo(
    () => new CodeCustomStore(codeCustomPerParentCode, setCodeCustomPerParentCode),
    [codeCustomPerParentCode]
  )
  return <CodeCustomContext.Provider value={codeCustomStore}>{children} </CodeCustomContext.Provider>
}

export class CodeCustomStore {
  codeCustomPerParentCode: CodeCustomPerParentCode

  setCodeCustomPerParentCode: Dispatch<SetStateAction<CodeCustomPerParentCode>>

  constructor(
    codeCustomPerParentCode: CodeCustomPerParentCode,
    setCodeCustomPerParentCode: Dispatch<SetStateAction<CodeCustomPerParentCode>>
  ) {
    this.codeCustomPerParentCode = codeCustomPerParentCode
    this.setCodeCustomPerParentCode = setCodeCustomPerParentCode
  }
}
