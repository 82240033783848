interface IInjectGoogleReCaptchaScriptParams {
  render: string
  onLoadCallbackName: string
  useRecaptchaNet: boolean
  useEnterprise: boolean
  onLoad: () => void
  onError: () => void
  language?: string
  scriptProps?: {
    nonce?: string
    defer?: boolean
    async?: boolean
    appendTo?: 'head' | 'body'
    id?: string
  }
}

/**
 * Function to generate the src for the script tag
 *
 * @param param0
 * @returns
 */
// function generateGoogleRecaptchaSrc({
//   useRecaptchaNet,
//   useEnterprise,
// }: {
//   useRecaptchaNet: boolean
//   useEnterprise: boolean
// }): string {
//   const hostName = useRecaptchaNet ? 'recaptcha.net' : 'google.com'
//   const script = useEnterprise ? 'enterprise.js' : 'api.js'
//
//   return `https://www.${hostName}/recaptcha/${script}`
// }

/**
 * Function to clean the recaptcha_[language] script injected by the recaptcha.js
 */
function cleanGstaticRecaptchaScript(): void {
  const script = document.querySelector('script[src^="https://www.gstatic.com/recaptcha/releases"]')

  if (script) {
    script.remove()
  }
}

/**
 * Function to check if script has already been injected
 *
 * @param scriptId
 * @returns
 */
export function isScriptInjected(scriptId: string): boolean {
  return !!document.querySelector(`#${scriptId}`)
}

/**
 * Function to remove default badge
 *
 * @returns
 */
function removeDefaultBadge(): void {
  const nodeBadge = document.querySelector('.grecaptcha-badge')
  if (nodeBadge && nodeBadge.parentNode) {
    document.body.removeChild(nodeBadge.parentNode)
  }
}

/**
 * Function to clear custom badge
 *
 * @returns
 */
function cleanCustomBadge(customBadge: HTMLElement | null): void {
  if (!customBadge) {
    return
  }

  while (customBadge.lastChild) {
    customBadge.lastChild.remove()
  }
}

/**
 * Function to clean node of badge element
 *
 * @param container
 * @returns
 */
export function cleanBadge(container?: HTMLElement | string): void {
  if (!container) {
    removeDefaultBadge()
    return
  }

  const customBadge = typeof container === 'string' ? document.getElementById(container) : container

  cleanCustomBadge(customBadge)
}

/**
 * Function to clean google recaptcha script
 *
 * @param scriptId
 * @param container
 */
export function cleanGoogleRecaptcha(scriptId: string, container?: HTMLElement | string): void {
  // remove badge
  cleanBadge(container)

  // remove old config from window
  // eslint-disable-next-line no-underscore-dangle
  ;(window as any).___grecaptcha_cfg = undefined

  // remove script
  const script = document.querySelector(`#${scriptId}`)
  if (script) {
    script.remove()
  }

  cleanGstaticRecaptchaScript()
}

/**
 * Function to inject the google recaptcha script
 *
 * @returns
 */
export function injectGoogleReCaptchaScript(siteKey: string, onLoad: () => void): void {
  const scriptId = 'google-recaptcha-v3'

  // Script has already been injected, just call onLoad and does nothing else
  if (isScriptInjected(scriptId)) {
    onLoad()
    return
  }

  const js: HTMLScriptElement = document.createElement('script')
  js.id = scriptId
  js.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}&hl=fr`
  js.defer = false
  js.async = false
  js.onload = onLoad

  /**
   * Append it to the body // head
   */
  const elementToInjectScript: HTMLElement = document.getElementsByTagName('head')[0]

  elementToInjectScript.appendChild(js)
}
