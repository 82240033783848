import { CodeCompletion } from '../dto/code-completion/code-completion'
import { CodeExtrait } from '../dto/code-extrait/code-extrait'
import { Material } from '../dto/material/material'

function codeExtraitToKey(parentCode: CodeExtrait | CodeCompletion): string {
  if (parentCode.id === undefined) {
    return ''
  } else {
    return parentCode.id
  }
}

export class CodesCompletionsPerCodeParent {
  codesCompletions: Map<string, CodeCompletion[]> = new Map()

  static from(codesCompletionList: CodeCompletion[]): CodesCompletionsPerCodeParent {
    const newCodesCompletionsForVariant = new CodesCompletionsPerCodeParent()
    const map = new Map<string, CodeCompletion[]>()

    codesCompletionList.forEach((codeCompletion) => {
      let codesCompletions: CodeCompletion[] | undefined = map.get(codeCompletion.parentCodeExtraitId)
      if (codesCompletions === undefined) {
        codesCompletions = []
        map.set(codeCompletion.parentCodeExtraitId, codesCompletions)
      }
      codeCompletion.material = Material.fromCodeDto(codeCompletion.material)
      codesCompletions.push(codeCompletion)
    })
    newCodesCompletionsForVariant.codesCompletions = map
    return newCodesCompletionsForVariant
  }

  static addCodeCompletion(
    codesCompletionsForVariant: CodesCompletionsPerCodeParent,
    codeCompletion: CodeCompletion
  ): CodesCompletionsPerCodeParent {
    const newCodesCompletionsForVariant = codesCompletionsForVariant.shallowClone()

    newCodesCompletionsForVariant.add(codeCompletion)

    return newCodesCompletionsForVariant
  }

  static setCodeCompletionList(
    codesCompletionsForVariant: CodesCompletionsPerCodeParent,
    parentCode: CodeExtrait | CodeCompletion | undefined,
    codeCompletionList: CodeCompletion[] | undefined
  ): CodesCompletionsPerCodeParent {
    if (parentCode && codeCompletionList) {
      const newCodesCompletionsForVariant = codesCompletionsForVariant.shallowClone()

      newCodesCompletionsForVariant.setList(parentCode, codeCompletionList)

      return newCodesCompletionsForVariant
    } else {
      return codesCompletionsForVariant
    }
  }

  static removeCodeCompletion(
    codesCompletionsForVariant: CodesCompletionsPerCodeParent,
    codeCompletion: CodeCompletion
  ): CodesCompletionsPerCodeParent {
    const newCodesCompletionsForVariant = codesCompletionsForVariant.shallowClone()

    newCodesCompletionsForVariant.remove(codeCompletion)

    return newCodesCompletionsForVariant
  }

  getForCodeParent(codeExtrait: CodeExtrait | CodeCompletion | undefined): CodeCompletion[] | undefined {
    if (codeExtrait === undefined) {
      return undefined
    }
    const key = codeExtraitToKey(codeExtrait)
    return this.getForKey(key)
  }

  getForKey(key: string): CodeCompletion[] {
    let codesCompletions = this.codesCompletions.get(key)
    if (codesCompletions === undefined) {
      codesCompletions = []
    }
    return codesCompletions
  }

  containCodeCompletion(code: string, codeExtrait: CodeExtrait): boolean {
    const codesCompletions: CodeCompletion[] | undefined = this.getForCodeParent(codeExtrait)
    if (codesCompletions === undefined) {
      return false
    }
    const index = codesCompletions.findIndex((codeCompletion) => codeCompletion.code === code)
    return index !== -1
  }

  getForCodeAndParent(code: string, parent: CodeExtrait): CodeCompletion | undefined {
    const key = codeExtraitToKey(parent)
    return this.getForCodeAndKey(code, key)
  }

  getForCodeAndKey(code: string, key: string): CodeCompletion | undefined {
    const codesCompletions: CodeCompletion[] = this.getForKey(key)
    const index = codesCompletions.findIndex((codeCompletion) => codeCompletion.code === code)
    return index === -1 ? undefined : codesCompletions[index]
  }

  shallowClone(): CodesCompletionsPerCodeParent {
    const newCodesCompletionsForVariant: CodesCompletionsPerCodeParent = new CodesCompletionsPerCodeParent()
    newCodesCompletionsForVariant.codesCompletions = this.codesCompletions
    return newCodesCompletionsForVariant
  }

  asList(): CodeCompletion[] {
    const codesCompletionsList: CodeCompletion[] = []
    for (const codesCompletions of this.codesCompletions.values()) {
      codesCompletionsList.push(...codesCompletions)
    }
    return codesCompletionsList
  }

  replace(codeCompletion: CodeCompletion): void {
    let brothers: CodeCompletion[] | undefined = this.codesCompletions.get(codeCompletion.parentCodeExtraitId)
    if (brothers === undefined) {
      brothers = []
      this.codesCompletions.set(codeCompletion.parentCodeExtraitId, brothers)
    }
    brothers.push(codeCompletion)
  }

  removeFromKeyAndCode(parentKey: string, code: string): void {
    const brothers: CodeCompletion[] | undefined = this.codesCompletions.get(parentKey)
    if (brothers === undefined) {
      return
    }
    const index = brothers.findIndex((codeCompletion) => codeCompletion.code === code)
    if (index !== -1) {
      brothers.splice(index, 1)
    }
  }

  // Use the static method to update state
  public setList(codeParent: CodeExtrait | CodeCompletion | undefined, newList: CodeCompletion[]): void {
    if (codeParent !== undefined) {
      this.codesCompletions.set(codeExtraitToKey(codeParent), newList)
    }
  }

  // Use the static method to update state
  private add(codeCompletion: CodeCompletion): void {
    let brothers: CodeCompletion[] | undefined = this.codesCompletions.get(codeCompletion.parentCodeExtraitId)
    if (brothers === undefined) {
      brothers = []
      this.codesCompletions.set(codeCompletion.parentCodeExtraitId, brothers)
    }
    brothers.push(codeCompletion)
  }

  // Use the static method to update state
  private remove(codeToDelete: CodeCompletion): void {
    this.removeFromKeyAndCode(codeToDelete.parentCodeExtraitId, codeToDelete.code)
  }
}
