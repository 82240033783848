import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined'
import { Box, Grid, Toolbar } from '@mui/material'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { theme } from '../../../theme'
import { BSProjectContext } from '../../core/context/beem-shot/BSProject/BSProjectContext'
import { AppNavBarContext } from '../../core/context/nav-bar/AppNavBarContext'
import './app-navbar-v2.scss'

export function HeaderWithoutStep(): React.JSX.Element {
  const { previousUrl } = useContext(AppNavBarContext)
  const { bsProject } = useContext(BSProjectContext)

  const navigate = useNavigate()

  return (
    <Toolbar
      disableGutters
      sx={{
        color: 'white',
        borderRadius: 5,
        backgroundColor: '#070F27',
        zIndex: -5000,
        ...theme.mixins.toolbar,
      }}>
      <Grid container display='flex' justifyContent='space-between' alignContent='center'>
        <Grid container item xs={2} display='flex' justifyContent='space-around' alignContent='center' alignItems='center'>
          <ArrowBackOutlinedIcon onClick={() => navigate(previousUrl)} fontSize='medium' sx={{ cursor: 'pointer' }} />
          <Box display='flex' alignItems='center' sx={{ borderRadius: 2, background: '#b7ffe3', p: 1, m: 1 }}>
            <DonutSmallOutlinedIcon fontSize='large' sx={{ color: '#0BE2A0', fontWeight: '2px' }} />
          </Box>
          <Box>
            <Box>{bsProject?.name}</Box>
            <Box> BEEM SHOT</Box>
          </Box>
        </Grid>

        <Grid item xs={10} display='flex' justifyContent='center' />
      </Grid>
    </Toolbar>
  )
}
