import { useContext, useEffect } from 'react'
import { appConstants } from '../../appConstants'
import { MaterialLibDeclarationTypeContext } from '../../context/material/material-lib-declaration-type-context'
import { useHttp } from '../use-http'

export type DeclarationTypeHook = {
  loading: boolean
  declarationTypes: string[]
}

export function useFetchMaterialLibDeclarationType(): DeclarationTypeHook {
  const { get } = useHttp()
  const { declarationTypes, setDeclarationTypes, loading, setLoading } = useContext(MaterialLibDeclarationTypeContext)

  useEffect(() => {
    setLoading(true)
    get(appConstants.apiEndpoints.INIES_DECLARATION_TYPE, [])
      .then((response) => response.json())
      .then((response) => {
        setDeclarationTypes(response)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [get, setDeclarationTypes, setLoading])

  return {
    loading,
    declarationTypes,
  }
}
