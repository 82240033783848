import React, { useContext, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import UserFormComponent from '../../../components/forms/user-invitation-form/user-form-component'
import { SelectOption } from '../../../components/inputs/select-input/select-input'
import { SuccessContext } from '../../../components/layout/success-snackbar'
import { adminPagesUrl } from '../../../core/appConstants'
import { AdminOrganizationContext } from '../../../core/context/organization/admin-organization-context'
import { OrganizationsListContext } from '../../../core/context/organization/organization-list-context'
import { UserForm } from '../../../core/dto/user/user-form'
import { OrganizationRoleEnum } from '../../../core/enum/user/organization-role-enum'
import { useUser } from '../../../core/hooks/use-user'
import { resolveUrl } from '../../../core/services/http-service'

const adminMegaUserRoleOption: SelectOption<OrganizationRoleEnum>[] = [
  {
    value: OrganizationRoleEnum.MEGA_USER,
    label: 'Mega user',
  },
]

const adminRoleSelectOptions: SelectOption<OrganizationRoleEnum>[] = [
  {
    value: OrganizationRoleEnum.MEGA_USER,
    label: 'Mega user',
  },
  {
    value: OrganizationRoleEnum.SUPER_USER,
    label: 'Super user',
  },
  {
    value: OrganizationRoleEnum.NORMAL_USER,
    label: 'Normal user',
  },
]

export default function AdminInviteUserForm(): React.JSX.Element {
  const navigate = useNavigate()
  const { organizationId } = useParams()
  const openSuccessSnackbar = useContext(SuccessContext)
  const { refreshOrganizationList } = useContext(OrganizationsListContext)
  const { organization } = useContext(AdminOrganizationContext)
  const { sendAdminInvitation } = useUser()
  const isFirstMegaUser = useMemo(() => organizationId === 'new', [organizationId])

  function sendInvitationFromAdminUser(userInvitationDto: UserForm): Promise<void> {
    if (organization?.id) {
      return sendAdminInvitation(organization.id, userInvitationDto)
        .then(() => refreshOrganizationList())
        .then(() => {
          openSuccessSnackbar("Invitation envoyée à l'utilisateur")
          navigate(resolveUrl(adminPagesUrl.ADMIN_ORGANIZATION_PAGE, [organization?.id]))
        })
    } else {
      return Promise.resolve()
    }
  }

  return (
    <UserFormComponent
      onSubmit={sendInvitationFromAdminUser}
      selectOptions={isFirstMegaUser ? adminMegaUserRoleOption : adminRoleSelectOptions}
      defaultRole={OrganizationRoleEnum.MEGA_USER}
      isFirstMegaUser={isFirstMegaUser}
    />
  )
}
