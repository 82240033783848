import { Box, Button, Checkbox, FormControlLabel, Grid } from '@mui/material'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { adminPagesUrl, pagesUrl } from '../../../../core/appConstants'
import { CalculationContext } from '../../../../core/context/calculation/calculation-context'
import { ProjectContext, ProjectStore } from '../../../../core/context/project/project-context'
import { ProjectRoleContext } from '../../../../core/context/user/project-role-context'
import { VariantContext } from '../../../../core/context/variant-context'
import FlatRates from '../../../../core/dto/calculation/flat-rates'
import { CalculationStepsEnum } from '../../../../core/enum/calculationStepsEnum'
import { RoleEnum } from '../../../../core/enum/roleEnum'
import { useUpdateCalculateCarbonFlateRate } from '../../../../core/hooks/calcul-carbon/use-calcul-carbon-flate-rate-data-mutation'
import { useCalculation } from '../../../../core/hooks/use-calculation'
import { resolveUrl } from '../../../../core/services/http-service'
import ProjectCarbonBreadcrumb from '../components/project-carbon-breadcrumb'
import ProjectCarbonCalculatorHeader from '../components/project-carbon-calculator-header'
import ListRateDataForm from './list-rate-data-form'
import SelectVariant from './select-variant'

export default function FlateRatesPage(): React.JSX.Element {
  const navigate = useNavigate()

  const { calculation, setCalculation } = useContext(CalculationContext)
  const { variant1, variant2, refreshVariant } = useContext(VariantContext)
  const { project } = useContext<ProjectStore>(ProjectContext)
  const { hasRole, isOrganizationUltimateUser } = useContext(ProjectRoleContext)

  const { updateSameFlatRatesV1V2 } = useCalculation()

  const { organizationId } = useParams()

  const { flatRates: flatRates1 } = variant1
  const { flatRates: flatRates2 } = variant2

  const { updateCalculateCarbonFlateRate, loading: loadingUpdateCalculCarbon } = useUpdateCalculateCarbonFlateRate()

  const [variantCategory, setVariantCategory] = useState<1 | 2>(1)
  const [rateValueVariant1, setRateValueVariant1] = useState<FlatRates>(new FlatRates())
  const [rateValueVariant2, setRateValueVariant2] = useState<FlatRates>(new FlatRates())
  const isWriting = useMemo(() => hasRole([RoleEnum.ADMINISTRATOR, RoleEnum.BE]), [hasRole])

  useEffect(() => {
    if (calculation.sameFlatRatesV1V2) setRateValueVariant2(rateValueVariant1)
  }, [calculation.sameFlatRatesV1V2, rateValueVariant1])

  useEffect(() => {
    setRateValueVariant1({
      ...rateValueVariant1,
      ...{ ...flatRates1 },
    })
    setRateValueVariant2({
      ...rateValueVariant2,
      ...{ ...flatRates2 },
    })
  }, [flatRates1, flatRates2])

  const handleChangeVariant = useCallback((variant: 1 | 2): void => {
    setVariantCategory(variant)
  }, [])

  const onSubmit = useCallback((): Promise<boolean> => {
    if (!variant1?.id || !variant2?.id) {
      return Promise.resolve(false)
    }

    return updateCalculateCarbonFlateRate(
      {
        variantId: variant1.id,
        ...rateValueVariant1,
      },
      {
        variantId: variant2.id,
        ...rateValueVariant2,
      }
    ).then(() => true)
  }, [updateCalculateCarbonFlateRate, variant1.id, rateValueVariant1, variant2.id, rateValueVariant2, navigate, project?.id])

  function navigateToNextPage(): void {
    if (isWriting) {
      onSubmit().then((success) => {
        if (success) {
          isOrganizationUltimateUser
            ? navigate(resolveUrl(adminPagesUrl.PROJECT_CALCULATION_EXTRACTED_DATA, [organizationId, project?.id]))
            : navigate(resolveUrl(pagesUrl.PROJECT_CALCULATION_EXTRACTED_DATA, [project?.id]))
        }
      })
    } else {
      isOrganizationUltimateUser
        ? navigate(resolveUrl(adminPagesUrl.PROJECT_CALCULATION_EXTRACTED_DATA, [organizationId, project?.id]))
        : navigate(resolveUrl(pagesUrl.PROJECT_CALCULATION_EXTRACTED_DATA, [project?.id]))
    }
  }

  function handleClickPreviousStep(): void {
    isOrganizationUltimateUser
      ? navigate(resolveUrl(adminPagesUrl.PROJECT_CALCULATION_CORE_DATA, [organizationId, project?.id]))
      : navigate(resolveUrl(pagesUrl.PROJECT_CALCULATION_CORE_DATA, [project?.id]))
  }

  function changeRates(fieldName: keyof FlatRates, value: string | boolean, isCheckbox: boolean): void {
    if (variantCategory === 1) {
      setRateValueVariant1({
        ...rateValueVariant1,
        [fieldName]: {
          ...rateValueVariant1[fieldName],
          active: typeof value === 'boolean' && isCheckbox ? value : rateValueVariant1[fieldName].active,
        },
      })
    } else {
      setRateValueVariant2({
        ...rateValueVariant2,
        [fieldName]: {
          ...rateValueVariant2[fieldName],
          active: typeof value === 'boolean' && isCheckbox ? value : rateValueVariant2[fieldName].active,
        },
      })
    }
  }

  function onUserChangeRates(fieldName: keyof FlatRates, value: string | boolean, isCheckbox: boolean): void {
    if (variantCategory === 2 && calculation.sameFlatRatesV1V2) {
      setCalculation({ ...calculation, sameFlatRatesV1V2: false })
      if (calculation.id) {
        updateSameFlatRatesV1V2(calculation.id, false)
      }
    }
    changeRates(fieldName, value, isCheckbox)
  }

  const onCheckBoxChange = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    setCalculation({ ...calculation, sameFlatRatesV1V2: checked })
    if (calculation.id) {
      updateSameFlatRatesV1V2(calculation.id, checked)
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={3}>
          <ProjectCarbonCalculatorHeader />
        </Grid>
        <Grid item xs={7}>
          <ProjectCarbonBreadcrumb selected={CalculationStepsEnum.FLAT_RATES} submit={onSubmit} isWriting={isWriting} />
        </Grid>
        <Grid item xs={2}>
          <Box
            sx={{
              width: 200,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <Button variant='outlined' onClick={handleClickPreviousStep}>
              Retour
            </Button>
            <Button variant='contained' onClick={navigateToNextPage} disabled={loadingUpdateCalculCarbon}>
              Suivant
            </Button>
          </Box>
        </Grid>
      </Grid>
      <SelectVariant selectedVariant={variantCategory} handleChangeVariant={handleChangeVariant} />
      <ListRateDataForm
        usage={calculation.usage}
        onChange={changeRates}
        onUserChange={onUserChangeRates}
        rate={variantCategory === 1 ? rateValueVariant1 : rateValueVariant2}
        variant={variantCategory}
        disabled={!isWriting}
      />
      {variantCategory === 2 && (
        <Box display='flex' justifyContent='center'>
          <FormControlLabel
            value='end'
            control={
              <Checkbox
                checked={calculation.sameFlatRatesV1V2 === undefined ? false : calculation.sameFlatRatesV1V2}
                onChange={onCheckBoxChange}
              />
            }
            label='Utiliser les valeurs de la BASE'
            labelPlacement='end'
            disabled={!isWriting}
          />
        </Box>
      )}
    </>
  )
}
