import { TableCell, TableRow, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { customPalette } from '../../../../../../../../theme'
import IniesRecord from '../../../../../../../core/dto/material/inies-record'
import { originMaterialRecordToLabel } from '../../../../../../../core/dto/material/material'
import MaterialRecord from '../../../../../../../core/dto/material/material-record'
import { DeclarationTypeEnum } from '../../../../../../../core/enum/declarationTypeEnum'
import { getDeclarationTypeLabel } from '../../../../../../../core/services/declaration-type-service'

type MaterialRowProps = {
  record: IniesRecord | MaterialRecord
  onSelected: (row: IniesRecord | MaterialRecord | undefined) => void
  isSelected?: boolean
  getTypologyBackground: (typologie: DeclarationTypeEnum) => string
  tab: 1 | 2
}

export default function MaterialRow({
  record,
  onSelected,
  isSelected,
  getTypologyBackground,
  tab,
}: MaterialRowProps): React.JSX.Element {
  const materialLib = useMemo(() => (tab === 1 ? (record as MaterialRecord) : undefined), [record, tab])
  const inies = useMemo(() => (tab === 2 ? (record as IniesRecord) : undefined), [record, tab])
  return (
    <TableRow
      onClick={() => {
        onSelected(record)
      }}
      sx={
        isSelected
          ? { background: '#f6f6f6' }
          : {
              cursor: 'pointer',
              ':hover': {
                backgroundColor: '#f6f6f6',
              },
            }
      }>
      <TableCell align='left' component='th' scope='row'>
        <Typography
          component='span'
          sx={{
            backgroundColor: getTypologyBackground(record.declarationType),
            color: customPalette.default,
            padding: '8px',
            borderRadius: '28px',
            fontSize: 12,
            whiteSpace: 'nowrap',
          }}>
          {getDeclarationTypeLabel(record.declarationType)}
        </Typography>
      </TableCell>
      <TableCell align='left'>
        {record.fdesName}{' '}
        {record instanceof MaterialRecord && (
          <Typography
            component='span'
            sx={{
              fontWeight: 'bold',

              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: record instanceof MaterialRecord ? originMaterialRecordToLabel(record).color : '',
            }}>
            {` (${record instanceof MaterialRecord ? originMaterialRecordToLabel(record).text : ''})`}
          </Typography>
        )}
      </TableCell>
      <TableCell align='left'>
        <Typography>{materialLib?.responsibleOrganism || inies?.responsibleOrganism || ''}</Typography>
      </TableCell>
    </TableRow>
  )
}
