export enum FloorTypeEnum {
  ACIER_BETON = 'ACIER_BETON',
  BETON = 'BETON',
  BOIS_BETON = 'BOIS_BETON',
  BOIS = 'BOIS',
  BOIS_CLT = 'BOIS_CLT',
}

export const floorTypeEnumLabels = {
  [FloorTypeEnum.ACIER_BETON]: 'Acier-béton',
  [FloorTypeEnum.BETON]: 'Béton',
  [FloorTypeEnum.BOIS_BETON]: 'Bois-béton',
  [FloorTypeEnum.BOIS]: 'Bois',
  [FloorTypeEnum.BOIS_CLT]: 'Bois CLT',
}
