import CodeReference from '../dto/code-acv/code-reference'
import CodeCustom from '../dto/code-acv-custom/code-custom'
import { CodeExtrait } from '../dto/code-extrait/code-extrait'
import { CodeCompletion } from '../dto/code-completion/code-completion'
import { CodeCustomPerParentCode } from '../data-structures/code-custom-per-parent-code'
import { CodeExtraitDisplay } from '../dto/code-extrait/CodeExtraitDisplay'

export function fromCodeReferenceListToMap(list: CodeReference[]): Map<string, CodeReference> {
  const hashMap = new Map<string, CodeReference>()

  list.forEach((item: CodeReference) => {
    hashMap.set(item.code, item)
  })

  return hashMap
}

export function fromCompletionToMap(codeCompletionList: CodeReference[]): Map<string, CodeReference[]> {
  const codeCompletionMap = new Map<string, CodeReference[]>()

  codeCompletionList.forEach((codeCompletionReference: CodeReference) => {
    codeCompletionReference.parentCodes.forEach((parentCode) => {
      const codeChild: CodeReference[] | undefined = codeCompletionMap.get(parentCode)
      if (codeChild === undefined) {
        codeCompletionMap.set(parentCode, [codeCompletionReference])
      } else {
        codeChild.push(codeCompletionReference)
      }
    })
  })

  return codeCompletionMap
}

export function fromCodeCustomToMap(codeCompletionList: CodeCustom[]): CodeCustomPerParentCode {
  const codeCompletionMap: CodeCustomPerParentCode = {}

  codeCompletionList.forEach((codeCustom: CodeCustom) => {
    const codeChild: CodeCustom[] | undefined = codeCompletionMap[codeCustom.parentCode]
    if (codeChild === undefined) {
      codeCompletionMap[codeCustom.parentCode] = [codeCustom]
    } else {
      codeChild.push(codeCustom)
    }
  })

  return codeCompletionMap
}

export function sortCodeAcv(
  codeAcv1: CodeReference | CodeExtrait | CodeCompletion,
  codeAcv2: CodeReference | CodeExtrait | CodeCompletion
): -1 | 0 | 1 {
  if (codeAcv1.order < codeAcv2.order) {
    return -1
  } else if (codeAcv1.order > codeAcv2.order) {
    return 1
  } else {
    return 0
  }
}

export function sortCodeExtrait(codeExtrait1: CodeExtrait, codeExtrait2: CodeExtrait): -1 | 0 | 1 {
  if (codeExtrait1.code === codeExtrait2.code) {
    if (codeExtrait1.occurence < codeExtrait2.occurence) {
      return -1
    } else if (codeExtrait1.occurence > codeExtrait2.occurence) {
      return 1
    } else {
      return 0
    }
  } else {
    return sortCodeAcv(codeExtrait1, codeExtrait2)
  }
}

export function groupBy<T extends Record<string, any>>(list: T[], k: keyof T): Record<string, T[]> {
  return list.reduce((res: Record<string, T[]>, item) => {
    if (k in item) {
      return {
        ...res,
        [item[k]]: res[item[k]] ? [...res[item[k]], item] : [item],
      }
    }
    return {}
  }, {})
}

export function isExtrait(code: CodeExtrait | CodeCompletion | undefined): boolean {
  return !!code && 'extractedQuantities' in code
}

export function isCompletion(code: CodeExtrait | CodeCompletion | undefined): boolean {
  return !!code && 'parentCodeExtraitId' in code
}

export function codeToKey(code: CodeExtrait | CodeExtraitDisplay | undefined): string {
  if (!code) {
    return ''
  }
  return `${code.code + code.occurence}`
}
