import { useMemo } from 'react'
import { appConstants } from '../../appConstants'
import { BSProject } from '../../dto/beem-shot/BSProject/BSProject'
import { BSProjectCardDto } from '../../dto/beem-shot/BSProject/BSProjectCardDto'
import { BSProjectCreationDto } from '../../dto/beem-shot/BSProject/BSProjectCreationDto'
import { BSProjectUpdateDto } from '../../dto/beem-shot/BSProject/BSProjectUpdateDto'
import { BSVariant } from '../../dto/beem-shot/BSVariant/BSVariant'
import { CalculStatusEnum } from '../../enum/calculStatusEnum'
import { streamToBlob } from '../../services/file-service'
import { resolveUrl } from '../../services/http-service'
import { useHttp } from '../use-http'

type BsProjectHook = {
  getBSProjects(limit?: number): Promise<BSProjectCardDto[]>
  createBSProject(beemShotProjectCreationDto: BSProjectCreationDto): Promise<BSProject>
  getBSProject(beemShotProjectId: string): Promise<BSProject | undefined>
  updateBSProject(beemShotProjectUpdateDto: BSProjectUpdateDto): Promise<BSProject>
  updateBSCalculStatus(bsVariantId: string, status: CalculStatusEnum): Promise<BSVariant>
  getStartEstimating(bsVariantId: string): Promise<Response>
  sendCoverImageFile(coverImageFile: File, bsProjectId: string): Promise<Response>
  fetchCoverImage(bsProjectId: string): Promise<Blob | undefined>
  deleteCoverImage(bsProjectId: string): Promise<Response>
  listenIsCalculationReady(bsVariant: BSVariant): Promise<boolean>
}

export function useBSProject(): BsProjectHook {
  const { get, put, post, postFile, deleteRequest } = useHttp()

  return useMemo(
    () => ({
      getBSProjects(limit = 25): Promise<BSProjectCardDto[]> {
        return get(resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_PROJECTS, [], { limit })).then((response) =>
          response.json()
        )
      },
      createBSProject(bsProjectCreationDto: BSProjectCreationDto): Promise<BSProject> {
        return post(appConstants.apiEndpoints.BEEM_SHOT_PROJECT, bsProjectCreationDto)
          .then((response) => response.json())
          .then((data) => BSProject.fromDto(data))
      },
      getBSProject(bsProjectId: string): Promise<BSProject | undefined> {
        return get(resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_PROJECT_ID, [bsProjectId])).then((response) => {
          if (response.status === 404) {
            return Promise.resolve(undefined)
          } else {
            return response.json().then((data) => BSProject.fromDto(data))
          }
        })
      },
      updateBSProject(beemShotProjectUpdateDto: BSProjectUpdateDto): Promise<BSProject> {
        return put(appConstants.apiEndpoints.BEEM_SHOT_PROJECT, beemShotProjectUpdateDto)
          .then((response) => response.json())
          .then((data) => BSProject.fromDto(data))
      },
      updateBSCalculStatus(bsVariantId: string, status: CalculStatusEnum): Promise<BSVariant> {
        return put(resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_UPDATE_CALCUL_STATUS_ENUM, [bsVariantId]), {}, [
          {
            key: 'status',
            value: status,
          },
        ]).then((response) => response.json())
      },
      getStartEstimating(bsVariantId: string): Promise<Response> {
        return get(resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_PROJECT_ID_START_ESTIMATE, [bsVariantId]))
      },
      sendCoverImageFile(coverImageFile: File, bsProjectId: string): Promise<Response> {
        const formData = new FormData()
        formData.append('coverImageFile', coverImageFile)
        formData.append('bsProjectId', bsProjectId)
        return postFile(`${appConstants.apiEndpoints.BEEM_SHOT_COVER_IMAGE}`, formData)
      },
      fetchCoverImage(bsProjectId: string): Promise<Blob | undefined> {
        return get(resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_COVER_IMAGE, []), [
          {
            key: 'bsProjectId',
            value: bsProjectId,
          },
        ])
          .then((res) => streamToBlob(res))
          .catch(() => undefined)
      },
      deleteCoverImage(bsProjectId: string): Promise<Response> {
        return deleteRequest(`${appConstants.apiEndpoints.BEEM_SHOT_COVER_IMAGE}`, [
          {
            key: 'projectId',
            value: bsProjectId,
          },
        ])
      },
      listenIsCalculationReady(bsVariant: BSVariant): Promise<boolean> {
        return get(`${appConstants.apiEndpoints.BEEM_SHOT_CALCULATION_READY}`, [
          {
            key: 'bsVariantId',
            value: bsVariant.id ?? '',
          },
        ])
          .then((response) => {
            if (response.status !== 200) {
              throw new Error(`Error: http status code ${response.status}`)
            }
            return response.text()
          })
          .then((responseText) => responseText !== 'false')
      },
    }),
    [get, put, post, postFile, deleteRequest]
  )
}
