import { Vector3 } from 'three'
import { CameraProjections, IfcViewerAPI } from 'web-ifc-viewer'
import { CameraOrientations } from '../enums/CameraOrientation'

export class CameraManager {
  viewer: IfcViewerAPI
  container: HTMLElement | null

  constructor(ifcViewer: IfcViewerAPI, container: HTMLElement | null) {
    this.viewer = ifcViewer
    this.container = container
  }

  /**
   * Resize the viewer when the window is resized, this is needed to have a good experience when using the viewer
   * Note: This is the right way to handle full-screen mode with three.js, but what happend before was that the developer reloaded
   * the entire model and reinilalized the viewer which costed model reload time and loss of current scene and was not a good solution.
   * - M.T
   * @param  {number} width  The new width of the viewer
   * @param  {number} height The new height of the viewer
   * @returns void
   */
  resizeViewer(width?: number, height?: number): void {
    if (this.container === null) return
    let w: number | undefined = width
    let h: number | undefined = height
    if (w === undefined) w = this.container?.clientWidth ?? 0
    if (h === undefined) h = this.container?.clientHeight ?? 0
    const renderer = this.viewer.context.getRenderer()
    const camera = this.viewer.context.getIfcCamera().cameraControls.camera
    camera.updateProjectionMatrix()
    const scene = this.viewer.context.getScene()
    renderer.setSize(w, h)
    scene.dispatchEvent({ type: 'resize' })
    renderer.render(scene, camera)
    const resizeEvent = new Event('resize')
    window.dispatchEvent(resizeEvent)
  }

  /**
   * Zoom in the viewer
   */
  zoomIn(): void {
    this.viewer.context.ifcCamera.cameraControls.zoom(this.viewer.context.ifcCamera.cameraControls.minZoom * 10, true)
  }

  /**
   * Zoom out the viewer
   */
  zoomOut(): void {
    this.viewer.context.ifcCamera.cameraControls.zoom(-(this.viewer.context.ifcCamera.cameraControls.minZoom * 10), true)
  }

  /**
   * focus the viewer in its original state
   */
  async focus(): Promise<void> {
    const ifcCamera = this.viewer.context.getIfcCamera()
    const projection = ifcCamera.projection
    ifcCamera.cameraControls.reset(true)
    this.changeCameraProjection(projection)
  }

  /**
   * Switch the camera projection to perspective
   */
  makePerspective(): void {
    this.changeCameraProjection(CameraProjections.Perspective)
  }

  /**
   * Switch the camera projection to isometric
   */
  makeIsometric(): void {
    this.changeCameraProjection(CameraProjections.Orthographic)
  }

  /**
   * Change the camera projection
   * @param  {CameraProjections} projection The new projection
   */
  changeCameraProjection(projection: CameraProjections): void {
    const ifcCamera = this.viewer.context.getIfcCamera()
    const cameraPos: Vector3 = new Vector3(0, 0, 0)
    ifcCamera.cameraControls.getPosition(cameraPos)
    const currentProjection = ifcCamera.projection
    if (currentProjection !== null && currentProjection !== projection) ifcCamera.toggleProjection()
  }

  /**
   * Change the camera orientation like front, back, top, bottom, left, right, etc.
   * @param  {CameraOrientation} orientation The new orientation
   */
  async changeCameraOrientation(orientation: CameraOrientations): Promise<void> {
    const cameraPos = new Vector3(0, 0, 0)
    const camera = this.viewer.context.getIfcCamera()
    camera.cameraControls.getPosition(cameraPos)
    this.changeCameraProjection(CameraProjections.Orthographic)
    switch (orientation) {
      case CameraOrientations.FRONT:
        await camera.cameraControls.setLookAt(cameraPos.x, cameraPos.y, cameraPos.z, 0, 0, 0, false)
        await camera.cameraControls.rotateTo(0, Math.PI / 2, true)
        break
      case CameraOrientations.BACK:
        await camera.cameraControls.setLookAt(cameraPos.x, cameraPos.y, cameraPos.z, 0, 0, 0, false)
        await camera.cameraControls.rotateTo(Math.PI, Math.PI / 2, true)
        break
      case CameraOrientations.LEFT:
        await camera.cameraControls.setLookAt(cameraPos.x, cameraPos.y, cameraPos.z, 0, 0, 0, false)
        await camera.cameraControls.rotateTo(-Math.PI / 2, Math.PI / 2, true)
        break
      case CameraOrientations.RIGHT:
        await camera.cameraControls.setLookAt(cameraPos.x, cameraPos.y, cameraPos.z, 0, 0, 0, false)
        await camera.cameraControls.rotateTo(Math.PI / 2, Math.PI / 2, true)
        break
      case CameraOrientations.TOP:
        await camera.cameraControls.rotateTo(0, 0, true)
        break
      case CameraOrientations.BOTTOM:
        await camera.cameraControls.rotateTo(0, Math.PI, true)
        break
      default:
        break
    }
  }
}
