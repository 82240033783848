import { FlatRate } from './flat-rate'

export default class FlatRates {
  lot8Dot1Production: FlatRate = new FlatRate(false, 170)
  lot8Dot3Emission: FlatRate = new FlatRate(true, 0)
  lot8Dot4AirTreatment: FlatRate = new FlatRate(true, 0)
  lot8Dot5PipeNetwork: FlatRate = new FlatRate(true, 0)
  lot10EnergyNetwork: FlatRate = new FlatRate(false, 268)
  lot11CommunicationNetwork: FlatRate = new FlatRate(false, 2)
}
