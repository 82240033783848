import { Visibility, VisibilityOff } from '@mui/icons-material'
import { FormControl, FormHelperText, IconButton, InputAdornment, TextField } from '@mui/material'
import React, { useState } from 'react'

type iProps = {
  err: any | undefined
  value: string
  handleChange(event: React.ChangeEvent<HTMLInputElement>): void
  variant: 'standard' | 'filled' | 'outlined' | undefined
  label: string
}

export default function PasswordInput({ err, value, handleChange, variant, label }: iProps): React.JSX.Element {
  const [showPassword, setShowPassword] = useState(false)
  return (
    <FormControl error={err} fullWidth>
      <TextField
        id='password'
        label={label}
        margin='normal'
        type={showPassword ? 'text' : 'password'}
        value={value}
        onChange={handleChange}
        variant={variant}
        error={err}
        aria-describedby='password-helper'
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton aria-label='toggle password visibility' onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <FormHelperText component='span' id='password-helper'>
        Le mot de passe doit respecter les règles suivantes:
        <ul>
          <li>Longueur minimum: 8 caractères</li>
          <li>Contient au minimum 1 chiffre</li>
          <li>
            Contient au minimum un caractère spécial (^ $ * . [ ] {} ( ) ? - " ! @ # % & / \ , &lt; &gt; ' : ; | _ ~ ` + =)
          </li>
          <li>Une lettre minuscule</li>
          <li>Une lettre majuscule</li>
        </ul>
      </FormHelperText>
    </FormControl>
  )
}
