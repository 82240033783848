import { useMemo } from 'react'
import { appConstants } from '../../appConstants'
import { Project } from '../../dto/project/project'
import { ProjectCardDto } from '../../dto/project/project-card-dto'
import { ProjectCreationOrUpdateDto } from '../../dto/project/project-creation-or-update-dto'
import { ProjectPhase } from '../../dto/project/project-phase'
import { ShareLink } from '../../dto/rsee/share-link'
import { ShareLinkCreationDto } from '../../dto/rsee/share-link-creation-dto'
import { ShareLinkUpdateDto } from '../../dto/rsee/share-link-update-dto'
import { User } from '../../dto/user/user'
import { ProjectTypeEnum } from '../../enum/project/projectTypeEnum'
import { ProjectStatusEnum } from '../../enum/projectStatusEnum'
import { streamToBlob } from '../../services/file-service'
import { RequestParam, resolveUrl } from '../../services/http-service'
import { useHttp } from '../use-http'
import { DocumentSharedInfo } from '../../dto/rsee/documentSharedInfo'

type ProjectsHook = {
  fetchProjectCards(
    projectType: ProjectTypeEnum,
    projectStatus: ProjectStatusEnum,
    size: number,
    lastProjectName: string,
    lastId: string,
    organizationId: string | undefined,
    isUltimate: boolean
  ): Promise<ProjectCardDto[]>
  fetchProjects(user: User | undefined): Promise<Project[]>
  postPhases(projectId: string, projectPhase: ProjectPhase[]): Promise<void>
  getProject(projectId: string): Promise<Project>
  fetchEarlyProjects(organizationId: string): Promise<Project[]>
  fetchProjectEarlyCollaborators(projectId: string, oganizationId: string): Promise<string[]>
  createProject(project: ProjectCreationOrUpdateDto, isEarlyProject: boolean, organizationId: string): Promise<Project>
  updateProject(projectForm: ProjectCreationOrUpdateDto): Promise<Project>
  updateProjectStatus(projectId: string, status: ProjectStatusEnum): Promise<Project>
  updateEarlyCollabForProject(projectId: string, userIds: string[]): Promise<Response>
  deleteProject(projectId: string): Promise<Response>
  getUserProjectsCount(): Promise<number>
  sendCoverImageFile(coverImageFile: File, projectId: string): Promise<Response>
  fetchCoverImage(projectId: string): Promise<Blob | undefined>
  deleteCoverImage(projectId: string): Promise<Response>
  getSumCarbonImpactWholeOrganization(): Promise<number>
  duplicateTestProject(newAdministratorEmail: string): Promise<Response>
  generateShareLink(documentId: string, shareLinkCreationDto: ShareLinkCreationDto): Promise<ShareLink>
  fetchShareLinkInformation(accessToken: string | undefined): Promise<DocumentSharedInfo>
  fetchAllRseeProjectShareLink(projectId: string): Promise<ShareLink[]>
  fetchAllRseeDocumentShareLink(projectId: string): Promise<ShareLink[]>
  deleteRseeShareToken(tokenId: string): Promise<Response>
  updateShareLink(shareLinkUpdate: ShareLinkUpdateDto): Promise<ShareLink>
}

export function useProjects(): ProjectsHook {
  const { get, put, post, postFile, deleteRequest } = useHttp()

  return useMemo(
    () => ({
      fetchProjectCards(
        projectType: ProjectTypeEnum,
        projectStatus: ProjectStatusEnum,
        limit: number,
        lastProjectName: string,
        lastId: string,
        organizationId: string,
        isUltimate: boolean
      ): Promise<ProjectCardDto[]> {
        if (projectType === ProjectTypeEnum.RSEE) {
          return get(appConstants.apiEndpoints.RSEE_PROJECT_CARDS_LIST, [
            { key: 'limit', value: `${limit}` },
            { key: 'lastProjectName', value: lastProjectName },
            { key: 'lastId', value: `${lastId}` },
            { key: 'status', value: projectStatus },
          ]).then((response) => response.json())
        } else if (projectType === ProjectTypeEnum.BIM_ORGANIZATION) {
          const params = [
            { key: 'limit', value: `${limit}` },
            { key: 'lastProjectName', value: lastProjectName },
            { key: 'lastId', value: `${lastId}` },
            { key: 'status', value: projectStatus },
          ]

          if (isUltimate) {
            params.push({ key: 'organizationId', value: organizationId })
          }

          return get(
            isUltimate
              ? appConstants.apiEndpoints.ADMIN_ORGANIZATION_PROJECT_CARDS_LIST
              : appConstants.apiEndpoints.ORGANIZATION_PROJECT_CARDS_LIST,
            params
          ).then((response) => response.json())
        } else if (projectType === ProjectTypeEnum.RSEE_ORGANIZATION) {
          const params = [
            { key: 'limit', value: `${limit}` },
            { key: 'lastProjectName', value: lastProjectName },
            { key: 'lastId', value: `${lastId}` },
            { key: 'status', value: projectStatus },
          ]

          if (isUltimate) {
            params.push({ key: 'organizationId', value: organizationId })
          }
          return get(
            isUltimate
              ? appConstants.apiEndpoints.ADMIN_RSEE_ORGANIZATION_PROJECT_CARDS_LIST
              : appConstants.apiEndpoints.RSEE_ORGANIZATION_PROJECT_CARDS_LIST,
            params
          ).then((response) => response.json())
        } else {
          // It's a BIM project
          const url = appConstants.apiEndpoints.PROJECTS_LIST_CARD
          return get(url, [
            { key: 'limit', value: `${limit}` },
            { key: 'lastProjectName', value: lastProjectName },
            { key: 'lastId', value: `${lastId}` },
            { key: 'status', value: projectStatus },
          ]).then((response) => response.json())
        }
      },
      fetchProjects(user: User | undefined): Promise<Project[]> {
        if (user) {
          return get(appConstants.apiEndpoints.PROJECTS)
            .then((response) => response.json())
            .then((projectsListData: Project[]) => {
              if (projectsListData !== undefined) {
                // TODO: Par défaut, récupérer le projet du localStorage pour mettre le projet précedement selectionné par défaut
                //  et non pas le premier de la liste
                return projectsListData.map((project: any) => Project.from(project))
              } else {
                return []
              }
            })
        } else {
          return Promise.resolve([])
        }
      },
      fetchEarlyProjects(organizationId: string): Promise<Project[]> {
        return get(`${appConstants.apiEndpoints.PROJECTS}/early-projects`, [
          { key: 'organizationId', value: organizationId },
        ])
          .then((response) => {
            if (response.status === 200) {
              return response.json()
            } else {
              return []
            }
          })
          .then((projectList) => projectList.map((project: any) => Project.from(project)))
      },
      createProject(
        projectDto: ProjectCreationOrUpdateDto,
        isEarlyProject: boolean,
        organizationId: string
      ): Promise<Project> {
        const urlEnding = isEarlyProject ? 'create-early-project' : ''
        const requestParams: RequestParam[] | undefined = isEarlyProject
          ? [{ key: 'organizationId', value: organizationId }]
          : undefined
        return post(`${appConstants.apiEndpoints.PROJECTS}/${urlEnding}`, projectDto, requestParams)
          .then((response) => response.json())
          .then((project: any) => Project.from(project))
      },
      updateProject(projectForm: ProjectCreationOrUpdateDto): Promise<Project> {
        return put(resolveUrl(appConstants.apiEndpoints.PROJECTS, [undefined]), projectForm)
          .then((response) => response.json())
          .then((project: any) => Project.from(project))
      },
      updateProjectStatus(projectId: string, status: ProjectStatusEnum): Promise<Project> {
        return put(appConstants.apiEndpoints.PROJECTS_STATUS, status, [{ key: 'projectId', value: projectId }])
          .then((response) => response.json())
          .then((project: any) => Project.from(project))
      },
      postPhases(projectId: string, projectPhase: ProjectPhase[]): Promise<void> {
        return post(`${appConstants.apiEndpoints.PROJECTS}/${projectId}/phases`, projectPhase, [
          { key: 'projectId', value: projectId },
        ]).then()
      },
      getProject(projectId: string): Promise<Project> {
        return get(`${appConstants.apiEndpoints.PROJECTS}/${projectId}`)
          .then((response) => response.json())
          .then((project: any) => Project.from(project))
      },
      fetchProjectEarlyCollaborators(projectId: string, oganizationId: string): Promise<string[]> {
        return get(`${appConstants.apiEndpoints.PROJECTS}/${projectId}/early-collaborators`, [
          { key: 'organizationId', value: oganizationId },
        ]).then((response) => response.json())
      },
      updateEarlyCollabForProject(projectId: string, userIds: string[]): Promise<Response> {
        return put(`${appConstants.apiEndpoints.PROJECTS}/${projectId}/early-collaborators`, userIds)
      },
      deleteProject(projectId: string): Promise<Response> {
        return deleteRequest(resolveUrl(appConstants.apiEndpoints.PROJECTS_DELETE, [projectId]))
      },
      getUserProjectsCount(): Promise<number> {
        return get(`${appConstants.apiEndpoints.PROJECTS}/count`).then((response) => response.json())
      },
      getSumCarbonImpactWholeOrganization(): Promise<number> {
        return get(`${appConstants.apiEndpoints.PROJECTS}/co2-indicator`).then((response) => response.json())
      },
      sendCoverImageFile(coverImageFile: File, projectId: string): Promise<Response> {
        const formData = new FormData()
        formData.append('coverImageFile', coverImageFile)
        formData.append('projectId', projectId)
        return postFile(`${appConstants.apiEndpoints.PROJECTS}/coverImage`, formData)
      },
      fetchCoverImage(projectId: string): Promise<Blob | undefined> {
        return get(`${appConstants.apiEndpoints.PROJECTS}/coverImage`, [
          {
            key: 'projectId',
            value: projectId,
          },
        ])
          .then((res) => streamToBlob(res))
          .catch(() => undefined)
      },
      deleteCoverImage(projectId: string): Promise<Response> {
        return deleteRequest(`${appConstants.apiEndpoints.PROJECTS}/coverImage`, [
          {
            key: 'projectId',
            value: projectId,
          },
        ])
      },
      duplicateTestProject(newAdministratorEmail: string): Promise<Response> {
        return get(`${appConstants.apiEndpoints.PROJECTS}/duplicate-test-project`, [
          {
            key: 'newAdministratorEmail',
            value: newAdministratorEmail.toLowerCase(),
          },
        ])
      },
      generateShareLink(documentId: string, shareLinkCreationDto: ShareLinkCreationDto): Promise<ShareLink> {
        return post(resolveUrl(appConstants.apiEndpoints.RSEE_DOCUMENT_SHARE_LINK, [documentId]), shareLinkCreationDto)
          .then((response) => {
            if (response.status === 404) {
              throw new Error('DOCUMENT_NOT_FOUND')
            }
            return response.json()
          })
          .then((data) => new ShareLink(data))
      },
      fetchShareLinkInformation(dashboardToken: string): Promise<DocumentSharedInfo> {
        return get(appConstants.apiEndpoints.RSEE_SHARE_INFORMATION, [{ key: 'dashboardToken', value: dashboardToken }])
          .then((response) => response.json())
          .then((data) => new DocumentSharedInfo(data))
      },
      fetchAllRseeProjectShareLink(projectId: string): Promise<ShareLink[]> {
        return get(resolveUrl(appConstants.apiEndpoints.RSEE_PROJECT_LINK_LIST, [projectId]))
          .then((response) => response.json())
          .then((data: any[]) => data.map((link) => new ShareLink(link)))
      },
      fetchAllRseeDocumentShareLink(projectId: string): Promise<ShareLink[]> {
        return get(resolveUrl(appConstants.apiEndpoints.RSEE_DOCUMENT_LINK_LIST, [projectId]))
          .then((response) => response.json())
          .then((data: any[]) => data.map((link) => new ShareLink(link)))
      },
      deleteRseeShareToken(shareLinkId: string): Promise<Response> {
        return deleteRequest(resolveUrl(appConstants.apiEndpoints.RSEE_SHARE_LINK_ID, [shareLinkId]))
      },
      updateShareLink(shareLinkUpdate: ShareLinkUpdateDto): Promise<ShareLink> {
        return put(appConstants.apiEndpoints.RSEE_SHARE_LINK, shareLinkUpdate).then((response) => response.json())
      },
    }),
    [get, put, post, postFile, deleteRequest]
  )
}
