import { Box3, Vector3 } from 'three'
import { IfcViewerAPI } from 'web-ifc-viewer'
import { DimensionMode } from '../enums/DimensionMode'
import { RaySelectMode } from '../enums/RaySelectMode'
import { RayCastingHelper } from '../helpers/RayCastingHelper'
import { DimensionFactory } from '../models/measurement/DimensionFactory'
import { DimensionCommand } from '../models/measurement/base/DimensionCommand'
import { DeleteObjectsCommand } from '../models/measurement/commands/DeleteObjectsCommand'
import { PointCoordinatesCommand } from '../models/measurement/commands/PointCoordinatesCommand'

export class MeasurementManager {
  viewer: IfcViewerAPI
  openMessage?: (message: string) => void
  AddingDimensionsAllowed: boolean
  GeometryRayCaster: RayCastingHelper
  rayCastGeometry: boolean
  boundingBox: Box3 | undefined

  constructor(viewer: IfcViewerAPI, openViewerMessage?: (message: string) => void) {
    this.viewer = viewer
    this.openMessage = openViewerMessage
    this.AddingDimensionsAllowed = true
    this.GeometryRayCaster = new RayCastingHelper(viewer)
    this.rayCastGeometry = false
  }

  /**
   * Add point coordinates to a selected point
   */
  addDimensionBetweenTwoPoints(): void {
    if (!this.AddingDimensionsAllowed) {
      if (this.openMessage) this.openMessage("L'ajout de mesure est désactivé")
      return
    }
    if (this.openMessage) this.openMessage('Veuillez sélectionner deux points pour dessiner la mesure')
    this.abortDimentioning()
    this.rayCastGeometry = true
    this.GeometryRayCaster.dimensionMode = DimensionMode.TWO_POINTS
    this.GeometryRayCaster.selectMode = RaySelectMode.TWO_POINTS
    this.viewer?.IFC.selector.unpickIfcItems()
    this.GeometryRayCaster?.on('TwoPointsSelected', () => {
      if (
        this.GeometryRayCaster &&
        this.viewer &&
        this.GeometryRayCaster.pointsList &&
        this.GeometryRayCaster.pointsList.length > 0
      ) {
        const startPoint = this.GeometryRayCaster.pointsList[0]
        const endPoint = this.GeometryRayCaster.pointsList[1]
        DimensionFactory.addTwoPointsDimension(startPoint, endPoint, this.viewer?.context.getScene(), this.viewer)
      }

      this.rayCastGeometry = false
      this.GeometryRayCaster?.off('TwoPointsSelected')
      this.GeometryRayCaster?.disable()
      this.addDimensionBetweenTwoPoints()
    })
  }

  /**
   * Add dimension on a selected edge
   */
  addEdgeDimension(): void {
    if (!this.AddingDimensionsAllowed) {
      if (this.openMessage) this.openMessage("L'ajout de mesure est désactivé")
      return
    }
    if (this.openMessage) this.openMessage('Veuillez sélectionner un segment pour dessiner la mesure')
    this.abortDimentioning()
    this.rayCastGeometry = true
    this.GeometryRayCaster.selectMode = RaySelectMode.LINE
    this.GeometryRayCaster.dimensionMode = DimensionMode.ONE_EDGE

    this.viewer?.IFC.selector.unpickIfcItems()
    this.GeometryRayCaster?.on('LineSelected', () => {
      if (
        this.GeometryRayCaster &&
        this.viewer &&
        this.GeometryRayCaster.linePoints &&
        this.GeometryRayCaster.linePoints.length >= 2
      ) {
        const startPoint = this.GeometryRayCaster.linePoints[0]
        const endPoint = this.GeometryRayCaster.linePoints[1]
        DimensionFactory.addTwoPointsDimension(startPoint, endPoint, this.viewer?.context.getScene(), this.viewer)
      }
      this.rayCastGeometry = false
      this.GeometryRayCaster?.off('LineSelected')
      this.GeometryRayCaster?.disable()
      this.addEdgeDimension()
    })
  }

  /**
   * Add point coordinates to a selected point
   */
  addPointCoordinates(): void {
    if (!this.AddingDimensionsAllowed) {
      if (this.openMessage) this.openMessage("L'ajout de mesure est désactivé")
      return
    }
    if (this.openMessage) this.openMessage('Veuillez sélectionner un point pour afficher ses coordonnées.')
    this.abortDimentioning()
    this.rayCastGeometry = true
    this.GeometryRayCaster.dimensionMode = DimensionMode.ONE_POINT
    this.GeometryRayCaster.selectMode = RaySelectMode.ONE_POINT
    this.viewer?.IFC.selector.unpickIfcItems()
    this.GeometryRayCaster?.on('OnePointSelected', () => {
      if (
        this.GeometryRayCaster &&
        this.viewer &&
        this.GeometryRayCaster.pointsList &&
        this.GeometryRayCaster.pointsList.length > 0
      ) {
        const point = this.GeometryRayCaster.pointsList[0].point
        const textPoint = this.adjustPointToZeroZ(point)
        const expressId = this.GeometryRayCaster.intersectionId
        const modelId = this.GeometryRayCaster.modelID
        DimensionFactory.addOnePointCoordinates(
          point,
          point.clone(),
          this.viewer?.context.getScene(),
          this.viewer,
          expressId,
          modelId
        )
      }

      this.rayCastGeometry = false
      this.GeometryRayCaster?.off('OnePointSelected')
      this.GeometryRayCaster?.disable()
      this.addPointCoordinates()
    })
  }

  /**
   * Add point coordinates to a selected point to make the lowest z point to be 0 based
   * on the model bounding box min point
   * @param point the point to adjust
   * @returns the new adjusted point
   */
  adjustPointToZeroZ(point: Vector3): Vector3 {
    let labelPoint = point.clone()
    const minPoint = this.boundingBox?.min
    if (minPoint) {
      const factor = 0 - minPoint.y
      labelPoint = new Vector3(point.x, point.y + factor, point.z)
    }
    return labelPoint
  }

  /**
   * Add point coordinates to a selected points to form a polygon for area measurement
   */
  addAreaPoints(): void {
    if (!this.AddingDimensionsAllowed) {
      if (this.openMessage) this.openMessage("L'ajout de mesure est désactivé")
      return
    }
    if (this.openMessage) this.openMessage('Veuillez sélectionner deux points pour dessiner la mesure')
    this.abortDimentioning()
    this.GeometryRayCaster = new RayCastingHelper(this.viewer)
    this.rayCastGeometry = true
    this.viewer?.IFC.selector.unpickIfcItems()
    this.GeometryRayCaster.dimensionMode = DimensionMode.AREA
    this.GeometryRayCaster.selectMode = RaySelectMode.AREA
    this.GeometryRayCaster.pointsList = []
    this.GeometryRayCaster?.on('AreaPointsSelected', () => {
      if (
        this.GeometryRayCaster &&
        this.viewer &&
        this.GeometryRayCaster.pointsList &&
        this.GeometryRayCaster.pointsList.length > 0
      ) {
        DimensionFactory.addAreaPointsDimension(
          this.GeometryRayCaster.pointsList,
          this.viewer?.context.getScene(),
          this.viewer
        )
      }

      this.rayCastGeometry = false
      this.GeometryRayCaster?.off('AreaPointsSelected')
      this.GeometryRayCaster?.disable()
      this.addAreaPoints()
    })
  }

  /**
   * Add an area measurement to the viewer by selecting a geometry face
   */
  addAreaByFace(): void {
    if (!this.AddingDimensionsAllowed) {
      if (this.openMessage) this.openMessage("L'ajout de mesure est désactivé")
      return
    }
    if (this.openMessage) this.openMessage('Veuillez sélectionner une face géométrique pour dessiner la mesure')
    this.abortDimentioning()
    this.GeometryRayCaster = new RayCastingHelper(this.viewer)
    this.rayCastGeometry = true
    this.viewer?.IFC.selector.unpickIfcItems()
    this.GeometryRayCaster.dimensionMode = DimensionMode.FACE_AREA
    this.GeometryRayCaster.selectMode = RaySelectMode.FACE
    this.GeometryRayCaster.pointsList = []
    this.GeometryRayCaster?.on('FacePointsSelected', () => {
      if (
        this.GeometryRayCaster &&
        this.viewer &&
        this.GeometryRayCaster.pointsList &&
        this.GeometryRayCaster.pointsList.length > 0
      ) {
        DimensionFactory.addAreaPointsDimension(
          this.GeometryRayCaster.pointsList,
          this.viewer?.context.getScene(),
          this.viewer
        )
      }

      this.rayCastGeometry = false
      this.GeometryRayCaster?.off('FacePointsSelected')
      this.GeometryRayCaster?.disable()
      this.addAreaByFace()
    })
  }

  /**
   * Add an area measurement to the viewer by selecting a geometry face
   */
  addSegmentsByFace(): void {
    if (!this.AddingDimensionsAllowed) {
      if (this.openMessage) this.openMessage("L'ajout de mesure est désactivé")
      return
    }
    if (this.openMessage) this.openMessage('Veuillez sélectionner une face géométrique pour dessiner la mesure')
    this.abortDimentioning()
    this.GeometryRayCaster = new RayCastingHelper(this.viewer)
    this.rayCastGeometry = true
    this.viewer?.IFC.selector.unpickIfcItems()
    this.GeometryRayCaster.dimensionMode = DimensionMode.FACE_SEGMENTS
    this.GeometryRayCaster.selectMode = RaySelectMode.FACE
    this.GeometryRayCaster.pointsList = []
    this.GeometryRayCaster?.on('FaceSegmentsSelected', () => {
      if (this.GeometryRayCaster && this.viewer) {
        for (const line of this.GeometryRayCaster.areaLines) {
          const startPoint = line.points[0].clone()
          const endPoint = line.points[1].clone()
          DimensionFactory.addTwoPointsDimension(startPoint, endPoint, this.viewer?.context.getScene(), this.viewer)
        }
      }

      this.rayCastGeometry = false
      this.GeometryRayCaster?.off('FaceSegmentsSelected')
      this.GeometryRayCaster?.disable()
      this.addSegmentsByFace()
    })
  }

  /**
   * Add dimension on a selected edge
   * @param minimumLineLength minimum length of a line in an object to be detected
   */
  addObjectEdgesDimensions(minimumLineLength: number): void {
    if (!this.AddingDimensionsAllowed) {
      if (this.openMessage) this.openMessage("L'ajout de mesure est désactivé")
      return
    }
    if (this.openMessage) this.openMessage('Veuillez sélectionner un objet pour dessiner les mesures')
    this.abortDimentioning()
    this.rayCastGeometry = true
    this.GeometryRayCaster.selectMode = RaySelectMode.OBJECT_EDGES
    this.GeometryRayCaster.dimensionMode = DimensionMode.OBJECT_EDGES
    this.GeometryRayCaster.minimunLineLength = minimumLineLength
    this.viewer?.IFC.selector.unpickIfcItems()
    this.GeometryRayCaster?.on('ObjectEdgesSelected', () => {
      if (this.GeometryRayCaster && this.viewer) {
        for (const line of this.GeometryRayCaster.areaLines) {
          const startPoint = line.points[0]
          const endPoint = line.points[1]
          DimensionFactory.addTwoPointsDimension(startPoint, endPoint, this.viewer?.context.getScene(), this.viewer)
        }
      }
      this.rayCastGeometry = false
      this.GeometryRayCaster?.off('ObjectEdgesSelected')
      this.GeometryRayCaster?.disable()
      this.addObjectEdgesDimensions(minimumLineLength)
    })
  }

  /**
   * Abort any ongoing dimensioning by the user
   */
  abortDimentioning() {
    this.rayCastGeometry = false
    this.GeometryRayCaster?.off('TwoPointsSelected')
    this.GeometryRayCaster?.off('LineSelected')
    this.GeometryRayCaster?.off('OnePointSelected')
    this.GeometryRayCaster?.off('AreaPointsSelected')
    this.GeometryRayCaster?.off('ObjectEdgesSelected')
    this.GeometryRayCaster?.off('FacePointsSelected')
    this.GeometryRayCaster?.off('FaceSegmentsSelected')
    this.GeometryRayCaster?.disable()
  }

  /**
   * Enable or disable adding dimensions according to user input, if disabled, hide all previously added dimensions
   * @param allow enable if true, disable otherwise
   */
  allowAddingDimensions(allow: boolean): void {
    this.AddingDimensionsAllowed = allow

    const dimensions = PointCoordinatesCommand.addedPoints
    dimensions.push(...DimensionCommand.addedLines)
    if (dimensions !== null && dimensions.length > 0) {
      const scene = this.viewer?.context.getScene()
      if (!this.AddingDimensionsAllowed) {
        for (const dim of dimensions) {
          scene?.remove(dim)
        }
      } else {
        for (const dimension of dimensions) {
          if (dimension.canBeVisible !== undefined) {
            if (dimension.canBeVisible) scene?.add(dimension)
          } else scene?.add(dimension)
        }
      }
    }

    if (!this.AddingDimensionsAllowed) this.abortDimentioning()
  }

  /**
   * Completely delete all added point coordinates
   */
  deleteAllPointCoordinates(modelID?: number, hideMessage?: boolean): void {
    if (!this.AddingDimensionsAllowed) {
      if (this.openMessage && !hideMessage) this.openMessage("L'ajout de mesure est désactivé")
      return
    }
    const selectedObjects: Array<any> = []
    if (PointCoordinatesCommand.addedPoints.length > 0)
      selectedObjects.push(
        ...(modelID !== undefined
          ? PointCoordinatesCommand.addedPoints.filter((x) => x.modelId === modelID)
          : PointCoordinatesCommand.addedPoints)
      )

    if (DimensionCommand.addedLines.length > 0)
      selectedObjects.push(
        ...(modelID !== undefined
          ? DimensionCommand.addedLines.filter((x) => x.endPointModelId === modelID || x.startPointModelId === modelID)
          : DimensionCommand.addedLines)
      )

    const deleteObjectsCommandHelper = new DeleteObjectsCommand(selectedObjects, this.viewer?.context.getScene())
    deleteObjectsCommandHelper.dispose()
    PointCoordinatesCommand.addedPoints = []
    DimensionCommand.addedLines = []

    if (this.openMessage && !hideMessage) this.openMessage('Toutes les mesures ont été supprimées')
  }
}
