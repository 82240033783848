export enum DeclarationTypeEnum {
  DONNEE_PAR_DEFAUT = 'DONNEE_PAR_DEFAUT',
  COLLECTIVE = 'COLLECTIVE',
  INDIVIDUELLE = 'INDIVIDUELLE',
  FORFAITAIRE = 'FORFAITAIRE', // Conventionnelle
  EMPTY = 'EMPTY',
  REUSE = 'REUSE',
  FICHE_CONFIGUREE = 'FICHE_CONFIGUREE',
  UNKNOWN = 'UNKNOWN',
}
