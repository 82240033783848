import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'
import React from 'react'

/**
 * @deprecated
 */
export function LayoutIcade(): React.JSX.Element {
  return (
    <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
      <Outlet />
    </Box>
  )
}
