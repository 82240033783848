export default class CodeCustom {
  id = ''
  code = ''
  name = ''
  projectId = ''
  lot = ''
  sousLot = ''
  description = ''
  parentCode = ''
  projectOrganizationId = ''

  static fromDto(record: CodeCustom): CodeCustom {
    const codeAcvCustom = new CodeCustom()

    codeAcvCustom.id = record.id
    codeAcvCustom.code = record.code
    codeAcvCustom.name = record.name
    codeAcvCustom.projectId = record.projectId
    codeAcvCustom.lot = record.lot
    codeAcvCustom.sousLot = record.sousLot
    codeAcvCustom.description = record.description
    codeAcvCustom.projectOrganizationId = record.projectOrganizationId
    codeAcvCustom.parentCode = record.parentCode

    return codeAcvCustom
  }
}
