import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { customPalette } from '../../../../theme'

export type SelectOption<T> = {
  id?: string
  value: T
  label: string | undefined
}

type DefaultOption = SelectOption<''>

interface IProps<T> {
  id: string // it must be a key of the form
  label: string
  selectedOption: T | undefined
  options: SelectOption<T>[]
  isBeemShot?: boolean
  mode?: 'direct' | 'event'
  disabled?: boolean
  errors?: Record<string, string | undefined>
  size?: 'small' | 'medium'

  handleChange?(selectedValue: T): void

  handleEventChange?(event: ChangeEvent<HTMLInputElement>): void

  handleSubmit?(): void
}

export default function SelectInput<T>({
  id,
  label,
  selectedOption,
  options,
  handleChange,
  mode = 'direct',
  handleEventChange,
  disabled = false,
  errors = {},
  isBeemShot = false,
  size = 'medium',
  handleSubmit,
}: Readonly<IProps<T>>): React.JSX.Element {
  const [isOptionsEmpty, setIsOptionsEmpty] = useState<boolean>(false)

  const isInitialized = useRef<boolean>(false)

  const setValue = useCallback(
    (value: T): void => {
      if (!value) {
        return
      }

      if (mode === 'direct' && handleChange) {
        handleChange(value)
      } else if (mode === 'event' && handleEventChange) {
        // It's when handleChange expects ChangeEvent
        const e = {
          target: {
            id,
            value,
          },
        } as unknown as ChangeEvent<HTMLInputElement>
        handleEventChange(e)
      }
    },
    [handleChange, handleEventChange, id, mode]
  )

  const initSelectedOption = useCallback((): T | '' => {
    if (selectedOption) {
      return selectedOption
    } else if (options?.length > 0 && options[0]) {
      setValue(options[0].value)
      return options[0].value
    } else {
      return ''
    }
  }, [selectedOption, options, setValue])

  const initOptions: () => SelectOption<T>[] | DefaultOption[] = useCallback(() => {
    const newOption = initSelectedOption()
    setActualSelected(newOption)
    if (options === undefined || options.length === 0) {
      setIsOptionsEmpty(true)
      return [{ value: '', label: '' }]
    } else {
      setIsOptionsEmpty(false)
      return options
    }
  }, [options, selectedOption])

  const initSelected = useMemo(() => initSelectedOption(), [initSelectedOption, selectedOption])
  const [actualSelected, setActualSelected] = useState<T | ''>(initSelected)
  const actualOptions = useMemo<SelectOption<T>[] | DefaultOption[]>(() => initOptions(), [initOptions])

  function handleChangeLocal(event: SelectChangeEvent): void {
    const newSelectedValue = deserializeValue(event.target.value)
    setActualSelected(newSelectedValue)
    if (!isOptionsEmpty) {
      setValue(newSelectedValue as T)
    }
  }

  function serializeValue(value: T | ''): string {
    return String(value)
  }

  // Converts string back to original value
  function deserializeValue(value: string): T | '' {
    return options.find((option) => String(option.value) === value)?.value ?? ''
  }

  useEffect(() => {
    if (handleSubmit && isInitialized.current) {
      handleSubmit()
    }
  }, [initSelected])

  function authorizeToUpdate(): void {
    isInitialized.current = true
  }

  return (
    <FormControl fullWidth sx={{ backgroundColor: isBeemShot ? customPalette.textPrimaryWhite : undefined }}>
      <InputLabel>{label}</InputLabel>
      <Select
        id={id}
        disabled={disabled}
        IconComponent={disabled ? 'p' : undefined}
        labelId={`${label}-id-label`}
        value={serializeValue(actualSelected)}
        onChange={handleChangeLocal}
        label={label}
        size={size}>
        {actualOptions.map((option) => (
          <MenuItem key={option.label} value={serializeValue(option.value)} onClick={() => authorizeToUpdate()}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{errors[id]}</FormHelperText>
    </FormControl>
  )
}
