import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

type IProps = {
  isSelected: boolean
  toUrl: string
  label: string
}

export function PanelButton({ isSelected, toUrl, label }: IProps): React.JSX.Element {
  const navigate = useNavigate()

  return (
    <Button
      onClick={() => {
        navigate(toUrl, {})
      }}
      variant={isSelected ? 'contained' : 'outlined'}
      sx={{ mt: 3, ml: 1, width: '100%' }}>
      {label}
    </Button>
  )
}
