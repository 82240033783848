import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material'
import React, { ReactNode, useState } from 'react'

type IProps = {
  id: string
  title: string
  contentText: ReactNode
  handleClose: () => void
  action: () => Promise<any>
  open: boolean
}
export function ConfirmationDialog({ id, title, contentText, handleClose, action, open }: IProps): React.JSX.Element {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  function onAction(): void {
    setIsSubmitting(true)
    action()
      .then(() => {
        handleClose()
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  return (
    <Box>
      <Dialog open={open} onClose={handleClose} aria-describedby={id}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id={id}>{contentText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid item xs={6} />
            <Grid item xs={3}>
              <Button onClick={handleClose}>Annuler</Button>
            </Grid>
            <Grid item xs={3}>
              {isSubmitting ? <CircularProgress /> : <Button onClick={onAction}>Confirmer</Button>}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
