import { ChevronRight, ExpandMore, Folder } from '@mui/icons-material'
import { TreeItem, TreeView } from '@mui/lab'
import { Box, Typography } from '@mui/material'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { LotContext } from '../../core/context/lot-context'
import { CodeExtrait } from '../../core/dto/code-extrait/code-extrait'
import Lot from '../../core/dto/lots/lot'
import SousLot from '../../core/dto/lots/sous-lot'
import { groupBy } from '../../core/services/code-service'

type IProps = {
  codesExtraits: CodeExtrait[]
  handleClickAcv?: (codeExtract: CodeExtrait) => void
  selectedCodeExtrait?: CodeExtrait
  renderCodeElement: (code: CodeExtrait) => React.JSX.Element
}

export default function TreeList({
  codesExtraits,
  handleClickAcv,
  selectedCodeExtrait,
  renderCodeElement,
}: Readonly<IProps>): React.JSX.Element {
  const { lots } = useContext(LotContext)
  const [expanded, setExpanded] = useState<string[]>([])
  const [selected, setSelected] = useState<string>(`${selectedCodeExtrait?.code}${selectedCodeExtrait?.occurence}`)

  const groupBySousLot = useMemo(() => groupBy(codesExtraits, 'sousLot'), [codesExtraits])

  const filteredLot = useMemo(
    () =>
      (lots || [])
        .map((l) => {
          if (l.children.length) {
            const newChildren = l.children.filter((c) => c.name in groupBySousLot && [c.name].length)
            return {
              ...l,
              children: newChildren,
            }
          }
          return l
        })
        .filter((l) => l.children.length),
    [lots, groupBySousLot]
  )

  useEffect(() => {
    setExpanded((prev) =>
      selectedCodeExtrait
        ? [
            selectedCodeExtrait.lot,
            selectedCodeExtrait.sousLot,
            `${selectedCodeExtrait.code}${selectedCodeExtrait.occurence}`,
          ]
        : prev
    )
    setSelected(selectedCodeExtrait ? `${selectedCodeExtrait.code}${selectedCodeExtrait.occurence}` : '')
  }, [selectedCodeExtrait])

  const renderLotTree = (l: Lot | SousLot): React.ReactNode => (
    <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
      <Box component={Folder} color='inherit' sx={{ mr: 1 }} />
      <Typography variant='body2' sx={{ fontWeight: 'inherit', flexGrow: 1, wordWrap: 'break-word' }}>
        {l.label}
      </Typography>
    </Box>
  )

  const handleExpand = (key: string): void => {
    setExpanded((prev) => {
      if (prev.some((i) => i === key)) {
        return prev.filter((i) => i !== key)
      }
      return [...prev, key]
    })
  }
  
  return (
    <TreeView
      defaultCollapseIcon={<ExpandMore />}
      defaultExpandIcon={<ChevronRight />}
      expanded={expanded}
      selected={selected}>
      {filteredLot.map((lot) => (
        <TreeItem key={lot.name} nodeId={lot.name} label={renderLotTree(lot)} onClick={() => handleExpand(lot.name)}>
          {lot.children.map((sousLot) => (
            <TreeItem
              key={sousLot.name}
              nodeId={sousLot.name}
              label={renderLotTree(sousLot)}
              onClick={() => handleExpand(sousLot.name)}>
              {groupBySousLot[sousLot.name].map((code, index) => (
                <TreeItem
                  key={`${code.code}${code.occurence}`}
                  nodeId={`${code.code}${code.occurence}`}
                  defaultChecked={selected === `${code.code}${code.occurence}`}
                  onClick={() => {
                    if (handleClickAcv) {
                      handleClickAcv(code)
                    }
                  }}
                  label={
                    <Box
                      className='code-extrait'
                      display='flex'
                      alignItems='center'
                      sx={{
                        width: '100%',
                        minHeight: 60,
                        borderTop: index === 0 ? `0.2px solid #e0e0e0` : undefined,
                        borderBottom: `0.2px solid #e0e0e0`,
                      }}>
                      {renderCodeElement(code)}
                    </Box>
                  }
                />
              ))}
            </TreeItem>
          ))}
        </TreeItem>
      ))}
    </TreeView>
  )
}
