import { TextField, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DeleteDialog from '../../../components/dialog/delete-dialog'
import { SuccessContext } from '../../../components/layout/success-snackbar'
import { pagesUrl } from '../../../core/appConstants'
import { ProjectContext } from '../../../core/context/project/project-context'
import { ProjectTypeEnum } from '../../../core/enum/project/projectTypeEnum'
import { ProjectStatusEnum } from '../../../core/enum/projectStatusEnum'
import { useProjects } from '../../../core/hooks/projects/use-projects'
import { resolveUrl } from '../../../core/services/http-service'
import { OrganizationContext } from '../../../core/context/organization/organization-context'

type IProps = {
  openDeleteDialog: boolean
  setOpenDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>
}

export default function DeleteProject({ openDeleteDialog, setOpenDeleteDialog }: IProps): React.JSX.Element {
  const navigate = useNavigate()
  const openSuccessSnackbar = useContext(SuccessContext)
  const { project, setProject } = useContext(ProjectContext)
  const { refreshOrganization } = useContext(OrganizationContext)

  const { updateProjectStatus, deleteProject } = useProjects()

  const [nameToDelete, setNameToDelete] = useState<string>('')
  const [isDeleting, setIsDeleting] = useState<boolean>(false)

  const [error, setError] = useState<any>({})

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const target = event.target

    setError({ ...error, rseeNameToDelete: undefined })
    setNameToDelete(target.value)
  }

  function validate(): boolean {
    const newError: Record<string, any> = {}
    let isValid = true
    const nameFormatted: string | undefined = nameToDelete?.trim()

    if (nameFormatted && !nameFormatted?.length) {
      newError.nameToDelete = 'Veuillez remplir ce champ'
      isValid = false
    }

    if (nameFormatted !== project.name) {
      newError.nameToDelete = 'Le nom du projet est incorrect'
      isValid = false
    }

    if (nameFormatted?.length > 1000) {
      newError.nameToDelete = '1000 caractères maximum'
      isValid = false
    }
    setError(newError)
    return isValid
  }

  function updateStatusToArchived(): void {
    project.status = ProjectStatusEnum.ARCHIVED
    if (project.id) {
      updateProjectStatus(project.id, project.status)
        .then((updateToArchived) => {
          setProject(updateToArchived)
          openSuccessSnackbar("Statut du projet mis à jour à l'état Archivé")
        })
        .finally(() => {
          handleClose()
        })
    }
  }

  function handleClose(): void {
    setOpenDeleteDialog(false)
  }

  function handleDelete(): void {
    if (project.id && project.status !== ProjectStatusEnum.ARCHIVED) {
      setOpenDeleteDialog(true)
      updateStatusToArchived()
    } else if (validate() && project.id && !isDeleting) {
      setOpenDeleteDialog(true)
      setIsDeleting(true)
      deleteProject(project.id)
        .then(() => refreshOrganization()) // Refresh organization to refresh the projects counter
        .then(() => {
          handleClose()
          navigate(resolveUrl(pagesUrl.PROJECTS_PAGE, [], { type: ProjectTypeEnum.BIM }))
        })
        .finally(() => {
          setIsDeleting(false)
        })
    }
  }

  return (
    <DeleteDialog
      id='deleteProject'
      title={`Suppression du projet "${project.name}"`}
      contentText={
        project.status !== ProjectStatusEnum.ARCHIVED ? (
          `La suppression du projet n'est possible qu'à l'état Archivé. Voulez-vous passer le statut du projet à Archivé ?`
        ) : (
          <>
            <Typography variant='h6' sx={{ fontSize: 19 }}>
              Pour confirmer la suppression, tapez "{project.name}" ci-dessous (sans les guillemets)
            </Typography>
            <TextField
              required
              id='name'
              onChange={handleChange}
              value={nameToDelete}
              variant='outlined'
              fullWidth
              error={!!error.nameToDelete}
              helperText={error.nameToDelete}
            />
          </>
        )
      }
      handleClose={handleClose}
      action={handleDelete}
      open={openDeleteDialog}
    />
  )
}
