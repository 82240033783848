import { useCallback, useState } from 'react'
import { appConstants } from '../../appConstants'
import MaterialRecord from '../../dto/material/material-record'
import { useHttp } from '../use-http'

type useUpdateMaterialReturn = {
  loading: boolean
  deleteMaterial: (id: string) => Promise<Response>
  updateMaterial: (materialId: string, provider: string, productReference: string) => Promise<MaterialRecord>
  updateProjectMaterial: (
    materialId: string,
    provider: string,
    productReference: string,
    fdesName: string,
    projectId: string
  ) => Promise<MaterialRecord>
}

export const useUpdateMaterial = (): useUpdateMaterialReturn => {
  const { deleteWithId, put } = useHttp()
  const [loading, setLoading] = useState<boolean>(false)

  const deleteMaterial = useCallback(
    async (id: string): Promise<Response> => {
      setLoading(true)

      return deleteWithId(appConstants.apiEndpoints.MATERIAL_ORGANIZATION, id)
        .then((response) => response.json())
        .then((data) => data)
        .finally(() => {
          setLoading(false)
        })
    },
    [deleteWithId]
  )

  const updateMaterial = useCallback(
    async (materialId: string, provider: string, productReference: string): Promise<MaterialRecord> => {
      setLoading(true)

      return put(appConstants.apiEndpoints.MATERIAL_ORGANIZATION, {
        materialId,
        provider,
        productReference,
      })
        .then((response) => response.json())
        .then((data) => data)
        .finally(() => {
          setLoading(false)
        })
    },
    [put]
  )

  const updateProjectMaterial = useCallback(
    async (
      materialId: string,
      provider: string,
      productReference: string,
      fdesName: string,
      projectId: string
    ): Promise<MaterialRecord> => {
      setLoading(true)

      return put(
        appConstants.apiEndpoints.PROJECT_ORGANIZATION,
        {
          materialId,
          provider,
          productReference,
          fdesName,
        },
        [
          {
            key: 'projectId',
            value: projectId,
          },
        ]
      )
        .then((response) => response.json())
        .finally(() => {
          setLoading(false)
        })
    },
    [put]
  )

  return {
    loading,
    deleteMaterial,
    updateMaterial,
    updateProjectMaterial,
  }
}
