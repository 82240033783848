import { LabelColor } from './LabelColor'

export class LabelStyle {
  static defaultStyle: LabelStyle = {
    fontsize: 160,
    fontface: 'Inter',
    fillColor: new LabelColor(255, 255, 255, 0.9),
    textColor: new LabelColor(40, 40, 40, 1.0),
    vAlign: 'center',
    hAlign: 'center',
    padding: 8,
    borderThickness: 0,
    borderColor: new LabelColor(225, 225, 225, 0.0),
    radius: 30,
  }

  static pointLabelStyle: LabelStyle = {
    fontsize: 140,
    fontface: 'Inter',
    fillColor: new LabelColor(255, 255, 255, 0.9),
    textColor: new LabelColor(40, 40, 40, 1.0),
    vAlign: 'center',
    hAlign: 'right',
    padding: 8,
    borderThickness: 0,
    borderColor: new LabelColor(225, 225, 225, 0.0),
    radius: 30,
  }

  fontsize?: number = 120
  fontface?: string = 'Georgia'
  fillColor?: LabelColor = new LabelColor(255, 255, 255, 0.0)
  textColor?: LabelColor = new LabelColor(25, 25, 25, 1.0)
  vAlign?: string = 'center'
  hAlign?: string = 'center'
  borderThickness?: number = 0
  borderColor?: LabelColor = new LabelColor(255, 255, 255, 0.0)
  radius?: number = 10
  padding?: number = 0
}
