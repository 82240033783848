import { Group, Vector3 } from 'three'

export class PointLabelGroup extends Group {
  expressId?: number
  modelId?: number
  point?: Vector3
  canBeVisible?: boolean
  assosiatedObject?: any
}

export class LineGroup extends Group {
  startPointExpressId?: number
  endPointExpressId?: number
  startPointModelId?: number
  endPointModelId?: number
  startPoint?: Vector3
  endPoint?: Vector3
  canBeVisible?: boolean
  assosiatedObject?: any
}
