import { Search } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'

import { Box, Button, InputAdornment, TextField } from '@mui/material'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { adminPagesUrl } from '../../../core/appConstants'
import { AdminOrganizationContext } from '../../../core/context/organization/admin-organization-context'
import { UserContext } from '../../../core/context/user/user-context'
import { Collaborator } from '../../../core/dto/user/collaborator'
import { Contact } from '../../../core/dto/user/contact'
import { OrganizationRoleEnum } from '../../../core/enum/user/organization-role-enum'
import { useProjects } from '../../../core/hooks/projects/use-projects'
import { resolveUrl } from '../../../core/services/http-service'
import AssignTestProjectDialog from '../../../pages/admin-page/organization/assign-test-project-dialog'
import CollaboratorDataTable from './collaborator-data-table'
import ContactDataTable from './contact-data-table'

export enum DataGridType {
  BILL = 'BILL',
  COLLAB = 'COLLAB',
  CONTACT = 'CONTACT',
}

type IProps = {
  readonly dataCollab: Collaborator[]
  readonly dataContact: Contact[]
  readonly type: DataGridType
  refreshCollab(): void
  readonly userList?: Collaborator[]
  readonly isForUltimateUser: boolean
}

export default function DataGridOrganization({
  dataCollab,
  dataContact,
  type,
  refreshCollab,
  userList,
  isForUltimateUser,
}: Readonly<IProps>): React.JSX.Element {
  const [page, setPage] = React.useState(0)
  const [dataCollabList, setDataCollabList] = useState<Collaborator[]>(dataCollab)
  const [dataContactList, setDataContactList] = useState<Contact[]>(dataContact)
  const [filter, setFilter] = useState<string>('')
  const { user } = useContext(UserContext)
  const navigate = useNavigate()
  const { organization } = useContext(AdminOrganizationContext)

  const isWriting: boolean = useMemo(
    () => user?.role === OrganizationRoleEnum.MEGA_USER || user?.role === OrganizationRoleEnum.ULTIMATE_USER,
    [user?.role]
  )

  const { duplicateTestProject } = useProjects()
  const [isDuplicating, setIsDuplicating] = useState<boolean>(false)
  const [openAssignTestProject, setOpenAssignTestProject] = useState<boolean>(false)

  useEffect(() => {
    setDataCollabList(dataCollab)
    setDataContactList(dataContact)
  }, [dataCollab, dataContact])

  const handleChangePage = (_event: unknown, newPage: number): void => {
    setPage(newPage)
  }

  function getByPlaceholderText(): string {
    if (type === DataGridType.CONTACT) return 'Rechercher dans les contacts de mon organisation'
    if (type === DataGridType.COLLAB) return 'Rechercher un collaborateur'
    return ''
  }

  function handleClose(): void {
    setOpenAssignTestProject(false)
  }

  function addTestProject(selectedUser: string): void {
    setIsDuplicating(true)
    duplicateTestProject(selectedUser)
      .then(() => setOpenAssignTestProject(false))
      .finally(() => setIsDuplicating(false))
  }

  function createUser(): void {
    if (organization?.id) {
      navigate(resolveUrl(adminPagesUrl.ADMIN_ORGANIZATION_INVITE_USER_PAGE, [organization.id]))
    }
  }

  function isLicenceAvailable(): boolean {
    return (
      !!organization && (organization.licenceCounter < organization.maxLicenceCount || organization.maxLicenceCount === -1)
    )
  }

  function onFilterData(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    const target = event.target
    const value = target.value
    setFilter(value)
    if (type === DataGridType.COLLAB) {
      setDataCollabList(
        dataCollab.filter(
          (userData) =>
            userData.givenName?.toLowerCase().includes(value) || userData.familyName?.toLowerCase().includes(value)
        )
      )
    }
    if (type === DataGridType.CONTACT) {
      setDataContactList(
        dataContact.filter(
          (userData) => userData.firstName?.toLowerCase().includes(value) || userData.lastName?.toLowerCase().includes(value)
        )
      )
    }
  }

  function clearFilter(): void {
    setFilter('')
    if (type === DataGridType.COLLAB) {
      setDataCollabList(dataCollab)
    } else {
      setDataContactList(dataContact)
    }
  }

  return (
    <Box sx={{ pl: 10, pr: 10 }}>
      {type !== DataGridType.BILL && (
        <Box sx={{ pb: 2, width: '60%' }}>
          <TextField
            placeholder={getByPlaceholderText()}
            onChange={(e) => onFilterData(e)}
            value={filter}
            sx={{
              borderRadius: '4px',
              background: '#0000000F 0% 0% no-repeat padding-box;',
              boxSizing: 'border-box',
              minWidth: 800,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Search />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='end' onClick={clearFilter} sx={{ cursor: 'pointer' }}>
                  <CloseIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}

      {type === DataGridType.COLLAB && (
        <CollaboratorDataTable
          page={page}
          dataCollabList={dataCollabList}
          handleChangePage={handleChangePage}
          refreshCollab={refreshCollab}
          isWriting={isWriting}
          isForUltimateUser={isForUltimateUser}
        />
      )}

      {type === DataGridType.CONTACT && (
        <ContactDataTable page={page} dataContactList={dataContactList} handleChangePage={handleChangePage} />
      )}

      {userList && (
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
          {organization && (
            <Button variant='contained' onClick={() => setOpenAssignTestProject(true)} sx={{ mt: 3, ml: 1 }}>
              Affecter projet test
            </Button>
          )}
          <Button onClick={createUser} disabled={!isLicenceAvailable()} variant='contained' sx={{ mt: 3, ml: 1 }}>
            Ajouter ou inviter un utilisateur
          </Button>
        </Box>
      )}
      <AssignTestProjectDialog
        open={openAssignTestProject}
        handleClose={handleClose}
        action={addTestProject}
        userList={userList || []}
        isDuplicating={isDuplicating}
      />

      {/*Todo: put this dialog into CollaboratorDataTable*/}
    </Box>
  )
}
