import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { Box, Button, Dialog, DialogTitle, Grid, Grow, IconButton, Typography } from '@mui/material'
import React, { useState } from 'react'
import { DefaultTypography } from '../../../components/typography/default-typography'
import { PageCenter } from '../../../core/enum/help-center/helpCenterEnum'
import { ConfigurationHelper } from '../../support/configuration-helper'
import { HelperCenterPage } from '../../support/helper-center'
import { TutorialHelper } from '../../support/tutorial-bimmodel-helper'

export function MissingInfoCode(): React.JSX.Element {
  const [open, setOpen] = React.useState(false)
  const [actualPage, setActualPage] = useState<PageCenter>(PageCenter.CENTRE_AIDE)

  function handleClickOpen(): void {
    setOpen(true)
  }

  function handleClose(): void {
    setOpen(false)
    setActualPage(PageCenter.CENTRE_AIDE)
  }

  function handleChange(toChange: PageCenter): void {
    setActualPage(toChange)
  }
  return (
    <>
      <Grow in>
        <Box
          display='flex'
          justifyContent='center'
          boxShadow='2px 4px 20px 0px rgba(0, 0, 0, 0.05)'
          flexDirection='column'
          sx={{ backgroundColor: 'white', borderRadius: '25px', p: 5, gap: 4 }}>
          <Box>
            <WarningAmberIcon htmlColor='red' />
          </Box>
          <Box>
            <DefaultTypography
              label='Vous devez renseigner au minimum les codes ACV Time To Beem dans les objets modélisés de la maquette numérique avant
        import.'
            />
          </Box>
          <Box>
            <DefaultTypography label="C'est simple et rapide, laissez-vous guider !" fontWeight={0} />
          </Box>
          <Button variant='contained' onClick={handleClickOpen}>
            Comment renseigner ces codes ?
          </Button>

          <Box display='flex' flexDirection='column' p={2} sx={{ background: '#E5FFF4', borderRadius: 1 }}>
            <Box display='flex' alignItems='center' gap={1}>
              <CheckIcon sx={{ color: '#0BE2A0' }} />
              <DefaultTypography label='Les codes ACV' />
            </Box>
            <Box display='flex' alignItems='center' gap={1}>
              <CheckIcon sx={{ color: '#BDBDBD' }} />
              <DefaultTypography label='Les descriptions des codes ACV' />
            </Box>
            <Box display='flex' alignItems='center' gap={1}>
              <CheckIcon sx={{ color: '#BDBDBD' }} />
              <DefaultTypography label='Les différents usages' />
            </Box>
          </Box>
        </Box>
      </Grow>

      <Dialog fullScreen open={open} onClose={handleClose}>
        {(actualPage === PageCenter.TUTORIAL_EXEMPLE || actualPage === PageCenter.AIDE_PARAMETRAGE) && (
          <DialogTitle>
            <Grid container display='flex' alignContent='center' p={3} borderRadius={3} sx={{ background: '#070F27' }}>
              <Grid container item xs={2} display='flex' alignContent='center' alignItems='center' gap={2}>
                <ArrowBackOutlinedIcon
                  fontSize='medium'
                  sx={{ cursor: 'pointer', color: 'white' }}
                  onClick={() => handleChange(PageCenter.CENTRE_AIDE)}
                />

                <Typography variant='body1' color='white'>
                  Centre d'aide
                </Typography>
              </Grid>

              <Grid
                container
                item
                xs={8}
                display='flex'
                justifyContent='center'
                alignContent='center'
                alignItems='center'
                gap={2}>
                <Typography variant='h6' sx={{ color: 'white' }}>
                  {actualPage === PageCenter.AIDE_PARAMETRAGE && 'Aide au paramétrage'}
                  {actualPage === PageCenter.TUTORIAL_EXEMPLE && "Tester notre maquette d'exemple"}
                </Typography>
              </Grid>

              <Grid container item xs={2} display='flex' justifyContent='end' alignItems='center' gap={2}>
                <IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close'>
                  <CloseIcon sx={{ color: 'white' }} />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
        )}

        {actualPage === PageCenter.CENTRE_AIDE && <HelperCenterPage handleChange={handleChange} handleClose={handleClose} />}
        {actualPage === PageCenter.AIDE_PARAMETRAGE && <ConfigurationHelper />}
        {actualPage === PageCenter.TUTORIAL_EXEMPLE && <TutorialHelper />}
      </Dialog>
    </>
  )
}
