import { Box, CircularProgress } from '@mui/material'
import React from 'react'
import LotContextProvider from '../../../../core/context/lot-context'
import { AdvancedQuestionnaire } from './components/AdvancedQuestionnaire/AdvancedQuestionnaire'
import { QuestionnairesTitle } from './components/QuestionnairesTitle'
import { SimplifiedQuestionnaire } from './components/SimplifiedQuestionnaire/SimplifiedQuestionnaire'
import { TabPanel } from './components/TabPanel'

type IProps = {
  selectedTab: number
  handleChange(event: React.SyntheticEvent, newValue: number): void
  progress: number
  isSubmitting: boolean
}

export function Questionnaires({ selectedTab, handleChange, progress, isSubmitting }: IProps): React.JSX.Element {
  return (
    <LotContextProvider>
      <QuestionnairesCore
        selectedTab={selectedTab}
        handleChange={handleChange}
        progress={progress}
        isSubmitting={isSubmitting}
      />
    </LotContextProvider>
  )
}

function QuestionnairesCore({ selectedTab, handleChange, progress, isSubmitting }: IProps): React.JSX.Element {
  return (
    <Box
      id='AccordionController'
      display='flex'
      flexGrow={1}
      flexShrink={1}
      border={2}
      borderRadius={3}
      justifyContent='flex-start'
      flexDirection='column'
      alignContent='flex-start'
      alignItems='center'
      borderColor='#ECF3F3'
      my={3}
      sx={{
        backgroundColor: '#F5F8F8',
        height: '70vh',
        overflow: 'auto',
        p: 3,
        pt: 0,
        cursor: progress !== 100 ? 'wait' : 'default',
      }}>
      <QuestionnairesTitle selectedTab={selectedTab} handleChange={handleChange} progress={progress} />
      <Box id='AccordionContainer' display='flex' minWidth='100%'>
        <TabPanel selectedTab={selectedTab} index={0}>
          <SimplifiedQuestionnaire />
        </TabPanel>
        <TabPanel selectedTab={selectedTab} index={1}>
          {isSubmitting ? (
            <Box display='flex' justifyContent='center' width='100%'>
              <CircularProgress />
            </Box>
          ) : (
            <AdvancedQuestionnaire />
          )}
        </TabPanel>
      </Box>
    </Box>
  )
}
