import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import React, { ChangeEvent, FormEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import TermOfServiceDialog from '../../components/terme-of-service-dialog'
import { appConstants, pagesUrl } from '../../core/appConstants'
import { useHttp } from '../../core/hooks/use-http'

const PERSON = 'PERSON'
const COMPANY = 'COMPANY'

export default function BeemGuideExcelPage(): React.JSX.Element {
  const [form, setForm] = useState({
    email: '',
    actorType: PERSON,
    personName: '',
    personFirstName: '',
    nationality: '',
    birthdate: '',
    address: '',
    specialty: '',
    companyName: '',
    companyType: '',
    capital: '',
    companyAddress: '',
    register: '',
    siren: '',
    representativeName: '',
  })
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const { post } = useHttp()
  const [error, setError] = useState<Record<string, any>>({})
  const navigate = useNavigate()

  function handleChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    event.preventDefault()
    const target = event.target
    const value = target.value
    const name = target.id

    setError({})

    setForm({
      ...form,
      [name]: value,
    })
  }

  function handleChangeRadio(event: React.ChangeEvent<HTMLInputElement>): void {
    setForm({
      ...form,
      actorType: (event.target as HTMLInputElement).value,
    })
  }

  function validate(): boolean {
    const newError: Record<string, any> = {}
    let isValid = true

    if (!appConstants.regex.email.test(form.email)) {
      newError.email = "L'adresse email ne respecte pas la forme classique : email@complement.fin"
      isValid = false
    } else if (!form.email.endsWith('@gmail.com')) {
      newError.email = "L'adresse email doit être une adresse gmail (finir par @gmail.com)"
      isValid = false
    }

    setError(newError)
    return isValid
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault()
    if (!validate()) {
      return
    }

    setOpenDialog(true)
  }

  function handleClose(): void {
    setOpenDialog(false)
  }

  function accept(): void {
    handleClose()

    post(appConstants.apiEndpoints.BEEM_GUIDE_EXCEL, form)
    navigate(pagesUrl.BEEM_GUIDE_EXCEL_ACCEPT)
  }

  function specificForm(): React.JSX.Element | null {
    if (form.actorType === PERSON) {
      return (
        <>
          <Grid item xs={12} sm={12}>
            <Typography variant='h6'>Personne Physique</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id='personName'
              variant='standard'
              margin='normal'
              required
              fullWidth
              label='Nom'
              value={form.personName}
              onChange={handleChange}
              error={!!error.personName}
              helperText={error.personName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id='personFirstName'
              variant='standard'
              margin='normal'
              required
              fullWidth
              label='Prénom'
              value={form.personFirstName}
              onChange={handleChange}
              error={!!error.personFirstName}
              helperText={error.personFirstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id='nationality'
              variant='standard'
              margin='normal'
              required
              fullWidth
              label='Nationalité'
              value={form.nationality}
              onChange={handleChange}
              error={!!error.nationality}
              helperText={error.nationality}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id='birthdate'
              variant='standard'
              margin='normal'
              required
              fullWidth
              label='Né le'
              value={form.birthdate}
              onChange={handleChange}
              error={!!error.birthdate}
              helperText={error.birthdate}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id='address'
              variant='standard'
              margin='normal'
              required
              fullWidth
              label='Domicilié au'
              value={form.address}
              onChange={handleChange}
              error={!!error.address}
              helperText={error.address}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id='specialty'
              variant='standard'
              margin='normal'
              required
              fullWidth
              label='Spécialité'
              value={form.specialty}
              onChange={handleChange}
              error={!!error.specialty}
              helperText={error.specialty}
            />
          </Grid>
        </>
      )
    } else if (form.actorType === COMPANY) {
      return (
        <>
          <Grid item xs={12} sm={12}>
            <Typography variant='h6'>Société</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id='companyName'
              variant='standard'
              margin='normal'
              required
              fullWidth
              label='Nom'
              value={form.companyName}
              onChange={handleChange}
              error={!!error.companyName}
              helperText={error.companyName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id='companyType'
              variant='standard'
              margin='normal'
              required
              fullWidth
              label="Type d'entreprise"
              value={form.companyType}
              onChange={handleChange}
              error={!!error.companyType}
              helperText={error.companyType}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id='capital'
              variant='standard'
              margin='normal'
              required
              fullWidth
              label='Au capital de '
              value={form.capital}
              onChange={handleChange}
              error={!!error.capital}
              helperText={error.capital}
              InputProps={{
                endAdornment: <InputAdornment position='end'>€</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id='register'
              variant='standard'
              margin='normal'
              required
              fullWidth
              label='Immatriculée au Registre du Commerce et des Sociétés de'
              value={form.register}
              onChange={handleChange}
              error={!!error.register}
              helperText={error.register}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id='siren'
              variant='standard'
              margin='normal'
              required
              fullWidth
              label='Numéro SIREN'
              value={form.siren}
              onChange={handleChange}
              error={!!error.siren}
              helperText={error.siren}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id='representativeName'
              variant='standard'
              margin='normal'
              required
              fullWidth
              label='Nom du représentant'
              value={form.representativeName}
              onChange={handleChange}
              error={!!error.representativeName}
              helperText={error.representativeName}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id='companyAddress'
              variant='standard'
              margin='normal'
              required
              fullWidth
              label='Adresse du siège social'
              value={form.companyAddress}
              onChange={handleChange}
              error={!!error.companyAddress}
              helperText={error.companyAddress}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id='specialty'
              variant='standard'
              margin='normal'
              required
              fullWidth
              label='Spécialité'
              value={form.specialty}
              onChange={handleChange}
              error={!!error.specialty}
              helperText={error.specialty}
            />
          </Grid>
        </>
      )
    } else {
      return null
    }
  }

  return (
    <Container maxWidth='md'>
      <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
        <Box component='form' onSubmit={handleSubmit}>
          <Paper variant='outlined' sx={{ my: { xs: 3, md: 3 }, p: { xs: 2, md: 3 } }}>
            <Typography component='h1' variant='h5' sx={{ pt: '-200px' }} gutterBottom>
              Demande d'accès au Beem Guide
            </Typography>
            <Typography>Bienvenue,</Typography>
            <Typography>
              Pour accèder au Beem Guide, veuillez remplir les informations ci-dessous et accepter les conditions
              d'utilisation.
            </Typography>
            <Typography>
              Le Beem Guide est hébergé sur Google Drive afin d'utiliser le contrôle des droits de la plateforme.
            </Typography>
            <Typography>
              C'est pour cela qu'il vous est demandé une addresse GMail et non pas une adresse email générique.
            </Typography>
            <Typography gutterBottom>N'hésitez pas à créer une adresse GMail pour l'occasion.</Typography>

            <Grid container columnSpacing={3} sx={{ pt: 3 }}>
              <Grid item xs={12} sm={12}>
                <Typography variant='h5'>Général</Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  id='email'
                  variant='standard'
                  margin='normal'
                  required
                  fullWidth
                  label='Adresse gmail'
                  value={form.email}
                  onChange={handleChange}
                  error={!!error.email}
                  helperText={error.email}
                />
              </Grid>
              <Grid item xs={12} sm={12} sx={{ pt: 2 }}>
                <FormControl>
                  <FormLabel id='type-acteur-radio-buttons-group'>Représentez-vous une :</FormLabel>
                  <RadioGroup
                    id='actorType'
                    value={form.actorType}
                    onChange={handleChangeRadio}
                    name='radio-buttons-group'
                    aria-labelledby='type-acteur-radio-buttons-group'>
                    <FormControlLabel value={PERSON} control={<Radio />} label='Personne Physique' />
                    <FormControlLabel value={COMPANY} control={<Radio />} label='Société' />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {specificForm()}
            </Grid>
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant='contained' type='submit'>
                Envoyer la demande
              </Button>
            </Box>
          </Paper>
        </Box>
      </Box>
      {/* Confidentiallity agreement */}
      <TermOfServiceDialog
        handleConfirm={accept}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        data={{
          actorType: form.actorType,
          companyName: form.companyName,
          personFirstName: form.personFirstName,
          personName: form.personName,
          specialty: form.specialty,
        }}
      />
    </Container>
  )
}
