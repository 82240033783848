import { getEnvProperty, PROPERTIES } from './environment-service'

export type RequestParam = {
  key: string
  value: string
}

export type QueryParams = {
  [key: string]: string | number | boolean | undefined | null
}

export async function retry(
  input: RequestInfo,
  token: string | undefined,
  headers: Headers,
  options?: RequestInit
): Promise<Response> {
  if (options && options.headers && token) {
    headers.set('Authorization', `Bearer ${token}`)
    options.headers = headers
  }
  return fetch(input, options)
}

export function addRequestParams(
  url: string,
  token: string | undefined,
  requestParams?: RequestParam[]
): { finalUrl: RequestInfo; headers: Headers } {
  let finalUrl = getEnvProperty(PROPERTIES.REACT_APP_API_URL) + url
  if (requestParams && requestParams.length > 0) {
    finalUrl += '?'
    requestParams.forEach((requestParam, index) => {
      if (index !== 0) {
        finalUrl += '&'
      }
      finalUrl += `${requestParam.key}=${requestParam.value}`
    })
  }
  const headers = new Headers()

  if (token !== undefined) {
    headers.set('Authorization', `Bearer ${token}`)
  }

  return { finalUrl, headers }
}

export function resolveUrl(urlTemplate: string, params: (string | undefined)[], queryParams?: QueryParams): string {
  let url = urlTemplate
  const placeholders = urlTemplate.match(/:[a-z]+/gi) ?? []

  placeholders.forEach((placeholder, index) => {
    const pathVariable = params[index]
    if (index < params.length && pathVariable !== undefined) {
      url = url.replace(placeholder, pathVariable.toString())
    }
  })

  if (queryParams) {
    const queryStrs: string[] = []
    for (const key in queryParams) {
      if (Object.hasOwn(queryParams, key)) {
        const queryParam = queryParams[key]
        if (queryParam !== undefined && queryParam !== null) {
          queryStrs.push(`${encodeURIComponent(key)}=${encodeURIComponent(queryParam.toString())}`)
        }
      }
    }
    if (queryStrs.length) {
      url += `?${queryStrs.join('&')}`
    }
  }

  return url
}

export function resolveUrl1(urlTemplate: string, params: { [key: string]: string }): string {
  let url = urlTemplate
  for (const key in params) {
    // comes from eslint guard-for-in: avoid unwanted inherited properties
    if (Object.hasOwn(params, key)) {
      url = url.replace(`:${key}`, params[key].toString())
    }
  }
  return url
}
