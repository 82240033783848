import { useMemo } from 'react'
import { appConstants } from '../appConstants'
import { Material } from '../dto/material/material'
import { streamToBlob } from '../services/file-service'
import { useHttp } from './use-http'

type MaterialHook = {
  fetchMaterialsByProject: (projectId: string) => Promise<Material[]>
  sendMaterial: (material: Material[]) => Promise<Material[]>
  fetchMaterialsLibByProject: (projectId: string) => Promise<Map<string, Material[]>>
  addIniesToProject: (projectId: string, iniesId: string) => Promise<Material>
  sendFicheConfiguree: (ficheConfiguree: File, projectId: string) => Promise<Response>
  getFicheConfigureeFile(ficheConfigureeId: string): Promise<Blob | undefined>
}

export function useMaterial(): MaterialHook {
  const { get, post, postFile } = useHttp()

  const listToMap = useMemo(
    () => (materials: Material[]) => {
      const hashMap = new Map<string, Material[]>()

      materials.forEach((material: Material) => {
        const materialList: Material[] | undefined = hashMap.get(material.codeAcv)
        if (materialList === undefined) {
          hashMap.set(material.codeAcv, [material])
        } else {
          materialList.push(material)
        }
      })

      return hashMap
    },
    []
  )

  const fetchMaterialsByProject = useMemo(
    () => (projectId: string) =>
      get(`${appConstants.apiEndpoints.MATERIAL}/by-project`, [
        {
          key: 'projectId',
          value: projectId,
        },
      ]).then((response) => response.json()),
    [get]
  )

  return useMemo(
    () => ({
      fetchMaterialsByProject,
      sendMaterial(material: Material[]): Promise<Material[]> {
        return post(`${appConstants.apiEndpoints.MATERIAL}`, material).then((r) => r.json())
      },
      fetchMaterialsLibByProject(projectId: string): Promise<Map<string, Material[]>> {
        return fetchMaterialsByProject(projectId).then((materials: Material[]) => listToMap(materials))
      },
      addIniesToProject(projectId, iniesId): Promise<Material> {
        return post(`${appConstants.apiEndpoints.MATERIAL}/add-inies-to-project`, {}, [
          {
            key: 'projectId',
            value: projectId,
          },
          {
            key: 'iniesId',
            value: iniesId,
          },
        ])
          .then((response) => response.json())
          .then((res: Material) => {
            const material = Material.fromCodeDto(res)
            if (material) {
              return material
            } else {
              throw new Error("Erreur lors de l'affection de la fiche inies")
            }
          })
      },
      sendFicheConfiguree(ficheConfiguree: File, projectId: string): Promise<Response> {
        const formData = new FormData()
        formData.append('ficheConfiguree', ficheConfiguree)
        formData.append('projectId', projectId)
        return postFile(`${appConstants.apiEndpoints.MATERIAL}/add-fiche-configuree-to-project`, formData)
      },
      getFicheConfigureeFile(ficheConfigureeId: string): Promise<Blob | undefined> {
        return get(`${appConstants.apiEndpoints.MATERIAL}/fiche-configuree`, [
          {
            key: 'ficheConfigureeId',
            value: ficheConfigureeId,
          },
        ])
          .then((res) => streamToBlob(res))
          .catch(() => undefined)
      },
    }),
    [fetchMaterialsByProject, listToMap, post, postFile, get]
  )
}
