import SearchIcon from '@mui/icons-material/Search'
import TuneIcon from '@mui/icons-material/Tune'
import { Box, Drawer, Grid, IconButton, InputBase, Pagination, Typography, Zoom } from '@mui/material'
import React, { useContext, useMemo, useState } from 'react'
import city from '../../../../assets/img/empty-result-city.png'
import { BsProjectCard } from '../../../components/card/bs-project-card'
import SelectInput from '../../../components/inputs/select-input/select-input'
import { DefaultTypography } from '../../../components/typography/default-typography'
import { BSProjectListContext } from '../../../core/context/beem-shot/BSProject/BSProjectListContext'
import { BSProjectCardDto } from '../../../core/dto/beem-shot/BSProject/BSProjectCardDto'
import { ProjectStatusEnum, projectStatusEnumLabels } from '../../../core/enum/projectStatusEnum'
import { enumToSelectOptions } from '../../../core/services/helper-service'

const ITEMS_PER_PAGE = 6

type IProps = {
  setNumberProject: React.Dispatch<React.SetStateAction<number>>
}

export function BSProjectList({ setNumberProject }: IProps): React.JSX.Element {
  const { bsProjectList, statusFilter, setStatusFilter } = useContext(BSProjectListContext)

  const [openDrawerFilter, setOpenDrawerFilter] = React.useState(false)
  const [searchState, setSearchState] = useState<string | undefined>(undefined)
  const [page, setPage] = useState(1)

  const startIndex = useMemo(() => (page - 1) * ITEMS_PER_PAGE, [page])
  const endIndex = useMemo(() => startIndex + ITEMS_PER_PAGE, [startIndex])

  const itemsToShow = useMemo(() => {
    const list = bsProjectList
      .filter((x) => x.status === statusFilter)
      .filter(
        (x) =>
          x.name.toLowerCase().includes(searchState?.toLowerCase() ?? '') ||
          x.businessCode.toLowerCase().includes(searchState?.toLowerCase() ?? '') ||
          x.projectOrganizationBusinessName.toLowerCase().includes(searchState?.toLowerCase() ?? '')
      )
    setNumberProject(list.length)
    return list
  }, [bsProjectList, searchState, setNumberProject, statusFilter])

  function handleChange(event: any, value: React.SetStateAction<number>): void {
    setPage(value)
  }

  function handleSelectChange(value: ProjectStatusEnum): void {
    setStatusFilter(value)
  }

  function handleSearch(e: string): void {
    setPage(1)
    setSearchState(e)
  }

  return (
    <Box display='flex' flexDirection='column' height='100%' sx={{ pt: 2 }}>
      <DefaultTypography label='Estimez l’empreinte carbone de vos projets BIM.' color='#070F27' fontWeight={400} />
      <Grid
        container
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        flexDirection='row'
        rowGap={1}
        width='100%'
        sx={{ py: 1 }}>
        <Grid
          item
          xs={5}
          sx={{
            flex: 3,
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#ffffff',
            borderRadius: 3,
          }}>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            value={searchState}
            placeholder='Rechercher par nom, numéro d’affaire, propriétaire'
            inputProps={{ 'aria-label': 'search' }}
            endAdornment={<SearchIcon sx={{ margin: '0 8px' }} />}
            onChange={(e: { target: { value: any } }) => {
              handleSearch(e.target.value)
            }}
          />
        </Grid>

        <Grid item xs={2} display='flex' flexDirection='row' alignItems='center'>
          <SelectInput
            id='bsProjectStatusFilter'
            label='Statut'
            selectedOption={statusFilter}
            options={enumToSelectOptions(ProjectStatusEnum, projectStatusEnumLabels)}
            mode='direct'
            handleChange={(e) => handleSelectChange(e)}
            isBeemShot
            size='small'
          />
          <IconButton onClick={() => setOpenDrawerFilter(true)} sx={{ background: '#C9FFE8', borderRadius: 2, ml: 2 }}>
            <TuneIcon fontSize='medium' sx={{ color: '#009069' }} />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container columnSpacing={4} alignItems='center' height='100%' rowGap={3}>
        {itemsToShow.length > 0 ? (
          itemsToShow
            .slice(startIndex, endIndex)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((bsProject: BSProjectCardDto, index) => (
              <Zoom in style={{ transitionDelay: `${index * 150}ms` }} key={bsProject.id}>
                <Grid item xs={12} md={4} lg={4} key={bsProject.id}>
                  <BsProjectCard key={bsProject.id} bsProjectCardInfo={bsProject} hasCoverImage={false} />
                </Grid>
              </Zoom>
            ))
        ) : (
          <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' height='100%' width='100%'>
            <Typography fontSize='24px' fontWeight={600} lineHeight='36px' textAlign='center' color='#374771'>
              Aucun résultat
            </Typography>
            <Typography variant='body1'>Modifier les options de recherche.</Typography>
            <Box sx={{ p: 2 }}>
              <img
                src={city}
                alt={city}
                height='300px'
                width='max-content'
                style={{
                  marginLeft: 15,
                  marginTop: -8,
                }}
              />
            </Box>
          </Box>
        )}
      </Grid>

      <Box
        display='flex'
        flexGrow={1}
        justifyContent='right'
        alignItems='flex-end'
        alignContent='flex-end'
        alignSelf='flex-end'>
        <Pagination
          count={Math.ceil(itemsToShow.length / ITEMS_PER_PAGE)}
          page={page}
          onChange={handleChange}
          color='primary'
        />
      </Box>

      <Drawer open={openDrawerFilter} onClose={() => setOpenDrawerFilter(false)} anchor='right'>
        Have to be implemented ; )
      </Drawer>
    </Box>
  )
}
