import { Groups, Logout, Settings } from '@mui/icons-material'

import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  styled,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import React, { useContext, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ReactComponent as ReactLogoTTB } from '../../../assets/LogoTTB_black.svg'
import { pagesUrl } from '../../core/appConstants'
import { OrganizationContext } from '../../core/context/organization/organization-context'
import { UserContext, UserStore } from '../../core/context/user/user-context'
import { ProjectTypeEnum } from '../../core/enum/project/projectTypeEnum'
import {
  hasSuperUserRights,
  isMegaUser,
  isNormalUser,
  isTestUser,
  isUltimateUser,
} from '../../core/services/authentication-service'
import { resolveUrl } from '../../core/services/http-service'
import { MenuItemType } from '../../core/type/MenuItemType'
import MenuNotification from '../notifications/notifications-menu'
import './app-navbar.scss'
import { InfoDialog } from '../dialog/InfoDialog'

type IProps = {
  readonly mainMenu: MenuItemType[]
}

const SPACE = ' '

export function AppNavbar({ mainMenu }: IProps): React.JSX.Element {
  const { user, logout, setIsFromLogoutButton } = React.useContext<UserStore>(UserContext)
  const { organization } = useContext(OrganizationContext)
  const { pathname } = useLocation()
  const [anchorElUser, setAnchorElUser] = React.useState(null)

  const initialDialogsStates = useMemo(
    () => {
      const dialogs: Record<string, boolean> = {}
      mainMenu.forEach((menuItem) => {
        dialogs[menuItem.id] = false
      })
      return dialogs
    },
    // mainMenu
    []
  )

  const [dialogsStates, setDialogsStates] = useState<Record<string, boolean>>(initialDialogsStates)

  /* Definition initial utilisateur */
  const initName = user?.givenName?.[0]?.toUpperCase() ?? ''
  const fullName = user ? user.givenName + SPACE + user.familyName : ''
  const ultimateUser = isUltimateUser(user)
  const megaUser = isMegaUser(user)
  const superUser = hasSuperUserRights(user)
  const normalUser = isNormalUser(user)
  const testUser = isTestUser(user)

  /* Declaration account menu */
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  function handleClick(event: any): void {
    setAnchorEl(event.currentTarget)
  }

  function handleClose(): void {
    setAnchorEl(null)
  }

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    },
  }))

  function onLogout(): Promise<void> {
    handleCloseUserMenu()
    return logout().then(() => {
      setIsFromLogoutButton(true)
    })
  }

  const settings = [{ label: 'Se déconnecter', action: onLogout }]

  function handleCloseUserMenu(): void {
    setAnchorElUser(null)
  }

  function displayMenu(page: MenuItemType): boolean {
    let hasRightToSeeMenuItem = false

    let isActive: boolean
    if (typeof page.isActive !== 'boolean' && organization !== undefined) {
      isActive = page.isActive(organization)
    } else if (typeof page.isActive === 'boolean') {
      isActive = page.isActive
    } else {
      isActive = false
    }

    if (
      (page.isForMegaUser && megaUser) ||
      (page.isForSuperUser && superUser) ||
      (page.isForTestUser && testUser) ||
      (page.isForNormalUser && normalUser) ||
      (page.isForUltimateUser && ultimateUser)
    ) {
      hasRightToSeeMenuItem = true
    }

    return user ? isActive && hasRightToSeeMenuItem : false
  }

  function openDialog(menuItem: MenuItemType): void {
    setDialogsStates((previous) => ({ ...previous, [menuItem.id]: true }))
  }

  function closeDialog(menuItem: MenuItemType): void {
    setDialogsStates((previous) => ({ ...previous, [menuItem.id]: false }))
  }

  function applyHasDialog(menuItem: MenuItemType): boolean {
    return menuItem.hasDialog ? menuItem.hasDialog(organization) : false
  }

  return (
    <header>
      <AppBar className='navBar'>
        <Toolbar disableGutters>
          {/*Computer screen*/}
          <Grid container columnSpacing={1}>
            <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
              <Link
                to={resolveUrl(pagesUrl.PROJECTS_PAGE, [], { type: ProjectTypeEnum.BIM })}
                style={{ height: '50px', display: 'flex', alignItems: 'center' }}>
                <ReactLogoTTB className='logo' />
              </Link>
            </Grid>
            <Grid
              item
              xs={9}
              sx={{
                flexGrow: 1,
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                display: { xs: 'none', md: 'flex' },
                columnGap: 10,
              }}>
              {mainMenu.map(
                (menuItem) =>
                  displayMenu(menuItem) && (
                    <span key={menuItem.id}>
                      <Tooltip title={menuItem.tooltip ?? ''}>
                        <Link
                          to={applyHasDialog(menuItem) ? '#' : menuItem.link}
                          style={{ textDecoration: 'none', color: 'inherit' }}>
                          <Button
                            key={menuItem.label}
                            sx={{ display: 'flex', color: 'white', width: 'auto' }}
                            onClick={() => openDialog(menuItem)}>
                            <Box
                              sx={{
                                display: 'flex',
                                alignContent: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                                columnGap: '7px',
                              }}>
                              {menuItem.hasChip && (
                                <Chip
                                  label={menuItem.chipText}
                                  sx={{
                                    position: 'absolute',
                                    top: -8,
                                    right: -35,
                                    fontSize: 9,
                                    height: 16,
                                    backgroundColor: menuItem.chipColor,
                                  }}
                                />
                              )}
                              <Typography
                                variant={pathname === menuItem.link ? 'body1' : 'body2'}
                                component='span'
                                fontWeight={pathname === menuItem.link ? 'bold' : 'medium'}
                                sx={{ whiteSpace: 'nowrap' }}>
                                {menuItem.label}
                              </Typography>
                            </Box>
                          </Button>
                        </Link>
                      </Tooltip>
                      {applyHasDialog(menuItem) && (
                        <InfoDialog
                          title={menuItem.dialogTitle ?? ''}
                          isOpen={dialogsStates[menuItem.id]}
                          content={menuItem.dialogContent}
                          handleClose={() => closeDialog(menuItem)}
                        />
                      )}
                    </span>
                  )
              )}
            </Grid>

            <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'flex-end', padding: '0% 1%' }}>
              {!ultimateUser && <MenuNotification />}
              <Tooltip title='Paramètre du compte'>
                <IconButton
                  id='basic-button'
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}>
                  <StyledBadge overlap='circular' anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant='dot'>
                    <Avatar sx={{ width: 45, height: 45 }}>{initName}</Avatar>
                  </StyledBadge>
                </IconButton>
              </Tooltip>
              <Menu
                id='account-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'account-menu',
                }}>
                <Box
                  component='li'
                  sx={{
                    height: 120,
                    width: '100%',
                    display: 'flex',
                    alignContent: 'center',
                    alignItems: 'center',
                    p: '6px 16px',
                  }}>
                  <Avatar sx={{ m: 1 }}>{initName}</Avatar>
                  <Typography className='notranslate'>{fullName}</Typography>
                </Box>
                <Divider sx={{ mt: '8px', mb: '8px' }} />
                <MenuItem sx={{ width: 300 }} component={Link} to={pagesUrl.ACCOUNT_PAGE} onClick={handleClose}>
                  <ListItemIcon>
                    <Settings fontSize='small' />
                  </ListItemIcon>
                  Mon compte
                </MenuItem>
                {superUser
                  ? [
                      <MenuItem
                        key='my-organization'
                        sx={{ width: 300 }}
                        component={Link}
                        to={pagesUrl.MY_ORGANIZATION_PAGE}
                        onClick={handleClose}>
                        <ListItemIcon>
                          <Groups fontSize='small' />
                        </ListItemIcon>
                        Mon organisation
                      </MenuItem>,
                    ]
                  : null}
                <Divider />
                <MenuItem sx={{ width: 300 }} component={Link} to={pagesUrl.SUPPORT_PAGE} onClick={handleClose}>
                  <ListItemIcon>
                    <SupportAgentIcon fontSize='small' />
                  </ListItemIcon>
                  Support
                </MenuItem>
                <Divider />
                <MenuItem sx={{ width: 300 }} onClick={onLogout}>
                  <ListItemIcon>
                    <Logout fontSize='small' />
                  </ListItemIcon>
                  Se déconnecter
                </MenuItem>
              </Menu>
            </Grid>
            {/* All screen */}
            <Grid item xs={0} sx={{ flexGrow: 0 }}>
              <Menu
                sx={{ mt: '45px' }}
                id='menu-appbar'
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}>
                {settings.map((setting) => (
                  <MenuItem key={setting.label} onClick={setting.action}>
                    <Typography textAlign='center'>{setting.label}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </header>
  )
}
