import AddIcon from '@mui/icons-material/Add'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Modal, TextField, Typography } from '@mui/material'
import React, { ChangeEvent, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { DisplayerMode, fileTypeAccepted, UploadImageModal } from '../../../../components/image-displayer/upload-image-modal'
import { NumberInput } from '../../../../components/inputs/number-input/number-input'
import { ErrorContext } from '../../../../components/layout/error-snackbar'
import { ProjectContext } from '../../../../core/context/project/project-context'
import { CodeCompletion } from '../../../../core/dto/code-completion/code-completion'
import IniesRecord from '../../../../core/dto/material/inies-record'
import { Material } from '../../../../core/dto/material/material'
import MaterialRecord from '../../../../core/dto/material/material-record'
import { useCode } from '../../../../core/hooks/use-code'
import { useMaterial } from '../../../../core/hooks/use-material'
import { getLabelForIniesUnit } from '../../../../core/services/unit-service'
import AssignMaterialCalculationModalFormModal from '../components/material-page-modal-add-inies/assign-material-calculation-modal'
import { MaterialPageCard } from '../components/material-page-modal-add-inies/material-page-card'
import { validateReuseImage } from '../extracted-code-page/form-add-material-to-extracted'
import { AffectMaterialInput } from '../../../../components/inputs/affect-material-input/affect-material-input'

type IProps = {
  selectedAdditionalCode: CodeCompletion
  setSelectedAdditionalCode: Dispatch<SetStateAction<CodeCompletion | undefined>>
  handleUpdateAdditionalCode: (projectId: string, additionalCodeToUpdate: CodeCompletion) => void
  disabled: boolean
}

export default function FormAddMaterialToAdditional({
  selectedAdditionalCode,
  setSelectedAdditionalCode,
  handleUpdateAdditionalCode,
  disabled,
}: Readonly<IProps>): React.JSX.Element {
  const { project } = useContext(ProjectContext)

  const { addIniesToProject } = useMaterial()

  const [projectMaterial, setProjectMaterial] = useState<Material | undefined>(selectedAdditionalCode.material)
  const [isAssignMaterialModalOpen, setIsAssignMaterialModalOpen] = useState<boolean>(false)
  const [filterMaterialIdSelected, setFilterMaterialIdSelected] = useState<string>('')
  const [displayMaterialModalOpen, setDisplayMaterialModalOpen] = useState(false)

  const { sendAdditionalCodeMaterialReuseImage, fetchReuseImageAdditionalCode } = useCode()

  const openErrorSnackbar = useContext(ErrorContext)

  const [file, setFile] = useState<File | undefined>(undefined)

  const [fileLoading, setFileLoading] = useState<boolean>(false)
  const [openModalFile, setOpenModalFile] = useState<boolean>(false)
  const [validateImage, setValidateImage] = useState<boolean>(true)
  const [imageTextError, setImageTextError] = useState<string>()

  const [displayerMode, setDisplayerMode] = useState<DisplayerMode>(DisplayerMode.SAVE)

  useEffect(() => {
    setProjectMaterial(selectedAdditionalCode.material)
  }, [selectedAdditionalCode])

  useEffect(() => {
    if (selectedAdditionalCode.awsFileKey !== null) {
      setDisplayerMode(DisplayerMode.UPDATE)
    }
  }, [selectedAdditionalCode.awsFileKey, selectedAdditionalCode])

  function openAssignMaterialModal(): void {
    if (selectedAdditionalCode) {
      setFilterMaterialIdSelected(`${selectedAdditionalCode.id}}`) // TODO : It seems to be an additional '}'. Try remove it. Weirdly, the feature seem to be working currently so careful...
    }
    setIsAssignMaterialModalOpen(true)
  }

  function isReuseChecked(): boolean {
    if (selectedAdditionalCode) {
      return selectedAdditionalCode.reuse
    } else {
      return false
    }
  }

  async function handleAssignMaterial(newValue: IniesRecord | MaterialRecord, isMaterial?: boolean): Promise<void> {
    if (project?.id) {
      let material: Material
      if (!isMaterial) {
        // If it is an inies record, we need to create the material into the "project material lib"
        material = await addIniesToProject(project.id, `${newValue.iniesId}`)
      } else {
        // If it is a MaterialRecord, it means it comes from the "project material lib"
        material = Material.from(newValue, project)
      }
      selectedAdditionalCode.material = material
      selectedAdditionalCode.materialId = material.id
      selectedAdditionalCode.reuse = false
      handleUpdateAdditionalCode(project.id, selectedAdditionalCode)
      setProjectMaterial(material)
    }
  }

  function updateAdditionalCode(): void {
    // This send the update to the server
    if (project?.id && !disabled) {
      handleUpdateAdditionalCode(project.id, selectedAdditionalCode)
    }
  }

  function handleUpdateAmount(value: string): void {
    // This only update local state
    setSelectedAdditionalCode({ ...selectedAdditionalCode, amount: parseFloat(value) })
  }

  function handleUpdateHypothesis(e: { target: { name: string; value: string } }): void {
    // This only update local state
    setSelectedAdditionalCode({ ...selectedAdditionalCode, hypothesis: e.target.value })
  }

  function handleReuseChange(checked: boolean): void {
    if (project?.id) {
      selectedAdditionalCode.reuse = checked
      if (checked) {
        selectedAdditionalCode.materialId = undefined
        selectedAdditionalCode.material = undefined
        selectedAdditionalCode.amount = 0
      }

      handleUpdateAdditionalCode(project.id, selectedAdditionalCode)
    }
  }

  function closeAssignMaterialModal(): void {
    setIsAssignMaterialModalOpen(false)
    setFilterMaterialIdSelected('')
  }

  function handleChangeFile(event: ChangeEvent<HTMLInputElement>): void {
    if (event?.target?.files?.[0]) {
      onChange(event.target.files[0])
      setDisplayerMode(DisplayerMode.SAVE)
      event.target.value = ''
    }
  }

  function onChange(coverImageFile: File): void {
    const image = new Image()
    image.src = URL.createObjectURL(coverImageFile)
    image.onload = () => {
      validateReuseImage(coverImageFile.size, coverImageFile.name, setImageTextError, setValidateImage)
    }
    setOpenModalFile(true)
    setFileLoading(true)
    setFile(coverImageFile)
  }

  function reuseImageModal(): void {
    if (selectedAdditionalCode.id) {
      fetchReuseImageAdditionalCode(selectedAdditionalCode.id)
        .then(async (blob) => {
          if (blob) {
            const fileName = selectedAdditionalCode.filename
            let newFile: SetStateAction<File | undefined>
            if (fileName) {
              newFile = new File([blob], fileName, { type: selectedAdditionalCode.fileType })
            }
            setFile(newFile)
            setValidateImage(true)
          }
        })
        .then(() => {
          setFileLoading(true)
        })
      setOpenModalFile(true)
    }
  }

  function saveFile(): void {
    if (!file) {
      openErrorSnackbar(new Error("Erreur: il n'y a pas de fichier chargé"))
      return
    }
    if (!project.id) {
      openErrorSnackbar(new Error("Aucun projet n'est sélectionné."))
      return
    }
    if (!selectedAdditionalCode.id) {
      openErrorSnackbar(new Error("Aucun code n'est sélectionné."))
      return
    }

    sendAdditionalCodeMaterialReuseImage(file, project.id, selectedAdditionalCode.id).catch(() => setFile(undefined))
    selectedAdditionalCode.filename = file.name

    setOpenModalFile(false)
  }

  function cancelUpload(): void {
    if (!file) {
      setFile(undefined)
    }
    setOpenModalFile(false)
  }

  function handleCloseReuseModalImage(): void {
    setOpenModalFile(false)
    setFile(undefined)
    setFileLoading(false)
  }

  function removeMaterial(): void {
    if (project?.id) {
      selectedAdditionalCode.materialId = undefined
      selectedAdditionalCode.material = undefined
      selectedAdditionalCode.amount = 0
      selectedAdditionalCode.reuse = false
      handleUpdateAdditionalCode(project.id, selectedAdditionalCode)
    }
  }

  return (
    <>
      <UploadImageModal
        file={file}
        saveFile={saveFile}
        cancelUpload={cancelUpload}
        validateImage={validateImage}
        handleChangeFile={handleChangeFile}
        imageTextError={imageTextError}
        fileType={fileTypeAccepted}
        open={openModalFile}
        handleClose={handleCloseReuseModalImage}
        imageTitle='Matériau de réemploi'
        isloading={fileLoading}
        displayMode={displayerMode}
      />

      {/*Modal to assign material on a code*/}
      <AssignMaterialCalculationModalFormModal
        handleAssignMaterial={handleAssignMaterial}
        isAssignMaterialModalOpen={isAssignMaterialModalOpen}
        closeAssignMaterialModal={closeAssignMaterialModal}
        materialId={filterMaterialIdSelected}
      />

      {/*Form to assign material and values in the additional code which is selected*/}
      <Box
        component='div'
        display='flex'
        flexDirection='column'
        sx={{ pr: 3, pl: 3, pt: 4, pb: 4, backgroundColor: '#ebebeb' }}>
        <Modal
          open={displayMaterialModalOpen}
          onClose={() => setDisplayMaterialModalOpen(false)}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          aria-labelledby='add-material-modal'
          aria-describedby='modal-modal-description'>
          <Box>
            <MaterialPageCard
              material={projectMaterial}
              isMaterial
              setDisplayMaterialModalOpen={setDisplayMaterialModalOpen}
            />
          </Box>
        </Modal>

        <Typography sx={{ textTransform: 'uppercase', fontWeight: 'bold', mb: 4 }}>{selectedAdditionalCode.name}</Typography>
        <Grid container>
          <Grid item sx={{ minHeight: '350px' }}>
            <Box component='div' sx={{ display: 'flex', flexDirection: 'row', marginLeft: '20px' }}>
              <AffectMaterialInput
                openAssignMaterialModal={openAssignMaterialModal}
                disabled={disabled}
                removeMaterial={removeMaterial}
                selectedCode={selectedAdditionalCode}
              />
            </Box>
            {selectedAdditionalCode && projectMaterial && (
              <Button
                className='notranslate'
                style={{ textTransform: 'none' }}
                endIcon={<ArrowOutwardIcon />}
                sx={{ mb: 2, border: 1, borderWidth: 2 }}
                onClick={() => setDisplayMaterialModalOpen(true)}>
                {projectMaterial.fdesName}
              </Button>
            )}
            <Box component='div' display='flex' flexDirection='column'>
              <FormGroup
                sx={{
                  display: 'flex',
                  alignContent: 'space-around',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={disabled}
                      checked={selectedAdditionalCode?.reuse ? selectedAdditionalCode.reuse : false}
                      onChange={(_, checked) => {
                        handleReuseChange(checked)
                      }}
                    />
                  }
                  label="Il s'agit d'un matériau de réemploi"
                />
              </FormGroup>
            </Box>

            {isReuseChecked() && (
              <Box component='div' sx={{ display: 'flex', flexDirection: 'row', marginLeft: '20px' }}>
                <Box component='label' htmlFor='upload-photo'>
                  <input
                    accept={fileTypeAccepted}
                    style={{ display: 'none' }}
                    onChange={handleChangeFile}
                    id='upload-photo'
                    name='upload-photo'
                    type='file'
                  />
                  <Button disabled={disabled} style={{ textTransform: 'none' }} sx={{ mb: 2 }} component='span'>
                    <AddIcon sx={{ mr: 2 }} />
                    Affecter une image
                  </Button>
                </Box>
              </Box>
            )}

            {selectedAdditionalCode.filename && isReuseChecked() && (
              <Button
                disabled={disabled}
                className='notranslate'
                onClick={reuseImageModal}
                style={{ textTransform: 'none' }}
                sx={{ mb: 2, border: 1, borderWidth: 2 }}>
                {selectedAdditionalCode.filename}
              </Button>
            )}

            {projectMaterial && (
              <div onBlur={updateAdditionalCode}>
                <NumberInput
                  id='amount'
                  disabled={disabled}
                  label='Unité'
                  variant='outlined'
                  size='small'
                  value={selectedAdditionalCode?.amount ? selectedAdditionalCode.amount : 0}
                  handleChange={handleUpdateAmount}
                  decimalScale={2}
                  unit={getLabelForIniesUnit(projectMaterial.functionalUnit as any)}
                />
              </div>
            )}
            <div onBlur={updateAdditionalCode}>
              <TextField
                sx={{ mt: 2, width: '100%' }}
                value={selectedAdditionalCode.hypothesis ? selectedAdditionalCode.hypothesis : ''}
                onChange={handleUpdateHypothesis}
                label='Hypothèse BE'
                multiline
                rows={4}
                disabled={disabled}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
