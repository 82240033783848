import { Button, Grid } from '@mui/material'
import React, { ReactElement } from 'react'

interface ButtonProps {
  children: string | React.JSX.Element
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
  background?: string
}

export default function CalculatorButton({ children, onClick, background }: ButtonProps): ReactElement {
  return (
    <Grid item xs={3} sx={{ height: 56 }}>
      <Button
        style={{ width: '100%', height: '100%', background: background ?? '#E5E5E5', borderRadius: 'unset !important' }}
        onClick={onClick}>
        {children}
      </Button>
    </Grid>
  )
}
