import CircleIcon from '@mui/icons-material/Circle'
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { SubsetInfo } from '../../../../../components/ifc-displayer/models/SubsetInfo'
import { codeStateEnum } from '../../../../../core/enum/codeStateEnum'
import '../../components/bim-model-analyzer/project-bim-model-list/project-bim-model-list.css'

type iProps = {
  variant?: codeStateEnum
  codeManquantElements: SubsetInfo[]
  selectedManquantElement?: SubsetInfo
  setSelectedManquantElement?: (manquantElement: SubsetInfo) => void
}

export default function BimModelManquantList({
  variant,
  codeManquantElements,
  selectedManquantElement,
  setSelectedManquantElement,
}: iProps): React.JSX.Element {
  const tableRef = useRef<HTMLTableElement>(null)

  useEffect(() => {
    if (tableRef) {
      const rowsList = tableRef.current?.querySelectorAll('tr')
      rowsList?.forEach((row) => {
        if (row.id === selectedManquantElement?.id) {
          row.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
        }
      })
    }
  }, [selectedManquantElement, variant, codeManquantElements])

  const data: SubsetInfo[] = codeManquantElements

  return (
    <Box
      component='div'
      sx={{
        overflow: 'hidden',
        height: 'calc(100vh - 350px)',
        display: variant === codeStateEnum.CODE_MANQUANT ? 'block' : 'none',
      }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                p: 0,
                width: '30%',
              }}>
              Type IFC
            </TableCell>
            <TableCell
              sx={{
                p: 0,
                width: '15%',
              }}>
              Etiquette
            </TableCell>
            <TableCell
              sx={{
                p: 0,
                width: '15%',
              }}>
              Nombre
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
      <Box className='table-bim-model' component='div' sx={{ flex: 1, overflow: 'auto', maxHeight: '800px' }}>
        <Table style={{ tableLayout: 'fixed' }} ref={tableRef}>
          {data !== undefined && data.length > 0 ? (
            <TableBody>
              {data.map((row) => {
                const keyCode = row.id
                return (
                  <TableRow
                    id={keyCode}
                    key={keyCode}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      backgroundColor: row.id === selectedManquantElement?.id ? '#ebebeb' : '',
                    }}
                    onClick={() => {
                      if (setSelectedManquantElement) setSelectedManquantElement(row)
                    }}>
                    <TableCell
                      sx={{
                        p: 0,
                        width: '30%',
                      }}>
                      {row.name}
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        width: '15%',
                      }}>
                      <CircleIcon sx={{ fontSize: '48px', color: 'red' }} />
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        width: '15%',
                      }}>
                      {row.elementIds.length}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    p: 0,
                    width: '100%',
                  }}>
                  Pas de données..
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Box>
    </Box>
  )
}
