import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SubmitButton } from '../../../components/buttons/SubmitButton/SubmitButton'
import CancelButton from '../../../components/buttons/cancel-button/cancel-button'
import { acceptedExtensions, DisplayerMode, UploadImageModal } from '../../../components/image-displayer/upload-image-modal'
import { DateInput } from '../../../components/inputs/date-input/date-input'
import BsFileInputImage from '../../../components/inputs/file-input/bs-file-input-image'
import { TextInput } from '../../../components/inputs/text-input/text-input'
import { ErrorContext } from '../../../components/layout/error-snackbar'
import { DefaultTypography } from '../../../components/typography/default-typography'
import { TitleFormTypography } from '../../../components/typography/title-form-typography'
import { appConstants } from '../../../core/appConstants'
import {
  BSProjectContext,
  dtoToForm,
  formToDto,
  IForm,
  updateFormToDto,
} from '../../../core/context/beem-shot/BSProject/BSProjectContext'
import { useBSProject } from '../../../core/hooks/beem-shot/useBSProject'
import { useForm } from '../../../core/hooks/form/use-form'
import { required, zipCode } from '../../../core/hooks/form/validation'

interface IProps {
  open: boolean
  handleClose(openCreateVariant?: boolean): void
}

export function BSProjectForm({ open, handleClose }: Readonly<IProps>): React.JSX.Element {
  const fileTypeAccepted = '.png,.jpeg,.jpg'
  const { bsProject, createProject, updateProject } = useContext(BSProjectContext)
  const openErrorSnackbar = useContext(ErrorContext)
  const { deleteCoverImage, sendCoverImageFile, fetchCoverImage } = useBSProject()
  const navigate = useNavigate()
  const [imageTextError, setImageTextError] = useState<string>()
  const [validateImage, setValidateImage] = useState<boolean>(false)
  const [openImageModal, setOpenImageModal] = useState<boolean>(false)
  const [tmpFile, setTmpFile] = useState<File | undefined>()
  const [file, setFile] = useState<File | undefined>()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const isCreation: boolean = useMemo(() => bsProject?.id === 'new', [bsProject?.id])

  useEffect(() => {
    if (bsProject?.coverImgFileName && bsProject?.id && bsProject?.coverImgFileName) {
      fetchCoverImage(bsProject.id).then(async (blob) => {
        if (blob) {
          const fileName = bsProject.coverImgFileName
          const newFile = new File([blob], fileName, { type: fileTypeAccepted })
          setFile(newFile)
        }
      })
    }
  }, [fetchCoverImage, bsProject?.coverImgFileName, bsProject?.id])

  const submit: (form: IForm) => Promise<any> = useCallback(
    (form: IForm) => {
      if (bsProject?.id) {
        setIsSubmitting(true)
        return updateProject(updateFormToDto(bsProject, form))
          .then(() => {
            onClose()
          })
          .finally(() => setIsSubmitting(false))
      } else {
        setIsSubmitting(true)
        return createProject(formToDto(form))
          .then((newBsProject) => {
            onClose(true)
          })
          .finally(() => setIsSubmitting(false))
      }
    },
    // onClose
    [bsProject, createProject, updateProject]
  )

  const { form, errors, handleChange, handleSubmit, resetForm } = useForm(
    bsProject,
    dtoToForm,
    [
      required('name'),
      required('businessCode'),
      required('street'),
      required('zipCode'),
      zipCode('zipCode'),
      required('city'),
    ],
    submit
  )

  function handleChangeDate(date: Date): void {
    if (!date) {
      return
    }
    // It's when handleChange expects ChangeEvent
    const e = {
      target: {
        id: 'licenceDate',
        value: date,
      },
    } as unknown as ChangeEvent<HTMLInputElement>

    handleChange(e)
  }

  function onClose(openCreateVariant?: boolean): void {
    resetForm()
    handleClose(openCreateVariant)
  }

  function onChange(coverImageFile: File): void {
    const image = new Image()
    image.src = URL.createObjectURL(coverImageFile)
    image.onload = () => {
      const width = image.width
      const height = image.height
      validateCoverImage(coverImageFile.size, height, width, coverImageFile.name)
    }
    setOpenImageModal(true)
    setTmpFile(coverImageFile)
  }

  function validateCoverImage(size: number, height: number, width: number, filename: string): void {
    if (size > appConstants.miscellaneous.PROJECT_IMG_MAX_SIZE * 1024 * 1024) {
      setImageTextError(
        `Le fichier est trop volumineux. La taille maximale est de ${appConstants.miscellaneous.PROJECT_IMG_MAX_HEIGTH} Mo.`
      )
      setValidateImage(false)
    } else if (
      height > appConstants.miscellaneous.PROJECT_IMG_MAX_HEIGTH ||
      width > appConstants.miscellaneous.PROJECT_IMG_MAX_WIDTH
    ) {
      setImageTextError(
        `Le fichier est trop volumineux. La taille maximale est de ${appConstants.miscellaneous.PROJECT_IMG_MAX_HEIGTH} x ${appConstants.miscellaneous.PROJECT_IMG_MAX_HEIGTH}.`
      )
      setValidateImage(false)
    } else if (!acceptedExtensions.includes(filename.toLowerCase().substring(filename.lastIndexOf('.')))) {
      setImageTextError(`Le format du fichier n'est pas valide. Voici les extensions acceptés: ${fileTypeAccepted}`)
      setValidateImage(false)
    } else {
      setValidateImage(true)
    }
  }

  function sendCoverImage(id: string | undefined): void {
    if (tmpFile === undefined) {
      openErrorSnackbar(new Error("Erreur: il n'y a pas de fichier chargé"))
      return
    }
    if (id === undefined) {
      openErrorSnackbar(new Error("Aucun projet n'est sélectionné."))
      return
    }

    sendCoverImageFile(tmpFile, id).catch((e) => {
      console.error("Erreur lors de l'enregistrement de l'image: ", e)
      setImageTextError("Erreur lors de l'enregistrement de l'image. Veuillez rafraîchir votre page")
      setFile(undefined)
    })
  }

  function deleteImage(): void {
    if (bsProject?.id) {
      deleteCoverImage(bsProject.id).then(() => {
        setFile(undefined)
      })
    }
  }

  function saveFile(): void {
    if (!isCreation) {
      sendCoverImage(bsProject?.id)
      setOpenImageModal(false)
    }
  }

  function cancelUpload(): void {
    setTmpFile(undefined)
    setOpenImageModal(false)
  }
  function handleCloseModal(): void {
    setOpenImageModal(false)
  }

  return (
    <Dialog open={open} maxWidth='md' fullWidth onClose={() => onClose()}>
      <Box component='form' id='create-bs-form' onSubmit={handleSubmit} sx={{ m: 1 }}>
        <UploadImageModal
          file={tmpFile}
          saveFile={saveFile}
          cancelUpload={cancelUpload}
          validateImage={validateImage}
          imageTextError={imageTextError}
          fileType={fileTypeAccepted}
          open={openImageModal}
          handleClose={handleCloseModal}
          imageTitle='Valider la photo de couverture'
          isloading
          displayMode={DisplayerMode.SAVE}
        />
        <DialogTitle>
          {bsProject?.id ? (
            <TitleFormTypography label='Mettre à jour le projet Beem Shot' />
          ) : (
            <TitleFormTypography label='Nouveau projet Beem Shot' />
          )}
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={3} container>
              <BsFileInputImage
                onChange={onChange}
                cancelUpload={cancelUpload}
                deleteImage={deleteImage}
                file={file}
                disabled={false}
                fileType={fileTypeAccepted}
              />
            </Grid>
            <Grid item xs={9} container>
              <Grid item xs={12}>
                <DefaultTypography label='Informations' />
                <TextInput
                  fieldName='name'
                  label='Renseignez le nom du projet'
                  handleChange={handleChange}
                  form={form}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  fieldName='businessCode'
                  label="Numéro de l'affaire"
                  handleChange={handleChange}
                  form={form}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={6}>
                <DateInput
                  label='Date de dépôt de permis'
                  value={form?.licenceDate}
                  handleChange={handleChangeDate}
                  views={['year', 'month', 'day']}
                />
              </Grid>
            </Grid>

            <Grid item xs={3} />
            <Grid item xs={9} container>
              <Grid item xs={12}>
                <DefaultTypography label='Localisation' />

                <TextInput fieldName='street' label='Adresse' handleChange={handleChange} form={form} errors={errors} />
              </Grid>

              <Grid item xs={12}>
                <TextInput
                  fieldName='additional'
                  label='Complément'
                  handleChange={handleChange}
                  form={form}
                  errors={errors}
                  required={false}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput fieldName='zipCode' label='Code Postal' handleChange={handleChange} form={form} errors={errors} />
              </Grid>
              <Grid item xs={6}>
                <TextInput fieldName='city' label='Ville' handleChange={handleChange} form={form} errors={errors} />
              </Grid>
              <Grid item xs={12}>
                <Typography>Les champs précédés d'un astérisque sont obligatoires.</Typography>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box sx={{ px: 1 }}>
            <CancelButton action={() => onClose(false)} label='Annuler' />
          </Box>
          {bsProject?.id ? (
            <SubmitButton label='Mettre à jour' formId='create-bs-form' isSubmiting={false} />
          ) : (
            <SubmitButton label='Créer' formId='create-bs-form' isSubmiting={false} />
          )}
        </DialogActions>
      </Box>
    </Dialog>
  )
}
