import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Contact } from '../../core/dto/user/contact'
import { useOrganization } from '../../core/hooks/use-organization'
import DataGridOrganization, { DataGridType } from '../../components/tables/display-user/data-grid-organization'

export default function OrganizationContact(): React.JSX.Element {
  const [contactsList, setContactsList] = useState<Contact[]>([])
  const { fetchContacts } = useOrganization()

  useEffect(() => {
    fetchContacts().then((contact) => {
      setContactsList(contact)
    })
  }, [fetchContacts])

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  function refreshCollab(): void {}

  return (
    <Box sx={{ pl: 20, pr: 20 }}>
      <Typography fontWeight='bold' variant='h5' fontSize={20} sx={{ mt: 4, mb: 2 }}>
        MES CONTACTS
      </Typography>
      <DataGridOrganization
        dataCollab={[]}
        dataContact={contactsList}
        type={DataGridType.CONTACT}
        refreshCollab={refreshCollab}
        isForUltimateUser={false}
      />
    </Box>
  )
}
