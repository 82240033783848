import React, { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { Children } from '../../../components/miscellianous/children'
import MaterialRecord from '../../dto/material/material-record'

export const MaterialLibContext = React.createContext<MaterialLibStore>({} as MaterialLibStore)

export default function MaterialLibContextProvider({ children }: Children): React.JSX.Element {
  const [materialRecords, setMaterialRecords] = useState<MaterialRecord[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [limit, setLimit] = useState(5)

  const materialsLibStore: MaterialLibStore = useMemo(
    () => ({ materialRecords, setMaterialRecords, loading, setLoading, limit, setLimit }),
    [loading, materialRecords, limit]
  )

  return <MaterialLibContext.Provider value={materialsLibStore}>{children}</MaterialLibContext.Provider>
}

export type MaterialLibStore = {
  materialRecords: MaterialRecord[]
  setMaterialRecords: Dispatch<SetStateAction<MaterialRecord[]>>
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  limit: number
  setLimit: Dispatch<SetStateAction<number>>
}
