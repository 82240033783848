import { useMemo } from 'react'
import { appConstants } from '../appConstants'
import { EventNotification } from '../dto/event/event'
import { useHttp } from './use-http'

type EventHook = {
  fetchUserNotifications(): Promise<EventNotification[]>
  markEventAsRead(eventId: string): Promise<void>
  markAllEventAsRead(): Promise<void>
}

export function useEvents(): EventHook {
  const { get, post } = useHttp()

  return useMemo(
    () => ({
      fetchUserNotifications(): Promise<EventNotification[]> {
        return get(`${appConstants.apiEndpoints.EVENT}/user-event`, []).then((response) => response.json())
      },
      markEventAsRead(eventId: string): Promise<void> {
        return post(`${appConstants.apiEndpoints.EVENT}/read-event`, {}, [
          {
            key: 'eventNotificationId',
            value: eventId,
          },
        ]).then()
      },
      markAllEventAsRead(): Promise<void> {
        return post(`${appConstants.apiEndpoints.EVENT}/read-all-event`, []).then()
      },
    }),
    [get, post]
  )
}
