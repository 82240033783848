import React, { ErrorInfo, ReactNode } from 'react'
import { Children } from '../miscellianous/children'

type ErrorBoundaryState = {
  hasUIError: boolean
}

export default class ErrorBoundary extends React.Component<Children, ErrorBoundaryState> {
  constructor(props: Children) {
    super(props)
    this.state = { hasUIError: false }
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    // Display fallback UI
    this.setState({ hasUIError: true })
    // You can also log the error to an error reporting service
    console.error('ErrorBoundary: ', info)
  }

  render(): ReactNode {
    const { children } = this.props
    const { hasUIError } = this.state
    try {
      return hasUIError ? (
        // You can render any custom fallback UI
        <h1>Something went wrong.</h1>
      ) : (
        children
      )
    } catch (error) {
      console.error('Error in render', error)

      return <h1>Something went wrong.</h1>
    }
  }
}
