import { useMemo } from 'react'
import { appConstants } from '../../appConstants'
import { RseeDocument } from '../../dto/rsee/rsee-document'
import { RseeImportLog } from '../../dto/rsee/rsee-import-log'
import { RseeProject } from '../../dto/rsee/rsee-project'
import { RseeProjectForm } from '../../dto/rsee/rsee-project-form'
import { resolveUrl } from '../../services/http-service'
import { useHttp } from '../use-http'
import { RseeProjectCreationResponse } from '../../dto/rsee/rsee-project-creation-response'

type RseeHook = {
  fetchRseeProject(rseeProjectId: string): Promise<RseeProject>
  fetchRseeDocumentList(rseeProjectId: string): Promise<RseeDocument[]>
  fetchRseeDocument(rseeDocumentId: string): Promise<RseeDocument>
  createRseeProject(rseeFile: File): Promise<RseeProjectCreationResponse>
  addRseeDocument(rseeFile: File, rseeProjectId: string): Promise<string>
  updateRseeDocument(rseeFile: File, rseeDocumentId: string): Promise<RseeProject>
  deleteRseeDocument(rseeDocumentId: string): Promise<Response>
  updateCurrentRseeDocument(rseeDocumentId: string, toUpdate: boolean): Promise<RseeDocument>
  updateRseeProject(rseeProjectId: string, rseeprojectForm: RseeProjectForm): Promise<RseeProject>
  deleteRseeProject(rseeProjectId: string): Promise<Response>
  fetchDashboardUrl(documentVersion: string): Promise<string>
  fetchRseeProjectNameList(): Promise<RseeProject[]>
  fetchRseeDocumentNameList(): Promise<RseeDocument[]>
  listenIsDocumentReady(rseeDocumentId: string): Promise<boolean>
  fetchRseeImportLogList(rseeProjectId: string, rseeDocumentId: string): Promise<RseeImportLog[]>
}

export function useRsee(): RseeHook {
  const { get, put, postFile, putFile, deleteRequest } = useHttp()

  return useMemo(
    () => ({
      //fetchProjectCards() c.f. useProject,
      fetchRseeProject(rseeProjectId: string): Promise<RseeProject> {
        return get(resolveUrl(appConstants.apiEndpoints.RSEE_PROJECT, [rseeProjectId])).then((response) => response.json())
      },
      updateRseeProject(rseeProjectId: string, rseeProjectForm: RseeProjectForm): Promise<RseeProject> {
        return put(resolveUrl(appConstants.apiEndpoints.RSEE_PROJECT, [rseeProjectId]), rseeProjectForm).then((response) =>
          response.json()
        )
      },
      deleteRseeProject(rseeProjectId: string): Promise<Response> {
        return deleteRequest(resolveUrl(appConstants.apiEndpoints.RSEE_PROJECT, [rseeProjectId]))
      },
      fetchRseeDocumentList(rseeProjectId: string): Promise<RseeDocument[]> {
        return get(resolveUrl(appConstants.apiEndpoints.RSEE_DOCUMENT_CARDS_LIST, [rseeProjectId]))
          .then((response) => response.json())
          .then((rseeDocumentList: any[]) => rseeDocumentList.map((data) => new RseeDocument(data)))
      },
      fetchRseeDocument(rseeDocumentId: string): Promise<RseeDocument> {
        return get(resolveUrl(appConstants.apiEndpoints.RSEE_DOCUMENT, [rseeDocumentId]))
          .then((response) => {
            if (response.status === 403) {
              throw new Error("Vous n'avez pas le droit d'accèder à ce document")
            }
            return response.json()
          })
          .then((data: any) => new RseeDocument(data))
      },
      createRseeProject(rseeFile: File): Promise<RseeProjectCreationResponse> {
        const formData = new FormData()
        formData.append('rseeFile', rseeFile)
        return postFile(resolveUrl(appConstants.apiEndpoints.RSEE_PROJECT, ['']), formData).then((response) =>
          response.json()
        )
      },
      addRseeDocument(rseeFile: File, rseeProjectId: string): Promise<string> {
        const formData = new FormData()
        formData.append('rseeProjectId', rseeProjectId)
        formData.append('rseeFile', rseeFile)
        return postFile(appConstants.apiEndpoints.RSEE_ADD_DOCUMENT, formData).then((rsee) => rsee.text())
      },
      updateRseeDocument(rseeFile: File, rseeDocumentId: string): Promise<RseeProject> {
        const formData = new FormData()
        formData.append('rseeDocumentId', rseeDocumentId)
        formData.append('rseeFile', rseeFile)
        return putFile(resolveUrl(appConstants.apiEndpoints.RSEE_DOCUMENT, ['']), formData).then((rsee) => rsee.json())
      },
      deleteRseeDocument(rseeDocumentId: string): Promise<Response> {
        return deleteRequest(resolveUrl(appConstants.apiEndpoints.RSEE_DOCUMENT, [rseeDocumentId]))
      },
      updateCurrentRseeDocument(rseeDocumentId: string, toUpdate: boolean): Promise<RseeDocument> {
        return put(`${appConstants.apiEndpoints.RSEE_UPDATE_CURRENT_DOCUMENT}`, {}, [
          { key: 'documentId', value: rseeDocumentId },
          { key: 'toUpdate', value: toUpdate.toString() },
        ]).then((r) => r.json())
      },
      fetchDashboardUrl(documentVersion: string): Promise<string> {
        return get(appConstants.apiEndpoints.RSEE_DOCUMENT_URL, [{ key: 'documentVersion', value: documentVersion }]).then(
          (rsee) => rsee.text()
        )
      },
      fetchRseeProjectNameList(): Promise<RseeProject[]> {
        return get(`${appConstants.apiEndpoints.RSEE_PROJECT_LIST_GLOBAL_DASHBOARD}`).then((response) => response.json())
      },
      fetchRseeDocumentNameList(): Promise<RseeDocument[]> {
        return get(`${appConstants.apiEndpoints.RSEE_DOCUMENT_LIST_GLOBAL_DASHBOARD}`).then((response) => response.json())
      },
      listenIsDocumentReady(rseeDocumentId: string): Promise<boolean> {
        return get(appConstants.apiEndpoints.RSEE_DOCUMENT_IS_DASHBOARD_READY, [
          { key: 'documentId', value: rseeDocumentId },
        ])
          .then((response) => {
            if (response.status !== 200) {
              throw new Error(`Error: http status code ${response.status}`)
            }
            return response.text()
          })
          .then((responseText) => responseText !== 'false')
      },
      fetchRseeImportLogList(rseeProjectId: string, rseeDocumentId: string): Promise<RseeImportLog[]> {
        return get(resolveUrl(appConstants.apiEndpoints.RSEE_SUMMARY_IMPORT_LIST, [rseeProjectId, rseeDocumentId])).then(
          (response) => response.json()
        )
      },
    }),
    [deleteRequest, get, postFile, put, putFile]
  )
}
