import { createTheme } from '@mui/material'

/** color:
 * orange:
 * #fa7929
 *
 * orange for text (lighter)
 * #fb914fff
 *
 * Green gradient:
 * lighter green
 * #a2ffd1
 * #b0d6cf
 * #7bbbaf
 *
 * medium
 * #008aa2
 * #245d71
 *
 * darkest green
 * #103f59
 *
 */
export const customPalette = {
  // orange
  orange: '#fa7929',
  // orange for text (lighter)
  orangeText: '#fb914fff',
  // Green gradient:
  // lighter green
  lightGreen1: '#f1fff7',
  lightGreen2: '#e1ffee',
  lightGreen3: '#a2ffd1',
  lightGreen4: '#b0d6cf',
  lightGreen5: '#7bbbaf',
  lightGreen6: '#5bbb7f',
  lightGreen7: '#0048fb',
  // medium green
  mediumGreen1: '#008aa2',
  mediumGreen2: '#245d71',
  // darkest green
  darkestGreen: '#103f59',
  default: '#f4f5f8',
  // gray
  lightGray1: '#dbdbdb',
  lightGray2: '#979797',
  //lightYellow
  lightYellow1: '#ecd293',
  textPrimaryWhite: '#FFFFFF',
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#fa7929',
    },
    info: {
      main: '#f7f7f7',
      contrastText: '#000000',
    },
    success: {
      main: '#47B27C',
    },
    error: {
      main: '#ff0000',
    },
  },
  components: {
    // @ts-ignore: special MUI object
    MuiDatePicker: {
      styleOverrides: {
        root: {
          backgroundColor: 'red',
        },
      },
    },
  },
})
