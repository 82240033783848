import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { Children } from '../../../components/miscellianous/children'
import { User } from '../../dto/user/user'
// import { getCurrentUserFromStorage } from '../../services/authentication-service'
import { SuccessContext } from '../../../components/layout/success-snackbar'
import { MyUserUpdateDto } from '../../dto/user/my-user-update-dto'
import { useUser } from '../../hooks/use-user'
import { getUserFromStorage } from '../../services/authentication-service'
import { TokenContext } from './token-context'

export const UserContext = React.createContext<UserStore>({} as UserStore)
export default function UserContextProvider({ children }: Children): React.JSX.Element {
  const initialUser: User | undefined = useMemo(() => getUserFromStorage(), [])

  const { getCurrentUser, updateMyUser } = useUser()
  const openSuccessSnackbar: (message: string) => void = React.useContext(SuccessContext)
  const { cognitoUserId, unsetCookieAndUserId } = React.useContext(TokenContext)

  const [user, setUser] = useState<User | undefined>(initialUser)
  const [userLoading, setUserLoading] = useState<boolean>(false)
  const [isFromLogoutButton, setIsFromLogoutButton] = useState<boolean>(false)

  useEffect(() => {
    if (user === undefined) {
      localStorage.removeItem('user')
      unsetCookieAndUserId()
    } else {
      localStorage.setItem('user', JSON.stringify(user))
    }
  }, [user, unsetCookieAndUserId])

  useEffect(
    () => {
      if (cognitoUserId && !userLoading) {
        setUserLoading(true)
        getCurrentUser()
          .then((newUser) => {
            setUser(newUser)
          })
          .catch((reason) => {
            setUser(undefined)
            console.error('reason: ', reason)
            return unsetCookieAndUserId()
          })
          .finally(() => setUserLoading(false))
      } else if (!userLoading) {
        setUser(undefined)
      }
    },
    // userLoading
    [cognitoUserId]
  )

  const logout = useCallback(async () => {
    await unsetCookieAndUserId()
  }, [unsetCookieAndUserId])

  const updateUserContext = useCallback(
    (userUpdateDto: MyUserUpdateDto) => {
      console.info('update user')
      setUserLoading(true)
      updateMyUser(userUpdateDto)
        .then((updatedUser) => {
          if (user) {
            const newUser = {
              ...user,
              givenName: updatedUser.givenName,
              email: updatedUser.email,
              familyName: updatedUser.familyName,
              phone: updatedUser.phone,
            }
            setUser(newUser)
          } else {
            logout()
          }
          openSuccessSnackbar('Modification réussie !')
        })
        .finally(() => setUserLoading(false))
    },
    [logout, openSuccessSnackbar, updateMyUser, user]
  )

  const userStore: UserStore = useMemo(
    () => ({
      user,
      userLoading,
      updateUserContext,
      logout,
      isFromLogoutButton,
      setIsFromLogoutButton,
    }),
    [user, userLoading, updateUserContext, logout, isFromLogoutButton]
  )
  return <UserContext.Provider value={userStore}>{children}</UserContext.Provider>
}

export type UserStore = {
  user: User | undefined
  userLoading: boolean
  updateUserContext(userUpdateDto: MyUserUpdateDto): void
  logout(): Promise<void>
  isFromLogoutButton: boolean
  setIsFromLogoutButton: Dispatch<SetStateAction<boolean>>
}
