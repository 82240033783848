import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { customPalette } from '../../../../theme'
import { MaterialLibIndicator } from '../../../core/dto/organization/organization-indicator'

type InventoryCardProps = {
  getIndicator: MaterialLibIndicator | undefined
}

export default function InventoryCard({ getIndicator }: InventoryCardProps): React.JSX.Element {
  return (
    <Box
      display='flex'
      flexDirection='row'
      alignItems='center'
      sx={{
        border: 'none',
        padding: '16px',
        boxShadow: '1px 2px 5px #d6d2d2',
        backgroundColor: customPalette.default,
      }}>
      <ContentCopyOutlinedIcon />
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        sx={{
          width: '100%',
        }}>
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: 'bold',
          }}>
          {getIndicator?.total} Déclarations
        </Typography>
        <Typography
          sx={{
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}>
          {getIndicator?.fdes} Produits / {getIndicator?.pep} Equipements
        </Typography>
      </Box>
    </Box>
  )
}
