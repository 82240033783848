import { Grid, Paper, TextField } from '@mui/material'
import * as math from 'mathjs'
import React, { useEffect, useState } from 'react'
import { useCalculator } from './hooks/useCalculator'
import Uturn from './icon/Uturn'
import CalculatorButton from './calculator-button/CalculatorButton'

interface CalculatorProps {
  setValue: (value: string) => void
  calculatorResult: string
}

export default function Calculator({ setValue, calculatorResult }: CalculatorProps): React.JSX.Element {
  const [result, setResult] = useState(calculatorResult)

  useCalculator({ setResult })

  useEffect(() => {
    setValue(result)
  }, [result, setValue])

  const addToResult = (value: string): void => {
    setResult((prev) => prev + value)
  }

  const removeCharacter = (): void => {
    const editedResult = result.slice(0, -1)
    setResult(editedResult)
  }

  const clear = (): void => {
    setResult('')
  }

  const equal = (): void => {
    try {
      setResult(math.evaluate(result))
    } catch (e) {
      setTimeout(() => setResult(''), 1000)
    }
  }

  const squaredResult = (resultPow: string): void => {
    const converResult = +resultPow
    const response = converResult ** 2
    setResult(String(response))
  }
  const keyBoardEvent = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    e.stopPropagation()
    e.preventDefault()
    if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '/', '+', '-', '*'].includes(e.key)) {
      addToResult(e.key)
    } else if (e.key === 'Enter') {
      equal()
    } else if (e.key === '/') {
      addToResult('%')
    } else if (e.key === 'Delete') {
      clear()
    } else if (e.key === 'Backspace') {
      removeCharacter()
    }
  }
  return (
    <Paper
      style={{
        margin: '10px 0px',
        padding: 20,
        width: '100%',
        border: 'none',
        boxShadow: 'unset',
      }}
      onKeyUp={keyBoardEvent}>
      <TextField
        variant='outlined'
        value={result === undefined ? 'Invalid' : result || 0}
        style={{ width: '100%', border: 'none' }}
        onKeyUp={keyBoardEvent}
        InputProps={{
          readOnly: true,
          autoFocus: true,
        }}
      />
      <Grid container rowSpacing={1} columnSpacing={1} sx={{ marginTop: 4 }}>
        <CalculatorButton
          background='#F5F5F5'
          onClick={() => {
            addToResult('/')
          }}>
          /
        </CalculatorButton>
        <CalculatorButton
          background='#F5F5F5'
          onClick={() => {
            clear()
          }}>
          C
        </CalculatorButton>
        <CalculatorButton
          background='#F5F5F5'
          onClick={() => {
            squaredResult(result)
          }}>
          X²
        </CalculatorButton>
        <CalculatorButton
          background='#F5F5F5'
          onClick={(e) => {
            if (result === '') {
              e.preventDefault()
            } else {
              removeCharacter()
            }
          }}>
          <Uturn />
        </CalculatorButton>
        <CalculatorButton
          onClick={() => {
            addToResult('7')
          }}>
          7
        </CalculatorButton>
        <CalculatorButton
          onClick={() => {
            addToResult('8')
          }}>
          8
        </CalculatorButton>
        <CalculatorButton
          onClick={() => {
            addToResult('9')
          }}>
          9
        </CalculatorButton>
        <CalculatorButton
          background='#F5F5F5'
          onClick={() => {
            addToResult('+')
          }}>
          +
        </CalculatorButton>
        <CalculatorButton
          onClick={() => {
            addToResult('4')
          }}>
          4
        </CalculatorButton>
        <CalculatorButton
          onClick={() => {
            addToResult('5')
          }}>
          5
        </CalculatorButton>
        <CalculatorButton
          onClick={() => {
            addToResult('6')
          }}>
          6
        </CalculatorButton>
        <CalculatorButton
          background='#F5F5F5'
          onClick={() => {
            addToResult('*')
          }}>
          X
        </CalculatorButton>
        <CalculatorButton
          onClick={() => {
            addToResult('1')
          }}>
          1
        </CalculatorButton>
        <CalculatorButton
          onClick={() => {
            addToResult('2')
          }}>
          2
        </CalculatorButton>
        <CalculatorButton
          onClick={() => {
            addToResult('3')
          }}>
          3
        </CalculatorButton>
        <CalculatorButton
          background='#F5F5F5'
          onClick={() => {
            addToResult('-')
          }}>
          -
        </CalculatorButton>
        <CalculatorButton
          onClick={() => {
            addToResult('%')
          }}>
          %
        </CalculatorButton>
        <CalculatorButton
          onClick={() => {
            addToResult('0')
          }}>
          0
        </CalculatorButton>
        <CalculatorButton
          onClick={() => {
            addToResult('.')
          }}>
          ,
        </CalculatorButton>
        <CalculatorButton
          background='#989898'
          onClick={() => {
            equal()
          }}>
          =
        </CalculatorButton>
      </Grid>
    </Paper>
  )
}
