import { Grid, InputAdornment, SelectChangeEvent, TextField } from '@mui/material'
import { Box } from '@mui/system'
import React, { ChangeEvent, Dispatch, MutableRefObject, SetStateAction } from 'react'
import GenericSelect from '../../../../../components/miscellianous/generic-select'
import { BimModelLotEnum } from '../../../../../core/enum/bimModelLotEnum'
import { IBimModelForm } from '../../../../../core/dto/bim-model/bim-model-form'

type IProps = {
  bimModelForm: IBimModelForm
  setBimModelForm: Dispatch<SetStateAction<IBimModelForm>>
  error: any
  setError: React.Dispatch<any>
  isFormUpdateRef: MutableRefObject<boolean>
  isWriting: boolean
}

const bimModelLotSelect = [
  { label: 'Architecture', value: BimModelLotEnum.ARCHITECTURE },
  { label: 'Structure', value: BimModelLotEnum.STRUCTURE },
  { label: 'Technique CVCP', value: BimModelLotEnum.TECHNIQUE_CVCP },
  {
    label: 'Voirie réseaux divers',
    value: BimModelLotEnum.VOIRIE_RESEAUX_DIVERS,
  },
]

function enumToLabel(bimModelLot: BimModelLotEnum): string {
  const index = bimModelLotSelect.findIndex((lot) => lot.value === bimModelLot)
  return bimModelLotSelect[index]?.label
}

export default function BimModelForm({
  bimModelForm,
  setBimModelForm,
  error,
  setError,
  isFormUpdateRef,
  isWriting,
}: IProps): React.JSX.Element {
  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.id

    if (error[name]) {
      setError({ ...error, [name]: undefined })
    }

    isFormUpdateRef.current = true
    setBimModelForm({
      ...bimModelForm,
      [name]: value,
    })
  }

  function handleSelectChange(event: SelectChangeEvent): void {
    const value: string = event.target.value
    const index = bimModelLotSelect.findIndex((lot) => lot.label === value)
    setBimModelForm({ ...bimModelForm, lots: bimModelLotSelect[index].value })
  }

  return (
    <Box display='flex' justifyContent='space-between' alignItems='center'>
      <Grid container rowSpacing={2} columnSpacing={3}>
        <Grid item xs={12} sm={12}>
          <GenericSelect
            name='bimModelLot'
            label='Lots Concernés'
            valuesList={bimModelLotSelect.map((lot) => lot.label)}
            currentValue={enumToLabel(bimModelForm?.lots)}
            onChange={handleSelectChange}
            disabled={!isWriting}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            id='version'
            value={bimModelForm.version}
            label='Version'
            onChange={handleChange}
            disabled={!isWriting}
            error={!!error.version}
            helperText={error.version}
            required
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            id='surfaceShab'
            value={bimModelForm.surfaceShab}
            label='Surface utile ou SHAB'
            onChange={handleChange}
            disabled={!isWriting}
            error={!!error.surfaceShab}
            helperText={error.surfaceShab}
            required
            type='number'
            InputProps={{
              endAdornment: <InputAdornment position='end'>m²</InputAdornment>,
            }}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            id='surfacePlancher'
            value={bimModelForm.surfacePlancher}
            label='Surface de plancher'
            onChange={handleChange}
            disabled={!isWriting}
            error={!!error.surfacePlancher}
            helperText={error.surfacePlancher}
            required
            type='number'
            InputProps={{
              endAdornment: <InputAdornment position='end'>m²</InputAdornment>,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField
            id='empriseAuSol'
            value={bimModelForm.empriseAuSol}
            label='Emprise au sol'
            onChange={handleChange}
            disabled={!isWriting}
            error={!!error.empriseAuSol}
            helperText={error.empriseAuSol}
            required
            type='number'
            InputProps={{
              endAdornment: <InputAdornment position='end'>m²</InputAdornment>,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField
            id='surfaceComble'
            value={bimModelForm.surfaceComble}
            label='Surface des combles aménagés'
            onChange={handleChange}
            disabled={!isWriting}
            error={!!error.surfaceComble}
            helperText={error.surfaceComble}
            type='number'
            InputProps={{
              endAdornment: <InputAdornment position='end'>m²</InputAdornment>,
            }}
            fullWidth
          />
        </Grid>
      </Grid>
    </Box>
  )
}
