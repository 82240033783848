import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export function IconBox(props: SvgIconProps): React.JSX.Element {
  return (
    <SvgIcon {...props}>
      {/*  SVG from https://tablericons.com/ */}
      <svg width='24' height='24' viewBox='0 0 33 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          // eslint-disable-next-line max-len
          d='M16.5 29.8327C16.0323 29.8327 15.5727 29.7107 15.1667 29.4787L5.83333 24.1347C5.008 23.6627 4.5 22.7907 4.5 21.8453V11.1547C4.5 10.2107 5.008 9.33735 5.83333 8.86401L15.1667 3.52001C15.5727 3.28803 16.0323 3.16602 16.5 3.16602C16.9677 3.16602 17.4273 3.28803 17.8333 3.52001L27.1667 8.86401C27.5709 9.09428 27.9072 9.42729 28.1413 9.82932C28.3754 10.2313 28.4992 10.6881 28.5 11.1533V21.844C28.5 22.788 27.992 23.6613 27.1667 24.1347L17.8333 29.4787C17.4273 29.7107 16.9677 29.8327 16.5 29.8327ZM16.5 29.8327V16.5M16.5 16.5L28.14 9.78003M16.5 16.5L4.86003 9.78003'
          stroke='black'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </SvgIcon>
  )
}
