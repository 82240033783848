export enum SeuilEnum {
  SEUIL_2022 = 'SEUIL_2022',
  SEUIL_2025 = 'SEUIL_2025',
  SEUIL_2028 = 'SEUIL_2028',
  SEUIL_2031 = 'SEUIL_2031',
  INVALIDE = 'INVALIDE',
}

export function getLabelText(value: SeuilEnum): string {
  if (value === SeuilEnum.SEUIL_2022) return 'SEUIL 2022'
  if (value === SeuilEnum.SEUIL_2025) return 'SEUIL 2025'
  if (value === SeuilEnum.SEUIL_2028) return 'SEUIL 2028'
  if (value === SeuilEnum.SEUIL_2031) return 'SEUIL 2031'
  if (value === SeuilEnum.INVALIDE) return 'SEUIL 2022'

  return ''
}
