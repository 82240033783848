import { Checkbox, FormControlLabel } from '@mui/material'
import React, { ChangeEvent } from 'react'

interface IProps {
  id: string
  label: string
  checked: boolean
  handleChange(event: ChangeEvent<HTMLInputElement>): void
}

export function CheckboxInput({ id, label, checked, handleChange }: IProps): React.JSX.Element {
  return (
    <FormControlLabel
      onClick={(e) => {
        e.stopPropagation()
      }}
      control={<Checkbox id={id} checked={checked} onChange={handleChange} />}
      label={label}
    />
  )
}
