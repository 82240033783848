import { useCallback, useContext, useMemo } from 'react'
import { ErrorContext } from '../../components/layout/error-snackbar'
import { appConstants } from '../appConstants'
import { CodeCustomPerParentCode } from '../data-structures/code-custom-per-parent-code'
import { CodesCompletionsPerCodeParent } from '../data-structures/codes-completions-per-code-parent'
import AdditionalCodeCreation from '../dto/additional-code/additional-code-creation'
import AdditionalCodeUpdate from '../dto/additional-code/additional-code-update'
import CodeCustom from '../dto/code-acv-custom/code-custom'
import CodeCustomCreation from '../dto/code-acv-custom/code-custom-creation'
import { CodeCustomUpdate } from '../dto/code-acv-custom/code-custom-update'
import CodeReference from '../dto/code-acv/code-reference'
import { CodeCompletion } from '../dto/code-completion/code-completion'
import { CodeCompletionUpdate } from '../dto/code-completion/code-completion-update'
import { CodeExtrait } from '../dto/code-extrait/code-extrait'
import { CodeExtraitUpdate } from '../dto/code-extrait/code-extrait-update'
import Lot from '../dto/lots/lot'
import SousLot from '../dto/lots/sous-lot'
import { Material } from '../dto/material/material'
import { SpringHttpError } from '../dto/spring-http-error'
import { CodesVersion } from '../enum/codesVersionEnum'
import { PhaseEnum } from '../enum/phaseEnum'
import { fromCodeCustomToMap, fromCodeReferenceListToMap, fromCompletionToMap } from '../services/code-service'
import { streamToBlob } from '../services/file-service'
import { useHttp } from './use-http'
import { resolveUrl } from '../services/http-service'

type CodeHook = {
  fetchCodeAcvList(codesVersion: CodesVersion): Promise<CodeReference[]>
  fetchCodeAcvForCompletionMap(codesVersion: CodesVersion): Promise<Map<string, CodeReference[]>>
  fetchCodeAcvExtraitMap(codesVersion: CodesVersion): Promise<Map<string, CodeReference>>
  fetchCodeAcvCustomMap(projectId: string): Promise<CodeCustomPerParentCode>
  fetchCodeAcvCustom(projectId: string): Promise<CodeCustom[]>
  createCodeAcvCustom(projectId: string, codeAcv: CodeCustomCreation): Promise<CodeCustom>
  fetchCodesExtraits(projectId: string, phase: PhaseEnum): Promise<CodeExtrait[]>
  updateCodeExtrait(projectId: string, codeExtrait: CodeExtraitUpdate): Promise<void>
  fetchCodeCompletionForVariant(projectId: string, variantId: string): Promise<CodesCompletionsPerCodeParent>
  updateCodesCompletionsCodeExtraitForVariant(
    projectId: string,
    variantId: string,
    codeExtraitId: string,
    codeCompletionList: CodeCompletion[]
  ): Promise<CodeCompletion[]>
  updateCodesCompletionsForAdditionalCode(
    additionalCodeId: string,
    codeCompletionList: CodeCompletion[]
  ): Promise<CodeCompletion[]>
  updateCodeCompletion(projectId: string, codeCompletion: CodeCompletionUpdate): Promise<void>
  deleteCodeCompletion(projectId: string, variantId: string, codeCompletionId: string): Promise<void>
  createAdditionalCode(
    projectId: string,
    variantId: string,
    additionalCodeCreation: AdditionalCodeCreation
  ): Promise<CodeCompletion>
  createAdditionalCodeV1V2(
    projectId: string,
    variant1Id: string,
    variant2Id: string,
    additionalCodeCreation: AdditionalCodeCreation
  ): Promise<CodeCompletion[]>
  updateAdditionalCode(
    projectId: string,
    variantId: string,
    additionalCodeCreation: AdditionalCodeUpdate
  ): Promise<CodeCompletion>
  deleteAdditionalCode(projectId: string, variantId: string, codeAdditionalId: string): Promise<void>
  fetchAdditionalCodesForVariant(projectId: string, variantId: string): Promise<CodeCompletion[]>
  fetchLot(): Promise<Lot[]>
  fetchSousLot(lot?: Lot): Promise<SousLot[]>
  updateCodeAcvCustom(projectId: string, codeAcvCustomUpdate: CodeCustomUpdate, parentCode: string): Promise<CodeCustom>
  sendExtractCodeMaterialReuseImage(
    reuseMaterialImage: File,
    projectId: string,
    codeExtraitId: string,
    variantId: string
  ): Promise<CodeExtrait>
  sendAdditionalCodeMaterialReuseImage(
    reuseMaterialImage: File,
    projectId: string,
    codeAdditionalId: string
  ): Promise<CodeCompletion>
  fetchReuseImageExtractedCode(codeExtraitId: string, variantId: string): Promise<Blob | undefined>
  fetchReuseImageAdditionalCode(codeCompletionId: string): Promise<Blob | undefined>
  synchronizeProjectMaterial(projectId: string, materialId: string, phase: string): Promise<Material>
}

export function useCode(): CodeHook {
  const { get, post, put, deleteRequest, postFile } = useHttp()
  const openErrorSnackbar = useContext(ErrorContext)

  const fetchCodeAcvExtraitList = useCallback(
    (codesVersion: string) =>
      get(`${appConstants.apiEndpoints.CODE_ACV}/for-extrait`, [{ key: 'codesVersion', value: codesVersion }]).then(
        (response) => response.json()
      ),
    [get]
  )

  return useMemo(
    () => ({
      fetchCodeAcvList: fetchCodeAcvExtraitList,
      fetchCodeAcvForCompletionMap(codesVersion: CodesVersion): Promise<Map<string, CodeReference[]>> {
        return get(`${appConstants.apiEndpoints.CODE_ACV}/for-completion`, [{ key: 'codesVersion', value: codesVersion }])
          .then((response) => response.json())
          .then((codeCompletionReferenceList: CodeReference[]) => fromCompletionToMap(codeCompletionReferenceList))
      },
      fetchCodeAcvExtraitMap(codesVersion: string): Promise<Map<string, CodeReference>> {
        return fetchCodeAcvExtraitList(codesVersion).then((codeAcvList: CodeReference[]) =>
          fromCodeReferenceListToMap(codeAcvList)
        )
      },
      fetchCodesExtraits(projectId, phase): Promise<CodeExtrait[]> {
        return get(`${appConstants.apiEndpoints.CODE_ACV}/codes-extraits`, [
          {
            key: 'projectId',
            value: projectId,
          },
          {
            key: 'phase',
            value: phase,
          },
        ])
          .then((response) => response.json())
          .then((res: CodeExtrait[]) => res.map((codeExtrait) => CodeExtrait.fromDto(codeExtrait)))
      },
      updateCodeExtrait(projectId, codeExtrait) {
        return put(`${appConstants.apiEndpoints.CODE_ACV}/code-extrait`, codeExtrait, [
          {
            key: 'projectId',
            value: projectId,
          },
        ]).then(() => undefined)
      },
      fetchAdditionalCodesForVariant(projectId: string, variantId: string): Promise<CodeCompletion[]> {
        return get(`${appConstants.apiEndpoints.CODE_ACV}/additional-by-variant-id`, [
          {
            key: 'projectId',
            value: projectId,
          },
          {
            key: 'variantId',
            value: variantId,
          },
        ])
          .then((response) => response.json())
          .then((res: CodeCompletion[]) => res.map((codeCompletion) => CodeCompletion.fromDto(codeCompletion)))
      },
      fetchCodeCompletionForVariant(projectId: string, variantId: string): Promise<CodesCompletionsPerCodeParent> {
        return get(`${appConstants.apiEndpoints.CODE_ACV}/completion-by-variant-id`, [
          {
            key: 'variantId',
            value: variantId,
          },
          {
            key: 'projectId',
            value: projectId,
          },
        ])
          .then((response) => response.json())
          .then((codesCompletions: CodeCompletion[]) => CodesCompletionsPerCodeParent.from(codesCompletions))
      },
      updateCodesCompletionsForAdditionalCode(
        additionalCodeId: string,
        codesCompletions: CodeCompletion[]
      ): Promise<CodeCompletion[]> {
        return post(`${appConstants.apiEndpoints.CODE_ACV}/completion/for-additional-code`, codesCompletions, [
          {
            key: 'additionalCodeId',
            value: additionalCodeId,
          },
        ]).then((response) => response.json())
      },
      updateCodeCompletion(projectId: string, codeCompletion: CodeCompletionUpdate): Promise<void> {
        return put(`${appConstants.apiEndpoints.CODE_ACV}/completion`, codeCompletion, [
          {
            key: 'projectId',
            value: projectId,
          },
        ]).then(() => undefined)
      },
      deleteCodeCompletion(projectId: string, variantId: string, codeCompletionId: string): Promise<void> {
        return deleteRequest(`${appConstants.apiEndpoints.CODE_ACV}/completion`, [
          {
            key: 'projectId',
            value: projectId,
          },
          {
            key: 'variantId',
            value: variantId,
          },
          {
            key: 'codeCompletionId',
            value: codeCompletionId,
          },
        ]).then(() => undefined)
      },
      updateCodesCompletionsCodeExtraitForVariant(projectId, variantId, codeExtraitId, code): Promise<CodeCompletion[]> {
        return post(`${appConstants.apiEndpoints.CODE_ACV}/completion/for-code-extrait`, code, [
          {
            key: 'variantId',
            value: variantId,
          },
          {
            key: 'projectId',
            value: projectId,
          },
          {
            key: 'codeExtraitId',
            value: codeExtraitId,
          },
        ])
          .then(async (response: Response) => {
            if (response.status === 404) {
              const err: SpringHttpError = new SpringHttpError(await response.json())
              openErrorSnackbar(new Error(err.message))
            }
            return response.json()
          })
          .then((codesCompletions: CodeCompletion[]) =>
            codesCompletions.map((codesCompletion) => CodeCompletion.fromDto(codesCompletion))
          )
      },
      createAdditionalCodeV1V2(
        projectId: string,
        variant1Id: string,
        variant2Id: string,
        additionalCode: AdditionalCodeCreation
      ): Promise<CodeCompletion[]> {
        return post(`${appConstants.apiEndpoints.CODE_ACV}/additional-v1v2`, additionalCode, [
          {
            key: 'projectId',
            value: projectId,
          },
          {
            key: 'variant1Id',
            value: variant1Id,
          },
          {
            key: 'variant2Id',
            value: variant2Id,
          },
        ]).then((response) => response.json())
      },
      createAdditionalCode(
        projectId: string,
        variantId: string,
        additionalCode: AdditionalCodeCreation
      ): Promise<CodeCompletion> {
        return post(`${appConstants.apiEndpoints.CODE_ACV}/additional`, additionalCode, [
          {
            key: 'projectId',
            value: projectId,
          },
          {
            key: 'variantId',
            value: variantId,
          },
        ]).then((response) => response.json())
      },
      updateAdditionalCode(
        projectId: string,
        variantId: string,
        additionalCode: AdditionalCodeUpdate
      ): Promise<CodeCompletion> {
        return put(`${appConstants.apiEndpoints.CODE_ACV}/additional`, additionalCode, [
          {
            key: 'projectId',
            value: projectId,
          },
          {
            key: 'variantId',
            value: variantId,
          },
        ]).then((response) => response.json())
      },
      deleteAdditionalCode(projectId: string, variantId: string, codeAdditionalId: string): Promise<void> {
        return deleteRequest(`${appConstants.apiEndpoints.CODE_ACV}/additional`, [
          {
            key: 'projectId',
            value: projectId,
          },
          {
            key: 'variantId',
            value: variantId,
          },
          {
            key: 'codeAdditionalId',
            value: codeAdditionalId,
          },
        ]).then(() => undefined)
      },
      fetchCodeAcvCustom(projectId): Promise<CodeCustom[]> {
        return get(`${appConstants.apiEndpoints.CODE_ACV}/custom`, [
          {
            key: 'projectId',
            value: projectId,
          },
        ])
          .then((response) => response.json())
          .then((res: CodeCustom[]) => res.map((record) => CodeCustom.fromDto(record)))
      },
      fetchCodeAcvCustomMap(projectId): Promise<CodeCustomPerParentCode> {
        return get(`${appConstants.apiEndpoints.CODE_ACV}/custom`, [
          {
            key: 'projectId',
            value: projectId,
          },
        ])
          .then((response) => response.json())
          .then((codeCustomList: CodeCustom[]) => fromCodeCustomToMap(codeCustomList))
      },
      createCodeAcvCustom(projectId, code): Promise<CodeCustom> {
        return post(`${appConstants.apiEndpoints.CODE_ACV}/custom`, { ...code, projectId: code.projectId || projectId })
          .then((response) => response.json())
          .then((codeAcvList: CodeCustom) => codeAcvList)
      },
      fetchLot(): Promise<Lot[]> {
        return get(`${appConstants.apiEndpoints.CODE_ACV}/lot`).then((response) => response.json())
      },
      fetchSousLot(lot?: Lot): Promise<SousLot[]> {
        let url
        if (lot) {
          url = resolveUrl(`${appConstants.apiEndpoints.CODE_ACV}/sous-lot`, [], { lot: lot.name })
        } else {
          url = `${appConstants.apiEndpoints.CODE_ACV}/sous-lot`
        }
        return get(url).then((response) => response.json())
      },
      updateCodeAcvCustom(projectId: string, codeAcvCustom: CodeCustomUpdate, parentCode: string): Promise<CodeCustom> {
        return put(`${appConstants.apiEndpoints.CODE_ACV}/custom`, codeAcvCustom, [
          {
            key: 'projectId',
            value: projectId,
          },
          {
            key: 'parentCode',
            value: parentCode,
          },
        ])
          .then((response) => response.json())
          .then((record: CodeCustom) => CodeCustom.fromDto(record))
      },
      sendExtractCodeMaterialReuseImage(
        reuseMaterialImage: File,
        projectId: string,
        codeExtraitId: string,
        variantId: string
      ): Promise<CodeExtrait> {
        const formData = new FormData()
        formData.append('reuseMaterialImage', reuseMaterialImage)
        formData.append('variantId', variantId)
        formData.append('codeExtraitId', codeExtraitId)
        formData.append('projectId', projectId)
        return postFile(`${appConstants.apiEndpoints.CODE_ACV}/image-reuse-material-extracted-code`, formData).then(
          (response) => response.json()
        )
      },
      sendAdditionalCodeMaterialReuseImage(
        reuseMaterialImage: File,
        projectId: string,
        codeAdditionalId: string
      ): Promise<CodeCompletion> {
        const formData = new FormData()
        formData.append('reuseMaterialImage', reuseMaterialImage)
        formData.append('codeAdditionalId', codeAdditionalId)
        formData.append('projectId', projectId)
        return postFile(`${appConstants.apiEndpoints.CODE_ACV}/image-reuse-material-additional-code`, formData).then(
          (response) => response.json()
        )
      },
      fetchReuseImageExtractedCode(codeExtraitId: string, variantId: string): Promise<Blob | undefined> {
        return get(`${appConstants.apiEndpoints.CODE_ACV}/image-reuse-material-extracted-code`, [
          {
            key: 'codeExtraitId',
            value: codeExtraitId,
          },
          {
            key: 'variantId',
            value: variantId,
          },
        ]).then((res) => streamToBlob(res))
      },
      fetchReuseImageAdditionalCode(codeCompletionId: string): Promise<Blob | undefined> {
        return get(`${appConstants.apiEndpoints.CODE_ACV}/image-reuse-material-additional-code`, [
          {
            key: 'codeCompletionId',
            value: codeCompletionId,
          },
        ]).then((res) => streamToBlob(res))
      },
      synchronizeProjectMaterial(projectId, materialId, phase): Promise<Material> {
        return put(`${appConstants.apiEndpoints.CODE_ACV}/synchronize-project-material`, {}, [
          {
            key: 'projectId',
            value: projectId,
          },
          {
            key: 'materialId',
            value: materialId,
          },
          {
            key: 'phase',
            value: phase,
          },
        ])
          .then((response) => response.json())
          .then((res: Material) => {
            const material = Material.fromCodeDto(res)
            if (material) {
              return material
            } else {
              throw new Error("Erreur lors de l'affection de la fiche inies")
            }
          })
      },
    }),
    [fetchCodeAcvExtraitList, get, put, post, deleteRequest, openErrorSnackbar, postFile]
  )
}
