import { getAwsErrorMessage } from './error-handler-aws'

export function getErrorMessage(error: Error): string {
  let message = error.message
  if (error.name !== 'Error') {
    message = error.name
    return getAwsErrorMessage(message)
  }
  if (message?.startsWith('Error:')) {
    message = message.substring(6, message.length).trim()
  }
  switch (message) {
    case '401_FORBIDDEN':
    case '403_FORBIDDEN':
      return "Vous n'avez pas le droit d'accéder à la ressource"
    case 'INVITATION_NOT_VALID_ANYMORE':
      return "L'invitation n'est plus valide."
    case 'ORGANIZATION_LICENCE_LIMIT_REACHED':
      return "Il n'y a plus de licence disponible pour votre organisation. Veuillez nous contacter pour ajouter des licences."
    case 'ONE_ROLE_MINIMUM':
      return "Il faut envoyer au minimum un rôle avec l'invitation."
    case 'PROJECT_FORBIDDEN':
      return "L'utilisateur n'est pas un administrateur de projet"
    case 'ORGANIZATION_NOT_FOUND':
      return "L'organisation n'a pas été trouvée"
    case 'EMAIL_ERROR':
      return "Erreur lors de l'envoi de l'e-mail"
    case 'EMAIL_IS_MANDATORY':
      return 'Il est obligatoire de spécifier un email'
    case 'ORGANIZATION_NOT_INVITED':
      return "L'utilisateur appartient déjà à une organisation qui n'est pas invitée"
    case 'ORGANIZATION_ID_SHOULD_NOT_BE_EMPTY':
      return "L'ID de l'organisation est vide"
    case 'LIMIT_TOO_HIGH':
      return 'La limite ne doit pas être supérieure à 100'
    case 'PROJECT_DOES_NOT_EXIST':
      return "Le projet n'existe pas"
    case 'MATERIAL_NOT_FOUND':
      return "Le matériel n'existe pas dans l'organisation de l'utilisateur"
    case 'FORBIDDEN':
      return "L'utilisateur n'a pas assez de droits sur le projet"
    case 'INCORRECT_OBJECTID':
      return "Le format de l'id incorrect"
    case 'USER_NOT_IN_PROJECT':
      return "L'utilisateur ne fait pas partie du projet"
    case 'NOT_ARCHITECT':
      return "L'utilisateur doit être ARCHITECTE sur le projet"
    case 'NO_MODEL_FOR_PROJECT_AND_PHASE':
      return 'Pas de modèle pour ce projet et cette phase'
    case 'INVITATION_ALREADY_EXIST':
      return "L'utilisateur à déjà reçu une invitation sur ce projet"
    case 'LAST_ADMIN':
      return 'Un projet doit avoir au moins un administrateur. Cet utilisateur est le dernier'
    case 'USER_NOT_FOUND':
      return 'Cette utilisateur est introuvable'
    case 'NOT_AUTHORIZED':
      return "Vous n'avez pas le droit d'accèder à la ressource"
    case 'ORGANIZATION_LOCK_FAILED':
      return "L'organisation est déjà en cours de modification"
    case 'USER_PROJECT_DOES_NOT_EXIST':
      return "Vous n'êtes aps affectésur le projet"
    case 'USER_WITH_EMAIL_ALREADY_EXIST':
      return 'Un utilisateur avec cet email existe déjà'
    case 'USER_NOT_IN_ORGANIZATION':
      return 'Votre utilisateur ne fait pas parti de la bonne organisation'
    case 'USER_NOT_IN_SAME_ORGANIZATION':
      return 'Votre utilisateur ne fait pas parti de la même organisation'
    case 'ORGANIZATION_NOT_ACTIVE':
      return 'Votre organisation est désactivée'
    case 'USER_ALREADY_DELETED':
      return 'Cet utilisateur est déjà supprimé'
    case 'USER_IS_DISABLED':
      return 'Votre utilisateur a été supprimé'
    case 'USER_NOT_FOUND_COGNITO':
      return 'Cette utilisateur est introuvable'
    case 'INVALID_INVITATION_ID':
      return "Il n'existe pas d'invitation correspondante"
    case 'INVALID_PASSWORD':
      return 'Password invalide'
    case 'USER_ALREADY_EXISTS':
      return 'Cet utilisateur existe déjà'
    case 'NO_LINK_BETWEEN_PROJECT_AND_USER_ID':
      return 'Votre utilisateur ne fait pas parti du projet'
    case 'INCORRECT_PHONE_NUMBER':
      return 'Le téléphone est incorrect'
    case 'UNIT_NOT_SUPPORTED':
      return "L'unité n'est pas supportée"
    case 'CODE_ACV_CUSTOM_NOT_FOUND':
      return 'La donnée custom est introuvable'
    case 'NO_QUANTITY_FOR_UNIT':
      return 'Pas de quantité pour cette unité'
    case 'UNKNOWN_UNIT':
      return 'Unité inconnue'
    case 'NO_QUANTITIES':
      return 'Pas de quantité'
    case 'NO_CUSTOM_ACV_ID_TO_UPDATE':
      return 'Pas de donnée ACV personnalisé à ettre à jour'
    case 'NO_CALCULATION_ID_TO_UPDATE':
      return "Le calcul à mettre à jour n'existe pas."
    case 'QUANTITY_IS_EMPTY_FOR_UNIT':
      return 'La quantité est vide pour cette unité'
    case 'UNKNOWN_UNIT_ERROR':
      return "L'unité est inconnue"
    case 'UNKNOWN_VARIANT':
      return "La variante n'existe pas"
    case 'NOT_BE':
      return "Vous devez avoir le rôle Bureau d'Etude"
    case 'NOT_MOA':
      return 'Vous devez avoir le rôle MOA'
    case 'NOT_ADMINISTRATOR':
      return 'Vous devez avoir le rôle administrateur'
    case 'NOT_CONSULTANT':
      return 'Vous devez avoir le rôle consultant'
    case 'NOT_CORRECT_ROLE':
      return "Vous n'avez pas le correct rôle"
    case 'MANDATORY_INIES_RECORD_NOT_FOUND':
      return "La fiche INIES n'a pas été trouvé"
    case 'CALCULATION_NOT_FOUND':
      return "Il n'y a pas de données de calcul sur ce project pour cette phase"
    case 'EXTRACTED_CODE_DOES_NOT_EXIST':
      return "Le code extrait n'existe pas"
    case 'EXTRACTED_CODE_WRONG_PROJECT':
      return "Le code extrait n'appartient pas à ce projet"
    case 'VARIANT_DOES_NOT_EXIST':
      return "Une des 2 variantes n'existe pas"
    case 'VARIANT_NOT_FOUND':
      return 'Pas de base/variante trouvée sur ce projet'
    case 'MANDATORY_MATERIAL_ID_NOT_FOUND':
      return "Le matériau est introuvable alors qu'il est obligatoire"
    case 'ADDITIONAL_CODE_NOT_FOUND':
      return "La donnée supplémentaire n'existe pas"
    case 'VARIANT_ID_MISSMATCH_PROJECT_ID':
      return "La variante n'appartient pas au project actullement sélectionné"
    case 'USER_NOT_AN_ARCHITECT':
      return "L'utilisateur n'est pas une architecte"
    case 'USER_NOT_ACTIVE_ON_PROJECT':
      return "Vous n'êtes pas affecté sur ce projet"
    case 'INVALID_PHASE':
      return "La phase n'est pas valide"
    case 'UNKNOWN_BIM_MODEL_ID':
      return "Il manque pas l'identifiant de la maquette BIM"
    case 'NO_TUTORIAL_MODEL':
      return "Impossible de savoir s'il faut afficher le tutoriel"
    case 'NO_PROJECT_ON_MODEL':
      return "Il n'y pas de projet associé à la maquette numérique de votre requête"
    case 'CODE_DOES_NOT_EXISTS_ON_PROJECT':
      return "Le code n'appartient pas au projet selectionné"
    case 'PROJECT_NOT_FOUND':
      return 'Le projet est introuvable'
    case 'RESULT_NOT_FOUND':
      return 'Pas de réultat pour ce calcul'
    case 'PROJECT_SHOULD_HAVE_AN_ID':
      return 'Le projet doit avoir un id'
    case 'PROJECT_NAME_ALREADY_EXIST':
      return 'Ce nom de projet existe déjà.'
    case 'MAX_PROJECT_NAME':
      return 'Pas plus de 100 project à partir du même projet ne peuvent être créé'
    case 'USER_ORGANIZATION_DOES_NOT_EXISTS':
      return "L'organisation de votre utilisateur n'exite pas"
    case 'ORGANIZATION_MAX_PROJECT_COUNT_REACHED':
      return 'Vous avez atteint le maximum de projet autorisé'
    case 'DELETE_NOT_IMPLEMENTED':
      return 'Il est impossible de supprimer cette donnée'
    case 'CONFIGURATION_INCORRECT':
      return 'La configuration du serveur est incorrecte'
    case 'NORME_SHOULD_EXISTS':
      return 'La norme de votre fiche inies est inconnue'
    case 'INIES_RECORD_NOT_FOUND':
      return 'La fiche INIES est introuvable'
    case 'NO_ROOT_NOMENCLATURE':
      return "Il n'y a pas de nomenclature"
    case 'NO_COGNITO_RIGHTS':
      return "Vous n'avez pas le droit d'effectuer cette action"
    case 'USER_DOES_NOT_EXISTS':
      return "L'utilisateur n'existe pas"
    case 'NO_COGNITO':
      return "Pas de liaison avec le serveur d'authentification"
    case 'NO_DELETE':
      return 'Impossible de supprimer cette donnée'
    case 'COGNITO_UNKNOWN_ERROR':
      return "Erreur inconnue de la part du serveur d'authentification"
    case 'INVALID_PHONE_NUMBER_FORMAT':
      return 'Le format du numéro de téléphone est invalide'
    case 'INVALID_PARAMETER':
      return 'Vous avez envoyé un paramètre invalide'
    case 'INVALID_PASSWORD_EXCEPTION':
      return 'Le mot de passe est invalide'
    case 'INVALID_PARAMETER_EXCEPTION':
      return 'Vous avez envoyé un paramètre invalide'
    case 'UNKNOWN_USER_ERROR':
      return "L'utilisateur est inconnu"
    case 'WRONG_ORGANIZATION':
      return "L'organizsation est incorrecte"
    case 'PROJECT_USER_HAS_NO_USER_ORGANIZATION_ID':
      return "Il n'y a pas d'organisaton associé à vous et au projet"
    case 'USER_ORGANIZATION_NOT_FOUND':
      return "L'organisation de l'utilisateur n'a pas été trouvée"
    case 'ORGANIZATION_ID_SHOULD_BE_EMPTY':
      return "Un id d'une organisation existante a été envoyée"
    case 'DOMAIN_NAME_FORBIDDEN':
      return "Votre adresse email doit être une adresse d'entreprise"
    case 'DOMAIN_LIST_IS_LOADING':
      return "La liste des noms de domaine autorisés pour l'email n'a pas fini de charger. Veuillez ré-essayer dans quelques secondes"
    case 'MAX_LICENSE_COUNT_REACHED':
      return 'Vous avez atteint le maximum de license'
    case 'MAX_RSEE_PROJECT_REACHED':
      return 'Vous avez atteint le maximum de RSEE que vous pouvez charger'
    case 'INVITATION_DOES_NOT_EXIST':
      return "L'invitation que vous souhaitez utiliser n'existe pas"
    case 'USER_ALREADY_ON_PROJECT':
      return "L'utilisateur est déjà affecté au projet"
    case 'NO_FILE':
      return 'Pas de fichier trouvé'
    case 'COMPLETION_WRONG_VARIANT':
      return 'Erreur lors de la modification du code complétion, il appartient à une autre variante.'
    case 'ERROR_CREATING_SIGNED_REQUEST':
      return 'Impossible de charger les vidéos'
    case 'DVE_CANNOT_BE_0':
      return "L'unité de la fiche INIES est incorrecte"
    case 'ADDITIONAL_CODE_WRONG_VARIANT':
      return "Le code additionel n'appartient pas à la variante envoyée"
    case 'CODE_COMPLETION_DOES_NOT_EXISTS':
      return "Le code completion n'existe pas"
    case 'VARIANT_WRONG_PROJECT':
      return "La variante n'appartient pas à ce projet"
    case 'ID_NEEDED_FOR_UPDATE':
      return "L'id de l'objet est obligatoire pour une mise à jour"
    case 'VARIANT_DOES_NOT_EXISTS':
      return "Le variante demandée n'existe pas"
    case 'CODE_ACV_CUSTOM_ALREADY_EXISTS':
      return 'Il ne peut pas y avoir 2 codes avec le même nom'
    case 'NO_INVITATION_TO_SELF':
      return "Vous ne pouvez pas envoyer d'invitation à vous même"
    case 'BOTH_MUST_BE_NULL_OR_NOT_NULL':
      return 'La requête pour récupérer les projets suivants est incorrecte'
    case 'CODE_COMPLETION_INCORRECT_ID':
      return "L'id du code est incorrect"
    case 'WRONG_RSEE_FILE':
      return "Soit le fichier n'est pas un RSEE valide, soit son format est invalide. Formats supportés: .xml, .zip"
    case 'NO_RSEE_FOUND':
      return "Aucun RSEE trouvé dans le fichier zip. Peut-être qu'il contient seulement un RSET ?"
    case 'ENDPOINT_DOES_NOT_EXISTS':
      return "Le endpoint backend n'existe pas"
    case 'NOT_ON_PROJECT':
      return "Vous n'avez pas le droit de voir ce RSEE"
    case 'NO_CODE_EXTRAIT':
      return "Aucun code n'est affecté dans la maquette. La maquette est ignorée"
    case 'MAIL_NOT_VALID_FOR_THIS_INVITATION':
      return "L'adresse email saisie ne correspond pas à l'adresse email de l'invitation"
    case 'INVITATION_NOT_FOUND':
      return "L'invitation n'a pas été trouvée"
    case 'FORBIDDEN_FILE_SIZE':
      return 'Le fichier est trop volumineux. La taille maximale est de 3 Mo'
    case 'FORBIDDEN_FILE_DIMENSIONS':
      return 'Le fichier est trop volumineux. La taille maximale est de 1920 x 1080'
    case 'FORBIDDEN_FILE_EXTENSIONS':
      return "Le format du fichier n'est pas validate. Voici les extensions acceptés: '.png, .jpeg, .jpg'"
    case 'FORBIDDEN_FICHE_CONFIGUREE_EXTENSIONS':
      return "Le format du fichier n'est pas validate. Voici les extensions acceptés: '.zip, .xml'"
    case 'FILE_NEEDS_NAME':
      return 'Erreur lors de la récupération du nom du fichier'
    case 'FILE_INCORRECT':
      return "Le fichier n'a pas pu être lu. Veuillez ré-essayer"
    case 'ZIP_NOT_SUPPORTED':
      return "Les fichiers zip ne sont pas encore supportées pour les fiches configurées. Veuillez poster l'XML"
    case 'USER_NOT_ON_PROJECT_RSEE':
      return "Vous n'avez pas le droit d'accèder à ce projet"
    case 'PROJECT_NOT_ARCHIVED':
      return "Le projet ne peut pas être supprimé, car il n'est pas à l'état Archivé"
    case 'PROJECT_IS_ARCHIVED':
      return "Le projet est à l'état archivé, l'action n'est pas possible"
    case 'DOCUMENT_NOT_FOUND':
      return "Le RSEE n'existe pas"
    case 'INCORRECT_XML':
    case 'INCORRECT_FICHE_XML':
      return 'Le XML Du RSEE est incorrect'
    case 'INCORRECT_DATASCOMP_VERSION':
      return 'La version de la partie données générales (Datas_Comp) est inconnue'
    case 'EMPTY_DATASCOMP_VERSION':
      return 'Pas de version pour la partie données générales (Datas_Comp) du RSEE'
    case 'EMPTY_DATASCOMP':
      return "Aucune information générale du projet n'est renseignée (Nom, Addresse, Logiciel, BE etc...). Êtes-vous sûr qu'il s'agit d'un RSEE RE2020"
    case 'INCORRECT_DATASCOMP_XML':
      return 'Impossible de lire la partie données générales (Datas_Comp) du RSEE'
    case 'EMPTY_RSENV_VERSION':
      return 'Pas de version pour la partie RsEnv du RSEE'
    case 'INCORRECT_RSENV_VERSION':
      return 'La version de la partie "RsEnv" est inconnue'
    case 'EMPTY_RSENV':
      return 'Aucune information pour la partie "RsEnv" du RSEE'
    case 'INCORRECT_RSENV_XML':
      return 'Impossible de lire la partie "RsEnv" du RSEE'
    case 'UNZIP_ERROR':
      return 'Erreur durant le dé-zippage du fichier. Veuillez ré-essayer'
    case 'WRONG_RSEE_DATA':
      return 'Les données contenues dans le RSEE sont incorrectes'
    case 'NO_IMG_FOR_CODE':
      return "Aucune image n'est affecté a ce code"
    case 'NO_IMG_FOR_PROJECT':
      return "Aucune image n'est affecté a ce projet"
    case 'IMAGE_UNREADABLE':
      return "L'image que vous avez sélectionnée ne semble pas être une image valide"
    case 'EMPTY_XML':
      return 'Le fichier XML est vide'
    case 'FICHE_NOT_EXISTS_ON_PROJECT':
      return "Ce matériau n'existe pas"
    case 'NO_FICHE_CONFIGUREE_FOUND':
      return "Aucune fiche configurée n'a été trouvé"
    case 'CALCULATION_EMPTY_VALUE':
      return 'Aucun de ces champs ne peut être vide'
    case 'INVALID_USER_ROLE':
      return 'Votre utilisateur peut uniquement créer des collaborateurs normaux et chef de projet'
    case 'USER_EMAIL_WRONG_DOMAIN':
      return "Vous ne pouvez pas rattacher cet utilisateur, car le nom de domaine de son adresse email n'est pas le même que celui de votre organisation"
    case 'CANNOT_DISABLE_ADMINISTRATOR':
      return "Un utilisateur qui est administrateur sur un projet ne peut pas être supprimé de votre organisation. Veuillez le remplacer sur les projets où c'est le cas"
    case 'CANNOT_DISABLE_MEGA_USER':
      return 'Un utilisateur qui est "Administrateur" ne peut pas être supprimé. Changez ses droits ou appelez le support pour effectuer cette action'
    case 'USER_HAS_NO_ORGANIZATION':
      return "Aucune organisation n'est attachée à votre utilisateur. Vous êtes surement en test gratuit."
    case 'INCORRECT_DASHBOARD_VERSION':
      return 'La version du document est incorrecte'
    case 'NO_DEFAULT_VERSION':
      return 'Pas de version par défaut des tableau de bord sur cet environnement'
    case 'LAST_MEGA_USER':
      return "Impossible de modifier les droits du dernier 'administrateur' de l'organisation. Il faut toujours au moins un administrateur"
    case 'NO_REFERENCE':
      return "Aucun code réference n'a été trouvé pour ce code extrait"
    case 'USER_EMAIL_DOMAIN_FORBIDDEN':
      return "Time To Beem est un service B2B. Merci d'utiliser une adresse email professionnelle"
    case 'NO_SHARE_INFORMATION_FOUND':
      return 'Le lien utilisé est invalide'
    case 'MAX_USAGE_COUNT_REACHED':
      return "Vous avez atteint la limite du nombre d'utilisation de ce lien"
    case 'MAX_VALIDITY_DATE_REACHED':
      return 'Le lien est périmé. La date validité a été dépassée.'
    case 'LINK_NOT_FOUND':
      return "Le lien n'existe pas"
    case 'UUID_COLLISION':
      return "Une erreur s'est produite à la sauvegarde du lien. Veuillez ré-essayer"
    case 'TOO_MANY_FILE_ZIP':
      return 'Le fichier .Zip contient trop de fichiers. Veuillez ré-essayer'
    case 'USER_SHOULD_BE_SAME_ORGANIZATION_HAS_PROJECT':
      return "L'utilisateur ne fait pas partie de l'organisation qui possède ce projet"
    case 'NEED_AT_LEAST_ONE_USER':
      return "Il faut au moins un utilisateur dans l'équipe du projet"
    case 'FORBIDDEN_FOR_TEST_USERS':
      return "Un test user n'a pas le droit de mettre à jour un RSEE"
    case 'USER_NO_RIGHT_DELETE_RSEE_PROJECT':
      return "Un test user n'a pas le droit de supprimer un projet RSEE"
    case 'ORGANIZATION_NO_RIGHT_GLOBAL_DASHBOARD':
      return 'Vous ne disposez pas des droits pour accéder au tableau de bord général'
    case 'RSEE_INCORRECT_FILE':
      return 'Le fichier XML du RSEE est illisible ou incorrect'
    case 'RSEE_INCORRECT_FILE_IN_ZIP':
      return 'Le fichier ZIP contient un fichier RSEE ou fiche configurée qui est illisible ou incorrect'
    case 'RSEE_PROJECT_NAME_ALREADY_EXIST':
      return 'Ce nom de projet existe déjà'
    case 'ORGANIZATION_NAME_ALREADY_EXIST':
      return 'Une organisation avec ce nom existe déjà'
    case 'INVALID_DATE':
      return 'La date est invalide'
    case 'FILTER_DATE_ERROR':
      return 'La date de début doit être antérieure à la date de fin. Veuillez ajuster vos dates et réessayer.'
    case 'TABLEAU_AUTHENTICATION_FAILED':
      return "Impossible de s'authentifier auprès de Tableau. Veuillez contacter le support Time to Beem"
    case 'VARIANT_SAME_NAME':
      return 'La base et la variante ne doivent pas avoir le même nom'
    case 'PROJECT_SOURCE_NOT_FOUND':
      return "Le projet source n'existe pas"
    case 'PROJECT_TARGET_NOT_FOUND':
      return "Le projet cible n'existe pas"
    case 'MATERIAL_DOES_NOT_EXISTS':
      return "Un des matériaux renseigné dans le projet source n'existe pas. Veuillez contacter le support"
    case 'CODE_CUSTOM_NOT_FOUND':
      return "Une anomalie s'est produite lors de la copie. L'un des codes complétions issue du projet d'origine a une anomalie"
    case 'SOURCE_CALCULATION_NOT_FOUND':
      return "Le projet sélectionné n'a pas de matériaux pour cette phase"
    case 'TARGET_CALCULATION_NOT_FOUND':
      return "La partie calcul du projet cible n'est pas initialisée"
    case 'IMPACT_FOR_PHASE_NUMBER_FORMAT_ERROR':
      return "L'impact sur une phase d'une fiche est dans un format de nombre inconnu (exemple: '1,21.12.1'). Cela provient probablement d'une erreur dans une fiche configurée."
    case 'DOOR_AVERAGE_HEIGHT_WRONG':
      return 'La hauteur de la porte ne peut pas être égale à zéro'
    case 'EMPTY_CODE_EXTRAIT_LIST':
      return "La maquette ne contient aucun code. Elle est probablement mal configurée. Veuillez consulter le support si vous ne comprenez pas pourquoi c'est le cas"
    case 'BS_USAGE_NOT_FOUND':
      return "L'usage du bâtiment n'est pas répértorié"
    case 'BS_PROJECT_NOT_FOUND':
      return "Le projet Beem Shot n'existe pas"
    case 'BS_PROJECT_CALCUL_STATUS_EMPTY':
      return 'Le status du calcul est invalide'
    case 'SURFACE_REF_CANNOT_BE_ZERO':
      return 'La surface de référence ne doit pas être égale à zéro'
    case 'BS_ITEM_NOT_FOUND':
      return "Cet élément n'existe pas"
    case 'CODE_EXTRAIT_ESCALIER_MANDATORY':
      return "Il y a un escalier qui n'est pas relié à un élément de la maquette (pas de code extrait). Or c'est obligatoire pour connaitre la quantité"
    case 'WRONG_CODE_EXTRAIT_ESCALIER':
      return "Il y a un escalier qui a un code extrait différent de SUP_ESC. C'est incorrect"
    case 'USAGE_NOT_SUPPORTED':
      return "L'usage n'est pas supporté. Les 4 valeurs possibles sont 'logement individuel', 'logement collectif', 'bureau' ou 'enseignement'"
    case ' BS_PROJECT_SHOULD_HAVE_AN_ID':
      return 'Le projet Beem Shot doit avoir un id '
    case 'BS_PROJECT_NAME_ALREADY_EXISTS':
      return 'Le nom du projet exist déjà'
    case 'BS_INVALID_DATE':
      return 'La date est invalide'
    case ' NO_IMG_FOR_BS_PROJECT':
      return "Aucune image n'est affectée à ce projet Beem Shot"
    case 'BS_PROJECT_FORBIDDEN':
      return "L'utilisateur n'est pas un administrateur de projet Beem Shot"
    case 'NOT_MAIN_USER':
      return "L'utilisateur n'est pas l'utilisateur principal du projet Beem Shot"
    case 'NOT_BIM_MODEL_OWNER':
      return "L'utilisateur n'est pas le propriétaire de la maquette Bim"
    case 'CODE_EXTRAIT_SHOULD_EXIST':
      return "Un item, extrait de la maquette, ne contient pas d'information extraite. Veuillez contacter le support."
    case 'FLOOR_NUMBER_MUST_NOT_BE_ZERO':
      return "Le nombre de niveau doit être au moins 1, actuellement, c'est 0."
    case 'BIM_MODEL_TOKEN_NOT_FOUND':
      return "Vous n'avez pas le droit d'accéder à ce model. Le token fourni n'existe pas"
    case 'BIM_MODEL_TOKEN_USED':
      return 'Le token fourni a déjà été utilisé'
    case 'REVETEMENT_SOL_SHOULD_HAVE_CODE_EXTRAIT':
      return 'Il devrait y avoir au moins un code extrait sur le revêtement de sol'
    case 'SURFACE_MUST_NOT_BE_ZERO':
      return 'La surface ne peut pas être égale à zéro'
    case 'MACRO_COMPONENT_NOT_FOUND':
      return "Le composant n'a pas été trouvé"
    case 'NO_MACRO_EQUIVALENT_FOR_IMPACT':
      return "Le composant avec le même impact n'a pas été trouvé"
    case 'FILENAME_EMPTY':
      return "Impossible de générer une clé d'identification pour le fichier car le nom du fichier est vide"
    case 'VARIANT_DESCRIPTION_FIELD_TOO_LONG':
      return 'La description de la variante est trop longue'
    case 'VARIANT_NAME_FIELD_TOO_LONG':
      return 'Le nom de la variante est trop long'
    case 'BS_VARIANT_NOT_FOUND':
      return "La variante n'existe pas"
    case 'BS_PROJECT_RESULT_NOT_FOUND':
      return "Les résultats de ce calcul n'existe pas"
    case 'NOT_ICADE_USER':
      return "Votre utilisateur ne fait pas partie de l'organisation Icade"
    case 'NOT_MEGA_USER':
      return "Votre utilisateur n'est pas administrateur"
    case 'UNKNOWN_CLIENT_ID':
      return 'Le client id est incorrect'
    case 'NO_PROJECT_IN_DB':
      return "Le projet n'existe pas encore dans Time to Beem"
    case 'SYNCHRONIZATION_ALREADY_IN_PROGRESS':
      return "La synchronization est déjà en cours. Veuillez attendre quelques minutes qu'elle se termine. Si vos données ne sont toujours pas à jour, alors vous pourrez la relancer."
    case 'NO_MODEL_FOR_VARIANT':
      return 'Pas de Maquette numérique pour cette variante'
    default:
      return message
  }
}
