import { Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import './IlluminatedButton.css'
import React from 'react'

type iProps = {
  icone: string
  title: string
  text: string
  backgroundcolor: string
  buttonLabel?: string
  url?: string
}

export default function InfoCard({ icone, title, text, backgroundcolor, buttonLabel, url }: iProps): React.JSX.Element {
  return (
    <Box
      display='flex'
      sx={{
        border: 1,
        bgcolor: backgroundcolor,
        minHeight: 150,
        flexDirection: 'column',
        justifyContent: 'center',
        borderRadius: 3,
        mt: 3,
        mb: 3,
        pt: 3,
        pr: 3,
        pl: 3,
        color: '#ffffff',
      }}>
      <Box display='flex' justifyContent='space-between'>
        <Typography className='notranslate' noWrap textOverflow='ellipsis' sx={{ flex: 1 }}>
          {title}
        </Typography>
        <span className='material-symbols-outlined' translate='no'>
          {icone}
        </span>
      </Box>
      <Box>
        <Typography
          textAlign='right'
          className='notranslate'
          noWrap
          fontSize={45}
          fontWeight='bold'
          textOverflow='ellipsis'
          sx={{ flex: 1, mr: 10 }}>
          {text}
        </Typography>
      </Box>
      <Box>
        <Link to={url ?? '#'} style={{ textDecoration: 'none' }}>
          <Button color='info' className='illuminated-button' disabled={buttonLabel === undefined} sx={{ color: '#fffff' }}>
            {buttonLabel}
          </Button>
        </Link>
      </Box>
    </Box>
  )
}
