import CalculateIcon from '@mui/icons-material/CalculateOutlined'
import { Box, FormControl, FormHelperText, FormLabel, Grid } from '@mui/material'
import React, { FormEvent, useContext, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ConfirmationDialog } from '../../../../components/dialog/confirmation-dialog'
import FileInput from '../../../../components/inputs/file-input/file-input'
import { pagesUrl } from '../../../../core/appConstants'
import { RseeDocumentContext } from '../../../../core/context/rsee/rsee-document-context'
import { RseeProjectRoleContext } from '../../../../core/context/user/rsee-project-role-context'
import { UserContext } from '../../../../core/context/user/user-context'
import { RseeProject } from '../../../../core/dto/rsee/rsee-project'
import { ProjectTypeEnum } from '../../../../core/enum/project/projectTypeEnum'
import { OrganizationRoleEnum } from '../../../../core/enum/user/organization-role-enum'
import { useRsee } from '../../../../core/hooks/rsee/use-rsee'
import { resolveUrl } from '../../../../core/services/http-service'
import RseeHeader from '../components/rsee-header'
import { RseeProjectCreationResponse } from '../../../../core/dto/rsee/rsee-project-creation-response'
import { RseeActionTypeEnum } from '../../../../core/enum/rsee/rseeActionTypeEnum'
import { RseeSummaryTypeEnum } from '../../../../core/enum/rsee/rseeSummaryTypeEnum'

export default function RseeImportForm(): React.JSX.Element {
  const navigate = useNavigate()
  const { rseeProjectId, rseeDocumentId } = useParams()

  const { hasProject } = useContext(RseeProjectRoleContext)
  const { user } = useContext(UserContext)
  const { refreshRseeDocument, rseeDocument } = useContext(RseeDocumentContext)

  const { createRseeProject, addRseeDocument, updateRseeDocument } = useRsee()

  const [file, setFile] = useState<File | undefined>(undefined)
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [progress, setProgress] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const actionType = chooseActionType()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const isWriting = useMemo(() => hasProject, [hasProject])

  function chooseActionType(): RseeActionTypeEnum {
    if (!rseeProjectId || rseeProjectId === 'new') {
      return RseeActionTypeEnum.RSEE_PROJECT_CREATION
    } else if (!rseeDocumentId || rseeDocumentId === 'new') {
      return RseeActionTypeEnum.RSEE_DOCUMENT_ADDITION
    } else {
      return RseeActionTypeEnum.RSEE_DOCUMENT_UPDATE
    }
  }

  function navigateBackUrl(): string {
    if (actionType === RseeActionTypeEnum.RSEE_PROJECT_CREATION) {
      return resolveUrl(pagesUrl.PROJECTS_PAGE, [], { type: ProjectTypeEnum.RSEE })
    } else if (actionType === RseeActionTypeEnum.RSEE_DOCUMENT_ADDITION) {
      return resolveUrl(pagesUrl.RSEE_PROJECT, [rseeProjectId])
    } else {
      // If update
      return resolveUrl(pagesUrl.RSEE_PROJECT_DOCUMENT, [rseeProjectId, rseeDocumentId])
    }
  }

  function onFileLoad(newFile: File): void {
    setErrors({})
    setFile(newFile)
    setProgress(100)
  }

  function postFile(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault()

    if (file) {
      if (actionType === RseeActionTypeEnum.RSEE_PROJECT_CREATION) {
        setIsLoading(true)
        createRseeProject(file)
          .then((rseeProjectResponse: RseeProjectCreationResponse) => {
            if (rseeProjectResponse?.id) {
              navigate(
                resolveUrl(
                  pagesUrl.RSEE_PROJECT_DOCUMENT_SUMMARY_IMPORT,
                  [rseeProjectResponse.id, rseeProjectResponse.rseeDocumentId],
                  { from: RseeSummaryTypeEnum.RSEE_DOWNLOADING }
                )
              )
            }
          })
          .finally(() => {
            setIsLoading(false)
          })
      } else if (actionType === RseeActionTypeEnum.RSEE_DOCUMENT_ADDITION && rseeProjectId) {
        setIsLoading(true)
        addRseeDocument(file, rseeProjectId)
          .then((newDocumentId: string) => {
            if (newDocumentId) {
              navigate(
                resolveUrl(pagesUrl.RSEE_PROJECT_DOCUMENT_SUMMARY_IMPORT, [rseeProjectId, newDocumentId], {
                  from: RseeSummaryTypeEnum.RSEE_DOWNLOADING,
                })
              )
            }
          })
          .finally(() => {
            setIsLoading(false)
          })
      } else if (actionType === RseeActionTypeEnum.RSEE_DOCUMENT_UPDATE && rseeProjectId && rseeDocumentId) {
        if (user?.role === OrganizationRoleEnum.TEST_USER) {
          setIsDialogOpen(true)
        } else {
          setIsLoading(true)
          updateRsee().finally(() => setIsLoading(false))
        }
      } else {
        setErrors({ fileInput: 'Id projet ou id document incorrect' })
      }
    } else {
      setErrors({ fileInput: 'Vous devez importer un fichier' })
    }
  }

  function updateRsee(): Promise<void> {
    if (file && rseeDocumentId) {
      return updateRseeDocument(file, rseeDocumentId).then((updatedRseeProject: RseeProject) => {
        if (updatedRseeProject?.id) {
          return refreshRseeDocument().then(() =>
            navigate(
              resolveUrl(pagesUrl.RSEE_PROJECT_DOCUMENT_SUMMARY_IMPORT, [updatedRseeProject.id, rseeDocument?.id], {
                from: RseeSummaryTypeEnum.RSEE_DOWNLOADING,
              })
            )
          )
        } else {
          return Promise.resolve()
        }
      })
    } else {
      return Promise.resolve()
    }
  }

  return (
    <>
      <ConfirmationDialog
        id='rseeConfirmUpdateDialog'
        action={() => updateRsee()}
        title='Attention'
        contentText="Mettre à jour votre RSEE consommera une de vos chances d'analyser vos RSEE. Êtes-vous sûr de vouloir continuer ?"
        handleClose={() => setIsDialogOpen(false)}
        open={isDialogOpen}
      />
      <Grid container rowSpacing={2} columnSpacing={2}>
        <RseeHeader
          title='Importation RSEE'
          action={{ formId: 'rseeFormId', disabled: false, isLoading }}
          navigateBackPath={navigateBackUrl()}
          actionLabel='Importer RSEE'
          icon={<CalculateIcon sx={{ fontSize: '40px' }} />}
          isWriting={actionType === RseeActionTypeEnum.RSEE_PROJECT_CREATION || isWriting}
        />
        <Grid item xs={12}>
          <Box id='rseeFormId' component='form' onSubmit={postFile} sx={{ display: 'flex', justifyContent: 'center' }}>
            <FormControl>
              <FormLabel>Selectionner un fichier RSEE</FormLabel>
              <FileInput onChange={onFileLoad} file={file} progress={progress} disabled={false} fileType='.xml,.zip' />
              {errors.fileInput && <FormHelperText error>{errors.fileInput}</FormHelperText>}
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
