import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import React, { Dispatch, SetStateAction, useCallback, useEffect } from 'react'
import { ProjectPhase } from '../../../../core/dto/project/project-phase'
import { PhaseEnum } from '../../../../core/enum/phaseEnum'
import ProjectPhaseStepperItem from './project-phase-stepper-item'

type IProps = {
  phasesForm: ProjectPhase[]
  error: Record<string, string | undefined>
  setProjectPhasesForm: Dispatch<SetStateAction<ProjectPhase[]>>
  createErrorKey(phase: PhaseEnum): string
  cleanError(errorKey: string): void
}

export default function ListProjectPhaseStepperItem({
  phasesForm,
  error,
  setProjectPhasesForm,
  createErrorKey,
  cleanError,
}: IProps): React.JSX.Element {
  const getCurrentPhase = useCallback((): PhaseEnum | undefined => {
    let newCurrentPhase: PhaseEnum | undefined
    phasesForm.forEach((phase) => {
      if (phase.currentPhase) {
        newCurrentPhase = phase.phase
      }
    })
    return newCurrentPhase
  }, [phasesForm])

  const [currentPhase, setCurrentPhase] = React.useState<PhaseEnum | undefined>(getCurrentPhase())

  const phaseStep = [PhaseEnum.ESQ, PhaseEnum.APS, PhaseEnum.APD, PhaseEnum.PRO, PhaseEnum.DCE]

  useEffect(() => {
    setCurrentPhase(getCurrentPhase())
  }, [getCurrentPhase])

  return (
    <Box
      sx={{
        ml: 10,
        mr: 10,
        mt: 10,
      }}>
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell align='left'>
              <Typography
                sx={{
                  fontWeight: 'bold',
                }}>
                PHASE
              </Typography>
            </TableCell>
            <TableCell align='left'>
              <Typography
                sx={{
                  fontWeight: 'bold',
                }}>
                {' '}
              </Typography>
            </TableCell>
            <TableCell align='left'>
              <Typography
                sx={{
                  fontWeight: 'bold',
                }}>
                NOM
              </Typography>
            </TableCell>
            <TableCell align='left'>
              <Typography
                sx={{
                  fontWeight: 'bold',
                }}>
                OBJECTIFS
              </Typography>
            </TableCell>
            <TableCell align='left'>
              <Typography
                sx={{
                  fontWeight: 'bold',
                }}>
                DATE LIMITE
              </Typography>
            </TableCell>
            <TableCell align='left' />
          </TableRow>
        </TableHead>
        <TableBody>
          {phaseStep.map((phaseEnum) => (
            <ProjectPhaseStepperItem
              key={phaseEnum}
              phaseEnum={phaseEnum}
              currentPhase={currentPhase}
              setCurrentPhase={setCurrentPhase}
              phasesForm={phasesForm}
              setProjectPhasesForm={setProjectPhasesForm}
              error={error}
              errorKey={createErrorKey(phaseEnum)}
              cleanError={cleanError}
            />
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}
