import { stringToDate } from '../../services/date-service'

export class Statistic {
  id: string
  totalUser: number | undefined
  userClientsAndProspects: number | undefined
  testUsers: number | undefined
  bimProjectTotal: number | undefined
  bimProjectForClientsAndProspects: number | undefined
  bimProjectForClientsAndProspectsInProgress: number | undefined
  rseeProjectTotal: number | undefined
  rseeProjectFromClients: number | undefined
  rseeProjectFromClientsAndInProgress: number | undefined
  rseeDocumentTotal: number | undefined
  rseeDocumentFromClients: number | undefined
  rseeDocumentFromClientsAndInProgress: number | undefined
  rseeDocumentFromTestUsers: number | undefined
  createdDate: Date | undefined
  userEmailsWhoModifiedBimProject: string[]
  userEmailsWhoModifiedRseeProject: string[]

  constructor(data: any) {
    this.id = data.id
    this.totalUser = data.totalUser
    this.userClientsAndProspects = data.userClientsAndProspects
    this.testUsers = data.testUsers
    this.bimProjectTotal = data.bimProjectTotal
    this.bimProjectForClientsAndProspects = data.bimProjectForClientsAndProspects
    this.bimProjectForClientsAndProspectsInProgress = data.bimProjectForClientsAndProspectsInProgress
    this.rseeProjectTotal = data.rseeProjectTotal
    this.rseeProjectFromClients = data.rseeProjectFromClients
    this.rseeProjectFromClientsAndInProgress = data.rseeProjectFromClientsAndInProgress
    this.rseeDocumentTotal = data.rseeDocumentTotal
    this.rseeDocumentFromClients = data.rseeDocumentFromClients
    this.rseeDocumentFromClientsAndInProgress = data.rseeDocumentFromClientsAndInProgress
    this.userEmailsWhoModifiedBimProject = data.userEmailsWhoModifiedBimProject
    this.userEmailsWhoModifiedRseeProject = data.userEmailsWhoModifiedRseeProject
    this.rseeDocumentFromTestUsers = data.rseeDocumentFromTestUsers
    this.createdDate = stringToDate(data.createdDate)
  }
}
