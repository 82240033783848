import { Box, CircularProgress } from '@mui/material'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { CustomBottomNavigation } from '../../components/buttons/navigate-button/CustomBottomNavigation'
import { SubsetInfo } from '../../components/ifc-displayer/models/SubsetInfo'
import { BsModelImportContext } from '../../core/context/beem-shot/BSBimModel/BSBimModelImportContext'
import { CodeExtraitDisplay } from '../../core/dto/code-extrait/CodeExtraitDisplay'
import { CodeExtrait } from '../../core/dto/code-extrait/code-extrait'
import { codeStateEnum } from '../../core/enum/codeStateEnum'
import { CodesVersion } from '../../core/enum/codesVersionEnum'
import { useBSParseBim } from '../../core/hooks/beem-shot/useBSParseBim'
import { useBimModel } from '../../core/hooks/bim-model/use-bim-model'
import { codeToKey } from '../../core/services/code-service'
import { BSBimModelAnalyzer } from '../beem-shot/components/BSBimModelAnalyzer'

export function TutorialHelper(): React.JSX.Element {
  const { codesExtraits, setCodesExtraits, viewer } = useContext(BsModelImportContext)

  const [bimModelFile, setBimModelFile] = useState<File | undefined>()
  const [selectedCodeExtrait, setSelectedCodeExtrait] = useState<CodeExtrait | undefined>(undefined)
  const [progress, setProgress] = useState<number>(0)

  const { analyzeModel } = useBSParseBim({ version: CodesVersion.VERSION_1_6, setProgress })
  const { fetchTutorialModel } = useBimModel()

  useEffect(() => {
    fetchTutorialModel().then((file: File | undefined) => {
      setBimModelFile(file)
    })
  }, [fetchTutorialModel])

  function reset(): void {
    setBimModelFile(undefined)
    setCodesExtraits([])
  }

  function cancelUpload(): void {
    setBimModelFile(undefined)
    setCodesExtraits([])
    setProgress(0)
  }

  const handleClickAcv = useCallback(
    (codeExtrait: CodeExtraitDisplay, disableViewerHilight?: boolean) => {
      if (
        !disableViewerHilight &&
        `${codeExtrait.code + codeExtrait.occurence}` !==
          `${selectedCodeExtrait ? selectedCodeExtrait.code + selectedCodeExtrait.occurence : ''}`
      )
        viewer?.manager.subsetsManager.highlightCodeExtrait(codeExtrait)

      const newCode = codesExtraits.find((code) => codeToKey(code) === codeToKey(codeExtrait))
      setSelectedCodeExtrait(newCode)
    },
    [selectedCodeExtrait, viewer?.manager.subsetsManager, codesExtraits]
  )

  function handleClickCodeManquant(codeManquant: SubsetInfo): void {
    setSelectedCodeExtrait(undefined)
    viewer?.manager.subsetsManager.toggleSubsetHighlight(codeManquant)
  }

  function handleCodeVariantChange(codeVariant: codeStateEnum, unhighlight?: boolean): void {
    viewer?.manager.subsetsManager.updateCodeState(codeVariant, unhighlight ?? false)
    if (unhighlight) setSelectedCodeExtrait(undefined)
  }

  return (
    <>
      {bimModelFile !== undefined ? (
        <BSBimModelAnalyzer
          isTree
          reset={reset}
          bimModelFile={bimModelFile}
          analyzeModel={analyzeModel}
          codesExtraits={codesExtraits}
          setProgress={setProgress}
          handleClickAcv={handleClickAcv}
          selectedCodeExtrait={selectedCodeExtrait}
          handleClickCodeManquant={handleClickCodeManquant}
          handleCodeVariantChange={handleCodeVariantChange}
          cancelUpload={cancelUpload}
          progress={progress}
        />
      ) : (
        <Box display='flex' height='100%' justifyContent='center' alignItems='center' sx={{ backgroundColor: '#FFFFFF' }}>
          <CircularProgress />
        </Box>
      )}

      <CustomBottomNavigation actionLabel='télécharger la méthode time to beem' actionButton={() => undefined} />
    </>
  )
}
