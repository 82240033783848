import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Children } from '../../../components/miscellianous/children'
import { RoleEnum } from '../../enum/roleEnum'
import { OrganizationRoleEnum } from '../../enum/user/organization-role-enum'
import { useUser } from '../../hooks/use-user'
import { ProjectContext } from '../project/project-context'
import { UserContext } from './user-context'

export const ProjectRoleContext = React.createContext<RoleStore>({} as RoleStore)
export default function ProjectRoleContextProvider({ children }: Children): React.JSX.Element {
  const { project } = useContext(ProjectContext)
  const { user } = useContext(UserContext)
  const { getUserRight } = useUser()
  const [role, setRole] = useState<RoleEnum[]>([])
  const [organizationRole, setOrganizationRole] = useState<OrganizationRoleEnum | undefined>(undefined)

  const isOrganizationMegaUser: boolean = useMemo(() => {
    if (user?.role && user.role === OrganizationRoleEnum.MEGA_USER) {
      return user.organizationId === project.organizationId
    } else {
      return false
    }
  }, [user, project])

  const isOrganizationUltimateUser: boolean = useMemo(
    () => !!(user?.role && user.role === OrganizationRoleEnum.ULTIMATE_USER),
    [user?.role]
  )

  useEffect(() => {
    if (project?.id) {
      getUserRight(project.id).then((res) => {
        setRole(res)
      })
    }
  }, [getUserRight, project.id])

  useEffect(() => {
    if (user?.role) {
      setOrganizationRole(user.role)
    } else {
      setOrganizationRole(undefined)
    }
  }, [user])

  const hasRole = useCallback((rolesToCheck: RoleEnum[]): boolean => rolesToCheck.some((i) => role.includes(i)), [role])

  const roleStore: RoleStore = useMemo(
    () => ({ role, setRole, hasRole, isOrganizationMegaUser, isOrganizationUltimateUser }),
    [hasRole, role]
  )
  return <ProjectRoleContext.Provider value={roleStore}>{children}</ProjectRoleContext.Provider>
}

export type RoleStore = {
  role: RoleEnum[]
  setRole: Dispatch<SetStateAction<RoleEnum[]>>
  hasRole(rolesToCheck: RoleEnum[]): boolean
  isOrganizationMegaUser: boolean
  isOrganizationUltimateUser: boolean
}
