import { useMemo } from 'react'
import { appConstants } from '../../appConstants'
import { Material } from '../../dto/material/material'
import MaterialRecordCreation from '../../dto/material/material-record-creation'
import { useHttp } from '../use-http'

type MaterialHook = {
  addOrganizationMaterialToProject: (materialId: string, projectId: string) => Promise<Response>
  addIniesToProject: (iniesId: number, projectId: string) => Promise<Material>
  addToOrganization: (row: MaterialRecordCreation) => Promise<Response>
}

export function useAddMaterial(): MaterialHook {
  const { post } = useHttp()

  return useMemo(
    () => ({
      addOrganizationMaterialToProject(materialId: string, projectId: string): Promise<Response> {
        return post(`${appConstants.apiEndpoints.MATERIAL}/add-organization-material-to-project`, {}, [
          {
            key: 'materialId',
            value: materialId.toString(),
          },
          {
            key: 'projectId',
            value: projectId,
          },
        ]).then((response) => response.json())
      },
      addIniesToProject(iniesId: number, projectId: string): Promise<Material> {
        return post(`${appConstants.apiEndpoints.MATERIAL}/add-inies-to-project`, {}, [
          {
            key: 'iniesId',
            value: iniesId.toString(),
          },
          {
            key: 'projectId',
            value: projectId,
          },
        ])
          .then((response) => response.json())
          .then((res: Material) => {
            const material = Material.fromCodeDto(res)
            if (material) {
              return material
            } else {
              throw new Error("Erreur lors de l'affection de la fiche inies")
            }
          })
      },
      addToOrganization(row: MaterialRecordCreation): Promise<Response> {
        return post(`${appConstants.apiEndpoints.MATERIAL}/add-to-organization`, row).then((response) => response.json())
      },
    }),
    [post]
  )
}
