import { Box, Container, Paper, Typography } from '@mui/material'

export default function BeemGuideExcelAcceptPage(): React.JSX.Element {
  return (
    <Container maxWidth='md'>
      <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
        <Paper variant='outlined' sx={{ my: { xs: 3, md: 3 }, p: { xs: 2, md: 3 } }}>
          <Typography component='h1' variant='h5' sx={{ pt: '-200px' }} gutterBottom>
            En attente de validation
          </Typography>
          <Typography>Une personne va traiter votre requête.</Typography>
          <Typography>
            Si celle-ci est validée, vous recevrez un email permettant d'accèder au document sur Google Drive.
          </Typography>
        </Paper>
      </Box>
    </Container>
  )
}
