import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react'
import { Children } from '../../../components/miscellianous/children'
import { useBimModel } from '../../../core/hooks/bim-model/use-bim-model'
import { CodeExtraitDisplay } from '../../../core/dto/code-extrait/CodeExtraitDisplay'
import { ModuleTypeEnum } from '../../../core/enum/bimModel/ModuleTypeEnum'
import { DashboardExtensionContext } from './DashboardExtensionContext'

export const CodeExtraitListPublicContext = React.createContext<CodeExtraitListPublicStore>({} as CodeExtraitListPublicStore)

export function CodeExtraitListPublicContextProvider({ children }: Children): React.JSX.Element {
  const { bimModelToken, dashboardType } = useContext(DashboardExtensionContext)
  const [codeExtraitList, setCodeExtraitList] = useState<CodeExtraitDisplay[]>([])
  const { fetchCodeExtraitsFromToken, fetchCodeExtraitsFromTokenForBeemShot } = useBimModel()

  useEffect(() => {
    if (bimModelToken) {
      if (dashboardType === ModuleTypeEnum.BEEM_SHOT) {
        fetchCodeExtraitsFromTokenForBeemShot(bimModelToken).then((newCodeExtraitList) => {
          setCodeExtraitList(newCodeExtraitList)
        })
      } else {
        fetchCodeExtraitsFromToken(bimModelToken).then((newCodeExtraitList) => {
          setCodeExtraitList(newCodeExtraitList)
        })
      }
    }
  }, [dashboardType, bimModelToken, fetchCodeExtraitsFromToken, fetchCodeExtraitsFromTokenForBeemShot])

  const codeExtraitListStore = useMemo(
    () => ({
      codeExtraitList,
      setCodeExtraitList,
    }),
    [codeExtraitList]
  )

  return (
    <CodeExtraitListPublicContext.Provider value={codeExtraitListStore}>{children}</CodeExtraitListPublicContext.Provider>
  )
}

export type CodeExtraitListPublicStore = {
  codeExtraitList: CodeExtraitDisplay[]
  setCodeExtraitList: Dispatch<SetStateAction<CodeExtraitDisplay[]>>
}
