import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Tab, Tabs, Typography } from '@mui/material'
import React, { useMemo, useState } from 'react'
import DigitalModelVideoCard from '../project-page/bim-model/tutorial-page/tutorial-section/project-bim-model-video-card'
import { videosData } from '../project-page/bim-model/tutorial-page/tutorial-section/tutorial-bim-video'
import { DownloadRevitPlugin } from './download-revit-plugin'
import { DownloadSharedSettings, SoftwareName } from './download-shared-settings'

export function ConfigurationHelper(): React.JSX.Element {
  const [tabs, setTabs] = React.useState(0)
  const [needParam, setNeedParam] = useState<boolean>(false)

  const software = useMemo(() => {
    if (tabs === 0) {
      return SoftwareName.REVIT
    } else if (tabs === 1) {
      return SoftwareName.ARCHICAD
    }
    return undefined
  }, [tabs])

  function handleChangeTabs(event: React.SyntheticEvent, newValue: number): void {
    setTabs(newValue)
    setNeedParam(false)
  }

  return (
    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
      <Tabs
        value={tabs}
        onChange={handleChangeTabs}
        aria-label='Tabs to choose between importing yourself or send invitation to someone'
        variant='fullWidth'
        TabIndicatorProps={{ style: { display: 'none' } }}
        textColor='inherit'
        sx={{ my: 3, backgroundColor: 'white', borderRadius: 2, height: '1vh', width: '30%' }}>
        <Tab
          label='REVIT'
          id='tab-0'
          sx={{ backgroundColor: tabs === 0 ? '#E6F0FB' : '', borderRadius: '15px', height: '1vh' }}
        />
        <Tab
          label='ARCHICAD'
          id='tab-1'
          sx={{ backgroundColor: tabs === 1 ? '#E6F0FB' : '', borderRadius: '15px', height: '1vh' }}
        />
      </Tabs>

      <Grid container columnGap={needParam ? 2 : 0}>
        <Grid item xs={needParam ? 1 : 4} />
        <Grid
          container
          item
          xs={needParam ? 5 : 4}
          p={3}
          boxShadow={4}
          borderRadius={3}
          gap={2}
          sx={{ background: 'white' }}>
          {tabs === 0 && <DownloadRevitPlugin />}
          {tabs === 1 && <DownloadSharedSettings software={software} />}

          <Box
            display='flex'
            flexDirection='column'
            borderRadius={4}
            gap={1}
            width='100%'
            sx={{ background: '#F5F8F8', border: 3, p: 3, borderColor: '#ECF3F3' }}>
            <Typography variant='subtitle1'>Tutorials vidéo</Typography>

            {videosData[tabs === 0 ? 1 : 2].map((item) => (
              <Accordion
                disableGutters
                square
                key={item.name}
                sx={{
                  borderRadius: 4,
                }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    border: 0,
                  }}>
                  <Typography variant='subtitle2'>{item.title}</Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Grid item sm={12} key={item.title}>
                    <DigitalModelVideoCard title='' video={item.name} thumbnail={item.thumbnail} />
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Grid>
        {tabs === 0 && (
          <Grid item xs={needParam ? 5 : 4}>
            {needParam ? (
              <Box p={3} boxShadow={4} borderRadius={3} gap={2} sx={{ background: 'white' }}>
                <DownloadSharedSettings software={software} />
              </Box>
            ) : (
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                border={3}
                borderRadius={3}
                width='60%'
                p={3}
                m={3}
                sx={{ background: '#ECF3F3', borderColor: '#C7D6DA', borderStyle: 'dotted', borderWidth: '4px' }}>
                <Typography variant='body2' textAlign='center'>
                  Un problème avec le plugin ?
                </Typography>
                <Typography
                  textAlign='center'
                  fontSize='14px'
                  fontWeight={400}
                  color='#009069'
                  onClick={() => setNeedParam(true)}
                  sx={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}>
                  Télécharger les paramètres partagés et jeu de propriétés
                </Typography>
              </Box>
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  )
}
