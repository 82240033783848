import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

function IconDefaultProjectCard(props: SvgIconProps): React.JSX.Element {
  return (
    <SvgIcon {...props}>
      <svg version='1.1' id='Calque_1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 457.03 455.68'>
        <path
          d='M226.16,29.15c108.15-0.12,197.19,88.82,196.47,197.69c-0.71,109.14-88.19,196.25-198.61,195.98
	c-107.04-0.27-196.91-87.81-195.19-200.85C30.46,115.99,117.46,29.17,226.16,29.15z M403.45,247.78
	c0.12-68.18-17.83-113.31-51.12-146.12c-40.47-39.89-89.47-57.39-145.98-52.09c-45.91,4.3-84.54,24.02-114.9,58.97
	c-14.88,17.14-22.19,36.97-17.14,59.86c4.39,19.94,16.71,34.64,34.51,43.4c15.21,7.49,31.5,13.12,47.77,18.08
	c34.27,10.46,57.24,32.07,69.39,65.73c4.72,13.08,9.89,26.06,15.91,38.58c8.57,17.83,21.5,31.87,41,37.64
	c26.81,7.93,50.15-0.07,69.26-19.26C386.45,318.12,403.05,276,403.45,247.78z'
        />
        <path
          d='M220.67,81.35c27.11,1.48,52.93,6.65,76.67,19.94c33.49,18.75,53.98,48.05,65.83,83.68c17.17,51.64,4,97.09-30.12,137.58
	c-6.91,8.2-16.14,13.82-26.36,17.37c-15.99,5.56-29.66,0.57-41.94-9.88c-9.29-7.91-15.87-17.78-20.72-28.88
	c-3.85-8.81-7.57-17.68-11.58-26.42c-11.57-25.21-30.44-42.71-56.28-52.7c-10.51-4.06-21.06-8.01-31.6-11.98
	c-10.98-4.14-20.06-10.94-27.63-19.8c-14.36-16.82-15.06-36.27-2-54.84c19.02-27.03,45.6-42.45,77.31-49.61
	C201.58,83.71,211.19,82.8,220.67,81.35z M226.13,115.05c-33.62-0.36-58.36,11.86-78.84,32.97c-18.08,18.63-15.27,39.06,6.92,52.65
	c7.73,4.74,16.14,8.51,24.56,11.96c29.71,12.17,50.73,32.97,64.33,61.98c4.54,9.67,10.31,19.01,16.93,27.39
	c7.63,9.66,18.44,14.03,30.65,9.73c5.75-2.02,11.41-5.65,15.82-9.89c24.83-23.92,32.96-54.04,29.52-87.33
	C329.98,156.31,282.34,114.98,226.13,115.05z'
        />
        <path
          d='M225.74,148.85c39.8-0.28,75.56,33.99,76.74,73.8c0.43,14.57-2.02,28.59-9.13,41.26c-3.21,5.72-8.05,11.06-13.32,14.99
	c-9.01,6.71-18.93,4.91-25.93-3.96c-3.46-4.38-6.18-9.42-8.78-14.4c-12.28-23.53-30.51-40.69-54.64-51.68
	c-4.37-1.99-8.77-3.97-12.89-6.41c-13.09-7.77-14.97-20.65-5.03-32.29c8.82-10.33,20.38-15.74,33.32-18.47
	C212.54,150.32,219.17,149.76,225.74,148.85z'
        />
      </svg>
    </SvgIcon>
  )
}

export default IconDefaultProjectCard
