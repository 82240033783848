import { InputAdornment, TextField, TextFieldProps } from '@mui/material'
import { FilledInputProps } from '@mui/material/FilledInput'
import { InputProps as StandardInputProps } from '@mui/material/Input/Input'
import { OutlinedInputProps } from '@mui/material/OutlinedInput'
import React, { ChangeEvent } from 'react'
import { NumericFormat } from 'react-number-format'
import { NumberFormatValues } from 'react-number-format/types/types'

interface IProps {
  id: string
  label: string
  value: number | undefined
  disabled?: boolean
  allowEmptyInput?: boolean
  variant?: 'standard' | 'filled' | 'outlined'
  size?: 'small' | 'medium'
  decimalScale?: number
  unit?: string
  fullWidth?: boolean
  shrink?: boolean
  backgroundColor?: 'white' | 'black'
  error?: Record<string, string | undefined>
  mode?: 'direct' | 'event'
  handleChange?(value: string): void
  handleEventChange?(event: ChangeEvent<HTMLInputElement>): void
  handleFocusOut?(event: React.FocusEvent<HTMLSelectElement | HTMLInputElement>): void
}

export function NumberInput({
  id,
  value,
  label,
  disabled,
  allowEmptyInput = false,
  variant = 'standard',
  size = undefined,
  decimalScale = 2,
  unit,
  fullWidth = true,
  shrink,
  backgroundColor,
  error,
  mode = 'direct',
  handleChange,
  handleEventChange,
  handleFocusOut,
}: Readonly<IProps>): React.JSX.Element {
  const materialUITextFieldProps = {
    id,
    label,
    variant,
    fullWidth,
    disabled,
    size,
    error: error ? !!error[id] : false,
    helperText: error ? error[id] : '',
  }

  function internalHandleChange(values: NumberFormatValues): void {
    const result = values.value !== '' ? values.value : '0'
    if (mode === 'direct' && handleChange) {
      handleChange(result)
    } else if (handleEventChange) {
      handleEventChange({ target: { id, value: result, type: 'number' } } as ChangeEvent<HTMLInputElement>)
    }
  }

  function computeInputProps():
    | Partial<StandardInputProps>
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>
    | undefined {
    if (unit && backgroundColor) {
      return {
        endAdornment: <InputAdornment position='end'>{unit}</InputAdornment>,
        style: {
          backgroundColor,
        },
      }
    } else if (unit && !backgroundColor) {
      return {
        endAdornment: <InputAdornment position='end'>{unit}</InputAdornment>,
      }
    } else if (!unit && backgroundColor) {
      return {
        style: {
          backgroundColor,
        },
      }
    } else {
      return undefined
    }
  }

  return (
    <NumericFormat
      onBlur={handleFocusOut}
      value={value}
      customInput={NumberTextField}
      allowNegative={false}
      decimalScale={decimalScale}
      decimalSeparator=','
      onValueChange={internalHandleChange}
      allowedDecimalSeparators={['.', ',']}
      thousandSeparator=' '
      InputProps={computeInputProps()}
      InputLabelProps={{ shrink }}
      allowEmptyInput={allowEmptyInput}
      {...materialUITextFieldProps}
    />
  )
}

type NumberTextFieldProps = TextFieldProps & {
  allowEmptyInput: boolean
}

function NumberTextField(props: NumberTextFieldProps): React.JSX.Element {
  const { value, label, allowEmptyInput, ...otherProps } = props
  let actualValue: string
  if (!allowEmptyInput && value !== undefined && value !== '' && typeof value === 'string') {
    actualValue = value
  } else {
    actualValue = '0'
  }

  return <TextField {...otherProps} label={label} value={actualValue} />
}
