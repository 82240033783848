import { Dispatch, SetStateAction } from 'react'

export class CalculationCarbonInformationProjectStore {
  data: any
  setData: Dispatch<SetStateAction<any>>
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  error: string | undefined
  setError: Dispatch<SetStateAction<string | undefined>>

  constructor(
    data: any,
    setData: Dispatch<SetStateAction<any>>,
    loading: boolean,
    setLoading: Dispatch<SetStateAction<boolean>>,
    error: string | undefined,
    setError: Dispatch<SetStateAction<string | undefined>>
  ) {
    this.data = data
    this.setData = setData
    this.loading = loading
    this.setLoading = setLoading
    this.error = error
    this.setError = setError
  }
}
