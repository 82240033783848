import { CodeCompletion } from '../code-completion/code-completion'

export default class AdditionalCodeUpdate {
  id = ''
  name = ''
  descriptionFromCodeAcv = ''
  lot = ''
  sousLot = ''
  materialId: string | undefined = undefined
  amount: number | undefined
  reuse = false
  hypothesis = ''

  static from(codeCompletion: CodeCompletion | undefined): AdditionalCodeUpdate | undefined {
    const additionalCodeUpdate = new AdditionalCodeUpdate()
    if (!codeCompletion || !codeCompletion.id) {
      return undefined
    }
    additionalCodeUpdate.id = codeCompletion.id
    additionalCodeUpdate.name = codeCompletion.name
    additionalCodeUpdate.descriptionFromCodeAcv = codeCompletion.descriptionFromCodeAcv
    additionalCodeUpdate.lot = codeCompletion.lot
    additionalCodeUpdate.sousLot = codeCompletion.sousLot

    additionalCodeUpdate.materialId = codeCompletion.materialId
    additionalCodeUpdate.amount = codeCompletion.amount
    additionalCodeUpdate.reuse = codeCompletion.reuse
    additionalCodeUpdate.reuse = codeCompletion.reuse
    additionalCodeUpdate.hypothesis = codeCompletion.hypothesis
    return additionalCodeUpdate
  }
}
