import React, { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { Children } from '../../../components/miscellianous/children'
import { MaterialLibIndicator } from '../../dto/organization/organization-indicator'

export const MaterialLibOrganizationIndicatorContext = React.createContext<OrganizationIndicatorStore>(
  {} as OrganizationIndicatorStore
)

export default function MaterialLibOrganizationIndicatorContextProvider({ children }: Children): React.JSX.Element {
  const [organizationIndicator, setOrganizationIndicator] = useState<MaterialLibIndicator>({
    total: 0,
    fdes: 0,
    pep: 0,
  })

  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | undefined>(undefined)

  const MaterialLibOrganizationIndicatorStore: OrganizationIndicatorStore = useMemo(
    () => ({ organizationIndicator, setOrganizationIndicator, loading, setLoading, error, setError }),
    [error, loading, organizationIndicator, setOrganizationIndicator]
  )

  return (
    <MaterialLibOrganizationIndicatorContext.Provider value={MaterialLibOrganizationIndicatorStore}>
      {children}
    </MaterialLibOrganizationIndicatorContext.Provider>
  )
}

export type OrganizationIndicatorStore = {
  organizationIndicator: MaterialLibIndicator
  setOrganizationIndicator: (newOrganizationIndicator: MaterialLibIndicator) => void
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  error: string | undefined
  setError: Dispatch<SetStateAction<string | undefined>>
}
