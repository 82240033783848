import { Autocomplete, Grid, TextField } from '@mui/material'
import React from 'react'
import { KairnialProject } from '../../../core/dto/kairnial/KairnialProject'
import { BaseDialog } from '../../../components/dialog/BaseDialog'
import { CopyLinkButton } from '../../../components/buttons/CopyLinkButton/CopyLinkButton'

interface IProps {
  isKairnialDialogOpen: boolean
  kairnialProjectList: KairnialProject[]
  kairnialProject: KairnialProject | undefined | null

  handleClose(): void

  handleChange(event: React.SyntheticEvent<Element, Event>, value: KairnialProject | null, reason: string): void
}

export function KairnialDialog({
  isKairnialDialogOpen,
  kairnialProjectList,
  kairnialProject,
  handleClose,
  handleChange,
}: IProps): React.JSX.Element {
  function getContent(): React.JSX.Element {
    return (
      <Grid container mt={3} gap={2}>
        <Grid item xs={12}>
          {kairnialProjectList?.length > 0 && (
            <Autocomplete
              onChange={handleChange}
              value={kairnialProject}
              renderInput={(params) => <TextField {...params} label='Selectionner un projet' />}
              options={kairnialProjectList ?? []}
              getOptionLabel={(value: KairnialProject) => value.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          )}
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          {kairnialProject && <CopyLinkButton link={kairnialProject.url} />}
        </Grid>
      </Grid>
    )
  }

  return (
    <BaseDialog
      title='Projets issus de Kairnial'
      open={isKairnialDialogOpen}
      handleClose={handleClose}
      dialogContent={getContent()}
    />
  )
}
