import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined'
import { Box, Grid, Toolbar } from '@mui/material'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { theme } from '../../../theme'
import { appConstants } from '../../core/appConstants'
import { BSProjectContext } from '../../core/context/beem-shot/BSProject/BSProjectContext'
import { AppNavBarContext } from '../../core/context/nav-bar/AppNavBarContext'
import { OrganizationContext } from '../../core/context/organization/organization-context'
import { UserContext, UserStore } from '../../core/context/user/user-context'
import { ProjectStatusEnum } from '../../core/enum/projectStatusEnum'
import { isUltimateUser } from '../../core/services/authentication-service'
import { BsProjectCardStatus } from '../card/bs-project-status-card'
import MenuNotification from '../notifications/notifications-menu'
import { SettingsMenu } from '../settings/settings-menu'
import { DefaultTypography } from '../typography/default-typography'
import './app-navbar-v2.scss'

export function DefaultNavbar(): React.JSX.Element {
  const { user } = React.useContext<UserStore>(UserContext)
  const { organization } = useContext(OrganizationContext)
  const { title, icon, typeNavBar, previousUrl, stepHeader, showProjectStatus, pagination } = useContext(AppNavBarContext)
  const { bsProject } = useContext(BSProjectContext)

  const navigate = useNavigate()

  /* Definition initial utilisateur */
  const fullName = user ? user.givenName + appConstants.utils.space + user.familyName : ''
  const ultimateUser = isUltimateUser(user)

  return (
    <Toolbar disableGutters sx={{ color: 'black', ...theme.mixins.toolbar }}>
      <Grid container columnSpacing={1} display='flex' justifyContent='space-between' alignItems='center'>
        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
          <ArrowBackOutlinedIcon onClick={() => navigate(previousUrl)} sx={{ cursor: 'pointer' }} />

          <Box display='flex' alignItems='center' sx={{ borderRadius: 2, background: '#b7ffe3', p: 1, m: 1 }}>
            <DonutSmallOutlinedIcon fontSize='medium' sx={{ color: '#0BE2A0', fontWeight: '2px' }} />
          </Box>

          <Box display='flex' flexDirection='column'>
            {pagination.map((item) => (
              <Box key={item} display='flex' alignItems='center' alignContent='center' columnGap={1}>
                <DefaultTypography
                  label='Tableau de bord'
                  color='#08CA8F'
                  fontSize='14px'
                  fontWeight={400}
                  textDecoration='underline'
                />
                <DefaultTypography label=' > ' color='#08CA8F' fontSize='14px' fontWeight={400} textDecoration='none' />
              </Box>
            ))}
            <Box display='flex' flexDirection='row' alignItems='center' columnGap={2}>
              <DefaultTypography label={title} fontSize='20px' lineHeight='20px' fontWeight={700} />
              {showProjectStatus && <BsProjectCardStatus status={bsProject?.status || ProjectStatusEnum.IN_PROGRESS} />}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={3} sx={{ display: 'flex', padding: '0% 1%', alignItems: 'center', justifyContent: 'flex-end' }}>
          {!ultimateUser && <MenuNotification />}
          <SettingsMenu />
          <Box display='flex' flexDirection='column'>
            <DefaultTypography label={fullName} color='#0BE2A0' fontWeight={700} fontSize='14px' />
            <DefaultTypography label={organization?.businessName ?? ''} />
          </Box>
        </Grid>
      </Grid>
    </Toolbar>
  )
}
