import { CodeCompletion } from './code-completion'

export class CodeCompletionUpdate {
  id: string | undefined
  variantId: string | undefined
  materialId: string | undefined = undefined
  reuse = false
  amount = 0
  hypothesis = ''

  static from(codeCompletion: CodeCompletion): CodeCompletionUpdate {
    const codeCompletionUpdate = new CodeCompletionUpdate()
    codeCompletionUpdate.id = codeCompletion.id
    codeCompletionUpdate.variantId = codeCompletion.variantId
    codeCompletionUpdate.materialId = codeCompletion.materialId
    codeCompletionUpdate.reuse = codeCompletion.reuse
    codeCompletionUpdate.amount = codeCompletion.amount
    codeCompletionUpdate.hypothesis = codeCompletion.hypothesis

    return codeCompletionUpdate
  }
}
