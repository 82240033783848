import UploadFileIcon from '@mui/icons-material/UploadFile'
import { Box, Button, Typography } from '@mui/material'
import IniesIcon from '../../../../assets/icons/inies.png'
import { customPalette } from '../../../../theme'
import { MaterialLibPage } from '../../../core/enum/materialLibPage'

type ImportIniesSectionProps = {
  handleClick: (v: 1 | 2) => void
  importFicheConfiguree: () => void
  tab: 1 | 2
  typeMaterialLib: MaterialLibPage
}

export default function ImportIniesSection({
  tab,
  handleClick,
  typeMaterialLib,
  importFicheConfiguree,
}: ImportIniesSectionProps): React.JSX.Element {
  return (
    <>
      <Box display='flex' flexDirection='row' justifyContent='center' mb='16px'>
        <Typography
          component='span'
          textAlign='center'
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'bold',
          }}>
          importer une déclaration depuis
        </Typography>
      </Box>
      <Box display='flex'>
        <Button
          component='label'
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            border: tab === 1 ? 1 : 'none',
            px: '16px',
            py: '25px',
            boxShadow: '1px 2px 5px #d6d2d2',
            backgroundColor: customPalette.default,
            textTransform: 'none',
          }}>
          <Typography component='span' fontWeight='bold'>
            La base
          </Typography>
          <img
            src={IniesIcon}
            alt={IniesIcon}
            height='30px'
            width='max-content'
            style={{
              marginLeft: 15,
              marginTop: -8,
            }}
          />
        </Button>
        {/* Temporary remove organization materialLib */}
        {/*{typeMaterialLib === MaterialLibPage.MATERIAL_LIB_PROJECT && (*/}
        {/*  <Button*/}
        {/*    sx={{*/}
        {/*      display: 'flex',*/}
        {/*      flexDirection: 'row',*/}
        {/*      alignItems: 'center',*/}
        {/*      justifyContent: 'center',*/}
        {/*      flex: 1,*/}
        {/*      border: tab === 2 ? 1 : 'none',*/}
        {/*      px: '16px',*/}
        {/*      py: '25px',*/}
        {/*      boxShadow: '1px 2px 5px #d6d2d2',*/}
        {/*      backgroundColor: customPalette.default,*/}
        {/*      ml: '12px',*/}
        {/*      textTransform: 'none',*/}
        {/*    }}*/}
        {/*    onClick={() => (tab === 2 ? undefined : handleClick(2))}>*/}
        {/*    <Typography component='span' fontWeight='bold'>*/}
        {/*      Ma matériauthèque*/}
        {/*    </Typography>*/}
        {/*    <Inventory2OutlinedIcon*/}
        {/*      fontSize='large'*/}
        {/*      sx={{*/}
        {/*        ml: 2,*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </Button>*/}
        {/*)}*/}
      </Box>
      <Box display='flex'>
        {typeMaterialLib === MaterialLibPage.MATERIAL_LIB_PROJECT && (
          <Button
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
              px: '16px',
              py: '10px',
              mt: 3,
              boxShadow: '1px 2px 5px #d6d2d2',
              backgroundColor: customPalette.default,
              textTransform: 'none',
            }}
            onClick={importFicheConfiguree}>
            <Typography component='span' fontWeight='bold'>
              Importer une fiche configurée (.xml)
            </Typography>
            <UploadFileIcon
              fontSize='large'
              sx={{
                ml: 2,
              }}
            />
          </Button>
        )}
      </Box>
    </>
  )
}
