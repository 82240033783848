import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined'
import TextureIcon from '@mui/icons-material/Texture'
import { Box, Button, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import TitleH1 from '../miscellianous/title-h1'
import { adminPagesUrl, pagesUrl } from '../../core/appConstants'
import { ProjectContext } from '../../core/context/project/project-context'
import { UserContext } from '../../core/context/user/user-context'
import { MaterialLibPage } from '../../core/enum/materialLibPage'
import { OrganizationRoleEnum } from '../../core/enum/user/organization-role-enum'
import { resolveUrl } from '../../core/services/http-service'

type MaterialPageHeaderProps = {
  typeMaterialLib: string
}

export default function MaterialPageHeader({ typeMaterialLib }: MaterialPageHeaderProps): React.JSX.Element {
  const { project } = useContext(ProjectContext)
  const { user } = useContext(UserContext)
  const { organizationId } = useParams()

  function getLinkDestination(): string {
    if (project?.id) {
      return user?.role === OrganizationRoleEnum.ULTIMATE_USER
        ? resolveUrl(adminPagesUrl.PROJECT_CONTROL_BOARD, [organizationId, project.id])
        : resolveUrl(pagesUrl.PROJECT_CONTROL_BOARD, [project.id])
    } else {
      return pagesUrl.PROJECTS_PAGE
    }
  }

  return (
    <Box display='flex' flexDirection='row' justifyContent='space-between'>
      <Box display='flex' flexDirection='row'>
        {typeMaterialLib === MaterialLibPage.MATERIAL_LIB_ORGANIZATION && (
          <Inventory2OutlinedIcon fontSize='large' sx={{ mt: 2, mr: 4 }} />
        )}
        {typeMaterialLib === MaterialLibPage.MATERIAL_LIB_PROJECT && <TextureIcon fontSize='large' sx={{ mt: 2, mr: 4 }} />}

        <Box display='flex' flexDirection='column'>
          <TitleH1
            text={
              typeMaterialLib === MaterialLibPage.MATERIAL_LIB_ORGANIZATION ? 'Ma Matériauthèque' : 'Matériauthèque projet'
            }
          />
          {typeMaterialLib === MaterialLibPage.MATERIAL_LIB_PROJECT && (
            <Typography
              sx={{
                textTransform: 'uppercase',
                fontSize: 15,
                fontWeight: 'bold',
                color: '#808080',
                mt: -3,
              }}>
              {project.name}
            </Typography>
          )}{' '}
        </Box>
      </Box>
      <Box display='flex' flexDirection='column' sx={{ mt: 2, mr: 4 }}>
        <Link to={getLinkDestination()}>
          <Button variant='outlined'>Retour</Button>
        </Link>
      </Box>
    </Box>
  )
}
