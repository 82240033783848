import React from 'react'
import { Box, Button, IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import { CodeExtrait } from '../../../core/dto/code-extrait/code-extrait'
import { CodeCompletion } from '../../../core/dto/code-completion/code-completion'
import { isCompletion, isExtrait } from '../../../core/services/code-service'

type IProps = {
  disabled: boolean
  readonly selectedCode: CodeExtrait | CodeCompletion | undefined
  readonly variantCategory?: 1 | 2
  removeMaterial(): void
  openAssignMaterialModal(): void
}

export function AffectMaterialInput({
  disabled,
  selectedCode,
  variantCategory,
  removeMaterial,
  openAssignMaterialModal,
}: Readonly<IProps>): React.JSX.Element {
  function isClearButton(): boolean {
    if (selectedCode && isExtrait(selectedCode)) {
      const selectedCodeExtrait: CodeExtrait = selectedCode as CodeExtrait
      if (variantCategory === 1) {
        return (
          !!selectedCodeExtrait &&
          (selectedCodeExtrait.materialIdVariant1 !== undefined ||
            selectedCodeExtrait.materialVariant1 !== undefined ||
            !!selectedCodeExtrait.amountVariant1 ||
            selectedCodeExtrait.reuseVariant1)
        )
      } else {
        return (
          !!selectedCodeExtrait &&
          (selectedCodeExtrait.materialIdVariant2 !== undefined ||
            selectedCodeExtrait.materialVariant2 !== undefined ||
            !!selectedCodeExtrait.amountVariant2 ||
            selectedCodeExtrait.reuseVariant2)
        )
      }
    } else if (selectedCode && isCompletion(selectedCode)) {
      const codeCompletion = selectedCode as CodeCompletion
      return (
        codeCompletion.materialId !== undefined ||
        codeCompletion.material !== undefined ||
        !!codeCompletion.number ||
        codeCompletion.reuse
      )
    } else {
      return false
    }
  }

  return (
    <Box sx={{ mb: 2, display: 'flex', justifyItems: 'center', alignItems: 'center' }}>
      <Button style={{ textTransform: 'none' }} onClick={openAssignMaterialModal} disabled={disabled}>
        <AddIcon sx={{ mr: 1 }} />
        Affecter une déclaration environnementale
      </Button>
      {isClearButton() && (
        <IconButton disabled={disabled} onClick={removeMaterial}>
          <ClearIcon />
        </IconButton>
      )}
    </Box>
  )
}
