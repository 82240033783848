import React, { forwardRef } from 'react'
import IfcDisplayerErrorBoundary from './ifc-displayer-error-boundary'
import IfcDisplayerCore, { IfcDisplayerProps } from './ifc-displayer-core'

function IfcDisplayer(props: IfcDisplayerProps): React.JSX.Element {
  const { height } = props
  return (
    <IfcDisplayerErrorBoundary height={height}>
      <IfcDisplayerCore {...props} />
    </IfcDisplayerErrorBoundary>
  )
}
export default forwardRef(IfcDisplayer)
