import CalculateIcon from '@mui/icons-material/CalculateOutlined'
import { Box, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { ProjectContext } from '../../../../core/context/project/project-context'
import { SelectedPhaseContext } from '../../../../core/context/selected-phase-context'
import { ProjectRoleContext } from '../../../../core/context/user/project-role-context'

export default function ProjectCarbonCalculatorHeader(): React.JSX.Element {
  const navigate = useNavigate()
  const { selectedPhase } = useContext(SelectedPhaseContext)
  const { project } = useContext(ProjectContext)
  const { isOrganizationUltimateUser } = useContext(ProjectRoleContext)

  function navigateTo(): void {
    isOrganizationUltimateUser
      ? navigate(`/project/admin/${project?.id}/control-board`)
      : navigate(`/project/${project?.id}/control-board`)
  }

  return (
    <Box component='a' sx={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }} onClick={navigateTo}>
      <CalculateIcon sx={{ fontSize: '40px', marginRight: '16px' }} />
      <Box>
        <Typography variant='h1' width={20} sx={{ fontSize: 50, fontWeight: 'bold' }}>
          Calculette carbone
        </Typography>
        <Typography variant='h6'>
          {project?.name} {selectedPhase ? `  (${selectedPhase})` : ''}
        </Typography>
      </Box>
    </Box>
  )
}
