import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import React, { Dispatch, FormEvent, SetStateAction, useContext, useEffect, useState } from 'react'
import SelectInput from '../../../../components/inputs/select-input/select-input'
import { ErrorContext } from '../../../../components/layout/error-snackbar'
import { SuccessContext } from '../../../../components/layout/success-snackbar'
import { ProjectContext } from '../../../../core/context/project/project-context'
import { ProjectRoleContext } from '../../../../core/context/user/project-role-context'
import { ProjectStatusEnum, projectStatusToLabel } from '../../../../core/enum/projectStatusEnum'
import { RoleEnum } from '../../../../core/enum/roleEnum'
import { useProjects } from '../../../../core/hooks/projects/use-projects'
import { enumToArray } from '../../../../core/services/helper-service'
import { Project } from '../../../../core/dto/project/project'

type Form = {
  status: ProjectStatusEnum
}

function dtoToForm(project: Project): Form {
  return {
    status: project.status ? project.status : ProjectStatusEnum.IN_PROGRESS,
  }
}

const selectOptions = enumToArray(ProjectStatusEnum)
  .filter(
    (status) =>
      status === ProjectStatusEnum.IN_PROGRESS ||
      status === ProjectStatusEnum.FINISHED ||
      status === ProjectStatusEnum.ARCHIVED
  )
  .map((enumm: ProjectStatusEnum) => ({
    value: enumm,
    label: projectStatusToLabel(enumm),
  }))

type IProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}
export default function ProjectFormDialog({ open, setOpen }: IProps): React.JSX.Element {
  const { project, setProject } = useContext(ProjectContext)
  const { hasRole } = useContext(ProjectRoleContext)
  const openSuccessSnackbar = useContext(SuccessContext)
  const openErrorSnackbar = useContext(ErrorContext)
  const { updateProjectStatus } = useProjects()

  const [projectForm, setProjectForm] = useState<Form>(dtoToForm(project))
  const [error, setError] = useState<Record<string, any>>({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    setProjectForm(dtoToForm(project))
  }, [project, project.id])

  function isValid(): boolean {
    return true
  }

  function handleChangeStatus(selectedStatus: ProjectStatusEnum): void {
    setProjectForm({
      ...projectForm,
      status: selectedStatus,
    })
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault()

    setError({})

    if (!isSubmitting && isValid() && project?.id) {
      if (projectForm.status) {
        setIsSubmitting(true)
        updateProjectStatus(project.id, projectForm.status)
          .then((updateStatus) => {
            setProject(updateStatus)
            openSuccessSnackbar('Statut du projet mis à jour')
            handleClose()
          })
          .finally(() => setIsSubmitting(false))
      }
    }
  }

  function handleClose(): void {
    setOpen(false)
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='md'>
      <DialogTitle>Mise à jour du statut</DialogTitle>
      <DialogContent>
        {hasRole([RoleEnum.ADMINISTRATOR]) && project?.id && (
          <Grid container spacing={2} component='form' id='project-form' onSubmit={handleSubmit}>
            <Grid item xs={12} mt={1}>
              <SelectInput
                id='status'
                mode='direct'
                handleChange={handleChangeStatus}
                label='Statut du projet'
                options={selectOptions}
                selectedOption={projectForm.status}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={handleClose}>
          Annuler
        </Button>
        {isSubmitting ? (
          <CircularProgress />
        ) : (
          <Button variant='contained' type='submit' form='project-form'>
            Valider
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
