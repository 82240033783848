import { FormLabel, TextField } from '@mui/material'
import React, { ChangeEvent, useMemo } from 'react'
import { CaptchaActions } from './captcha-field-utils'

const formLabel = {
  color: '#8096a7',
  pl: 1,
}

const starLabel = {
  color: '#ff4d4f',
}

type IProps = {
  fieldName: CaptchaActions
  label: string
  form: any
  error: Record<string, any>
  required: boolean
  handleChange(event: ChangeEvent<HTMLInputElement>): void
  maxFieldSize?: number
}

export default function FormField({
  fieldName,
  label,
  form,
  error,
  required,
  handleChange,
  maxFieldSize,
}: IProps): React.JSX.Element {
  const maxLength: number = useMemo(() => maxFieldSize || 250, [])

  function handleChangeSizeLimit(event: ChangeEvent<HTMLInputElement>): void {
    if (event?.target?.value?.length && event.target.value.length > maxLength) {
      //No update if field to long
    } else {
      handleChange(event)
    }
  }

  return (
    <>
      <FormLabel sx={formLabel}>
        {label}
        {required && <span style={starLabel}> *</span>}
      </FormLabel>
      <TextField
        id={fieldName}
        value={form[fieldName]}
        onChange={handleChangeSizeLimit}
        error={!!error[fieldName]}
        helperText={error[fieldName]}
        size='small'
        sx={{
          pl: 1,
          pr: 1,
          '& .MuiOutlinedInput-notchedOutline': {
            background: 'white',
          },
          '& .MuiOutlinedInput-input ': {
            zIndex: 1,
          },
        }}
      />
    </>
  )
}
