import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Children } from '../../../components/miscellianous/children'
import { pagesUrl } from '../../appConstants'
import { RseeProject } from '../../dto/rsee/rsee-project'
import { ProjectTypeEnum } from '../../enum/project/projectTypeEnum'
import { useRsee } from '../../hooks/rsee/use-rsee'
import { resolveUrl } from '../../services/http-service'

export const RseeProjectContext = React.createContext<RseeProjectHook>({} as RseeProjectHook)

export default function RseeProjectContextProvider({ children }: Children): React.JSX.Element {
  const navigate = useNavigate()
  const { rseeProjectId } = useParams()
  const { fetchRseeProject } = useRsee()
  const [rseeProject, setRseeProject] = useState<RseeProject | undefined>()
  const [isRseeProjectLoading, setIsRseeProjectLoading] = useState<boolean>(false)

  useEffect(() => {
    if (rseeProjectId && rseeProjectId !== rseeProject?.id) {
      setIsRseeProjectLoading(true)
      fetchRseeProject(rseeProjectId)
        .then((newRseeProject) => {
          setRseeProject(newRseeProject)
        })
        .catch((e) => {
          if (e?.status === 404) {
            navigate(resolveUrl(pagesUrl.PROJECTS_PAGE, [], { type: ProjectTypeEnum.RSEE }))
          } else {
            throw e
          }
        })
        .finally(() => {
          setIsRseeProjectLoading(false)
        })
    }
  }, [fetchRseeProject, rseeProjectId])

  const rseeProjectHook: RseeProjectHook = useMemo(
    () => ({ rseeProject, isRseeProjectLoading, setRseeProject }),
    [isRseeProjectLoading, rseeProject]
  )

  return <RseeProjectContext.Provider value={rseeProjectHook}>{children}</RseeProjectContext.Provider>
}

export type RseeProjectHook = {
  rseeProject?: RseeProject
  isRseeProjectLoading: boolean
  setRseeProject: Dispatch<SetStateAction<RseeProject | undefined>>
}
