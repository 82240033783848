import { Button, Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { adminPagesUrl } from '../../../core/appConstants'
import { AdminOrganizationContext } from '../../../core/context/organization/admin-organization-context'
import { Organization } from '../../../core/dto/organization/organization'
import { resolveUrl } from '../../../core/services/http-service'

type IProps = {
  organization: Organization
}
export default function OrganizationCard({ organization }: IProps): React.JSX.Element {
  const navigate = useNavigate()
  const { setOrganization } = useContext(AdminOrganizationContext)

  function adminCreateUser(): void {
    navigate(resolveUrl(adminPagesUrl.ADMIN_ORGANIZATION_INVITE_USER_PAGE, [organization.id]))
  }

  function navigateToOrganization(): void {
    if (organization?.id !== undefined) {
      setOrganization(organization)
      navigate(resolveUrl(adminPagesUrl.ADMIN_ORGANIZATION_PAGE, [organization.id]))
    }
  }

  return (
    <Card>
      <CardHeader title={organization.businessName} onClick={navigateToOrganization} sx={{ cursor: 'pointer' }} />
      <CardContent onClick={navigateToOrganization} sx={{ cursor: 'pointer' }}>
        <Typography>Nombre d'abonnements consommées: {organization.licenceCounter}</Typography>
        <Typography>
          Nombre d'abonnements maximum: {organization.maxLicenceCount < 0 ? '∞' : organization.maxLicenceCount}
        </Typography>
      </CardContent>
      <CardActions>
        {organization.maxLicenceCount === -1 || organization.licenceCounter < organization.maxLicenceCount ? (
          <Button onClick={adminCreateUser}>Créer un utilisateur</Button>
        ) : (
          <Button disabled>Nombre d'abonnements maximum atteint</Button>
        )}
      </CardActions>
    </Card>
  )
}
