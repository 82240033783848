import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { Project } from '../../../../core/dto/project/project'

type IProps = {
  project: Project
  goToPreviousPage: () => void
  isSubmitting: boolean
}

export default function ProjectPhaseStepperHeader({ project, goToPreviousPage, isSubmitting }: IProps): React.JSX.Element {
  const location = useLocation()
  const isCreation = location.pathname.endsWith('/new')

  return (
    <Box display='flex' justifyContent='space-between' flex={1} sx={{ margin: '0 84px' }}>
      <Box display='flex' flexDirection='row'>
        <AccessTimeIcon
          sx={{
            fontSize: '32px',
            marginTop: '8px',
          }}
        />
        <Box
          display='flex'
          flexDirection='column'
          sx={{
            margin: '0 16px',
          }}>
          <Typography variant='h1' sx={{ fontSize: '52px', fontWeight: 700, mb: 1 }}>
            Phases
          </Typography>
          <Typography
            sx={{
              textAlign: 'center',
              textTransform: 'uppercase',
              fontSize: 15,
              fontWeight: 'bold',
              color: '#4d4d4d',
              marginTop: '-16px',
            }}>
            {project?.name || 'Pas de projet selectionné'}
          </Typography>
        </Box>
      </Box>
      <Box>
        {isCreation ? (
          <Button variant='contained' type='submit'>
            Valider les phases
          </Button>
        ) : (
          <>
            <Button variant='outlined' onClick={goToPreviousPage}>
              Retour
            </Button>
            <Button disabled={isSubmitting} type='submit' variant='contained' sx={{ ml: 1, color: 'white' }}>
              Valider
            </Button>
          </>
        )}
      </Box>
    </Box>
  )
}
