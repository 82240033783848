import { Box, FormControlLabel, Switch, TableCell, TableRow, TextField, ToggleButton, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useMemo } from 'react'
import { ProjectPhase } from '../../../../core/dto/project/project-phase'
import { PhaseEnum, phaseList, renderPhaseEnum } from '../../../../core/enum/phaseEnum'

type ProjectPhaseStepperItemProps = {
  phaseEnum: PhaseEnum
  currentPhase: PhaseEnum | undefined
  setCurrentPhase: React.Dispatch<React.SetStateAction<PhaseEnum | undefined>>
  phasesForm: ProjectPhase[]
  setProjectPhasesForm: Dispatch<SetStateAction<ProjectPhase[]>>
  error: Record<string, string | undefined>
  errorKey: string
  cleanError(errorKey: string): void
}

export default function ProjectPhaseStepperItem({
  phaseEnum,
  currentPhase,
  setCurrentPhase,
  phasesForm,
  setProjectPhasesForm,
  error,
  errorKey,
  cleanError,
}: ProjectPhaseStepperItemProps): React.JSX.Element {
  const [projectPhase, setProjectPhase] = React.useState<ProjectPhase | undefined>(
    phasesForm.find((item) => item.phase === phaseEnum)
  )
  const isSelected = useMemo(() => projectPhase !== undefined, [projectPhase])

  useEffect(() => {
    setProjectPhase(phasesForm.find((item) => item.phase === phaseEnum))
  }, [phaseEnum, phasesForm])

  function handleChangeDescription(event: ChangeEvent<HTMLInputElement>): void {
    const target = event.target
    const value = target.value

    const newPhaseForm = [...phasesForm]
    newPhaseForm.forEach((item) => {
      if (item.phase === phaseEnum) {
        item.description = value
      }
    })
    setProjectPhasesForm(newPhaseForm)
  }

  function setNowPhase(actualPhase: PhaseEnum | undefined): void {
    if (projectPhase !== undefined) {
      setProjectPhase({ ...projectPhase, currentPhase: true })
      setCurrentPhase(actualPhase)

      const newPhaseForm = [...phasesForm]
      newPhaseForm.forEach((item) => {
        item.currentPhase = false
      })

      newPhaseForm.forEach((item) => {
        if (item.phase === actualPhase) {
          item.currentPhase = true
        }
      })
      setProjectPhasesForm(newPhaseForm)
    }
  }

  function haveAnActualPhase(): boolean {
    const index = phasesForm.findIndex((e) => e.currentPhase)
    return index > -1
  }

  function handleChangeNow(): void {
    if (!isSelected) {
      const newPhase = new ProjectPhase(phaseEnum, new Date())

      if (!haveAnActualPhase() && phasesForm.length === 0) {
        setNowPhase(phaseEnum)
        setCurrentPhase(phaseEnum)
        newPhase.currentPhase = true
      }

      phasesForm.push(newPhase)
      setProjectPhase(newPhase)
    } else {
      if (currentPhase === phaseEnum) {
        const index = phasesForm.findIndex((e) => e.phase === PhaseEnum[phaseEnum])
        if (index > -1) {
          selectedPreviousPhase()
        }
      }
      setProjectPhase(undefined)
      removePhase()
    }
  }

  function selectedPreviousPhase(): void {
    const index = phaseList.findIndex((e) => e === phaseEnum)

    let i: number
    for (i = index - 1; i >= 0; i -= 1) {
      if (searchPreviousPhase(i)) {
        return
      }
    }
    for (i = index + 1; i <= phaseList.length; i += 1) {
      if (searchPreviousPhase(i)) {
        return
      }
    }
    setCurrentPhase(undefined)
  }

  function searchPreviousPhase(index: number): boolean {
    const tmp = phasesForm.findIndex((e) => e.phase === phaseList[index])
    if (tmp > -1) {
      setCurrentPhase(phaseList[index])
      const newPhaseForm = [...phasesForm]
      newPhaseForm.forEach((item) => {
        item.currentPhase = false
      })

      newPhaseForm.forEach((item) => {
        if (item.phase === phaseList[index]) {
          item.currentPhase = true
        }
      })
      return true
    }
    return false
  }

  function removePhase(): void {
    const index = phasesForm.findIndex((e) => e.phase === PhaseEnum[phaseEnum])
    if (index > -1) phasesForm.splice(index, 1)
  }

  function changeDate(date: Date | null): void {
    if (date === null) {
      return
    }

    if (projectPhase !== undefined) {
      setProjectPhase({ ...projectPhase, endingDate: date })
    }

    phasesForm.forEach((item) => {
      if (item.phase === phaseEnum) {
        item.endingDate = date
      }
    })

    cleanError(errorKey)
  }

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component='th' scope='row'>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <FormControlLabel
            value='end'
            control={<Switch checked={isSelected} onChange={handleChangeNow} color='success' />}
            label={isSelected ? 'Activé' : 'Désactivé'}
            labelPlacement='end'
          />
        </Box>
      </TableCell>
      <TableCell>
        <ToggleButton
          id='isCurrentPhase'
          value='check'
          selected={currentPhase === phaseEnum}
          disabled={!isSelected}
          color='success'
          onChange={() => setNowPhase(phaseEnum)}>
          Actuelle
        </ToggleButton>
      </TableCell>
      <TableCell>
        <Typography
          sx={{
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}>
          {renderPhaseEnum(phaseEnum)}
        </Typography>
      </TableCell>
      <TableCell align='left'>
        <TextField
          id='description'
          placeholder='Votre description de la phase'
          disabled={!isSelected}
          onChange={handleChangeDescription}
          multiline
          rows={4}
          value={isSelected ? projectPhase?.description : 'Désactiver'}
        />
      </TableCell>
      <TableCell align='left'>
        <DatePicker
          disabled={!isSelected}
          label='Date'
          value={projectPhase?.endingDate}
          onChange={changeDate}
          renderInput={(params) => (
            <TextField
              error={!!error[errorKey]}
              helperText={error[errorKey]}
              variant='outlined'
              {...params}
              sx={{ mt: 2, mb: 2 }}
            />
          )}
        />
      </TableCell>
    </TableRow>
  )
}
