import { Dispatch, SetStateAction } from 'react'
import { CodeCompletion } from '../dto/code-completion/code-completion'

export class CalculationCarbonExtractedDataStore {
  data: any
  setData: Dispatch<SetStateAction<any>>
  codeCompletion: CodeCompletion[]
  setCodeCompletion: Dispatch<SetStateAction<CodeCompletion[]>>
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  error: string | undefined
  setError: Dispatch<SetStateAction<string | undefined>>

  constructor(
    data: any,
    setData: Dispatch<SetStateAction<any>>,
    codeCompletion: CodeCompletion[],
    setCodeCompletion: Dispatch<SetStateAction<CodeCompletion[]>>,
    loading: boolean,
    setLoading: Dispatch<SetStateAction<boolean>>,
    error: string | undefined,
    setError: Dispatch<SetStateAction<string | undefined>>
  ) {
    this.data = data
    this.setData = setData
    this.codeCompletion = codeCompletion
    this.setCodeCompletion = setCodeCompletion
    this.loading = loading
    this.setLoading = setLoading
    this.error = error
    this.setError = setError
  }
}
