import { Vector3 } from 'three'
import { viewerConstants } from '../constants/ViewerConstants'

/**
 * Class used to store the state of a point in 3D space.
 */
export class IfcPoint {
  x: number
  y: number
  z: number
  id: string

  constructor(x: number, y: number, z: number) {
    this.x = x
    this.y = y
    this.z = z
    this.id = this.toString()
  }

  /**
   * checks if two points are the same
   */
  static isSamePoint(point1: IfcPoint, point2: IfcPoint, tolerance: number = viewerConstants.mathTolerance): boolean {
    const vector1 = new Vector3(point1.x, point1.y, point1.z)
    const vector2 = new Vector3(point2.x, point2.y, point2.z)
    return this.isSameVector(vector1, vector2, tolerance)
  }

  static isSameVector = (point1: Vector3, point2: Vector3, tolerance: number = viewerConstants.mathTolerance): boolean =>
    point1.distanceTo(point2) < tolerance

  /**
   * converts the coordinates to a readable string
   */
  toString(): string {
    return `(${this.x}, ${this.y}, ${this.z})`
  }

  toVector3(): Vector3 {
    return new Vector3(this.x, this.y, this.z)
  }

  magnitude() {
    return Math.sqrt(this.x * this.x + this.y * this.y + this.z * this.z)
  }

  normalize() {
    const mag = this.magnitude()
    return new Vector3(this.x / mag, this.y / mag, this.z / mag)
  }

  dot(v: Vector3) {
    return this.x * v.x + this.y * v.y + this.z * v.z
  }

  cross(v: Vector3) {
    return new Vector3(this.y * v.z - this.z * v.y, this.z * v.x - this.x * v.z, this.x * v.y - this.y * v.x)
  }

  normal() {
    const mag = this.magnitude()
    return new Vector3(this.x / mag, this.y / mag, this.z / mag)
  }

  angleBetween(v: IfcPoint) {
    const mag1 = this.magnitude()
    const mag2 = v.magnitude()
    const dot = this.dot(v.toVector3())
    const angle = Math.acos(dot / (mag1 * mag2))
    return angle
  }
}
