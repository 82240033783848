import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React from 'react'

type IProps = {
  title?: string
  handleClose(): void
  open: boolean
  dialogContent: React.JSX.Element
}

export function BaseDialog({ title = '', handleClose, open, dialogContent }: IProps): React.JSX.Element {
  return (
    <Dialog fullWidth maxWidth='md' open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{dialogContent}</DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={handleClose}>
          Retour
        </Button>
      </DialogActions>
    </Dialog>
  )
}
