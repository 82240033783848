import { Box, Tab, Tabs } from '@mui/material'
import React from 'react'
import { DefaultTypography } from '../../../components/typography/default-typography'

export interface TabPanelProps {
  id: number
  Icon: React.ElementType
  label: string
  chips: string | undefined
  content: JSX.Element
}

interface ChildComponentProps {
  panels: TabPanelProps[]
  handleChange(event: React.SyntheticEvent, newValue: number): void
  tabs: number
}

function a11yProps(index: any): { id: string; 'aria-controls': string } {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export function CustomTabPanel({ panels, tabs, handleChange }: Readonly<ChildComponentProps>): React.JSX.Element {
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabs} onChange={handleChange}>
          {panels.map((x, index) => (
            <Tab
              label={
                <Box display='flex' flexDirection='row' alignItems='center'>
                  {x.Icon && <x.Icon sx={{ color: '#40548D' }} />}
                  <DefaultTypography label={x.label} color='#40548D' fontSize='16px' fontWeight={500} />

                  {x.chips && (
                    <Box sx={{ background: '#CEDEEF', px: 1, borderRadius: 4 }}>
                      <DefaultTypography label={x.chips} color='#40548D' />
                    </Box>
                  )}
                </Box>
              }
              {...a11yProps(index)}
              key={x.id}
            />
          ))}
        </Tabs>
      </Box>
      {panels.map((x) => Number(x.id) === tabs + 1 && <> {x.content}</>)}
    </>
  )
}
