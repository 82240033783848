import { Box, Toolbar } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'
import { MenuItemType } from '../../core/type/MenuItemType'
import { AppNavbar } from '../app-navbar/app-navbar'

type IProps = {
  mainMenu: MenuItemType[]
}

export default function Layout({ mainMenu }: IProps): React.JSX.Element {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <AppNavbar mainMenu={mainMenu} />

      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
          <Toolbar />
          <Outlet />
        </Box>
      </Box>
    </Box>
  )
}
