import { Dispatch, SetStateAction } from 'react'
import MaterialRecord from '../dto/material/material-record'

export class MaterialDtoStore {
  data: MaterialRecord[] | undefined
  setData: Dispatch<SetStateAction<MaterialRecord[] | undefined>>
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  error: string | undefined
  setError: Dispatch<SetStateAction<string | undefined>>

  constructor(
    data: MaterialRecord[] | undefined,
    setData: Dispatch<SetStateAction<MaterialRecord[] | undefined>>,
    loading: boolean,
    setLoading: Dispatch<SetStateAction<boolean>>,
    error: string | undefined,
    setError: Dispatch<SetStateAction<string | undefined>>
  ) {
    this.data = data
    this.setData = setData
    this.loading = loading
    this.setLoading = setLoading
    this.error = error
    this.setError = setError
  }
}
