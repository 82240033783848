import { UnitEnum } from '../enum/unitEnum'
import { FunctionalUnitEnum } from '../enum/functionalUnitEnum'

export type UnitLabel = {
  unit: UnitEnum
  label: string
}

export const unitLabelList: UnitLabel[] = [
  { unit: UnitEnum.METER, label: 'm' },
  { unit: UnitEnum.SQUARE_METER, label: 'm²' },
  { unit: UnitEnum.CUBIC_METER, label: 'm3' },
  { unit: UnitEnum.KG, label: 'kg' },
  { unit: UnitEnum.PER_UNIT, label: "Nombre d'unités" },
  { unit: UnitEnum.UNKNOWN, label: 'Unité inconnue' },
]

export function UnitService() {
  return {
    unitEnumToLabel(unitEnum: UnitEnum): string {
      const index = unitLabelList.findIndex((unitLabel) => unitLabel.unit === unitEnum)
      if (index === -1) {
        return 'Unité inconnue'
      } else {
        return unitLabelList[index].label
      }
    },
    labelToUnitEnum(label: string): UnitEnum {
      const index = unitLabelList.findIndex((unitLabel) => unitLabel.label === label)
      if (index === -1) {
        return UnitEnum.UNKNOWN
      } else {
        return unitLabelList[index].unit
      }
    },
  }
}

export function getLabelForIniesUnit(unit: keyof typeof FunctionalUnitEnum | string | undefined): string {
  const key = unit ?? FunctionalUnitEnum.NO_UNIT
  if (Object.keys(FunctionalUnitEnum).includes(key)) {
    return FunctionalUnitEnum[key as keyof typeof FunctionalUnitEnum]
  } else {
    return key
  }
}
