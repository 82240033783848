import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Children } from '../../../components/miscellianous/children'
import { Calculation } from '../../dto/calculation/calculation'
import { useCalculation } from '../../hooks/use-calculation'
import { BimModelFileContext } from '../bim-model/bim-model-file-context'
import { ProjectContext } from '../project/project-context'
import { SelectedPhaseContext } from '../selected-phase-context'

export const CalculationContext = React.createContext<CalculationStore>({} as CalculationStore)

export default function CalculationContextProvider({ children }: Children): React.JSX.Element {
  const { project } = useContext(ProjectContext)
  const { file } = useContext(BimModelFileContext)
  const { selectedPhase } = useContext(SelectedPhaseContext)
  const { fetchCalculation } = useCalculation()
  const [calculation, setCalculation] = useState<Calculation>(new Calculation())
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (project?.id && selectedPhase !== undefined) {
      setIsLoading(true)
      fetchCalculation(project.id, selectedPhase)
        .then((response) => {
          if (response.status !== 404) {
            response.json().then((body: Calculation) => {
              setCalculation(body)
            })
          } else {
            setCalculation(new Calculation())
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [fetchCalculation, project.id, project.phases, selectedPhase, file])

  const refreshCalculation = useCallback(() => {
    if (project?.id && selectedPhase) {
      setIsLoading(true)
      return fetchCalculation(project.id, selectedPhase)
        .then((response) => {
          response.json().then((updateInformations: Calculation) => {
            setCalculation(updateInformations)
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      return Promise.resolve(undefined)
    }
  }, [project?.id, selectedPhase, fetchCalculation])

  const calculationStore: CalculationStore = useMemo(
    () => ({
      calculation,
      isLoading,
      setCalculation,
      refreshCalculation,
    }),
    [calculation, isLoading, refreshCalculation]
  )

  return <CalculationContext.Provider value={calculationStore}>{children}</CalculationContext.Provider>
}

export type CalculationStore = {
  calculation: Calculation
  isLoading: boolean
  setCalculation: Dispatch<SetStateAction<Calculation>>
  refreshCalculation(): Promise<void>
}
