import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { CheckboxInput } from '../../../../../../components/inputs/checkbox-input/CheckboxInput'
import { BsModelImportContext } from '../../../../../../core/context/beem-shot/BSBimModel/BSBimModelImportContext'
import { BSItemContext, formToDto } from '../../../../../../core/context/beem-shot/BSItems/BSItemContext'
import { BSProjectContext } from '../../../../../../core/context/beem-shot/BSProject/BSProjectContext'
import { BSVariantContext } from '../../../../../../core/context/beem-shot/BSVariant/BSVariantContext'
import { BSItem } from '../../../../../../core/dto/beem-shot/BSItem/BSItem'
import { BSItemSubCategory } from '../../../../../../core/dto/beem-shot/BSItem/BSItemSubCategory'
import { useBSItem } from '../../../../../../core/hooks/beem-shot/useBSItem'
import { stringToNumber } from '../../../../../../core/services/helper-service'
import { BSItemAccordion } from './BSItemAccordion'
import { useAccordion } from './useAccordion'

interface IProps {
  subCategory: BSItemSubCategory
  expandedSubCategory: string
  handleExpandedSubCategory(toExpand?: string): void
}

export function SubCategoryAccordion({
  subCategory,
  expandedSubCategory,
  handleExpandedSubCategory,
}: Readonly<IProps>): React.JSX.Element {
  const { bsProject } = useContext(BSProjectContext)
  const { selectedVariant } = useContext(BSVariantContext)
  const { viewer } = useContext(BsModelImportContext)
  const {
    selectedCodeExtrait,
    setSelectedCodeExtrait,
    selectedBSItem,
    bsItemsForSubCategory,
    setBsItemsForSubCategory,
    updatBSItemFunction,
  } = useContext(BSItemContext)

  const { getBSItemsForSubCategory } = useBSItem()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { expanded: expandedBSItemId, expand, handleExpanded } = useAccordion()

  useEffect(
    () => {
      if (selectedCodeExtrait && selectedBSItem) {
        expand(selectedBSItem.id)
      }
    },
    // handleExpanded
    [selectedBSItem, selectedCodeExtrait]
  )

  useEffect(() => {
    if (selectedVariant?.id && expandedSubCategory === subCategory.name) {
      setIsLoading(true)
      getBSItemsForSubCategory(selectedVariant.id, subCategory)
        .then((response) => {
          setBsItemsForSubCategory(response)
        })
        .finally(() => setIsLoading(false))
    }
  }, [bsProject, expandedSubCategory, getBSItemsForSubCategory, selectedVariant?.id, setBsItemsForSubCategory, subCategory])

  function handleChangeCheckbox(bsItem: BSItem): void {
    updatBSItemFunction(
      formToDto(bsItem.id, {
        id: bsItem.id,
        projectId: bsItem.projectId,
        calculType: bsItem.calculType,
        codeExtrait: bsItem.codeExtrait,
        bsMacroComponentIds: bsItem.bsMacroComponents.reduce(
          (acc: { [x: string]: any }, x: { type: { name: string | number }; id: any }) => {
            acc[x.type.name] = x.id
            return acc
          },
          {} as Record<string, string>
        ),
        ignored: !bsItem.ignored,
      })
    )
  }

  function sortBsItem(a: BSItem, b: BSItem): number {
    if (a.type.label < b.type.label) {
      return -1
    } else if (a.type.label > b.type.label) {
      return 1
    } else {
      return stringToNumber(a.codeExtrait?.occurence) - stringToNumber(b.codeExtrait?.occurence)
    }
  }

  return (
    <Accordion
      id={subCategory.name}
      expanded={expandedSubCategory === subCategory.name}
      onChange={(e, toOpen) => {
        if (selectedBSItem && !toOpen) {
          handleExpandedSubCategory('')
          viewer?.manager.subsetsManager.restoreAllSubsetsMaterial()
          setSelectedCodeExtrait(undefined)
        } else {
          handleExpandedSubCategory(subCategory.name)
        }
      }}
      elevation={0}
      square
      disableGutters
      TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>{subCategory.label}</AccordionSummary>
      <AccordionDetails>
        {isLoading ? (
          <Box width='100%' display='flex' justifyContent='center'>
            <CircularProgress />
          </Box>
        ) : (
          bsItemsForSubCategory?.sort(sortBsItem).map((bsItem) => (
            <Box id={`${bsItem?.codeExtrait?.code}${bsItem?.codeExtrait?.occurence}`} key={bsItem.id} display='flex'>
              <CheckboxInput
                id={subCategory.name}
                checked={!bsItem.ignored}
                handleChange={() => handleChangeCheckbox(bsItem)}
                label=''
              />
              <BSItemAccordion
                key={bsItem.id}
                bsItem={bsItem}
                expandedBSItem={expandedBSItemId}
                handleExpandedBsItem={handleExpanded}
              />
            </Box>
          ))
        )}
      </AccordionDetails>
    </Accordion>
  )
}
