import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import Box from '@mui/material/Box'
import { Button, FormHelperText, TextField } from '@mui/material'
import { Auth } from 'aws-amplify'
import AccountHeadingForm from './account-heading-form'

import { ErrorContext } from '../../components/layout/error-snackbar'
import { SuccessContext } from '../../components/layout/success-snackbar'
import { isPasswordValid } from '../../core/services/user-service'

export default function AccountPasswordFormField(): React.JSX.Element {
  const openErrorSnackbar: (err: Error, message?: string) => void = React.useContext(ErrorContext)
  const openSuccessSnackbar: (message: string) => void = React.useContext(SuccessContext)

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm()
  const password = useRef({})
  password.current = watch('newPassword', '')

  const [isSubmiting, setIsSubmiting] = useState<boolean>(false)

  const onSubmit = (data: any): void => {
    setIsSubmiting(true)

    Auth.currentAuthenticatedUser()
      .then((user) => Auth.changePassword(user, data.oldPassword, data.newPassword))
      .then(() => {
        openSuccessSnackbar('Nouveau mot de passe enregistré !')
        reset()
      })
      .catch((err) => {
        console.error(err)

        let message = err.message
        switch (err.message) {
          case 'Incorrect username or password.':
            message = 'Mot de passe incorrecte.'
            break
          default:
            break
        }
        openErrorSnackbar(err, message)
      })
      .finally(() => {
        setIsSubmiting(false)
      })
  }

  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)} display='flex' flexDirection='column' sx={{ flex: 1, mr: 5 }}>
      <AccountHeadingForm title='MOT DE PASSE' />
      <TextField
        {...register('oldPassword', {
          required: 'Veuillez insérez un mot de passe',
        })}
        sx={{ mt: 2, mb: 2 }}
        label='mot de passe actuel'
        variant='outlined'
        type='password'
        error={Boolean(errors?.oldPassword)}
        helperText={Boolean(errors?.oldPassword?.message) && `${errors.oldPassword?.message}`}
      />
      <FormHelperText component='span' id='password-helper'>
        Le mot de passe doit respecter les règles suivantes:
        <ul>
          <li>Longueur minimum: 8 caractères</li>
          <li>Contient au minimum 1 chiffre</li>
          <li>
            Contient au minimum un caractère spécial (^ $ * . [ ] {} ( ) ? - " ! @ # % & / \ , &lt; &gt; ' : ; | _ ~ ` + =)
          </li>
          <li>Une lettre minuscule</li>
          <li>Une lettre majuscule</li>
        </ul>
      </FormHelperText>
      <TextField
        {...register('newPassword', {
          required: 'Veuillez insérez un nouveau mot de passe',
          minLength: {
            value: 7,
            message: 'Le mot de passe doit avoir au moins 7 caractères.',
          },
          validate: (value: any) =>
            isPasswordValid(value) ||
            'Le mot de passe doit contenir au moins une lettre minuscule, une lettre majuscule et un caractère spécial',
        })}
        sx={{ mt: 2, mb: 2 }}
        label='nouveau mot de passe'
        variant='outlined'
        type='password'
        error={Boolean(errors?.newPassword)}
        helperText={Boolean(errors?.newPassword?.message) && `${errors.newPassword?.message}`}
      />
      <TextField
        {...register('confirmationNewPassword', {
          validate: (value: any) => value === password.current || 'Les mot de passes ne correspondent pas.',
        })}
        error={Boolean(errors?.confirmationNewPassword)}
        helperText={Boolean(errors?.confirmationNewPassword?.message) && `${errors.confirmationNewPassword?.message}`}
        sx={{ mt: 2 }}
        label='confirmer votre nouveau mot de passe'
        variant='outlined'
        type='password'
      />

      <Button
        disabled={isSubmiting}
        sx={{ mt: 5, height: 50, alignSelf: 'center', width: 300 }}
        variant='contained'
        color='success'
        type='submit'>
        {isSubmiting ? 'Enregistrement...' : 'Enregistrer'}
      </Button>
    </Box>
  )
}
