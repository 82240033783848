import CodeCustom from './code-custom'

export class CodeCustomUpdate {
  id = ''
  projectId = ''
  lot = ''
  sousLot = ''
  description = ''
  name = ''
  parentCode = ''

  static from(codeCompletion: CodeCustom): CodeCustomUpdate {
    const codeAcvCustomUpdate = new CodeCustomUpdate()
    codeAcvCustomUpdate.id = codeCompletion.id
    codeAcvCustomUpdate.name = codeCompletion.name
    codeAcvCustomUpdate.projectId = codeCompletion.projectId
    codeAcvCustomUpdate.lot = codeCompletion.lot
    codeAcvCustomUpdate.sousLot = codeCompletion.sousLot
    codeAcvCustomUpdate.description = codeCompletion.description
    codeAcvCustomUpdate.parentCode = codeCompletion.parentCode

    return codeAcvCustomUpdate
  }
}
