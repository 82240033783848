import React, { ErrorInfo, ReactNode } from 'react'
import EmptyDisplayer from './empty-displayer'

type ErrorBoundaryState = {
  hasError: boolean
}

type IProps = {
  children: ReactNode
  height: string
}

export default class IfcDisplayerErrorBoundary extends React.Component<IProps, ErrorBoundaryState> {
  constructor(props: IProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: any): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    console.error('getDerivedStateFromError', error)
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can also log the error to an error reporting service
    console.error('componentDidCatch', error)
    console.error('errorInfo ', errorInfo)
    console.trace()
  }

  render(): ReactNode {
    const { children, height } = this.props
    const { hasError } = this.state
    try {
      return hasError ? <EmptyDisplayer height={height} /> : children
    } catch (error) {
      console.error('Error in IfcDisplayerErrorBoundary render', error)
      return <h1>Error catching error.</h1>
    }
  }
}
