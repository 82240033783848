import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { Dayjs } from 'dayjs'
import React, { useContext, useEffect, useState } from 'react'
import { ErrorContext } from '../../../components/layout/error-snackbar'
import TestUserDataTable from '../../../components/tables/display-user/test-user-data-table'
import { TestUser } from '../../../core/dto/user/test-users'
import { useUser } from '../../../core/hooks/use-user'
import { compareIgnoreCase } from '../../../core/services/helper-service'

export default function TestUsersList(): React.JSX.Element {
  const [testUserList, setTestUserList] = useState<TestUser[]>([])
  const [organizationList, setOrganizationList] = useState<string[]>([])
  const { adminGetTestUsers, changePropectOption } = useUser()
  const [page, setPage] = React.useState(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const openErrorSnackbar = useContext(ErrorContext)

  useEffect(() => {
    setIsLoading(true)
    adminGetTestUsers()
      .then((newTestUserList: TestUser[]) => {
        setTestUserList(newTestUserList)
        setOrganizationList(
          Array.from(new Set(newTestUserList.map((test) => test.organizationName).sort((a, b) => compareIgnoreCase(a, b))))
        )
      })
      .finally(() => setIsLoading(false))
  }, [])

  const [startDay, setStartDay] = React.useState<Dayjs | null>(null)
  const [endDay, setEndDay] = React.useState<Dayjs | null>(null)
  const [organizationFilter, setOrganizationFilter] = React.useState<string[]>([])
  const [searchString, setSearchString] = React.useState<string>()

  const handleChangePage = (_event: unknown, newPage: number): void => {
    setPage(newPage)
  }

  function changeProspectOptionUser(userId: string): void {
    setIsLoading(true)

    changePropectOption(userId).then(() => applyFilter())
  }

  function applyFilter(): void {
    setIsLoading(true)

    if (startDay && endDay) {
      openErrorSnackbar(
        new Error('La date de début doit être antérieure à la date de fin. Veuillez ajuster vos dates et réessayer.')
      )
      return
    }

    adminGetTestUsers(startDay?.toISOString(), endDay?.toISOString(), searchString, organizationFilter)
      .then((newTestUserList: TestUser[]) => {
        setTestUserList(newTestUserList)
      })
      .finally(() => setIsLoading(false))
  }

  function resetFilter(): void {
    setStartDay(null)
    setEndDay(null)
    setOrganizationFilter([])
    setSearchString('')

    setIsLoading(true)
    adminGetTestUsers()
      .then((newTestUserList: TestUser[]) => {
        setTestUserList(newTestUserList)
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
      <Box sx={{ width: '1400px' }}>
        <Typography variant='h6' sx={{ pt: '-200px', mb: 3 }}>
          Testeurs
        </Typography>
      </Box>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1-content' id='panel1-header'>
          <FilterAltOutlinedIcon />
          FILTRES
        </AccordionSummary>
        <AccordionDetails>
          <Grid container item sm={6} columnGap={3} rowGap={3} display='flex' direction='column'>
            <TextField
              label='Rechercher'
              value={searchString}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchString(e.target.value)
              }}
              sx={{ mt: 2 }}
            />
            <Autocomplete
              multiple
              disablePortal
              disableCloseOnSelect
              options={organizationList}
              noOptionsText="Pas d'éléments proposés..."
              value={organizationFilter}
              selectOnFocus
              onChange={(e: React.SyntheticEvent<Element, Event>, value: string[]) => setOrganizationFilter(value)}
              getOptionLabel={(e: string) => e}
              isOptionEqualToValue={(option, values) => option === values}
              renderOption={(props, option, { selected }) => <li {...props}>{option}</li>}
              renderInput={(params) => <TextField {...params} label='Filtrer par organisation' />}
              sx={{ mt: 2 }}
            />
            <Grid container item sm={12} columnGap={1} display='flex' direction='row' alignItems='center'>
              <Grid item xs={2} sm={2}>
                A été créé entre le
              </Grid>
              <Grid item xs={3} sm={3}>
                <DatePicker
                  label='Date de début'
                  disableFuture
                  onChange={(e) => setStartDay(e)}
                  value={startDay}
                  renderInput={(params) => <TextField variant='outlined' {...params} sx={{ mt: 2, mb: 2, width: '100%' }} />}
                />
              </Grid>
            </Grid>
            <Grid container item sm={12} columnGap={1} display='flex' direction='row' alignItems='center'>
              <Grid item xs={2} sm={2} sx={{ width: '100%' }}>
                et le
              </Grid>
              <Grid item xs={3} sm={3}>
                <DatePicker
                  label='Date de fin'
                  disableFuture
                  onChange={setEndDay}
                  value={endDay}
                  renderInput={(params) => <TextField variant='outlined' {...params} sx={{ mt: 2, mb: 2, width: '100%' }} />}
                />
              </Grid>
            </Grid>
            <Box display='flex' justifyContent='center' sx={{ m: 1 }}>
              <Button onClick={resetFilter}>Réinitialiser</Button>
              <Button variant='contained' onClick={applyFilter}>
                Appliquer
              </Button>
            </Box>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 3,
        }}>
        <TestUserDataTable
          page={page}
          allList={testUserList}
          handleChangePage={handleChangePage}
          isLoading={isLoading}
          changePropectOption={changeProspectOptionUser}
        />
      </Box>
    </Box>
  )
}
