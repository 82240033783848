import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Children } from '../../../components/miscellianous/children'
import { getModulTypeEnum, ModuleTypeEnum } from '../../../core/enum/bimModel/ModuleTypeEnum'
import {
  AUTH_TOKEN_PARAMETER_NAME,
  DASHBOARD_TYPE_PARAMETER_NAME,
  DataTable,
  DataValue,
  tableauExt,
  tableauExtensionsService,
  TableauParameter,
  tExtensions,
  WorkSheet,
  WORKSHEET_FOR_BIM_MODEL_INTERACTION,
} from '../../../core/services/tableau-extensions-service'
import { CodeExtraitDisplay } from '../../../core/dto/code-extrait/CodeExtraitDisplay'
import { codeToKey } from '../../../core/services/code-service'

export const DashboardExtensionContext = React.createContext<DashboardExtensionStore>({} as DashboardExtensionStore)

export function DashboardExtensionContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const [bimModelToken, setBimModelToken] = useState<string | undefined>(undefined)
  const [dashboardType, setDashboardType] = useState<ModuleTypeEnum>(ModuleTypeEnum.BEEM_MASTER)
  const [selectedData, setSelectedData] = useState<string[]>([])
  const filteringFromModelRef = useRef<boolean>(false)

  const filterChangedHandler = useCallback((): void => {
    if (!filteringFromModelRef.current) {
      const worksheet: WorkSheet | undefined = getWorksheetByName(WORKSHEET_FOR_BIM_MODEL_INTERACTION)
      if (worksheet) {
        // Get the data
        worksheet.getSummaryDataAsync().then((dataTable: DataTable) => {
          const data: string[] = []
          dataTable.data.forEach((dataValueArray: DataValue[]) => {
            if (Array.isArray(dataValueArray) && dataValueArray.length !== 0) {
              const dataValue = dataValueArray[0]
              if (dataValue.nativeValue) {
                data.push(dataValue.nativeValue)
              }
            } else {
              console.error("Attention, la valeur n'est pas bien récupérée")
              console.error('dataValueArray', dataValueArray)
            }
          })
          setSelectedData(data)
        })
      }
    }
  }, [])

  useEffect(() => {
    console.info('window.tableau', tExtensions)
    if (tExtensions) {
      tExtensions.initializeAsync().then(() => {
        tableauExtensionsService.fetchParameters(tExtensions).then((parameters: TableauParameter[]) => {
          parameters.forEach((parameter: TableauParameter) => {
            console.info('parameter:', parameter)
            if (parameter.name === AUTH_TOKEN_PARAMETER_NAME && parameter?.currentValue?.value) {
              console.info('setBimModelToken:')
              setBimModelToken(parameter.currentValue.value)
            }
            if (parameter.name === DASHBOARD_TYPE_PARAMETER_NAME) {
              setDashboardType(getModulTypeEnum(parameter?.currentValue?.value))
            }
          })
        })

        const codeOccurrenceWorksheet = tableauExtensionsService.getWorksheetByName(WORKSHEET_FOR_BIM_MODEL_INTERACTION)
        if (codeOccurrenceWorksheet) {
          codeOccurrenceWorksheet.addEventListener(tableauExt.TableauEventType.FilterChanged, filterChangedHandler)
        }
      })
    }
  }, [filterChangedHandler])

  function getWorksheetByName(sheetName: string): WorkSheet | undefined {
    const dashboard = tExtensions.dashboardContent.dashboard
    return dashboard.worksheets.find((sheet: WorkSheet) => sheet.name === sheetName)
  }

  /**
   * Marche presque. il faut utiliser selectMarksByValueAsync() au lieu de "applyFilterAsync('codeOccurrence', filteredValues, tableauExt.FilterUpdateType.Replace)"
   * Il faut aussi régler quelques petits détails. Revenir implémenter ça quand on aura le temps. Ne pas supprimer cette fonction
   */
  const setSelectedCodeExtrait1 = useCallback((code: CodeExtraitDisplay): void => {
    console.info('select code', code)
    const filteredValues = [codeToKey(code)]
    console.info('filteredValues', filteredValues)

    filteringFromModelRef.current = true
    tableauExtensionsService
      .clearAllActionFilters()
      .then(() => getWorksheetByName(WORKSHEET_FOR_BIM_MODEL_INTERACTION))
      .then((worksheet) => {
        if (worksheet) {
          console.info('tableauExt.FilterUpdateType', tableauExt.FilterUpdateType)
          console.info('applyFilterAsync', 'codeOccurrence', filteredValues, tableauExt.FilterUpdateType.Replace)
          return worksheet
            .applyFilterAsync('codeOccurrence', filteredValues, tableauExt.FilterUpdateType.Replace)
            .then(() => undefined)
        } else {
          return Promise.resolve()
        }
      })
      .finally(() => {
        filteringFromModelRef.current = false
      })
  }, [])

  const dummySelectCode = useCallback((code: CodeExtraitDisplay): void => {
    console.info('select code on model')
  }, [])

  const bimModelAuthTokenStore = useMemo(
    () => ({
      bimModelToken,
      dashboardType,
      selectedData,
      setSelectedCodeExtrait: dummySelectCode,
    }),
    [bimModelToken, dashboardType, selectedData, dummySelectCode]
  )
  return <DashboardExtensionContext.Provider value={bimModelAuthTokenStore}>{children}</DashboardExtensionContext.Provider>
}

export type DashboardExtensionStore = {
  bimModelToken: string | undefined
  dashboardType: ModuleTypeEnum
  selectedData: string[]
  setSelectedCodeExtrait(code: CodeExtraitDisplay): void
}
