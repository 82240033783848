import React, { ChangeEvent } from 'react'
import { TextField } from '@mui/material'
import { TextFieldVariants } from '@mui/material/TextField/TextField'
import { formatPhone } from '../../../core/services/user-service'

type IProps = {
  form: any
  error: Record<string, any>
  handleChange(event: ChangeEvent<HTMLInputElement>): void
  required: boolean
  disabled?: boolean
  variant?: TextFieldVariants
  fullWidth?: boolean
}

export default function PhoneInput({
  form,
  error,
  handleChange,
  required,
  disabled = false,
  variant = 'standard',
  fullWidth = false,
}: IProps): React.JSX.Element {
  function handleChangePhone(event: ChangeEvent<HTMLInputElement>): void {
    const value: string = event.target.value

    event.target.value = formatPhone(value)
    handleChange(event)
  }

  return (
    <TextField
      id='phone'
      variant={variant}
      label={required ? 'Téléphone *' : 'Téléphone'}
      value={form.phone}
      onChange={handleChangePhone}
      error={!!error.phone}
      helperText={error.phone}
      disabled={disabled}
      fullWidth={fullWidth}
    />
  )
}
