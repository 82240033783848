import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { adminPagesUrl, pagesUrl } from '../../../core/appConstants'
import { AdminOrganizationContext } from '../../../core/context/organization/admin-organization-context'
import { OrganizationsListContext } from '../../../core/context/organization/organization-list-context'
import { Organization } from '../../../core/dto/organization/organization'
import OrganizationCard from './organization-card'

function organizationCardList(organizationsList: Organization[]): React.JSX.Element[] {
  return organizationsList.map((organization: Organization) => (
    <Grid item xs={12} sm={6} md={4} lg={3} key={organization.id}>
      <OrganizationCard organization={organization} />
    </Grid>
  ))
}

export default function OrganizationList(): React.JSX.Element {
  const { organizationsList } = useContext(OrganizationsListContext)
  const { setOrganization } = useContext(AdminOrganizationContext)
  const navigate = useNavigate()

  useEffect(() => {
    setOrganization(undefined)
  }, [setOrganization])

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 3 }}>
        <Typography variant='h6'>Gestion des Organisations</Typography>
        <Button
          variant='contained'
          onClick={() => navigate(adminPagesUrl.ADMIN_ORGANIZATION_NEW_PAGE, { state: { organization: undefined } })}>
          Créer Organisation
        </Button>
      </Box>
      <Grid container spacing={2}>
        {organizationsList !== undefined && organizationsList.length !== 0 ? (
          organizationCardList(organizationsList)
        ) : (
          <Typography>Il n'y a pas encore d'organisation</Typography>
        )}
      </Grid>
    </Box>
  )
}
