import React, { ChangeEvent, useMemo } from 'react'
import { TextField } from '@mui/material'
import { TextFieldVariants } from '@mui/material/TextField/TextField'
import { appConstants } from '../../../core/appConstants'

const acceptedValueRegex = /^(Illimité|i|I|-[1]?|\d{0,12})$/

type IProps = {
  id: string
  label: string
  value: string
  error: Record<string, any>
  onChange(fieldId: string, newNumber: string): void
  fullWidth: boolean
  variant?: TextFieldVariants
}

/**
 * This component is used to handle "-1 = infinity" for licenses numbers.
 * Like the maximum BIM projets, the maximum users etc...
 *
 * @constructor
 */
export default function NumberLicenseInput({
  id,
  label,
  value,
  error,
  onChange,
  fullWidth,
  variant = 'standard',
}: IProps): React.JSX.Element {
  const currentValue = useMemo(() => formatValue(value), [value])

  function handleChangeNumber(event: ChangeEvent<HTMLInputElement>): void {
    const newValue: string = event.target.value

    if (acceptedValueRegex.test(newValue)) {
      onChange(id, newValue)
    }
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (event.key === 'Backspace') {
      if (currentValue === appConstants.miscellaneous.LABEL_UNLIMITED) {
        onChange(id, '') // Clear the input value
      }
    }
  }

  function formatValue(newValue: string): string {
    let result = ''
    if (newValue === '-1') {
      result = appConstants.miscellaneous.LABEL_UNLIMITED
    } else if (newValue === 'i' || newValue === 'I' || newValue === appConstants.miscellaneous.LABEL_UNLIMITED) {
      result = appConstants.miscellaneous.LABEL_UNLIMITED
    } else if (newValue.length > 1) {
      result = newValue
      while (result.startsWith('0') && result !== '0') {
        result = result.substring(1, result.length)
      }
    } else {
      result = newValue
    }

    return result
  }

  return (
    <TextField
      id={id}
      value={currentValue}
      label={label}
      onChange={handleChangeNumber}
      variant={variant}
      fullWidth={fullWidth}
      error={!!error[id]}
      helperText={error[id]}
      onKeyDown={handleKeyDown}
    />
  )
}
