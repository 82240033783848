import { Box, Divider, Typography } from '@mui/material'
import React from 'react'

type IProps = {
  label: string
}

export default function DividerSimplifiedQuestionnaire({ label }: Readonly<IProps>): React.JSX.Element {
  return (
    <Box display='flex' alignItems='center' width='100%'>
      <Typography variant='subtitle2' sx={{ color: '#40548D' }}>
        {label}
      </Typography>
      <Divider
        variant='fullWidth'
        textAlign='left'
        sx={{
          color: '#BDBDBD',
          backgroundColor: '#BDBDBD ',
          flexGrow: 1,
          ml: 1,
        }}
      />
    </Box>
  )
}
