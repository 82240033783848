import { ShareInformationStatus } from '../../enum/rsee/shareInformationStatus'
import { stringToDate } from '../../services/date-service'

export class ShareLink {
  id: string
  secureToken: string
  documentId: string
  projectId: string
  organizationId: string
  sharePageUrl: string
  comment: string
  createdByUser: string
  createdByUserName: string
  usageCounter: number
  maxUsageCount: number
  validityDate: Date | undefined
  status: ShareInformationStatus

  constructor(data: any) {
    this.id = data.id
    this.secureToken = data.secureToken
    this.documentId = data.documentId
    this.projectId = data.projectId
    this.organizationId = data.organizationId
    this.sharePageUrl = data.sharePageUrl
    this.comment = data.comment
    this.createdByUser = data.createdByUser
    this.createdByUserName = data.createdByUserName
    this.usageCounter = data.usageCounter
    this.maxUsageCount = data.maxUsageCount
    this.validityDate = stringToDate(data.validityDate)
    this.status = data.status
  }
}
