export type TestUserFormType = {
  givenName: string
  familyName: string
  email: string
  organizationName: string
  phone: string
  password: string
}

export const SUBMIT_ACTION = 'submit' as const
export type CaptchaActions = keyof TestUserFormType | typeof SUBMIT_ACTION | 'unknown'
