import React, { useMemo, useState } from 'react'
import { Children } from '../../../components/miscellianous/children'
import { CalculationCarbonFlateRateStore } from '../../store/calculation-carbon-flate-rate-store'

export const CalculationCarbonFlateRateContext = React.createContext<CalculationCarbonFlateRateStore>(
  {} as CalculationCarbonFlateRateStore
)

export default function CalculationCarbonFlateRateProvider({ children }: Children): React.JSX.Element {
  const [flatRate1, setFlateRate1] = useState<any>({})
  const [flatRate2, setFlateRate2] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>(undefined)

  const CalculationCarbonFlateRatesStore = useMemo(
    () =>
      new CalculationCarbonFlateRateStore(
        flatRate1,
        setFlateRate1,
        flatRate2,
        setFlateRate2,
        loading,
        setLoading,
        error,
        setError
      ),
    [flatRate1, flatRate2, loading, error]
  )
  return (
    <CalculationCarbonFlateRateContext.Provider value={CalculationCarbonFlateRatesStore}>
      {children}
    </CalculationCarbonFlateRateContext.Provider>
  )
}
