import { Box, Checkbox, FormControlLabel, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import React, { ChangeEvent, Dispatch, SetStateAction, useContext, useEffect, useMemo } from 'react'
import { ReactComponent as ElectricIcon } from '../../../../../assets/icons/electric.svg'
import { ReactComponent as ChantierIcon } from '../../../../../assets/icons/grue.svg'
import { ReactComponent as LandIcon } from '../../../../../assets/icons/land.svg'
import { ReactComponent as WaterIcon } from '../../../../../assets/icons/water.svg'
import { CalculationContext } from '../../../../core/context/calculation/calculation-context'
import { UsageEnum } from '../../../../core/enum/usageEnum'
import { useCalculation } from '../../../../core/hooks/use-calculation'
import WorksiteIc from '../../../../core/dto/calculation/worksite-ic'

function EndAdornment(label: string | React.JSX.Element): React.JSX.Element {
  return <InputAdornment position='end'>{label}</InputAdornment>
}

const rules: Record<
  string,
  {
    disabled: (keyof WorksiteIc)[]
    message1: string
    message2: string
  }
> = {
  '1': {
    disabled: ['eauPotableConsommee', 'eauUseeRejetee', 'gazConsomme', 'electriciteConsommee', 'carburantConsomme'],
    message1: 'Désactivé lors de calculs simplifiés',
    message2: '',
  },
  '2': {
    disabled: [
      'eauPotableConsommee',
      'eauUseeRejetee',
      'gazConsomme',
      'electriciteConsommee',
      'carburantConsomme',
      'moisEteAvecGrue',
      'moisEteSansGrue',
      'moisHiverAvecGrue',
      'moisHiverSansGrue',
      'terresExcavees',
      'terresEvacuees',
      'distanceDechetInerte',
    ],
    message1: 'Désactivé lors de calculs simplifiés',
    message2: 'Désactivé lorsque Maison Individuelle et calcul simplifié',
  },
  '3': {
    disabled: ['moisEteAvecGrue', 'moisEteSansGrue', 'moisHiverAvecGrue', 'moisHiverSansGrue'],
    message1: 'Désactivé lors de calculs détaillés',
    message2: '',
  },
}

type ProjectCarbonCalculatorWorksiteFormProps = {
  disabled: boolean
  selectedVariant: 1 | 2
  variantForm: {
    1: WorksiteIc
    2: WorksiteIc
  }
  setVariantForm: Dispatch<
    SetStateAction<{
      1: WorksiteIc
      2: WorksiteIc
    }>
  >
}

export default function WorksiteForm({
  selectedVariant,
  variantForm,
  setVariantForm,
  disabled,
}: ProjectCarbonCalculatorWorksiteFormProps): React.JSX.Element {
  const { calculation, setCalculation } = useContext(CalculationContext)

  const { updateSameWorksitesV1V2 } = useCalculation()

  const checkboxText = useMemo(() => `Utiliser les valeurs de la BASE`, [])

  const selectedRules = useMemo(() => {
    if (variantForm[selectedVariant].simplified) {
      if (calculation.usage === UsageEnum.MAISONS_INDIVIDUELLES_OU_ACCOLEES) {
        return rules['2']
      }
      return rules['1']
    }
    return rules['3']
  }, [calculation.usage, selectedVariant, variantForm])

  useEffect(() => {
    if (calculation.sameWorksiteV1V2) {
      setVariantForm({ ...variantForm, 2: variantForm[1] })
    }
  }, [calculation.sameWorksiteV1V2, setVariantForm, variantForm])

  function handleChange(e: ChangeEvent<HTMLInputElement>): void {
    if (selectedVariant === 2 && calculation.sameWorksiteV1V2) {
      setCalculation({ ...calculation, sameWorksiteV1V2: false })
    }

    if (selectedVariant === 1 && calculation.sameWorksiteV1V2) {
      setVariantForm({
        ...variantForm,
        2: { ...variantForm[2], [e.target.name]: !Number.isNaN(+e.target.value) ? +e.target.value : 0 },
      })
    }

    setVariantForm({
      ...variantForm,
      [selectedVariant]: {
        ...variantForm[selectedVariant],
        [e.target.name]: !Number.isNaN(+e.target.value) ? +e.target.value : 0,
      },
    })
  }

  const handleChangeCheckBox = (name: string, value: boolean): void => {
    if (selectedVariant === 2 && calculation.sameWorksiteV1V2) {
      setCalculation({ ...calculation, sameWorksiteV1V2: false })
    }

    setVariantForm((prev) => ({
      ...prev,
      [selectedVariant]: {
        ...prev[selectedVariant],
        [name]: value,
      },
    }))
  }

  async function handleUseSameValueVariant(checked: boolean): Promise<void> {
    setCalculation({ ...calculation, sameWorksiteV1V2: checked })
    if (calculation.id) {
      await updateSameWorksitesV1V2(calculation.id, checked)
    }
    if (checked) {
      setVariantForm({ ...variantForm, 2: variantForm[1] })
    }
  }

  return (
    <Box
      mx={{
        xs: 1,
        sm: 10,
        lg: 25,
      }}
      mt={2}>
      <Box display='flex' justifyContent='space-between' component='form'>
        <FormControlLabel
          value='end'
          control={<Checkbox disabled={disabled} />}
          name='simplified'
          checked={!variantForm[selectedVariant].simplified}
          onChange={(_, checked) => handleChangeCheckBox('simplified', !checked)}
          label='Calcul Détaillé'
          labelPlacement='end'
        />

        {selectedVariant === 2 && (
          <FormControlLabel
            value='end'
            checked={calculation.sameWorksiteV1V2 ?? false}
            control={<Checkbox disabled={disabled} />}
            onChange={(_, checked) => handleUseSameValueVariant(checked)}
            name='sameWorksiteV1V2'
            label={checkboxText}
            labelPlacement='start'
          />
        )}
      </Box>
      <Box mb={3} mt={2} display='flex' flexDirection='row' alignItems='center'>
        <ChantierIcon width={50} style={{ marginRight: 20 }} />
        <Typography fontWeight='bold' variant='body1'>
          Durée du chantier
        </Typography>
      </Box>
      <Grid container columnSpacing={3} rowSpacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            sx={{ backgroundColor: 'white' }}
            InputLabelProps={{ shrink: true }}
            fullWidth
            type='number'
            value={variantForm[selectedVariant].moisEteAvecGrue}
            name='moisEteAvecGrue'
            onChange={handleChange}
            label={`mois d'été avec grue`.toUpperCase()}
            required
            id='outlined-required'
            InputProps={{
              endAdornment: EndAdornment('Mois'),
            }}
            disabled={selectedRules.disabled.includes('moisEteAvecGrue') || disabled}
            helperText={
              selectedRules.disabled.includes('moisEteAvecGrue') && (selectedRules.message2 || selectedRules.message1)
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            sx={{ backgroundColor: 'white' }}
            fullWidth
            value={variantForm[selectedVariant].moisEteSansGrue}
            name='moisEteSansGrue'
            type='number'
            onChange={handleChange}
            label={`mois d'été sans grue`.toUpperCase()}
            required
            id='outlined-required'
            InputProps={{
              endAdornment: EndAdornment('Mois'),
            }}
            disabled={selectedRules.disabled.includes('moisEteSansGrue') || disabled}
            helperText={
              selectedRules.disabled.includes('moisEteSansGrue') && (selectedRules.message2 || selectedRules.message1)
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            sx={{ backgroundColor: 'white' }}
            fullWidth
            value={variantForm[selectedVariant].moisHiverAvecGrue}
            name='moisHiverAvecGrue'
            type='number'
            onChange={handleChange}
            label={`mois d'hiver avec grue`.toUpperCase()}
            required
            id='outlined-required'
            InputProps={{
              endAdornment: EndAdornment('Mois'),
            }}
            disabled={selectedRules.disabled.includes('moisHiverAvecGrue') || disabled}
            helperText={
              selectedRules.disabled.includes('moisHiverAvecGrue') && (selectedRules.message2 || selectedRules.message1)
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            sx={{ backgroundColor: 'white' }}
            fullWidth
            type='number'
            value={variantForm[selectedVariant].moisHiverSansGrue}
            name='moisHiverSansGrue'
            onChange={handleChange}
            label={`mois d'hiver sans grue`.toUpperCase()}
            required
            id='outlined-required'
            InputProps={{
              endAdornment: EndAdornment('Mois'),
            }}
            disabled={selectedRules.disabled.includes('moisHiverSansGrue') || disabled}
            helperText={
              selectedRules.disabled.includes('moisHiverSansGrue') && (selectedRules.message2 || selectedRules.message1)
            }
          />
        </Grid>
      </Grid>
      <Box mb={3} mt={2} display='flex' flexDirection='row' alignItems='center'>
        <LandIcon width={50} style={{ marginRight: 20 }} />
        <Typography fontWeight='bold' variant='body1'>
          Gestion des terres
        </Typography>
      </Box>
      <Grid container columnSpacing={3} rowSpacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            sx={{ backgroundColor: 'white' }}
            fullWidth
            type='number'
            value={variantForm[selectedVariant].terresExcavees}
            name='terresExcavees'
            onChange={handleChange}
            label={`Terres excavées`.toUpperCase()}
            required
            id='outlined-required'
            InputProps={{
              endAdornment: EndAdornment(
                <span>
                  m<sup>3</sup>
                </span>
              ),
            }}
            disabled={selectedRules.disabled.includes('terresExcavees') || disabled}
            helperText={
              selectedRules.disabled.includes('terresExcavees') && (selectedRules.message2 || selectedRules.message1)
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            sx={{ backgroundColor: 'white' }}
            fullWidth
            type='number'
            value={variantForm[selectedVariant].terresEvacuees}
            name='terresEvacuees'
            onChange={handleChange}
            label={`Terres évacuées`.toUpperCase()}
            required
            id='outlined-required'
            helperText={
              (selectedRules.disabled.includes('terresEvacuees') && (selectedRules.message2 || selectedRules.message1)) ||
              'X 1,45 tonnes'
            }
            InputProps={{
              endAdornment: EndAdornment('tonnes'),
            }}
            disabled={selectedRules.disabled.includes('terresEvacuees') || disabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            sx={{ backgroundColor: 'white' }}
            fullWidth
            type='number'
            value={variantForm[selectedVariant].distanceDechetInerte}
            name='distanceDechetInerte'
            onChange={handleChange}
            label={`Distance au centre de traitement des déchets inertes`.toUpperCase()}
            required
            id='outlined-required'
            InputProps={{
              endAdornment: EndAdornment('km'),
            }}
            disabled={selectedRules.disabled.includes('distanceDechetInerte') || disabled}
            helperText={
              selectedRules.disabled.includes('distanceDechetInerte') && (selectedRules.message2 || selectedRules.message1)
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            sx={{ backgroundColor: 'white' }}
            fullWidth
            type='number'
            value={variantForm[selectedVariant].terresImportees}
            name='terresImportees'
            onChange={handleChange}
            label={`Terres importées`.toUpperCase()}
            required
            id='outlined-required'
            InputProps={{
              endAdornment: EndAdornment('tonnes'),
            }}
            disabled={selectedRules.disabled.includes('terresImportees') || disabled}
            helperText={selectedRules.disabled.includes('terresImportees') && selectedRules.message1}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            sx={{ backgroundColor: 'white' }}
            fullWidth
            type='number'
            value={variantForm[selectedVariant].distanceImportDesTerres}
            name='distanceImportDesTerres'
            onChange={handleChange}
            label={`Distance depuis le centre d'import des terres`.toUpperCase()}
            required
            id='outlined-required'
            InputProps={{
              endAdornment: EndAdornment('km'),
            }}
            disabled={selectedRules.disabled.includes('distanceImportDesTerres') || disabled}
            helperText={selectedRules.disabled.includes('distanceImportDesTerres') && selectedRules.message1}
          />
        </Grid>
      </Grid>
      <Box mb={3} mt={2} display='flex' flexDirection='row' alignItems='center'>
        <WaterIcon width={50} style={{ marginRight: 20 }} />
        <Typography fontWeight='bold' variant='body1'>
          Utilisation de l'eau
        </Typography>
      </Box>
      <Grid container columnSpacing={3} rowSpacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            sx={{ backgroundColor: 'white' }}
            fullWidth
            type='number'
            value={variantForm[selectedVariant].eauPotableConsommee}
            name='eauPotableConsommee'
            onChange={handleChange}
            label={`Eaux potables consommées par le chantier`.toUpperCase()}
            required
            id='outlined-required'
            InputProps={{
              endAdornment: EndAdornment(
                <span>
                  m<sup>3</sup>
                </span>
              ),
            }}
            disabled={selectedRules.disabled.includes('eauPotableConsommee') || disabled}
            helperText={selectedRules.disabled.includes('eauPotableConsommee') && selectedRules.message1}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            sx={{ backgroundColor: 'white' }}
            fullWidth
            type='number'
            value={variantForm[selectedVariant].eauUseeRejetee}
            name='eauUseeRejetee'
            onChange={handleChange}
            label={`Eaux usées rejetées par le chantier`.toUpperCase()}
            required
            id='outlined-required'
            InputProps={{
              endAdornment: EndAdornment(
                <span>
                  m<sup>3</sup>
                </span>
              ),
            }}
            disabled={selectedRules.disabled.includes('eauUseeRejetee') || disabled}
            helperText={selectedRules.disabled.includes('eauUseeRejetee') && selectedRules.message1}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            sx={{ mb: 4 }}
            value='end'
            control={<Checkbox />}
            disabled={variantForm[selectedVariant].simplified || disabled}
            label='Assainissement des eaux usées domestique ou non domestique'
            labelPlacement='end'
          />
        </Grid>
      </Grid>
      <Box mb={3} mt={2} display='flex' flexDirection='row' alignItems='center'>
        <ElectricIcon width={50} style={{ marginRight: 20 }} />
        <Typography fontWeight='bold' variant='body1'>
          Utilisation des énergies
        </Typography>
      </Box>
      <Grid container columnSpacing={3} rowSpacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            sx={{ backgroundColor: 'white' }}
            fullWidth
            type='number'
            value={variantForm[selectedVariant].electriciteConsommee}
            name='electriciteConsommee'
            onChange={handleChange}
            label={`électricité consommée par le chantier`.toUpperCase()}
            required
            id='outlined-required'
            InputProps={{
              endAdornment: EndAdornment('kWh'),
            }}
            disabled={selectedRules.disabled.includes('electriciteConsommee') || disabled}
            helperText={
              selectedRules.disabled.includes('electriciteConsommee') && (selectedRules.message2 || selectedRules.message1)
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            sx={{ backgroundColor: 'white' }}
            fullWidth
            type='number'
            value={variantForm[selectedVariant].gazConsomme}
            name='gazConsomme'
            onChange={handleChange}
            label={`Gaz consommée par le chantier`.toUpperCase()}
            required
            id='outlined-required'
            InputProps={{
              endAdornment: EndAdornment('kWh'),
            }}
            disabled={selectedRules.disabled.includes('gazConsomme') || disabled}
            helperText={selectedRules.disabled.includes('gazConsomme') && (selectedRules.message2 || selectedRules.message1)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            sx={{ backgroundColor: 'white' }}
            fullWidth
            type='number'
            value={variantForm[selectedVariant].carburantConsomme}
            name='carburantConsomme'
            onChange={handleChange}
            label={`Carburant consommée par le chantier`.toUpperCase()}
            required
            id='outlined-required'
            InputProps={{
              endAdornment: EndAdornment('L'),
            }}
            disabled={selectedRules.disabled.includes('carburantConsomme') || disabled}
            helperText={
              selectedRules.disabled.includes('carburantConsomme') && (selectedRules.message2 || selectedRules.message1)
            }
          />
        </Grid>
      </Grid>
    </Box>
  )
}
