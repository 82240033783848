import { Quantity } from './quantity'

export default class PsetAcv {
  codeAcv: string
  quantity: Quantity = new Quantity()
  description = ''
  occurrence = ''
  errors: string[] = []
  tags: Map<string, number> = new Map() // key: ifc tag, value: number of elements with the same tag
  /**
   * The element id in the IFC model
   */
  elementIds: number[] = []

  constructor(codeAcv: string) {
    this.codeAcv = codeAcv
    this.quantity.number = 1 // A psetAcv is always 1 element
  }
}
