import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react'
import { SearchField } from '../../../components/inputs/search-field'
import { SuccessContext } from '../../../components/layout/success-snackbar'
import { appConstants } from '../../../core/appConstants'
import { ProjectContext } from '../../../core/context/project/project-context'
import { UserContext } from '../../../core/context/user/user-context'
import { SimpleContact } from '../../../core/dto/simple-contact'
import { ProjectInvitation } from '../../../core/dto/user/projectInvitation'
import { RoleEnum, getLabelText } from '../../../core/enum/roleEnum'
import { useOrganization } from '../../../core/hooks/use-organization'
import { useUser } from '../../../core/hooks/use-user'

type IProps = {
  searchCollab: SimpleContact | null
  setSearchCollab: React.Dispatch<React.SetStateAction<SimpleContact | null>>
  refreshTeamInfo(): void
}

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

export function InvitationTeamForm({ setSearchCollab, searchCollab, refreshTeamInfo }: IProps): React.JSX.Element {
  const openSuccessSnackbar: (message: string) => void = useContext(SuccessContext)
  const { project } = useContext(ProjectContext)
  const { user } = useContext(UserContext)
  const { getContactAndCollabs } = useOrganization()
  const { sendInvitationOnProject } = useUser()

  const guestRole = [RoleEnum.ADMINISTRATOR, RoleEnum.MOA, RoleEnum.ARCHITECT, RoleEnum.BE, RoleEnum.CONSULTANT]

  const [contacts, setContacts] = useState<SimpleContact[]>()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [invitationForm, setInvitationForm] = useState<ProjectInvitation>(new ProjectInvitation())
  const [error, setError] = useState<any>({})

  useEffect(() => {
    if (project.id !== undefined && project.id !== '') {
      getContactAndCollabs(project?.id).then((team) => {
        setContacts(team)
      })
    }
  }, [getContactAndCollabs, project.id])

  function updateNewProject(name: string, value: any): void {
    setInvitationForm({
      ...invitationForm,
      [name]: value,
    })
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.id
    setError({ ...error, [name]: undefined })

    updateNewProject(name, value)
  }

  function completeFillContact(event: any, value: SimpleContact | null): void {
    let givenName: string
    let organizationName: string
    let familyName: string
    let mail: string

    if (value !== null) {
      givenName = value?.firstName ?? ''
      organizationName = value?.userOrganizationName ?? ''
      familyName = value?.lastName ?? ''
      mail = value?.email ?? ''
    } else {
      givenName = ''
      organizationName = ''
      familyName = ''
      mail = ''
    }

    setSearchCollab(value)

    setInvitationForm({
      ...invitationForm,
      userOrganizationName: organizationName,
      firstName: givenName,
      lastName: familyName,
      email: mail,
    })
  }

  function addRole(event: any, value: RoleEnum[]): void {
    setError({ ...error, roles: undefined })
    setInvitationForm({ ...invitationForm, roles: value })
  }

  function validate(): boolean {
    const newError: Record<string, any> = {}
    let isValid = true

    if (!invitationForm) {
      return false
    }

    if (!invitationForm.roles || invitationForm.roles.length === 0) {
      newError.roles = 'Veuillez remplir ce champ'
      isValid = false
    }

    if (invitationForm.firstName === '') {
      newError.firstName = 'Veuillez remplir ce champ'
      isValid = false
    }

    if (invitationForm.lastName === '') {
      newError.lastName = 'Veuillez remplir ce champ'
      isValid = false
    }

    if (invitationForm.userOrganizationName === '') {
      newError.userOrganizationName = 'Veuillez remplir ce champ'
      isValid = false
    }

    if (invitationForm.email === '') {
      newError.email = 'Veuillez remplir ce champ'
      isValid = false
    } else if (!appConstants.regex.email.test(invitationForm.email)) {
      newError.email = 'Email invalide'
      isValid = false
    } else if (user && invitationForm.email === user.email) {
      newError.email = "Vous ne pouvez pas envoyer d'invitation à vous même"
      isValid = false
    }

    setError(newError)
    return isValid
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault()
    if (!validate()) {
      return
    }
    if (!project?.id) {
      return
    }
    if (!isSubmitting) {
      setIsSubmitting(true)
      invitationForm.projectId = project.id
      sendInvitationOnProject(invitationForm)
        .then(() => {
          refreshTeamInfo()
          openSuccessSnackbar('Invitation envoyé !')
        })
        .finally(() => {
          setInvitationForm(new ProjectInvitation())
          setSearchCollab(null)
          setIsSubmitting(false)
        })
    }
  }

  function getOptionLabel(option: SimpleContact | null): string {
    return option ? `${option?.firstName} ${option?.lastName}` : ''
  }

  return (
    <Box component='form' onSubmit={handleSubmit}>
      <Typography component='span' sx={{ fontWeight: 'bold', fontSize: '20px', display: 'flex', pt: 2, pb: 3 }}>
        INVITEZ LES ACTEURS DE CE PROJET
      </Typography>
      <Grid container rowSpacing={3}>
        <Grid item md={12}>
          <SearchField
            label='Rechercher dans les contacts de mon organisation'
            value={searchCollab}
            options={contacts}
            onChange={completeFillContact}
            getOptionLabel={getOptionLabel}
          />
        </Grid>
        <Grid item md={12}>
          <Autocomplete
            multiple
            disablePortal
            id='comboGuestRole'
            options={guestRole}
            value={invitationForm.roles}
            selectOnFocus
            onChange={addRole}
            getOptionLabel={(value: RoleEnum) => getLabelText(value)}
            isOptionEqualToValue={(option, value) => option === value}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                {getLabelText(option)}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} error={!!error.roles} label="CHOISIR LE(S) RÔLE(S) DE L'INVITÉ" />
            )}
          />
          {!!error.roles && <FormHelperText error>Il faut au moins un rôle</FormHelperText>}
        </Grid>
        <Grid item md={12}>
          <TextField
            id='userOrganizationName'
            value={invitationForm.userOrganizationName}
            label='ORGANISATION'
            onChange={handleChange}
            fullWidth
            error={!!error.userOrganizationName}
            helperText={error.userOrganizationName}
            inputProps={{ maxLength: 255 }}
          />
        </Grid>

        <Grid item md={12}>
          <Grid container rowSpacing={3} columnSpacing={5}>
            <Grid item md={6}>
              <TextField
                id='lastName'
                value={invitationForm.lastName}
                label='NOM'
                onChange={handleChange}
                fullWidth
                error={!!error.lastName}
                helperText={error.lastName}
                inputProps={{ maxLength: 255 }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                id='firstName'
                value={invitationForm.firstName}
                label='PRÉNOM'
                onChange={handleChange}
                fullWidth
                error={!!error.firstName}
                helperText={error.firstName}
                inputProps={{ maxLength: 255 }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <TextField
            id='email'
            value={invitationForm.email}
            label='ADRESSE E-MAIL'
            onChange={handleChange}
            fullWidth
            error={!!error.email}
            helperText={error.email}
            inputProps={{ maxLength: 255 }}
          />
        </Grid>

        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 3,
          }}>
          <Grid item md={4}>
            {isSubmitting ? (
              <CircularProgress />
            ) : (
              <Button fullWidth type='submit' variant='contained' color='success' sx={{ color: 'white' }}>
                ENVOYER
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
