import { Box } from '@mui/material'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { AdminOrganizationContext } from '../../../core/context/organization/admin-organization-context'
import { Collaborator } from '../../../core/dto/user/collaborator'
import { useOrganization } from '../../../core/hooks/use-organization'
import { AdminOrganizationForm } from './admin-organization-form'

export default function OrganizationPage(): React.JSX.Element {
  const [collaboratorList, setcollaboratorList] = useState<Collaborator[]>([])
  const { getCollaboratorsForOrganization } = useOrganization()
  const { organization } = useContext(AdminOrganizationContext)

  const refreshCollaborators: () => void = useCallback(() => {
    if (organization?.id) {
      getCollaboratorsForOrganization(organization?.id).then((newCollaboratorList: Collaborator[]) => {
        setcollaboratorList(newCollaboratorList)
      })
    }
  }, [getCollaboratorsForOrganization, organization?.id])

  useEffect(() => {
    refreshCollaborators()
  }, [refreshCollaborators])

  return (
    <Box>
      <AdminOrganizationForm />
    </Box>
  )
}
